<form [formGroup]="formGroup">
    <div class="flex flex-col gap-4">
        <div class="flex gap-4">
            <div class="flex-flex-col w-[30%]">
                <p class="text-s text-on-app-light">{{'ArticleStocks.Edit.BaseData.Stock' | transloco}}
                </p>
                <p class="text-xl">
                    @if(isUpdatingStock()){
                        <mat-spinner [diameter]="32" />
                    }@else {
                        {{ stockValue || item().stock }}
                    }
                </p>
            </div>
            <div class="flex gap-4 mt-4 w-[70%] flex-row-reverse">
                <arc-button actionButtonKey="StockEntryTransactionContextAction`1" actionButtonTitleKey="General.Actions.BookIn"
                    [currentData]="item()" (clicked)="updateStock()" type="flat" [isLoading]="isUpdatingStock()">
                </arc-button>
                <arc-button actionButtonKey="StockExitTransactionContextAction`1" actionButtonTitleKey="General.Actions.BookOut"
                    [currentData]="item()" (clicked)="updateStock()" type="flat" [isLoading]="isUpdatingStock()">
                </arc-button>
            </div>
        </div>
        <div class="mt-10 grid grid-cols-2 smd:grid-cols-1 gap-4">
            <arc-number-input label="ArticleStocks.Edit.BaseData.MinStock" [formControl]="formGroup.controls.minStock"
                [unit]="item().unit">
            </arc-number-input>
            <arc-number-input label="ArticleStocks.Edit.BaseData.TargetStock" [unit]="item().unit"
                [formControl]="formGroup.controls.targetStock">
            </arc-number-input>
        </div>
    </div>
</form>
