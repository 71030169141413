<div class="flex gap-2 w-full">
    <ngx-colors ngx-colors-trigger [ngModel]="control.value" class="mt-2" (ngModelChange)="colorChanged($event)"
        [disabled]="control.disabled" [formats]="availableFormats()" [colorPickerControls]="colorPickerControls()"
        [acceptLabel]="'Components.ColorPicker.AcceptLabel' | transloco" [cancelLabel]="'Components.ColorPicker.CancelLabel' | transloco">
    </ngx-colors>
    <mat-form-field class="grow" [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
        [arcErrorTooltip]="control" [arcPortalTooltip]="labelKey || label" [isTranslated]="!!label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
        @if (!!label || !!labelKey) {
            <mat-label>{{ label || (labelKey | transloco) }}</mat-label>
        }
        <input matInput [formControl]="castedControl" />
        @if (!isAllowed) {
            <div matSuffix class="flex gap-2 items-center">
                <arc-no-permission-lock *ngIf="!isAllowed" [missingPermission]="permission" />
            </div>
        }
    </mat-form-field>
</div>
