@for (btn of visibleButtons; track btn.key; let i = $index) {
    <button mat-icon-button [attr.color]="btn.color || 'secondary'" [color]="btn.color || 'secondary'"
        [disabled]="!btn.isEnabled || btn.isLoading || isLoading(btn.key, data().id)"
        [matTooltip]="btn.isTextTranslated ? btn.text : (btn.text | transloco)" matTooltipPosition="above"
        (click)="onClick($event, btn, data())">
        @if (!isLoading(btn.key, data().id)) {
            <i class="material-icons">{{ btn.icon }}</i>
        } @else {
            <mat-spinner diameter="22"></mat-spinner>
        }
    </button>

    @if (menuButtons.length > 0) {
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            @for (btn of menuButtons; track btn.key) {
                @if (!_actionButtonsService.isHidden(btn.key, data())) {
                    <button mat-menu-item [disabled]="!btn.isEnabled || btn.isLoading || isLoading(btn.key, data().id)"
                        (click)="onClick($event, btn, data())">
                        <div class="flex items-center">
                            @if (!isLoading(btn.key, data().id)) {
                                <mat-icon>{{ btn.icon }}</mat-icon>
                            } @else {
                                <mat-spinner class="mr-5" diameter="22"></mat-spinner>
                            }
                            <span>{{ btn.isTextTranslated ? btn.text : (btn.text | transloco) }}</span>
                        </div>
                    </button>
                }
            }
        </mat-menu>
    }
}
