<div class="h-full w-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToTransactionPaymentsPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="flex items-center justify-center text-xl">
        {{ 'General.Total' | transloco }} {{ total() | arcCurrency }}
    </div>
    <br/>
    <arc-payments-breakdown [items]="entityModel().usedPaymentTypes" [shouldDisplayTotal]="true" />
</div>
