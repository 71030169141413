import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { BaseComponent } from '../../../../../components/abstractions/base.component';
import { VoucherTypesStore } from '../../../../services/stores/voucher-types.store';
import { SearchRequestModel } from '../../../../models/requests/search-request.model';
import { ColumnFilterModel } from '../../../../../core/models/column-filter.model';
import { EditableTableConfigModel } from '../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { VoucherCreateModel } from '../../../../models/voucher-create.model';
import { QuickSearchColumnModel } from '../../../../../components/dynamic-table/models/column-types/quick-search-column.model';
import { ComplexDataTypesEnum } from '../../../../../core/models/complex-data-types.enum';
import { VouchersStore } from '../../../../services/stores/vouchers.store';
import { SelectOptionModel } from '../../../../../core/models/select-option.model';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { CustomValidators } from '../../../../../core/utils/custom-validators';
import { StringColumnModel } from '../../../../../components/dynamic-table/models/column-types/string-column.model';

@Component({
    selector: 'arc-voucher-create-dialog',
    templateUrl: './voucher-create-dialog.component.html',
    styleUrls: ['./voucher-create-dialog.component.scss']
})
export class VoucherCreateDialogComponent extends BaseComponent implements OnInit {
    formGroup = new FormGroup({
        type: new FormControl(undefined, Validators.required),
        quantity: new FormControl(1, [Validators.required, CustomValidators.number({ min: 1, max: 100, isInteger: true })])
    });
    vouchersTableConfig: EditableTableConfigModel<VoucherCreateModel>;
    types: SelectOptionModel<number>[] = [];
    isUpdatingRows = false;
    isSaving = false;
    get isFormSetValid(): boolean {
        return this.formGroup.valid && this.vouchersFormControl.value.length > 0 && this.vouchersFormControl.valid;
    }

    readonly vouchersFormControl = new ArcFormControl<VoucherCreateModel[]>([]);

    private readonly voucherTypesStore = inject(VoucherTypesStore);
    private readonly vouchersStore = inject(VouchersStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        super();
        this.vouchersTableConfig = new EditableTableConfigModel<VoucherCreateModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    voucherId: new FormControl(undefined, Validators.required),
                    personId: new FormControl(undefined),
                    voucherTypeId: new FormControl(this.formGroup.value.type!)
                }),
            shouldHideAddButton: true,
            showPaging: true,
            rowHeightPx: 55,
            columns: [
                new StringColumnModel({
                    propertyName: 'voucherId',
                    isEditable: true,
                    widthPixels: 350,
                    columnTitleKey: 'Vouchers.Create.VoucherNumber'
                }),
                new QuickSearchColumnModel({
                    propertyName: 'personId',
                    isEditable: true,
                    type: ComplexDataTypesEnum.Customer,
                    widthPixels: 350,
                    columnTitleKey: 'Vouchers.Create.IssuedFor'
                })
            ]
        });
    }

    ngOnInit(): void {
        this.voucherTypesStore.search(
            new SearchRequestModel({
                filters: [
                    new ColumnFilterModel({
                        column: 'VoucherType',
                        values: ['1'] // Only discount types.
                    })
                ]
            })
        ).subscribe(resp => {
            this.types = resp.value?.records.map(r => ({ value: r.id, label: r.title })) || [];
        });
    }

    saveRecords(): void {
        this.isSaving = true;
        this.vouchersStore.addInBatch(this.vouchersFormControl.value)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(resp => {
                if (resp.value) {
                    this.matDialogRef.close(true);
                } else {
                    this.toasterService.showError('General.Alert.UnexpectedError');
                }
            });
    }

    updateRows(): void {
        this.isUpdatingRows = true;
        const data = this.vouchersFormControl.value ?? [];
        const currentAmount = data.length;
        const desiredAmount = this.formGroup.value.quantity || 0;

        if (currentAmount === desiredAmount) {
            this.isUpdatingRows = false;
            return;
        }

        if (currentAmount > desiredAmount) {
            data.splice(desiredAmount);
            this.vouchersFormControl.setValue(data);
            this.isUpdatingRows = false;
        } else {
            const qty = desiredAmount - currentAmount;
            this.vouchersStore.generateIds(this.formGroup.value.type!, qty).subscribe(resp => {
                resp.value?.forEach(vId =>
                    data.push({
                        id: undefined,
                        voucherId: vId,
                        personId: undefined,
                        voucherTypeId: this.formGroup.value.type!
                    })
                );
                this.vouchersFormControl.setValue(data);
                this.isUpdatingRows = false;
            });
        }
    }
}
