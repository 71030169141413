import { Component, OnInit, inject, signal } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { SimpleTransactionModel } from '../../../../../models/simple-transaction.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';

@Component({
    selector: 'arc-customer-transactions',
    templateUrl: './customer-transactions.detail-widget.html',
    styleUrls: ['./customer-transactions.detail-widget.scss']
})
export class CustomerTransactionsDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    isLoading = signal(true);
    transactions = signal<SimpleTransactionModel[]>([]);

    private readonly customersStore = inject(CustomersStore);

    ngOnInit(): void {
        const transactions = this.entityModel().transactions;
        this.transactions.set(transactions);

        if (transactions.length < 5) {
            this.isLoading.set(false);
            return;
        }

        this.customersStore.getTransactions(this.entityModel().id).subscribe(r => {
            this.transactions.set(r.value || []);
            this.isLoading.set(false);
        });
    }
}
