import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CurrencyModel } from '../../../../../models/currency.model';
import { ArticleTaxModel } from '../../../../../models/article-tax.model';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';

@Component({
    selector: 'arc-settings-finance-taxes',
    templateUrl: './settings-finance-tax-rates.component.html',
    styleUrl: './settings-finance-tax-rates.component.scss'
})
export class SettingsFinanceTaxRatesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        articleTaxes: new ArcFormControl<ArticleTaxModel[]>([])
    });
    articleTaxesTableConfig: EditableTableConfigModel<CurrencyModel>;

    constructor() {
        super();
        this.articleTaxesTableConfig = new EditableTableConfigModel<CurrencyModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    title: new ArcFormControl('', Validators.required),
                    taxRate: new ArcFormControl(0)
                }),
            canDeleteRow: () => (this.formGroup.value.articleTaxes ? this.formGroup.value.articleTaxes.length > 1 : false),
            rowHeightPx: 63,
            columns: [
                new StringColumnModel({
                    propertyName: 'title',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Finance.Taxes.Title'
                }),
                new NumberColumnModel({
                    propertyName: 'taxRate',
                    isEditable: true,
                    unit: UnitsEnum.Percent,
                    columnTitleKey: 'Settings.Edit.Finance.Taxes.TaxRate'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.articleTaxesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { articleTaxes: this.formGroup.value.articleTaxes };
    }
}
