<form [formGroup]="formGroup" class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4">
    <arc-input class="col-span-3 ssm:col-span-6" labelKey="Customers.Edit.BaseData.CustomerNumber"
        [control]="formGroup.controls.personNumber"></arc-input>
    <div class="col-span-9 ssm:hidden"></div>
    <arc-checkbox class="col-span-2 ssm:col-span-6" labelKey="Customers.Edit.BaseData.IsCompany"
        [control]="formGroup.controls.isCompany"></arc-checkbox>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Customers.Edit.BaseData.CompanyName"
        [control]="formGroup.controls.companyName"></arc-input>
    <arc-general-data-select class="col-span-4 ssm:col-span-12" label="Customers.Edit.BaseData.CompanyLegalForm"
        [generalDataType]="GeneralDataTypeEnum.LegalForms" [formControl]="formGroup.controls.legalFormId"></arc-general-data-select>
    <arc-general-data-select class="col-span-6" label="Customers.Edit.BaseData.Salutation"
        [generalDataType]="GeneralDataTypeEnum.Salutations" [formControl]="formGroup.controls.salutationId"></arc-general-data-select>
    <arc-general-data-select class="col-span-6" label="Customers.Edit.BaseData.SalutationTitle"
        [generalDataType]="GeneralDataTypeEnum.SalutationTitles"
        [formControl]="formGroup.controls.salutationTitleId"></arc-general-data-select>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Customers.Edit.BaseData.FirstName"
        [control]="formGroup.controls.firstname"></arc-input>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Customers.Edit.BaseData.Name" [control]="formGroup.controls.name"></arc-input>

    <arc-address-picker class="col-span-12 mt-4" [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber" [streetSupplementControl]="formGroup.controls.streetSupplement"
        [isPoBoxControl]="formGroup.controls.isPoBox" [poBoxControl]="formGroup.controls.poBox" [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.city" [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>

    <arc-datepicker class="col-span-4 ssm:col-span-7 mt-4" labelKey="Customers.Edit.BaseData.BirthDate"
        [control]="formGroup.controls.birthdate"></arc-datepicker>
</form>
