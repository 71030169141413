import { RouteModel } from '../../core/models/route.model';
import { PermissionsEnum } from '../models/enums/permissions.enum';
import { BonusRunsComponent } from '../views/customers/bonus-runs/bonus-runs.component';
import { CustomersComponent } from '../views/customers/customers/customers.component';
import { InvoicesComponent } from '../views/customers/invoices/invoices.component';
import { ReservationsComponent } from '../views/customers/reservations/reservations.component';
import { CustomerProfilesComponent } from '../views/customers/customer-profiles/customer-profiles.component';

export const customerRoutes: RouteModel[] = [
    {
        path: 'customers',
        component: CustomersComponent,
        data: { subMenu: 'customers', subMenuIndex: 0, title: 'Customers.Customers', requiredPermission: PermissionsEnum.Customers }
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
        data: {
            subMenu: 'customers',
            subMenuIndex: 1,
            title: 'Customers.Invoices',
            requiredPermission: PermissionsEnum.Invoices,
            startingQueryParams: { ShowPaidInvoices: 'false' }
        }
    },
    {
        path: 'reservations',
        component: ReservationsComponent,
        data: {
            subMenu: 'customers',
            subMenuIndex: 2,
            title: 'Customers.Reservations',
            requiredPermission: PermissionsEnum.Reservations,
            startingQueryParams: { IsCompleted: 'false' }
        }
    },
    {
        path: 'bonus-runs',
        component: BonusRunsComponent,
        data: {
            subMenu: 'customers',
            subMenuIndex: 3,
            title: 'Customers.BonusRuns',
            requiredPermission: PermissionsEnum.BonusRuns
        }
    },
    {
        path: 'communicationRuns',
        redirectTo: 'customers', // TODO create component
        data: {
            subMenu: 'customers',
            subMenuIndex: 4,
            title: 'Customers.CommunicationRuns',
            requiredPermission: PermissionsEnum.CommunicationRuns,
            isDisabled: true
        }
    },
    {
        path: 'customer-profiles',
        component: CustomerProfilesComponent,
        data: {
            subMenu: 'customers',
            subMenuIndex: 5,
            title: 'Settings.CustomerProfiles',
            requiredPermission: PermissionsEnum.CustomerProfiles
        }
    }
];
