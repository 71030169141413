<h1 mat-dialog-title>
    {{ 'Components.AccountingRecordsMultiCreateDialog.Title' | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto !flex flex-col gap-4">
    @if (isLoading()) {
        <div class=" w-full h-[550px] grid place-items-center">
            <mat-spinner [diameter]="50" />
        </div>
    }
    @else {
        <p>{{ 'Components.AccountingRecordsMultiCreateDialog.ComponentDescription' | transloco }}</p>
        <div class="grid grid-cols-[min-content_repeat(3,_auto)] gap-4">
            <!-- Table headers -->
            <div></div>
            <p class="text-sm">{{ 'Components.AccountingRecordsMultiCreateDialog.Value' | transloco }}</p>
            <p class="text-sm">{{ 'Components.AccountingRecordsMultiCreateDialog.Filter' | transloco }}</p>
            <p class="text-sm">{{ 'Components.AccountingRecordsMultiCreateDialog.AdditionalValues' | transloco }}</p>
            <!-- Values -->
            @if (currentTax(); as currentTax){
                <mat-checkbox class="!w-min" [(ngModel)]="taxesSelected"></mat-checkbox>
                <p class="font-bold">{{ 'Components.AccountingRecordsMultiCreateDialog.VAT' | transloco }}</p>
                <p>{{ (+currentTax.value! * 1000) / 10 }}%</p>
                <p>{{ otherTaxesString() }}</p>
            }
            @if (currentBranch(); as currentBranch) {
                <mat-checkbox class="!w-min" [(ngModel)]="branchesSelected"></mat-checkbox>
                <p class="font-bold">{{ 'Components.AccountingRecordsMultiCreateDialog.Branch' | transloco }}</p>
                <p>{{ currentBranch.value! }}</p>
                <p>{{ otherBranchesString() }}</p>
            }
            @if (currentArticleGroup(); as currentArticleGroup){
                <mat-checkbox class="!w-min" [(ngModel)]="articleGroupsSelected"></mat-checkbox>
                <p class="font-bold">{{ 'Components.AccountingRecordsMultiCreateDialog.ArticleGroup' | transloco }}</p>
                <p>{{ currentArticleGroup.value }}</p>
                <p>{{ otherArticleGroupsString() }}</p>
            }
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isDisabled]="creatingCount() < 1" [isLoading]="isCreating()" (clicked)="create()">
        {{ 'Components.AccountingRecordsMultiCreateDialog.AddX' | transloco:{ amount: creatingCount()} }}
    </arc-button>
</mat-dialog-actions>
