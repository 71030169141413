import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { AccountingExportRecordListModel } from '../../../models/responses/accounting-export-record-list.model';
import { AccountingExportRecordModel } from '../../../models/accounting-export-record.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { AccountingExportRecordsStore } from '../../../services/stores/accounting-export-records.store';
import { TranslationService } from '../../../../core/services/translation.service';
import { AccountingExportRecordsEditDataComponent } from './accounting-export-records-edit-items/accounting-export-records-edit-data/accounting-export-records-edit-data.component';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';
import { AccountingExportRecordsEditFilterComponent } from './accounting-export-records-edit-items/accounting-export-records-edit-filter/accounting-export-records-edit-filter.component';
import { AccountingExportRecordsEditAccountingRecordComponent } from './accounting-export-records-edit-items/accounting-export-records-edit-accounting-record/accounting-export-records-edit-accounting-record.component';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { AccountingRecordsMultiCreateDialogComponent } from './accounting-records-multi-create-dialog/accounting-records-multi-create-dialog.component';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-accounting-export-records',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './accounting-export-records.component.scss'
})
export class AccountingExportRecordsComponent extends PageListViewComponent<AccountingExportRecordListModel, AccountingExportRecordModel> {
    private readonly accountingRecordsStore = inject(AccountingExportRecordsStore);
    private readonly translationService = inject(TranslationService);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super('ExtAccountingExport');
        this.config = new TableListViewConfigModel<AccountingExportRecordListModel, AccountingExportRecordModel>({
            entityName: 'ExtAccountingExport',
            availableColumns: {
                bookingText: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.BookingText',
                    propertyName: 'bookingText',
                    widthPixels: 200
                }),
                isPayment: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.Type',
                    propertyName: 'isPayment',
                    widthPixels: 200,
                    customFormatter: (record, value) => !!value
                        ? this.translationService.getText('ExtAccountingExport.List.Payment')
                        : this.translationService.getText('ExtAccountingExport.List.BookingArticle')
                }),
                debitAccount: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.DebitAccount',
                    propertyName: 'debitAccount',
                    widthPixels: 150
                }),
                debitCostCenter: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.DebitAccountCostCenter',
                    propertyName: 'debitCostCenter',
                    widthPixels: 150,
                    customFormatter: (record, value) => value || '-'
                }),
                creditAccount: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.CreditAccount',
                    propertyName: 'creditAccount',
                    widthPixels: 150
                }),
                creditCostCenter: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.CreditAccountCostCenter',
                    propertyName: 'creditCostCenter',
                    widthPixels: 150,
                    customFormatter: (record, value) => value || '-'
                }),
                taxCode: new StringColumnModel({
                    columnTitleKey: 'ExtAccountingExport.List.TaxCode',
                    propertyName: 'taxCode',
                    widthPixels: 150,
                    customFormatter: (record, value) => value || '-'
                })
            },
            defaultColumnOrder: [
                'bookingText',
                'isPayment',
                'debitAccount',
                'debitCostCenter',
                'creditAccount',
                'creditCostCenter',
                'taxCode'
            ],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            defaultSort: new ColumnSortModel({
                column: 'bookingText',
                direction: SortDirectionEnum.Asc
            }),
            customActionButtons: [
                new ActionButtonModel({
                    clickFn: (btn: ActionButtonModel, selectedItem: AccountingExportRecordListModel) => {
                        const dialogRef = this.matDialog.open(AccountingRecordsMultiCreateDialogComponent, {
                            data: { item: selectedItem },
                            disableClose: true,
                            maxHeight: '80svh',
                            width: '900px',
                            maxWidth: '98vw'
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                this.listView.refresh();
                            }
                        });
                    },
                    text: 'Components.AccountingRecordsMultiCreateDialog.Title',
                    icon: 'library_add',
                    min: 1,
                    max: 1,
                    conditionField: 'hasFilters',
                    isEnabled: true
                })
            ],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'ExtAccountingExport.Edit.Data.ComponentTitle',
                        component: AccountingExportRecordsEditDataComponent,
                        requiredPermission: PermissionsEnum.ExtAccountingExport,
                        requiredPermissionType: PermissionTypeEnum.Update
                    },
                    {
                        titleKey: 'ExtAccountingExport.Edit.Filter.ComponentTitle',
                        component: AccountingExportRecordsEditFilterComponent,
                        requiredPermission: PermissionsEnum.ExtAccountingExport,
                        requiredPermissionType: PermissionTypeEnum.Update
                    },
                    {
                        titleKey: 'ExtAccountingExport.Edit.AccountingRecord.ComponentTitle',
                        component: AccountingExportRecordsEditAccountingRecordComponent,
                        requiredPermission: PermissionsEnum.ExtAccountingExport,
                        requiredPermissionType: PermissionTypeEnum.Update
                    }
                ]
            },
            store: this.accountingRecordsStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasDuplicate: true })
        });
    }
}
