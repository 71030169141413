import { Injectable } from '@angular/core';

import { ExtensionModel } from '../../models/extension.model';
import { ExtensionListModel } from '../../models/responses/extension-list-model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';

@Injectable({
    providedIn: 'root'
})
export class ExtensionsStore extends BaseCrudStore<ExtensionModel, ExtensionListModel> {
    showTemplates = false;

    constructor() {
        super(new StoreConfig({ baseController: 'extensions' }));
    }

    protected override getUrl(action: string): string {
        let requestUrl = super.getUrl(action);
        if (action === 'search' && this.showTemplates) {
            requestUrl += '?showTemplates=1';
        }

        return requestUrl;
    }
}
