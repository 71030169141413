import { Component, computed, inject, input } from '@angular/core';

import { TicketMessageModel } from '../../../../app/models/ticket-message.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { BlobModel } from '../../../../app/models/blob.model';
import { SecuritySessionStorage } from '../../../../core/services/storages/security.session-storage';
import { TranslationService } from '../../../../core/services/translation.service';

@Component({
    selector: 'arc-ticket-message-item',
    templateUrl: './ticket-message-item.component.html',
    styleUrl: './ticket-message-item.component.scss'
})
export class TicketMessageItemComponent {
    message = input.required<TicketMessageModel>();
    createdBy = computed(() => {
        const myEmail = this.securitySessionStorage.getUserInfo()?.email;
        return this.message().isMyResponse && this.message().createdByEmail === myEmail
            ? this.translationService.getText('Tickets.View.Me')
            : this.message().createdByEmail;

    });

    private readonly securitySessionStorage = inject(SecuritySessionStorage);
    private readonly translationService = inject(TranslationService);

    downloadFile(file: BlobModel): void {
        const blob = new Blob([new Uint8Array(file.blobData)], { type: file.fileMimeType });
        Utils.saveFile(blob, file.fileName ?? 'file.bin');
    }
}
