import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { VoucherTypeListModel } from '../../../models/responses/voucher-type-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { VoucherTypeModel } from '../../../models/voucher-type.model';
import { VoucherTypeCardComponent } from './voucher-type-card/voucher-type-card.component';
import { VoucherTypesStore } from '../../../services/stores/voucher-types.store';
import { TranslationService } from '../../../../core/services/translation.service';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { VoucherTypeEditBaseDataComponent } from './voucher-type-edit-items/voucher-type-edit-base-data/voucher-type-edit-base-data.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-vouchers-types',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./vouchers-types.component.scss']
})
export class VouchersTypesComponent extends PageListViewComponent<VoucherTypeListModel> {
    private readonly voucherTypesStore = inject(VoucherTypesStore);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super('VoucherTypes');
        this.config = new CardListViewConfigModel<VoucherTypeModel, VoucherTypeListModel>({
            entityName: 'VoucherTypes',
            store: this.voucherTypesStore,
            cardComponent: VoucherTypeCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({ column: 'IsActive', direction: SortDirectionEnum.Desc }),
            cardTitle: currentItem => currentItem.title,
            cardSubtitle: currentItem => {
                const key = 'VoucherTypes.List.' + (currentItem.isDiscountVoucher ? 'DiscountVoucher' : 'ValueVoucher');
                return this.translationService.getText(key);
            },
            cardTitleClasses: currentItem =>
                currentItem.isActive
                    ? (currentItem.isDiscountVoucher ? ['bg-info', 'text-on-info'] : ['bg-primary', 'text-on-primary'])
                    : ['bg-element-disabled', 'text-on-element-disabled'],
            cardBackgroundColor: currentItem => (!currentItem.isActive ? 'var(--element-selected)' : ''),
            editSidebarConfig: {
                editComponents: [{ titleKey: 'VoucherTypes.Edit.BaseData.ComponentTitle', component: VoucherTypeEditBaseDataComponent }],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'VoucherTypes.List.ShowVouchers',
                clickFn: data => this._router.navigate(['vouchers'], { queryParams: { VoucherTypeId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService.updateIsHidden(
            ActionButtonsService.deleteButtonKey,
            (btn, data) => !!data && data.vouchersQuantity > 0
        );
    }
}
