<form class="flex flex-col gap-4" [formGroup]="form" (submit)="submit()">
    <arc-input [control]="form.controls.input" label="Input"></arc-input>

    <arc-select [formControl]="form.controls.select" label="Select" [isLabelTranslated]="true" [options]="selectOptions"></arc-select>

    <arc-datepicker [control]="form.controls.datepicker" label="Date"></arc-datepicker>

    <arc-date-range-picker [control]="form.controls.dateRangeStart" [controlEnd]="form.controls.dateRangeEnd"
        label="Date Range"></arc-date-range-picker>

    <arc-checkbox [control]="form.controls.checkbox" label="Checkbox"></arc-checkbox>

    <arc-quick-search [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig" [formControl]="form.controls.article" label="Artikel"
        [isLabelTranslated]="true"></arc-quick-search>

    <button mat-flat-button type="button" (click)="openCustomerMultiSelect()">Select customers</button>
    <div class="flex flex-wrap gap-2">
        <div *ngFor="let customer of selectedCustomers" class="bg-gray-400 px-3 py-2 rounded-2xl">
            {{ customer.displayName }}
        </div>
    </div>

    <arc-general-data-select [formControl]="form.controls.country" [generalDataType]="GeneralDataTypeEnum.Countries"
        label="Land" [isLabelTranslated]="true">
    </arc-general-data-select>

    <arc-file-upload
        [formControl]="form.controls.file"
        fileTypes=".xlsx,.csv"
        [isLabelTranslated]="true"
        placeholder=".xlsx oder .csv (UTF-8)"
        [isPlaceholderTranslated]="true"
        class="h-[200px]">
    </arc-file-upload>

    <button mat-flat-button color="primary" type="submit">Submit</button>
    <button mat-flat-button type="button" (click)="toggleDisabled()">
        {{ form.disabled ? 'Enable' : 'Disable' }}
    </button>

    <p>Value</p>
    <pre class="bg-gray-600 text-white text-[12px] px-4 py-2"><code>{{ formValue }}</code></pre>
</form>