import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';

import { BaseComponent } from '../../../abstractions/base.component';
import { HelpArticleModel } from '../../../../app/models/responses/help-article.model';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';

@Component({
    selector: 'arc-selected-article',
    templateUrl: './selected-help-article.component.html',
    styleUrl: './selected-help-article.component.scss'
})
export class SelectedHelpArticleComponent extends BaseComponent implements OnInit, OnDestroy {
    selectedArticle = input.required<HelpArticleModel>();
    showRelatedArticles = input(true);

    private readonly headerService = inject(SidebarHeaderService);

    ngOnInit(): void {
        this.headerService.hasBackButton.set(true);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.headerService.hasBackButton.set(false);
    }
}
