import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { CustomerGroupModel } from '../../models/customer-group.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupsStore extends BaseCrudStore<CustomerGroupModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'customer-groups' }));
    }
}
