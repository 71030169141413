import { Component, computed } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { PointOfSaleListModel } from '../../../../../models/responses/pos-list.model';
import { PointOfSaleModel } from '../../../../../models/point-of-sale.model';

@Component({
    selector: 'arc-pos-transactions',
    templateUrl: './pos-transactions.component.html',
    styleUrls: ['./pos-transactions.component.scss']
})
export class PosTransactionsComponent extends BaseDetailWidget<PointOfSaleListModel, PointOfSaleModel> {
    transactions = computed(() => this.entityModel().transactions);
}
