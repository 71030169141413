import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { InventoryModel } from '../../../../../models/inventory.model';
import { InventoryUpdateModel } from '../../../../../models/requests/inventory-update.model';
import { InventoryArticleModel } from '../../../../../models/inventory-article.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { RelatedEntityUpdateModel } from '../../../../../models/requests/related-entity-update.model';
import { RelatedEntityRequestModel } from '../../../../../models/requests/tag-articles-request.model';
import { ApiResponseModel } from '../../../../../models/responses/api-response.model';
import { SearchResponseModel } from '../../../../../models/responses/search-response.model';
import { ArticlesStore } from '../../../../../services/stores/articles.store';

@Component({
    selector: 'arc-inventory-edit-articles',
    templateUrl: './inventory-edit-articles.component.html',
    styleUrl: './inventory-edit-articles.component.scss'
})
export class InventoryEditArticlesComponent extends BaseEditSidebarItemComponent<InventoryModel, InventoryUpdateModel> {
    ComplexDataTypeEnum = ComplexDataTypesEnum;
    PermissionsEnum = PermissionsEnum;
    updatedArticles = new RelatedEntityUpdateModel();
    getDataFn: (id: number, requestModel: RelatedEntityRequestModel) => Observable<ApiResponseModel<SearchResponseModel<ArticleListModel>>>;

    override formGroup = new FormGroup({
        inventoryArticles: new ArcFormControl<InventoryArticleModel[]>([])
    });


    private readonly articlesStore = inject(ArticlesStore);

    constructor() {
        super();
        this.getDataFn = this.articlesStore.getArticlesByInventoryId.bind(this.articlesStore);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override hasUnsavedChanges(): boolean {
        return this.updatedArticles.hasChanges();
    }

    override prepareSaveModel(): Partial<InventoryUpdateModel> {
        if (this.updatedArticles.hasChanges()) {
            return { relatedArticleChanges: this.updatedArticles };
        }

        return {};
    }

    updateRelatedEntityChanges(event: RelatedEntityUpdateModel): void {
        this.updatedArticles = event;
    }
}
