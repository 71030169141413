import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { CustomerFormConfigModel } from '../../models/customer-form-config.model';
import { CustomerFormCreateModel } from '../../models/requests/customer-form-create.model';
import { CustomerFormCreateResponseModel } from '../../models/responses/customer-form-create-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { CustomerGroupModel } from '../../models/customer-group.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerFormStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'customer-form' }));
    }

    getCustomerFormConfig(subdomain: string): Observable<ApiResponseModel<CustomerFormConfigModel>> {
        return this._requestService.makeGet<ApiResponseModel<CustomerFormConfigModel>>(
            this.getUrl('config'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    createCustomer(
        subdomain: string,
        requestModel: CustomerFormCreateModel
    ): Observable<ApiResponseModel<CustomerFormCreateResponseModel>> {
        return this._requestService.makePut<ApiResponseModel<CustomerFormCreateResponseModel>>(
            this.getUrl(`create?subdomain=${subdomain}`),
            requestModel
        );
    }

    getLegalForms(subdomain: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('legal-forms'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    getSalutations(subdomain: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('salutations'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    getSalutationTitles(subdomain: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('salutation-titles'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    getCountries(subdomain: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('countries'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    getCustomerProfiles(subdomain: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('customer-profiles'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }

    getCustomerGroups(subdomain: string): Observable<ApiResponseModel<CustomerGroupModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<CustomerGroupModel[]>>(
            this.getUrl('customer-groups'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subdomain', value: subdomain }
        );
    }
}
