<mat-form-field class="w-full" [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="_formControl" [arcPortalTooltip]="label" [isTranslated]="isLabelTranslated" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <mat-chip-grid #chipGrid [formControl]="internalControl">
        @for (entry of (value ?? []); track uniqueIdFn(entry)) {
            <mat-chip-row (removed)="remove(entry)" 
            [style.background-color]="entry | transform : getBgColor.bind(this)">
                <span [style.color]="entry | transform : getFgColor.bind(this)">{{ entry | transform : displayFn }}</span>
                <button matChipRemove [style.color]="entry | transform : getFgColor.bind(this)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        }
    </mat-chip-grid>
    <input matInput #chipInput [matChipInputFor]="chipGrid" [value]="searchText()" (input)="searchText.set(chipInput.value)"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)" [matAutocomplete]="auto" (matChipInputTokenEnd)="add($event)"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" hideSingleSelectionIndicator
        autoActiveFirstOption>
        @for (option of searchResults(); track uniqueIdFn(option)) {
            <mat-option [value]="option">
                {{ option | transform : displayFn }}
            </mat-option>
        }
    </mat-autocomplete>
    <div matSuffix>
        @if (isSearching()) {
            <mat-spinner diameter="22"></mat-spinner>
        }
        @else if(!isAllowed) {
            <arc-no-permission-lock [missingPermission]="permission"></arc-no-permission-lock>
        }
    </div>
</mat-form-field>
