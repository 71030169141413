import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ArticleVariantListModel } from '../../models/responses/article-variant-list.model';
import { ArticleModel } from '../../models/article.model';
import { VariantEditModel } from '../../models/requests/variant-edit.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleVariantsStore extends BaseCrudStore<ArticleModel, ArticleVariantListModel, VariantEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'article-variants' }));
    }
}
