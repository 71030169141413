import { Component, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { SubscriptionListModel } from '../../../../../models/responses/subscription-list.model';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { TransactionArticlesStore } from '../../../../../services/stores/transaction-articles.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { ColumnFilterModel } from '../../../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../../core/models/enums/filter-item-type.enum';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { DateService } from '../../../../../../core/services/date.service';

@Component({
    selector: 'arc-subscription-transactions',
    templateUrl: './subscription-transactions.component.html',
    styleUrls: ['./subscription-transactions.component.scss']
})
export class SubscriptionTransactionsComponent extends BaseDetailWidget<SubscriptionListModel, SubscriptionModel> implements OnInit {
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 5,
        availableColumns: [
            new StackedColumnModel({
                columnTitleKey: 'Subscriptions.Detail.Transactions.Transaction',
                propertyName: 'transactionNumber',
                propertyName2: 'transactionTypeTitle',
                widthPixels: 115,
                isCompact: true
            }),
            new StackedColumnModel({
                columnTitleKey: 'Subscriptions.Detail.Transactions.Date',
                propertyName: 'saleTime',
                propertyName2: 'saleDate',
                widthPixels: 90,
                isCompact: true,
                customFormatter: (record, value, propertyName) => {
                    if (propertyName === 'saleDay') {
                        return record.displaySaleDay ? this.dateService.format(value) : '';
                    } else {
                        return this.dateService.format(value, 'short').replace(',', '');
                    }
                }
            }),
            new StringColumnModel({
                columnTitleKey: 'Subscriptions.Detail.Transactions.Quantity',
                propertyName: 'quantity',
                widthPixels: 40,
                isCompact: true
            }),
            new StringColumnModel({
                columnTitleKey: 'Subscriptions.Detail.Transactions.Article',
                propertyName: 'title',
                widthPixels: 180,
                isCompact: true
            })
        ]
    };
    readonly transactionArticles = signal<TransactionArticleListModel[]>([]);
    readonly isLoading = signal(true);

    private readonly router = inject(Router);
    private readonly transactionArticlesStore = inject(TransactionArticlesStore);
    private readonly dateService = inject(DateService);

    ngOnInit(): void {
        this.transactionArticlesStore
            .search(
                new SearchRequestModel({
                    pageSize: 999,
                    filters: [
                        new ColumnFilterModel({
                            column: 'SubscriptionId',
                            values: [this.listModel().id],
                            comparisonOperator: ComparisonOperatorsEnum.Equal,
                            dataType: FilterItemTypeEnum.Number
                        }),
                        new ColumnFilterModel({
                            column: 'ArticleId',
                            values: [this.entityModel().subscriptionTypeArticleId],
                            comparisonOperator: ComparisonOperatorsEnum.NotEqual,
                            dataType: FilterItemTypeEnum.Number
                        })
                    ]
                })
            )
            .subscribe(r => {
                this.transactionArticles.set(r.value?.records || []);
                this.isLoading.set(false);
            });
    }

    goTransactionsPage(): void {
        this.router.navigate(['transaction-articles'], { queryParams: { SubscriptionId: this.listModel().id } }).then();
    }
}
