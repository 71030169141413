import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { IconColumnModel } from '../../../../components/dynamic-table/models/column-types/icon-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CustomerModel } from '../../../models/customer.model';
import { IconModel } from '../../../../core/models/icon.model';
import { CustomerListModel } from '../../../models/responses/customer-list.model';
import { CustomersStore } from '../../../services/stores/customers.store';
import { CustomerEditBaseDataComponent } from './customer-edit-items/customer-edit-base-data/customer-edit-base-data.component';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomerTransactionsDetailWidget } from './customer-detail-widgets/customer-transactions/customer-transactions.detail-widget';
import { CustomerBaseDataDetailWidget } from './customer-detail-widgets/customer-base-data/customer-base-data.detail-widget';
import { CustomerPaymentsDetailWidget } from './customer-detail-widgets/customer-payments/customer-payments.detail-widget';
import { CustomerTurnoverDetailWidget } from './customer-detail-widgets/customer-turnover/customer-turnover.detail-widget';
import { CustomerEditContactComponent } from './customer-edit-items/customer-edit-contact/customer-edit-contact.component';
import { CustomerEditRequestModel } from '../../../models/requests/customer-edit-request.model';
import { CustomerEditMarketingComponent } from './customer-edit-items/customer-edit-marketing/customer-edit-marketing.component';
import { CustomerDescriptionDetailWidget } from './customer-detail-widgets/customer-description/customer-description.detail-widget';
import { CustomerSubscriptionsDetailWidget } from './customer-detail-widgets/customer-subscriptions/customer-subscriptions.detail-widget';
import { CustomerVouchersDetailWidget } from './customer-detail-widgets/customer-vouchers/customer-vouchers.detail-widget';
import { CustomerTopTransactionArticlesDetailWidget } from './customer-detail-widgets/customer-top-transaction-articles/customer-top-transaction-articles.detail-widget';
import { CustomerEditTextsDetailWidget } from './customer-edit-items/customer-edit-texts/customer-edit-texts.detail-widget';
import { CustomerEditAccountComponent } from './customer-edit-items/customer-edit-account/customer-edit-account.component';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-customers',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent extends PageListViewComponent<CustomerListModel, CustomerModel, CustomerEditRequestModel> {
    private readonly customersStore = inject(CustomersStore);

    constructor() {
        super('Customers');
        this.config = new TableListViewConfigModel<CustomerListModel, CustomerModel, CustomerEditRequestModel>({
            entityName: 'Customers',
            availableColumns: {
                customerName: new StackedColumnModel({
                    columnTitleKey: 'Customers.List.CustomerName',
                    propertyName: 'displayName',
                    propertyName2: 'personNumber',
                    suffixPropertyName: 'companyContactName',
                    widthPixels: 286
                }),
                isCompany: new IconColumnModel({
                    columnTitleKey: 'Customers.List.IsCompany',
                    propertyName: 'isCompany',
                    widthPixels: 50,
                    iconMapping: new Map([
                        [true, new IconModel({ name: 'apartment' })],
                        [false, new IconModel({ name: 'person_outline' })]
                    ])
                }),
                email: new StringColumnModel({
                    columnTitleKey: 'Customers.List.ContactEmail',
                    propertyName: 'contactEmail',
                    widthPixels: 236
                }),
                phone: new StackedColumnModel({
                    columnTitleKey: 'Customers.List.ContactPhone',
                    propertyName: 'contactMobile',
                    propertyName2: 'contactPhone',
                    isSecondPropertySmaller: false,
                    widthPixels: 156
                }),
                noCommunication: new IconColumnModel({
                    columnTitleKey: 'Customers.List.NoCommunication',
                    propertyName: 'noCommunication',
                    widthPixels: 126,
                    iconMapping: new Map([
                        [true, new IconModel({ name: 'block' })],
                        [false, new IconModel({ name: '' })]
                    ])
                }),
                zip: new StringColumnModel({ columnTitleKey: 'Customers.List.Zip', propertyName: 'zip', widthPixels: 75 }),
                city: new StringColumnModel({
                    columnTitleKey: 'Customers.List.City',
                    propertyName: 'city',
                    widthPixels: 150,
                    shouldTruncateText: true
                }),
                customerProfile: new StringColumnModel({
                    columnTitleKey: 'Customers.List.CustomerProfile',
                    propertyName: 'customerProfile',
                    widthPixels: 156,
                    requiredPermission: PermissionsEnum.CustomerProfiles
                }),
                bonusPoints: new StringColumnModel({
                    columnTitleKey: 'Customers.List.BonusPoints',
                    propertyName: 'bonusPoints',
                    widthPixels: 96,
                    requiredPermission: PermissionsEnum.CustomerBonusPrograms
                }),
                turnover: new CurrencyColumnModel({
                    columnTitleKey: 'Customers.List.Turnover',
                    propertyName: 'turnover',
                    widthPixels: 126
                }),
                turnoverYTD: new CurrencyColumnModel({
                    columnTitleKey: 'Customers.List.TurnoverYTD',
                    propertyName: 'turnoverYTD',
                    widthPixels: 126
                }),
                balance: new CurrencyColumnModel({
                    columnTitleKey: 'Customers.List.CustomerAccountBalance',
                    propertyName: 'customerAccountBalance',
                    widthPixels: 156,
                    requiredPermission: PermissionsEnum.CustomerProfiles
                })
            },
            defaultColumnOrder: [
                'customerName',
                'isCompany',
                'email',
                'phone',
                'noCommunication',
                'zip',
                'city',
                'customerProfile',
                'bonusPoints',
                'balance'
            ],
            defaultSort: new ColumnSortModel({
                column: 'displayName',
                direction: SortDirectionEnum.Asc
            }),
            availableDetailWidgets: {
                contactData: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.ContactData.ComponentTitle',
                    component: CustomerBaseDataDetailWidget
                }),
                paymentTypes: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.PaymentTypes.ComponentTitle',
                    component: CustomerPaymentsDetailWidget,
                    requiredPermission: PermissionsEnum.HomeStatistics
                }),
                transactions: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.Transactions.ComponentTitle',
                    component: CustomerTransactionsDetailWidget,
                    requiredPermission: PermissionsEnum.Transactions
                }),
                turnover: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.Turnover.ComponentTitle',
                    component: CustomerTurnoverDetailWidget,
                    requiredPermission: PermissionsEnum.HomeStatistics
                }),
                description: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.Description.ComponentTitle',
                    component: CustomerDescriptionDetailWidget
                }),
                subscriptions: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.Subscriptions.ComponentTitle',
                    component: CustomerSubscriptionsDetailWidget,
                    requiredPermission: PermissionsEnum.SubscriptionTypes
                }),
                vouchers: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.Vouchers.ComponentTitle',
                    component: CustomerVouchersDetailWidget,
                    requiredPermission: PermissionsEnum.Vouchers
                }),
                topTransactionArticles: new TableListDetailWidgetModel({
                    name: 'Customers.Detail.TopTransactionArticles.ComponentTitle',
                    component: CustomerTopTransactionArticlesDetailWidget,
                    requiredPermission: PermissionsEnum.HomeStatistics
                })
            },
            defaultDetailWidgetOrder: [
                'contactData',
                'description',
                'paymentTypes',
                'transactions',
                'turnover',
                'subscriptions',
                'vouchers',
                'topTransactionArticles'
            ],
            store: this.customersStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Customers.Edit.BaseData.ComponentTitle', component: CustomerEditBaseDataComponent },
                    { titleKey: 'Customers.Edit.Contact.ComponentTitle', component: CustomerEditContactComponent },
                    { titleKey: 'Customers.Edit.Account.ComponentTitle', component: CustomerEditAccountComponent },
                    { titleKey: 'Customers.Edit.Marketing.ComponentTitle', component: CustomerEditMarketingComponent },
                    { titleKey: 'Customers.Edit.Texts.ComponentTitle', component: CustomerEditTextsDetailWidget }
                ],
                headerSubtitle: currentItem => of(currentItem.displayName)
            },
            buttonsVisibility: new ButtonsVisibilityModel({ hasImport: true })
        });
    }
}
