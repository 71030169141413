@if (fabTogglerState === 'active') {
    <div class="z-10 fixed inset-0" (click)="toggle()"></div>
}

<div class="z-20 fixed bottom-8 right-8 smd:bottom-2 smd:right-2 flex flex-col-reverse items-center gap-2">
    <div class="flex flex-col-reverse gap-2">
        @for (btn of visibleButtons; track btn.key; let i = $index) {
            <button mat-fab [attr.color]="btn.color || (i === 0 ? 'accent' : '')" [color]="btn.color || (i === 0 ? 'accent' : '')"
                [ngClass]="{ '!bg-fab-more !text-on-fab-more': i > 0 }" [disabled]="!btn.isEnabled || btn.isLoading"
                [matTooltip]="btn.isTextTranslated ? btn.text : (btn.text | transloco)" matTooltipPosition="left"
                (click)="onClick($event, btn)">
                @if (!btn.isLoading) {
                    <i class="material-icons">{{ btn.icon }}</i>
                } @else {
                    <mat-spinner diameter="22"></mat-spinner>
                }
            </button>
        }
    </div>
    @if (menuButtons.length > 0) {
        <button mat-fab class="fab-main !bg-fab-more !text-on-fab-more" (click)="toggle()">
            <i class="material-icons" [@fabToggler]="fabTogglerState">more_vert</i>
        </button>
        <div [@fabStagger]="fabTogglerState" class="flex flex-col-reverse gap-4 mb-2">
            @for (btn of menuButtons; track btn.key) {
                <button mat-mini-fab class="!bg-fab-more !text-on-fab-more"
                    [matTooltip]="btn.isTextTranslated ? btn.text : (btn.text | transloco)" matTooltipPosition="left"
                    [disabled]="!btn.isEnabled || btn.isLoading" (click)="onClick($event, btn)">
                    @if (!btn.isLoading) {
                        <i class="material-icons">{{ btn.icon }}</i>
                    } @else {
                        <mat-spinner diameter="22"></mat-spinner>
                    }
                </button>
            }
        </div>
    }
</div>
