import { Component, inject } from '@angular/core';

import { UserListModel } from '../../../models/responses/user-list.model';
import { UserModel } from '../../../models/user.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { UsersStore } from '../../../services/stores/users.store';
import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { UserDataDetailWidget } from './user-detail-widgets/user-data/user-data.detail-widget';
import { UserQrCodeDetailWidget } from './user-detail-widgets/user-qr-code/user-qr-code.detail-widget';
import { UserEditModel } from '../../../models/user-edit.model';
import { UserBaseDataComponent } from './users-edit-items/user-base-data/user-base-data.component';
import { UserProfilePhotoComponent } from './users-edit-items/user-profile-photo/user-profile-photo.component';
import { UserPosCashRegistersComponent } from './users-edit-items/user-pos-cash-registers/user-pos-cash-registers.component';
import { UserWorkingTimesComponent } from './users-edit-items/user-working-times/user-working-times.component';
import { UserEmploymentDatesComponent } from './users-edit-items/user-employment-dates/user-employment-dates.component';

@Component({
    selector: 'arc-users',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent extends PageListViewComponent<UserListModel, UserModel, UserEditModel> {
    private readonly usersStore = inject(UsersStore);

    constructor() {
        super('Users');
        this.config = new TableListViewConfigModel<UserListModel, UserModel, UserEditModel>({
            entityName: 'Users',
            availableColumns: {
                username: new ColoredStackedColumnModel({
                    columnTitleKey: 'Users.List.UserName',
                    propertyName: 'username',
                    propertyName2: 'email',
                    color: 'userColor',
                    isColorInHex: true,
                    widthPixels: 350
                }),
                role: new StringColumnModel({
                    columnTitleKey: 'Users.List.Role',
                    propertyName: 'roleName',
                    widthPixels: 150
                }),
                realName: new StringColumnModel({
                    columnTitleKey: 'Users.List.RealName',
                    propertyName: 'realName',
                    widthPixels: 250
                }),
                language: new StringColumnModel({
                    columnTitleKey: 'Users.List.Language',
                    propertyName: 'language',
                    widthPixels: 150
                }),
                branch: new StringColumnModel({
                    columnTitleKey: 'Users.List.Branch',
                    propertyName: 'branchName',
                    widthPixels: 150
                }),
                region: new StringColumnModel({
                    columnTitleKey: 'Users.List.Region',
                    propertyName: 'regionName',
                    widthPixels: 150
                }),
                supplier: new StringColumnModel({
                    columnTitleKey: 'Users.List.Supplier',
                    propertyName: 'supplierName',
                    widthPixels: 156
                })
            },
            defaultColumnOrder: ['username', 'role', 'realName', 'language', 'branch', 'region', 'supplier'],
            defaultSort: new ColumnSortModel({
                column: 'username',
                direction: SortDirectionEnum.Asc
            }),
            availableDetailWidgets: {
                data: new TableListDetailWidgetModel({
                    name: 'Users.Detail.Data.ComponentTitle',
                    component: UserDataDetailWidget
                }),
                qrCode: new TableListDetailWidgetModel({
                    name: 'Users.Detail.QR.ComponentTitle',
                    component: UserQrCodeDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['data', 'qrCode'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Users.Edit.BaseData.ComponentTitle', component: UserBaseDataComponent },
                    { titleKey: 'Users.Edit.ProfilePhoto.ComponentTitle', component: UserProfilePhotoComponent },
                    { titleKey: 'Users.Edit.PosCashRegisters.ComponentTitle', component: UserPosCashRegistersComponent },
                    { titleKey: 'Users.Edit.WorkingTimes.ComponentTitle', component: UserWorkingTimesComponent },
                    { titleKey: 'Users.Edit.EmploymentDates.ComponentTitle', component: UserEmploymentDatesComponent }
                ]
            },
            store: this.usersStore
        });
    }
}
