import { Component, OnInit, inject, signal } from '@angular/core';
import { ChartData } from 'chart.js';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { CustomerTurnoverModel } from '../../../../../models/customer-turnover.model';
import { DateService } from '../../../../../../core/services/date.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { DictionaryType } from '../../../../../../core/models/types/dictionary.type';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionService } from '../../../../../../core/services/permission.service';

@Component({
    selector: 'arc-customer-turnover',
    templateUrl: './customer-turnover.detail-widget.html',
    styleUrls: ['./customer-turnover.detail-widget.scss']
})
export class CustomerTurnoverDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    readonly isLoading = signal(true);
    readonly chartData = signal<OptionalType<ChartData>>(undefined);
    readonly currentData = signal<OptionalType<CustomerTurnoverModel>>(undefined);
    readonly bonusPointsPermission: true | AuthPermissionModel;

    private readonly customersStore = inject(CustomersStore);
    private readonly dateService = inject(DateService);
    private readonly translationService = inject(TranslationService);
    private readonly permissionService = inject(PermissionService);

    constructor() {
        super();
        this.bonusPointsPermission = this.permissionService.hasPermission(PermissionsEnum.CustomerBonusPrograms);
    }

    ngOnInit(): void {
        const turnoverLabel = this.translationService.getText('Customers.Detail.Turnover.ComponentTitle');

        this.customersStore.getTurnover(this.entityModel().id).subscribe(r => {
            this.currentData.set(r.value);
            if (r.value?.last12MonthsTurnover) {
                this.chartData.set({
                    labels: this.getLabels(r.value.last12MonthsTurnover),
                    datasets: [
                        {
                            label: turnoverLabel,
                            data: Object.values(r.value.last12MonthsTurnover),
                            backgroundColor: 'rgb(12, 66, 69)'
                        }
                    ]
                });
            }
            this.isLoading.set(false);
        });
    }

    private getLabels(last12MonthsTurnover: DictionaryType<number>): string[] {
        return Object.keys(last12MonthsTurnover).map(k => `${this.dateService.format(new Date(k), 'MMM')}`.toUpperCase().substring(0, 3));
    }
}
