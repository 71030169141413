<h1 mat-dialog-title>
    @if (isAdd) {
        {{ 'PosLayouts.Edit.PosLayoutGroupCommands.DialogTitleAdd' | transloco }}
    } @else {
        {{ 'PosLayouts.Edit.PosLayoutGroupCommands.DialogTitleEdit' | transloco }}
    }
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    @if (isLoading()) {
        <div class="w-full flex justify-center">
            <mat-spinner [diameter]="48"></mat-spinner>
        </div>
    }@else {
        <form [formGroup]="formGroup" class="flex flex-col gap-4 pt-1">
            <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PosCommands" [formControl]="formGroup.controls.posCommandId"
                label="PosLayouts.Edit.PosLayoutGroupCommands.Command">
            </arc-general-data-select>

            @if (isLoadingCommandType()) {
                <div class="flex justify-center">
                    <mat-spinner [diameter]="48"></mat-spinner>
                </div>
            } @else if (hasParameters()) {
                @if (parameterFormFields().length > 0) {
                    <arc-dynamic-form #dynamicForm [formFields]="parameterFormFields()" (formSubmitted)="onParametersFormSubmitted($event)">
                    </arc-dynamic-form>
                } @else {
                    <p class="text-error mb-4">{{ 'PosLayouts.Edit.PosLayoutGroupCommands.LoadingParametersFailed' | transloco }}</p>
                }
                <mat-divider class="mb-4"></mat-divider>
            } @else if(!!formGroup.value.posCommandId) {
                <p class="text-on-app-light mb-4">{{ 'PosLayouts.Edit.PosLayoutGroupCommands.NoParameters' | transloco }}</p>
            }

            <arc-translation-input labelKey="PosLayouts.Edit.PosLayoutGroupCommands.Title" [controlDe]="formGroup.controls.title_de"
                [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
                [controlIt]="formGroup.controls.title_it" translationDialogTitleKey="PosLayouts.Edit.PosLayoutGroupCommands.Title">
            </arc-translation-input>

            <arc-file-upload class="block w-full h-full" fileTypes=".png, .jpg, .jpeg" [ngModel]="commandImageFile()"
                (ngModelChange)="commandImageFile.set($event)" [ngModelOptions]="{ standalone: true }" [maxFileSizeInKb]="2048"
                [shouldUseCompactStyle]="true">
            </arc-file-upload>

            <arc-color-picker labelKey="PosLayouts.Edit.PosLayoutGroupCommands.BackgroundColor"
                [control]="formGroup.controls.backgroundColor">
            </arc-color-picker>

            <arc-color-picker labelKey="PosLayouts.Edit.PosLayoutGroupCommands.TextColor" [control]="formGroup.controls.foreColor">
            </arc-color-picker>

            <arc-general-data-select label="PosLayouts.Edit.PosLayoutGroups.RequiredRole" [generalDataType]="GeneralDataTypeEnum.Roles"
                [formControl]="formGroup.controls.requiredRoleId">
            </arc-general-data-select>
        </form>
    }

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isSaving()" (click)="save()">
        {{ 'General.Actions.Save' | transloco }}
    </arc-button>
</mat-dialog-actions>
