<form [formGroup]="formGroup" class="grid items-center grid-cols-2 ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-input labelKey="ArticleVariants.Edit.BaseData.Ean" [control]="formGroup.controls.ean" actionIcon="rotate_left"
        [action]="generateNewIdentificationAction.bind(this)"></arc-input>
    <div></div>
    <arc-input labelKey="ArticleVariants.Edit.BaseData.OrderNumber" [control]="formGroup.controls.orderNumber"></arc-input>
    <div></div>
    <arc-currency-input label="ArticleVariants.Edit.BaseData.BuyingPrice" [requiredPermission]="PermissionsEnum.BuyingPrice"
        [requiredPermissionType]="PermissionTypeEnum.Update" [formControl]="formGroup.controls.buyingPrice">
    </arc-currency-input>
    <div></div>
    <arc-currency-input label="ArticleVariants.Edit.BaseData.Price" [formControl]="formGroup.controls.price"></arc-currency-input>
    <div class="col-span-2 flex flex-col gap-2 w-full">
        <label>
            {{ 'ArticleVariants.Edit.BaseData.ArticleImages' | transloco }}
        </label>
        <arc-article-images-editor class="w-full" [formControl]="formGroup.controls.articleImages">
        </arc-article-images-editor>
    </div>
</form>
