import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { SaleListModel } from '../../../models/responses/sale-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { SaleModel } from '../../../models/sale.model';
import { SalesStore } from '../../../services/stores/sales.store';
import { SaleCardComponent } from './sale-card/sale-card.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { SaleEditBaseDataComponent } from './sale-card-edit-items/sale-edit-base-data/sale-edit-base-data.component';
import { SaleEditValidityComponent } from './sale-card-edit-items/sale-edit-validity/sale-edit-validity.component';
import { SaleEditArticlesComponent } from './sale-card-edit-items/sale-edit-articles/sale-edit-articles.component';

@Component({
    selector: 'arc-sales',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./sales.component.scss']
})
export class SalesComponent extends PageListViewComponent<SaleListModel> {
    private readonly salesStore = inject(SalesStore);

    constructor() {
        super('Sales');
        this.config = new CardListViewConfigModel<SaleModel, SaleListModel, SaleModel>({
            entityName: 'Sales',
            store: this.salesStore,
            cardComponent: SaleCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({ column: 'IsActive', direction: SortDirectionEnum.Desc }),
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: currentItem =>
                currentItem.isActive ? ['bg-primary', 'text-on-primary'] : ['bg-element-disabled', 'text-on-element-disabled'],
            cardBackgroundColor: currentItem => (!currentItem.isActive ? 'var(--element-selected)' : ''),
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Sales.Edit.BaseData.ComponentTitle',
                        component: SaleEditBaseDataComponent,
                        requiredPermission: PermissionsEnum.Sales,
                        requiredPermissionType: PermissionTypeEnum.Update
                    },
                    {
                        titleKey: 'Sales.Edit.Validity.ComponentTitle',
                        component: SaleEditValidityComponent,
                        requiredPermission: PermissionsEnum.Sales,
                        requiredPermissionType: PermissionTypeEnum.Update
                    },
                    {
                        titleKey: 'Sales.Edit.Articles.ComponentTitle',
                        component: SaleEditArticlesComponent,
                        requiredPermission: PermissionsEnum.ArticleSales
                    }
                ],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'Sales.List.ShowArticles',
                clickFn: data => this._router.navigate(['articles'], { queryParams: { SaleId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }
}
