<div class="h-full w-full">
    @if(isLoading()) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32" />
        </div>
    } @else if(items().length > 0){
        <arc-static-table [config]="tableConfig" [staticData]="items()" [shouldUseCompactStyle]="true" />
    } @else {
        <div class="pt-10">
            <p class="text-on-app-light text-center">
                {{ 'InventoryArticles.Detail.Scans.NotCounted' | transloco }}
            </p>
        </div>
    }
</div>
