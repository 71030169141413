import { Component, computed } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { SaleListModel } from '../../../../models/responses/sale-list.model';
import { Utils } from '../../../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-sale-card',
    templateUrl: './sale-card.component.html',
    styleUrls: ['./sale-card.component.scss']
})
export class SaleCardComponent extends BaseCardComponent<SaleListModel> {
    isCurrencyAmount = computed(() => Utils.isNumber(this.item().value));
}
