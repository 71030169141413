<p class="text-2xl font-semibold mt-2 mb-2">{{ entityModel().name }}</p>
<div class="flex flex-row gap-4 mb-2">
    <div>
        <span class="text-on-app-light text-sm">{{ 'PointOfSales.Detail.Overview.Branch' | transloco }}</span>
        <br />
        <div class="text-lg">{{ entityModel().branchName }}</div>
    </div>
    <div>
        <span class="text-on-app-light text-sm">{{ 'PointOfSales.Detail.Overview.Layout' | transloco }}</span>
        <br />
        <div class="text-lg">{{ entityModel().layout }}</div>
    </div>
</div>
<div class="mb-2">
    <span class="text-on-app-light text-sm">{{ 'PointOfSales.Detail.Overview.Identification' | transloco }}</span>
    <br />
    <div class="text-lg">{{ entityModel().posIdentification }}</div>
</div>
<div class="mb-4">
    <span class="text-on-app-light text-sm">{{ 'PointOfSales.Detail.Overview.MachineInfold' | transloco }}</span>
    <br />
    <div class="text-lg overflow-hidden whitespace-nowrap overflow-ellipsis">{{ entityModel().machineInfoId ?? '-' }}</div>
</div>
<div class="grid grid-cols-2 gap-2">
    <arc-button customClasses="!h-auto !text-sm !text-start" actionButtonKey="PointOfSaleDownloadSettingsContextAction"
        actionButtonTitleKey="PointOfSales.Detail.Overview.DownloadLocalSettings" [currentData]="entityModel()" type="stroked">
    </arc-button>
    @if (entityModel().machineInfoId) {
        <arc-button customClasses="!h-auto !text-sm !text-left" actionButtonKey="PointOfSaleUnregisterContextAction"
            actionButtonTitleKey="PointOfSales.Detail.Overview.UnregisterPointOfSale" [currentData]="entityModel()" type="stroked">
        </arc-button>
    }
</div>
