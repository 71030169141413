import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-settings-formats',
    templateUrl: './settings-settings-formats.component.html',
    styleUrl: './settings-settings-formats.component.scss'
})
export class SettingsSettingsFormatsComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        articleNumberFormat: new ArcFormControl<OptionalType<string>>(undefined),
        customerNumberFormat: new ArcFormControl<OptionalType<string>>(undefined),
        supplierNumberFormat: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceNumberFormat: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            articleNumberFormat: value.articleNumberFormat,
            customerNumberFormat: value.customerNumberFormat,
            supplierNumberFormat: value.supplierNumberFormat,
            invoiceNumberFormat: value.invoiceNumberFormat
        };
    }
}
