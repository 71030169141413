/* eslint-disable no-null/no-null */
import { Component, ElementRef, Input, OnInit, ViewChild, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup } from '@angular/forms';

import { WarehouseRequestModel } from '../../../../../../models/warehouse-request.model';
import { WarehouseRequestArticleModel } from '../../../../../../models/warehouse-request-article.model';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { QuickSearchConfigModel } from '../../../../../../../components/form/quick-search/models/quick-search-config.model';
import { ArticleStockListModel } from '../../../../../../models/responses/article-stock-list.model';
import { StoreArticlesStore } from '../../../../../../services/stores/store-articles.store';
import { RequestService } from '../../../../../../../core/services/request.service';
import { WarehouseTransferArticlesTableConfigService } from '../warehouse-transfer-articles-table-config.service';

@Component({
    selector: 'arc-warehouse-transfer-article-quick-add',
    templateUrl: './warehouse-transfer-article-quick-add.component.html',
    styleUrl: './warehouse-transfer-article-quick-add.component.scss'
})
export class WarehouseTransferArticleQuickAddComponent implements OnInit {
    @ViewChild('quickAddArticleControl', { read: ElementRef }) quickAddArticleControl!: ElementRef;
    @ViewChild('quickAddQuantityControl', { read: ElementRef }) quickAddQuantityControl!: ElementRef;

    @Input({ required: true }) warehouseRequest!: WarehouseRequestModel;
    @Input({ required: true }) warehouseRequestArticlesControl!: AbstractControl<WarehouseRequestArticleModel[]>;

    quickAddFormGroup = new FormGroup({
        articleStockId: new ArcFormControl<string | null>(null),
        warehouseRequestArticle: new ArcFormControl<WarehouseRequestArticleModel | null>(null),
        quantity: new ArcFormControl<number | null>(null)
    });

    quickSearchConfig?: QuickSearchConfigModel<ArticleStockListModel, string>;
    isQuickAddLoading = false;

    quickAddFormGroupSignal = toSignal(this.quickAddFormGroup.valueChanges);
    quickAddWarehouseRequestArticle = computed(() => this.quickAddFormGroupSignal()?.warehouseRequestArticle);
    quickAddQuantity = computed(() => this.quickAddFormGroupSignal()?.quantity);
    quickAddQUnit = computed(() => this.quickAddWarehouseRequestArticle()?.articleUnitTitle ?? '');

    private readonly requestService = inject(RequestService);
    private readonly warehouseTransferArticlesTableConfigService = inject(WarehouseTransferArticlesTableConfigService);

    ngOnInit(): void {
        const filteredStore = new StoreArticlesStore(this.requestService, this.warehouseRequest.deliveringStoreId);
        this.quickSearchConfig = {
            store: filteredStore,
            displayFn: articleStock => articleStock?.articleTitle ?? '',
            dataSelectionDialogConfig: this.warehouseTransferArticlesTableConfigService.getDataSelectionDialogConfig(
                this.warehouseRequest,
                false
            )
        };
    }

    quickAddArticleChanged(value: ArticleStockListModel): void {
        if (!value) {
            this.quickAddFormGroup.patchValue({
                warehouseRequestArticle: null,
                quantity: null
            });
            return;
        }

        this.isQuickAddLoading = true;
        this.warehouseTransferArticlesTableConfigService
            .articleStocksToWarehouseRequestArticles(this.warehouseRequest, [value])
            .subscribe(result => {
                if (result.length > 0 && !result[0].addedDate) {
                    result[0].addedDate = new Date();
                }

                if (result.length > 0) {
                    this.quickAddFormGroup.patchValue({ warehouseRequestArticle: result[0] });
                }
            })
            .add(() => (this.isQuickAddLoading = false));

        setTimeout(() => this.quickAddQuantityControl.nativeElement.querySelector('input')?.focus());
    }

    quickAdd(): void {
        if (this.isQuickAddLoading) {
            return;
        }

        const value = this.quickAddFormGroup.value;

        if (!value.warehouseRequestArticle || !value.quantity) {
            return;
        }

        const warehouseRequestArticle = value.warehouseRequestArticle;
        warehouseRequestArticle.quantity = value.quantity;

        if (!warehouseRequestArticle.addedDate) {
            warehouseRequestArticle.addedDate = new Date();
        }

        const currentValue = this.warehouseRequestArticlesControl.value ?? [];
        // eslint-disable-next-line eqeqeq
        const existing = currentValue.find(oa => oa.articleId == warehouseRequestArticle.articleId);

        if (!!existing) {
            const quantity = Number.isNaN(Number(existing.quantity)) ? 0 : Number(existing.quantity);
            existing.quantity = quantity + value.quantity;
            this.warehouseRequestArticlesControl.setValue([...currentValue]);
        } else {
            this.warehouseRequestArticlesControl.setValue([warehouseRequestArticle, ...currentValue]);
        }

        this.quickAddFormGroup.setValue({
            articleStockId: null,
            warehouseRequestArticle: null,
            quantity: null
        });
        setTimeout(() => this.quickAddArticleControl.nativeElement.querySelector('input')?.focus());
    }
}
