import { Component, ViewChild, inject, signal } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OrderModel } from '../../../../../models/order.model';
import { OrderUpdateModel } from '../../../../../models/requests/order-update.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OrderArticleModel } from '../../../../../models/order-article.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { CurrencyPipe } from '../../../../../../core/pipes/currency.pipe';
import { NumberPipe } from '../../../../../../core/pipes/number.pipe';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { OrderArticlesTableConfigService } from './order-articles-table-config.service';
import { OrderStatusEnum } from '../../../../../models/enums/order-status.enum';
import { EditableTableComponent } from '../../../../../../components/form/editable-table/editable-table.component';

@Component({
    selector: 'arc-order-edit-articles',
    templateUrl: './order-edit-articles.component.html',
    styleUrls: ['./order-edit-articles.component.scss'],
    providers: [CurrencyPipe, NumberPipe, OrderArticlesTableConfigService]
})
export class OrderEditArticlesComponent extends BaseEditSidebarItemComponent<OrderModel, OrderUpdateModel> {
    @ViewChild(EditableTableComponent) editableTable!: EditableTableComponent<OrderArticleModel, string>;

    OrderStatusEnum = OrderStatusEnum;
    tableConfig?: EditableTableConfigModel<OrderArticleModel, string>;
    override formGroup = new FormGroup({
        orderArticles: new ArcFormControl<OrderArticleModel[]>([])
    });

    filterPredicate: (data: FormGroup<{ [K in keyof OrderArticleModel]: AbstractControl<any, any> }>, filter: string) => boolean;
    articlesTotalCount = signal(0);
    articlesDeviatingCount = signal(0);
    articlesNotDeliveredCount = signal(0);

    private readonly orderArticlesTableConfigService = inject(OrderArticlesTableConfigService);
    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();

        this.sharedDataService.setOrUpdateSignal<number>('orderValue', 0);
        this.sharedDataService.setOrUpdateSignal<number>('orderedArticlesCount', 0);
        this.sharedDataService.setOrUpdateSignal<number>('deliveredArticlesCount', 0);
        this.formGroup.controls.orderArticles.valueChanges.subscribe(newValue => {
            const orderValue = newValue
                .map(x => x.orderQuantity * x.orderUnitQuantity * x.orderBuyingPrice)
                .filter(total => !Number.isNaN(total))
                .reduce((prev, curr) => prev += curr, 0);
            const orderedArticlesCount = newValue
                .map(x => x.orderQuantity * x.orderUnitQuantity)
                .filter(total => !Number.isNaN(total))
                .reduce((prev, curr) => prev += curr, 0);
            const deliveredArticlesCount = newValue
                .map(x => (x.deliveredQuantity ?? 0) * (x.deliveredUnitQuantity ?? 0))
                .filter(total => !Number.isNaN(total))
                .reduce((prev, curr) => prev += curr, 0);

            this.sharedDataService.setOrUpdateSignal<number>('orderValue', orderValue);
            this.sharedDataService.setOrUpdateSignal<number>('orderedArticlesCount', orderedArticlesCount);
            this.sharedDataService.setOrUpdateSignal<number>('deliveredArticlesCount', deliveredArticlesCount);

            const articlesDeviatingCount = newValue
                // eslint-disable-next-line eqeqeq
                .filter(
                    x =>
                        !!x.deliveredQuantity &&
                        x.orderQuantity * x.orderUnitQuantity !== x.deliveredQuantity * (x.deliveredUnitQuantity ?? x.orderUnitQuantity)
                ).length;
            const articlesNotDeliveredCount = newValue.filter(x => !x.deliveredQuantity).length;

            this.articlesTotalCount.set(newValue.length);
            this.articlesDeviatingCount.set(articlesDeviatingCount);
            this.articlesNotDeliveredCount.set(articlesNotDeliveredCount);
        });

        this.filterPredicate = (formGroup, filter) => {
            const data = formGroup.value;
            switch (filter) {
                case 'deviating':
                    // eslint-disable-next-line eqeqeq
                    return !!data.deliveredQuantity && data.orderQuantity != data.deliveredQuantity;
                case 'notDelivered':
                    return !data.deliveredQuantity;
                case 'all':
                default:
                    return true;
            }
        };
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
        this.tableConfig = this.orderArticlesTableConfigService.getTableConfig(this.item(), this.formGroup);
    }

    override prepareSaveModel(): Partial<OrderUpdateModel> {
        return {
            orderArticles: this.formGroup.value.orderArticles?.map(oa => ({
                id: oa.id,
                orderId: oa.orderId,
                articleId: oa.articleId,
                orderNumber: oa.orderNumber,
                orderUnitQuantity: oa.orderUnitQuantity,
                orderQuantity: oa.orderQuantity,
                orderBuyingPrice: oa.orderBuyingPrice,
                orderBuyingPriceExclusive: oa.orderBuyingPriceExclusive,
                deliveredUnitQuantity: oa.deliveredUnitQuantity,
                deliveredQuantity: oa.deliveredQuantity,
                notes: oa.notes
            }))
        };
    }

    override onBrokenRulesLoad(): string[] {
        return this.tableConfig?.setBrokenRulesOnFormGroups(this.brokenRules) ?? [];
    }

    onFilterChanged(event: MatChipListboxChange): void {
        this.editableTable.filter(event.value);
    }
}
