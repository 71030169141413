@if (widgetPermission !== true) {
    <div class="flex items-center justify-center h-full w-full">
        <div [arcPermissionTooltip]="widgetPermission">
            <arc-icon icon="lock" [size]="64" class="text-on-app" (click)="showUpgradeModal(widgetPermission)">
            </arc-icon>
        </div>
    </div>
} @else {
    @if (article.ignoreStock) {
        <div class="flex items-center justify-center h-full">
            <span class="text-sm">
                {{ 'Articles.Edit.Properties.IgnoreStock' | transloco }}
            </span>
        </div>
    } @else {
        <div class="w-full h-full flex flex-col">
            <div *ngIf="isLoading; else contentTpl" class="w-full h-full grid place-items-center">
                <mat-spinner [diameter]="32"></mat-spinner>
            </div>
            <ng-template #contentTpl>
                <div class="grid grid-cols-8 mb-4">
                    <div class="col-span-4">
                        <div class="flex items-center">
                            <span class="mat-mdc-card-subtitle text-sm">{{ 'Articles.List.TotalStock' | transloco }}</span>
                            <button mat-icon-button (click)="goToStockArticlesPage()">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                        </div>
                        <span class="block text-xl mt-[-10px]">{{ totalStock }}</span>
                    </div>
                    <div class="col-span-4">
                        <div class="flex items-center">
                            <span class="mat-mdc-card-subtitle text-sm">{{ 'Suppliers.List.TotalOrders' | transloco }}</span>
                            @if(orderPermission) {
                                <button mat-icon-button (click)="goToOrderArticlesPage()">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            }
                        </div>
                        <span class="block text-xl mt-[-10px]">{{ totalOrdered }}</span>
                    </div>
                </div>
                <div class="flex grow">
                    <div class="flex w-full overflow-x-auto pb-1">
                        <div *ngFor="let articleStock of data" class="flex flex-col min-w-[22%] h-full mx-2">
                            <div class="flex flex-col items-center mt-auto">
                                <span class="text-power-search text-sm">
                                    @if(articleStock.stock >= 0){
                                        {{ articleStock.stock }}

                                    } @else {
                                        <span class="text-error">
                                            {{ articleStock.stock }}
                                        </span>
                                    }
                                    @if(articleStock.quantityOrdered > 0){
                                        <span class="text-accent">
                                            ({{ articleStock.quantityOrdered }} {{ 'Articles.List.QuantityOrdered' | transloco }})
                                        </span>
                                    }
                                </span>
                            </div>
                            @if(articleStock.quantityOrdered > 0){
                                <div class="flex justify-center items-center float-left h-full w-full overflow-hidden flex-wrap bg-accent"
                                    [style.height.%]="getHeightForItem(articleStock, true)">
                                </div>
                            }
                            @if(articleStock.stock >= 0){
                                <div class="flex justify-center items-center float-left h-full w-full overflow-hidden flex-wrap bg-power-search"
                                    [style.height.%]="getHeightForItem(articleStock)">
                                </div>
                            }@else {
                                <div class="flex justify-center items-center float-left h-full w-full overflow-hidden flex-wrap bg-error"
                                    [style.height.%]="1">
                                </div>
                            }
                            <span class="flex flex-col items-center">
                                {{ articleStock.storeName }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    }
}
