/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { CustomerModel } from '../../models/customer.model';
import { CustomerListModel } from '../../models/responses/customer-list.model';
import { CustomerEditRequestModel } from '../../models/requests/customer-edit-request.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { CustomerTurnoverModel } from '../../models/customer-turnover.model';
import { SimpleTransactionModel } from '../../models/simple-transaction.model';
import { SubscriptionModel } from '../../models/subscription.model';
import { VoucherModel } from '../../models/voucher.model';
import { TopTransactionArticleModel } from '../../models/responses/top-transaction-article.model';
import { CustomerAccountAddBalanceRequestModel } from '../../models/requests/customer-account-add-balance-request.model';

@Injectable({
    providedIn: 'root'
})
export class CustomersStore extends BaseCrudStore<CustomerModel, CustomerListModel, CustomerEditRequestModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'customers' }));
    }

    getTurnover(id: number): Observable<ApiResponseModel<CustomerTurnoverModel>> {
        return this._requestService.makeGet<ApiResponseModel<CustomerTurnoverModel>>(this.getUrl(`${id}/turnover`));
    }

    getTransactions(id: number): Observable<ApiResponseModel<SimpleTransactionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SimpleTransactionModel[]>>(this.getUrl(`${id}/transactions`));
    }

    getSubscriptions(id: number): Observable<ApiResponseModel<SubscriptionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SubscriptionModel[]>>(this.getUrl(`${id}/subscriptions`));
    }

    getVouchers(id: number): Observable<ApiResponseModel<VoucherModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<VoucherModel[]>>(this.getUrl(`${id}/vouchers`));
    }

    getTopTransactionArticles(id: number, limit = 5): Observable<ApiResponseModel<TopTransactionArticleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<TopTransactionArticleModel[]>>(
            this.getUrl(`${id}/top-transaction-articles/${limit}`)
        );
    }

    addBalance(requestModel: CustomerAccountAddBalanceRequestModel): Observable<ApiResponseModel<number>> {
        return this._requestService.makePost<ApiResponseModel<number>>(this.getUrl('add-balance'), requestModel);
    }

    generateCardNumbers(count = 1): Observable<ApiResponseModel<string[]>> {
        return this._requestService.makeGet<ApiResponseModel<string[]>>(this.getUrl(`card-numbers/generate/${count}`));
    }
}
