import { Component, Signal, WritableSignal, effect, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import Big from 'big.js';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { GeneralDataService } from '../../../../../../core/services/general-data.service';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { KeyValueModel } from '../../../../../../core/models/key-value.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { ArticleModel } from '../../../../../models/article.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleEditBaseDataComponent } from '../article-edit-base-data/article-edit-base-data.component';
import { Tools } from '../../../../../../core/utils/tools';
import { UserService } from '../../../../../../core/services/user.service';

@Component({
    selector: 'arc-article-edit-prices',
    templateUrl: './article-edit-prices.component.html',
    styleUrls: ['./article-edit-prices.component.scss']
})
export class ArticleEditPricesComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    UnitsEnum = UnitsEnum;
    PermissionsEnum = PermissionsEnum;
    PermissionTypeEnum = PermissionTypeEnum;
    pricePrefix = 'price';
    marginPrefix = 'margin';
    priceLevels: KeyValueModel[] = [];
    isLoading = true;
    override formGroup: FormGroup = new FormGroup({});
    // create a dummy formgroup for the local margin calculations
    marginFormGroup: FormGroup = new FormGroup({});

    private readonly generalDataService = inject(GeneralDataService);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly userService = inject(UserService);
    private buyingPrice: Signal<OptionalType<number>>;
    private taxRate: WritableSignal<number>;

    constructor() {
        super();
        this.buyingPrice = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.buyingPriceSignal);
        this.taxRate = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditBaseDataComponent.taxRateSignal, 0);
        effect(() => {
            // trigger when buyingPrice or taxRate changes
            this.buyingPrice();
            this.taxRate();

            this.priceLevels.forEach(priceLevel => {
                this.setMarginFromPrice(priceLevel.key!, this.formGroup.value[this.pricePrefix + priceLevel.key]);
            });
        });
    }

    override onItemSet(): void {
        this.generalDataService.getGeneralData(GeneralDataTypeEnum.PriceLevels).subscribe(result => {
            this.priceLevels = result.sort((a, b) => +(a.key ?? 0) - +(b.key ?? 0));
            this.initializeFormGroups();
            this.formGroup.patchValue(this.item());
            this.marginFormGroup.patchValue(this.item());
            this.isLoading = false;
        });
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return {
            ...this.formGroup.value
        };
    }

    setMarginFromPrice(id: string, newPrice?: number): void {
        const marginControl = this.marginFormGroup.controls[this.marginPrefix + id];

        // eslint-disable-next-line eqeqeq
        if (newPrice == undefined) {
            marginControl.setValue(undefined, { emitEvent: false });
            return;
        }

        if (newPrice <= 0) {
            marginControl.setValue(0, { emitEvent: false });
            return;
        }

        const buyingPriceIncl = this.getArticleBuyingPriceInclusiveVat();
        if (buyingPriceIncl === 0) {
            marginControl.setValue(100, { emitEvent: false });
            return;
        }

        // margin = (1 - buyingPriceIncl / price) * 100
        const newMargin = Big(1).minus(Big(buyingPriceIncl).div(newPrice)).times(100).toNumber();
        marginControl.setValue(newMargin, { emitEvent: false });
    }

    setPriceFromMargin(id: string, newMargin?: number): void {
        const priceControl = this.formGroup.controls[this.pricePrefix + id];

        // eslint-disable-next-line eqeqeq
        if (newMargin == undefined) {
            // do not trigger margin calculation
            priceControl.setValue(undefined, { emitEvent: false });
            return;
        }

        if (newMargin === 100) {
            // do not trigger margin calculation
            priceControl.setValue(0, { emitEvent: false });
            return;
        }

        const buyingPriceIncl = this.getArticleBuyingPriceInclusiveVat();
        // price = buyingPriceIncl / (1 - newMargin / 100)
        const newPrice = Big(buyingPriceIncl)
            .div(Big(1).minus(Big(newMargin).div(100)))
            .toNumber();
        const newPriceRounded = Tools.Utils.roundTo(newPrice, this.userService.defaultRoundTo);

        priceControl.setValue(newPriceRounded, { emitEvent: false });
    }

    private initializeFormGroups(): void {
        this.priceLevels.forEach(priceLevel => {
            const priceControl = new ArcFormControl<OptionalType<number>>(undefined);
            const marginControl = new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ max: 100 }));

            this.formGroup.addControl(`${this.pricePrefix}${priceLevel.key}`, priceControl);
            this.marginFormGroup.addControl(`${this.marginPrefix}${priceLevel.key}`, marginControl);

            const priceChangedSub = priceControl.valueChanges.subscribe(newPrice => {
                this.setMarginFromPrice(priceLevel.key!, newPrice);
            });

            const marginChangedSub = marginControl.valueChanges.subscribe(newMargin => {
                this.setPriceFromMargin(priceLevel.key!, newMargin);
            });

            this.addSubscriptions(priceChangedSub, marginChangedSub);
        });
    }

    private getArticleBuyingPriceInclusiveVat(): number {
        // buyingPriceInclVat = buyingPrice * (1 + taxRate)
        return Big(this.buyingPrice() ?? 0)
            .times(Big(1).plus(this.taxRate()))
            .toNumber();
    }
}
