import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ExtensionModel } from '../../../../../models/extension.model';

@Component({
    selector: 'arc-extensions-edit-base-data',
    templateUrl: './extensions-edit-base-data.component.html',
    styleUrl: './extensions-edit-base-data.component.scss'
})
export class ExtensionsEditBaseDataComponent extends BaseEditSidebarItemComponent<ExtensionModel> implements OnInit {
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        description_de: new ArcFormControl<OptionalType<string>>(undefined),
        description_en: new ArcFormControl<OptionalType<string>>(undefined),
        description_fr: new ArcFormControl<OptionalType<string>>(undefined),
        description_it: new ArcFormControl<OptionalType<string>>(undefined),
        isActive: new ArcFormControl<boolean>(false),
        nextExecutionTime: new ArcFormControl<OptionalType<Date>>(undefined),
        posExtensionDll: new ArcFormControl<OptionalType<number[]>>(undefined),
        posExtensionFileName: new ArcFormControl<OptionalType<string>>(undefined),
        fileControl: new ArcFormControl()
    });

    ngOnInit(): void {
        this.formGroup.controls.fileControl.valueChanges.subscribe(async (file?: File) => {
            this.formGroup.controls.posExtensionDll.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined);
            this.formGroup.controls.posExtensionFileName.setValue(file?.name ?? '');
        });

        this.formGroup.controls.posExtensionFileName.disable();
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }
    override prepareSaveModel(): Partial<ExtensionModel> {
        const formValue = this.formGroup.value;
        return {
            title_de: formValue.title_de,
            title_en: formValue.title_en,
            title_fr: formValue.title_fr,
            title_it: formValue.title_it,
            description_de: formValue.description_de,
            description_en: formValue.description_en,
            description_fr: formValue.description_fr,
            description_it: formValue.description_it,
            isActive: formValue.isActive,
            nextExecutionTime: formValue.nextExecutionTime,
            posExtensionDll: formValue.posExtensionDll,
            posExtensionFileName: this.formGroup.getRawValue().posExtensionFileName
        };
    }
}
