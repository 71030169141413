@if (supplier(); as supplier) {
    <div class="h-full w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-7">
            <div class="min-h-[100px]">
                <p class="text-on-app-light text-sm">{{ 'General.Address.Address' | transloco }}</p>
                @if (supplier.isCompany){
                    <p>{{ supplier.displayName }}</p>
                }
                <p>
                    {{ supplier.salutation }} {{ supplier.salutationTitle }}
                    {{ supplier.isCompany ? supplier.companyContactName : supplier.firstname + ' ' + supplier.name }}
                </p>
                <p>{{ supplier.street }} {{ supplier.streetNumber }}</p>
                <p>{{ supplier.streetSupplement }}</p>
                @if (supplier.isPoBox){
                    <p>{{ 'General.Address.IsPoBox' | transloco }} {{ supplier.poBox }}</p>
                }
                <p>{{ supplier.zip }} {{ supplier.city }}</p>
            </div>
            <div class="min-h-[50px]">
                <p class="text-on-app-light text-sm">{{ 'General.Contact' | transloco }}</p>
                @if (!!supplier.contactEmail) {
                    <p class="flex gap-2">
                        <arc-icon icon="mail_outline" [size]="14"></arc-icon>
                        <a href="mailto:{{ supplier.contactEmail }}" class="line-clamp-1">{{ supplier.contactEmail }}</a>
                    </p>
                }
                @if (!!supplier.contactPhone) {
                    <p class="flex gap-2">
                        <arc-icon icon="phone" [size]="14"></arc-icon>
                        <a href="tel:{{ supplier.contactPhone }}">{{ supplier.contactPhone }}</a>
                    </p>
                }
                @if (!!supplier.contactMobile) {
                    <p class="flex gap-2">
                        <arc-icon icon="smartphone" [size]="14"></arc-icon>
                        <a href="tel:{{ supplier.contactMobile }}">{{ supplier.contactMobile }}</a>
                    </p>
                }
                @if (!!supplier.contactFax) {
                    <p class="flex gap-2">
                        <arc-icon icon="fax" [size]="14"></arc-icon>
                        <a href="fax:{{ supplier.contactFax }}">{{ supplier.contactFax }}</a>
                    </p>
                }
                @if (!!supplier.contactUrl) {
                    <p class="flex gap-2">
                        <arc-icon icon="language" [size]="14"></arc-icon>
                        <a target="_blank" href="//{{ websiteUrl() }}">{{ supplier.contactUrl }}</a>
                    </p>
                }
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-5">
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Detail.ContactData.CustomerNumber' | transloco }}</p>
                <p>{{ supplier.supplierCustomerNumber }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Detail.ContactData.MinimumOrderAmount' | transloco }}</p>
                <p>{{ supplier.personDeliveryCondition?.minOrderAmount | arcCurrency }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Detail.ContactData.DeliveryDays' | transloco }}</p>
                <p>{{ deliveryDays() }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Detail.ContactData.DeliveryDeadline' | transloco }}</p>
                @if (!!supplier.supplierDeliveryDays) {
                    <p>
                        {{ supplier.supplierDeliveryDays }} {{ 'General.Units.Days.Short' | transloco }}
                    </p>
                }
            </div>
        </div>
    </div>
} @else {
    <div class="flex items-center justify-center h-full">
        <span class="text-sm">
            {{ 'Suppliers.Detail.ContactData.NoSupplierSelected' | transloco }}
        </span>
    </div>
}
