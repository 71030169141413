import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SupplierEditRequestModel } from '../../../../../models/requests/supplier-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { PersonDeliveryConditionModel } from '../../../../../models/requests/person-delivery-condition.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { WeekOfMonthEnum } from '../../../../../models/enums/week-of-month.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { SupplierModel } from '../../../../../models/supplier.model';

@Component({
    selector: 'arc-supplier-edit-delivery-conditions',
    templateUrl: './supplier-edit-delivery-conditions.component.html',
    styleUrls: ['./supplier-edit-delivery-conditions.component.scss']
})
export class SupplierEditDeliveryConditionsComponent extends BaseEditSidebarItemComponent<SupplierModel, SupplierEditRequestModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    UnitsEnum = UnitsEnum;
    weekOfMonthOpts: any[] = [];

    override formGroup = new FormGroup({
        minOrderAmount: new ArcFormControl<OptionalType<number>>(undefined),
        supplierCustomerNumber: new ArcFormControl<OptionalType<string>>(undefined, Validators.maxLength(20)),
        monday: new ArcFormControl(true),
        tuesday: new ArcFormControl(true),
        wednesday: new ArcFormControl(true),
        thursday: new ArcFormControl(true),
        friday: new ArcFormControl(true),
        saturday: new ArcFormControl(false),
        sunday: new ArcFormControl(false),
        weekOfMonth: new ArcFormControl<OptionalType<number>>(WeekOfMonthEnum.Every),
        supplierDeliveryDays: new ArcFormControl<OptionalType<number>>(undefined),
        sendOrderImportDefinitionId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.weekOfMonthOpts = [
            { value: WeekOfMonthEnum.Every, label: this.translationService.getText('Enums.WeekOfMonthEnum.EveryWeek') },
            { value: WeekOfMonthEnum.First, label: this.translationService.getText('Enums.WeekOfMonthEnum.FirstWeek') },
            { value: WeekOfMonthEnum.Second, label: this.translationService.getText('Enums.WeekOfMonthEnum.SecondWeek') },
            { value: WeekOfMonthEnum.Third, label: this.translationService.getText('Enums.WeekOfMonthEnum.ThirdWeek') },
            { value: WeekOfMonthEnum.Fourth, label: this.translationService.getText('Enums.WeekOfMonthEnum.FourthWeek') }
        ];
    }

    override onItemSet(): void {
        this.formGroup.patchValue({
            ...this.item(),
            ...this.item().personDeliveryCondition
        });
    }

    override prepareSaveModel(): Partial<SupplierEditRequestModel> {
        const value = this.formGroup.getRawValue();
        const supplierCustomerNumber = value.supplierCustomerNumber;
        const supplierDeliveryDays = value.supplierDeliveryDays;
        const personDeliveryCondition: PersonDeliveryConditionModel = {
            personId: this.item().id,
            monday: value.monday,
            tuesday: value.tuesday,
            wednesday: value.wednesday,
            thursday: value.thursday,
            friday: value.friday,
            saturday: value.saturday,
            sunday: value.sunday,
            minOrderAmount: value.minOrderAmount,
            weekOfMonth: value.weekOfMonth,
            sendOrderImportDefinitionId: value.sendOrderImportDefinitionId
        };

        return { supplierCustomerNumber, supplierDeliveryDays, personDeliveryCondition };
    }
}
