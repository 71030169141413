import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchEditModel } from '../../../../../models/branch-edit.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { Tools } from '../../../../../../core/utils/tools/index';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-branch-edit-base-data',
    templateUrl: './branch-edit-base-data.component.html',
    styleUrl: './branch-edit-base-data.component.scss'
})
export class BranchEditBaseDataComponent extends BaseEditSidebarItemComponent<BranchModel, BranchEditModel> {
    PermissionsEnum = PermissionsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        name: new ArcFormControl('', Validators.required),
        regionId: new ArcFormControl<OptionalType<number>>(undefined),
        street: new ArcFormControl<OptionalType<string>>(undefined),
        streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        zip: new ArcFormControl<OptionalType<string>>(undefined),
        countryIsoCode: new ArcFormControl<OptionalType<string>>(undefined),
        mainStoreId: new ArcFormControl<OptionalType<number>>(undefined),
        orderStoreId: new ArcFormControl<OptionalType<number>>(undefined),
        gln: new ArcFormControl<OptionalType<string>>(undefined),
        canGenerateOrderNotifications: new ArcFormControl<OptionalType<boolean>>(undefined),
        dayStart: new ArcFormControl<OptionalType<string>>(undefined),
        priceLevel: new ArcFormControl<OptionalType<number>>(undefined),
        place: new ArcFormControl<OptionalType<string>>(undefined)
    });

    readonly allowedTimeValues: string[] = Tools.Time.generateSelectList().map(d => d.label);

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<BranchEditModel> {
        const value = this.formGroup.value;
        const dayStart = (value.dayStart?.length || 0) < 6
            ? `${value.dayStart}:00`
            : value.dayStart;
        return {
            name: value.name,
            regionId: value.regionId,
            street: value.street,
            streetNumber: value.streetNumber,
            zip: value.zip,
            countryIsoCode: value.countryIsoCode,
            mainStoreId: value.mainStoreId,
            orderStoreId: value.orderStoreId,
            gln: value.gln,
            canGenerateOrderNotifications: value.canGenerateOrderNotifications,
            dayStart: dayStart,
            priceLevel: value.priceLevel,
            place: value.place
        };
    }

    dayStartCompare(op1: string, op2: string): boolean {
        op1 = op1.length > 5 ? op1.substring(0, 5) : op1;
        op2 = op2.length > 5 ? op2.substring(0, 5) : op2;

        return op1 === op2;
    }
}
