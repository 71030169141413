<div class="h-full w-full overflow-auto">
    <div class="flex gap-4 items-center h-[44px]">
        <mat-icon>{{ icon() }}</mat-icon>
        <span class="text-2xl">{{ entityModel().paymentTypeTitle }}</span>
    </div>
    <div class="grid grid-cols-12">
        @switch (entityModel().type) {
            @case(TransactionPaymentTypeEnum.Card) {
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.CardNumber' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().eftCardNumber }}
                </div>
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.TransactionNumber' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().eftTrxRef }}
                </div>
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.AuthCode' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().eftAuthNumber }}
                </div>
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.TerminalId' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().eftTerminalId }}
                </div>
            }
            @case(TransactionPaymentTypeEnum.Voucher) {
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.VoucherId' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().voucherId }}
                </div>
                <div class="flex flex-col gap-4 col-span-5">
                    {{ 'TransactionPayments.Detail.Details.RemainingValue' | transloco }}:
                </div>
                <div class="flex flex-col gap-4 col-span-7">
                    {{ entityModel().voucherRemainingValue | arcCurrency }}
                </div>
            }
            @default {
                <div>-</div>
            }
        }
    </div>
</div>
