export class ButtonsVisibilityModel {
    hasCreate = true;
    hasEdit = true;
    hasDelete = true;
    hasImport = false; // TODO: temporary for beta, since only article import is done yet (as of 2023/11/23)
    hasExport = true;
    hasDuplicate = false;

    constructor(init?: Partial<ButtonsVisibilityModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
