<div class="flex items-center mb-4">
    <span>{{ 'General.Type' | transloco }}</span>
    <arc-button-toggle-group [control]="formGroup.controls.isDiscountVoucher" [items]="isDiscountVoucherItems"
        class="ml-4 w-9/12"></arc-button-toggle-group>
</div>
<div class="mb-4">
    <arc-translation-input translationDialogTitleKey="General.Actions.TranslateTitle" labelKey="General.Title"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it">
    </arc-translation-input>
</div>
<div class="grid items-center grid-cols-2 ssm:grid-cols-1 gap-4">
    @if (!isDiscountVoucher()) {
        <arc-datepicker [control]="formGroup.controls.validFrom" labelKey="VoucherTypes.Edit.BaseData.ValidFrom"></arc-datepicker>
        <arc-datepicker [control]="formGroup.controls.validThru" labelKey="VoucherTypes.Edit.BaseData.ValidUntil"></arc-datepicker>
        <arc-general-data-select class="grow" label="VoucherTypes.Edit.BaseData.PaymentType"
            [generalDataType]="GeneralDataTypeEnum.PaymentTypes" [formControl]="formGroup.controls.paymentTypeId">
        </arc-general-data-select>
        <arc-currency-input label="VoucherTypes.Edit.BaseData.MinimumValue" [formControl]="formGroup.controls.minimalVoucherValue">
        </arc-currency-input>
        <arc-checkbox labelKey="VoucherTypes.Edit.BaseData.GenerateVoucherId" [control]="formGroup.controls.generateVoucherId">
        </arc-checkbox>
    } @else {
        @if (!formGroup.value.isDiscountPercent) {
            <arc-currency-input label="VoucherTypes.Edit.BaseData.Discount" [formControl]="formGroup.controls.discountAsNumber">
            </arc-currency-input>
        } @else {
            <arc-number-input label="VoucherTypes.Edit.BaseData.Discount" [formControl]="formGroup.controls.discountAsNumber"
                [unit]="UnitsEnum.SpacedPercentSymbol" [isUnitTranslated]="true" [decimalPlaces]="2">
            </arc-number-input>
        }
        <div class="flex items-center">
            <arc-button-toggle-group [control]="formGroup.controls.isDiscountPercent" [items]="isDiscountPercentItems"
                class="w-full"></arc-button-toggle-group>
        </div>
        <arc-datepicker [control]="formGroup.controls.validFrom" labelKey="VoucherTypes.Edit.BaseData.ValidFrom"></arc-datepicker>
        <arc-datepicker [control]="formGroup.controls.validThru" labelKey="VoucherTypes.Edit.BaseData.ValidUntil"></arc-datepicker>
        <arc-tree-autocomplete label="VoucherTypes.Edit.BaseData.ValidForGroup" [formControl]="formGroup.controls.validForArticleGroupId"
            [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfigForSelection">
        </arc-tree-autocomplete>
        <arc-chip-selector class="grow" label="VoucherTypes.Edit.BaseData.ValidForTags" [formControl]="formGroup.controls.tags"
            [uniqueIdFn]="getTagId" [displayFn]="tagDisplayFn" [searchFn]="tagSearchFn.bind(this)">
        </arc-chip-selector>
        <arc-general-data-select class="grow" label="VoucherTypes.Edit.BaseData.ValidForBranch"
            [generalDataType]="GeneralDataTypeEnum.Branches" [formControl]="formGroup.controls.validForBranchId"
            [requiredPermission]="PermissionsEnum.Branches">
        </arc-general-data-select>
        <arc-currency-input label="VoucherTypes.Edit.BaseData.ValidFromValue" [formControl]="formGroup.controls.validFromAmount">
        </arc-currency-input>
        <arc-currency-input label="VoucherTypes.Edit.BaseData.ValidForArticleMinPrice"
            [formControl]="formGroup.controls.validForArticleMinPrice">
        </arc-currency-input>
        <arc-number-input label="VoucherTypes.Edit.BaseData.BonusFactor" [formControl]="formGroup.controls.bonusFactor"
            [requiredPermission]="PermissionsEnum.CustomerBonusPrograms" [decimalPlaces]="2">
        </arc-number-input>
        <arc-checkbox labelKey="VoucherTypes.Edit.BaseData.VoucherCanBeAccumulated"
            [control]="formGroup.controls.cumulateVouchers"></arc-checkbox>
        <div></div>
        <arc-checkbox labelKey="VoucherTypes.Edit.BaseData.DiscountCanBeAccumulated"
            [control]="formGroup.controls.cumulateDiscounts"></arc-checkbox>
        <div></div>
        <arc-checkbox labelKey="VoucherTypes.Edit.BaseData.IsReusable" [control]="formGroup.controls.isReusable"></arc-checkbox>
        <div></div>
        <arc-number-input label="VoucherTypes.Edit.BaseData.ValidFromQuantity" [formControl]="formGroup.controls.validFromQuantity">
        </arc-number-input>
        <arc-number-input label="VoucherTypes.Edit.BaseData.ApplicableToXCheapestItems"
            [formControl]="formGroup.controls.applyOnCheapestNArticles">
        </arc-number-input>
    }
</div>
