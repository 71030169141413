import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerEditRequestModel } from '../../../../../models/requests/customer-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CustomerModel } from '../../../../../models/customer.model';

@Component({
    selector: 'arc-customer-edit-base-data',
    templateUrl: './customer-edit-base-data.component.html',
    styleUrls: ['./customer-edit-base-data.component.scss']
})
export class CustomerEditBaseDataComponent extends BaseEditSidebarItemComponent<CustomerModel, CustomerEditRequestModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        personNumber: new ArcFormControl('', [Validators.required, Validators.maxLength(20)]),
        isCompany: new ArcFormControl(false, Validators.required),
        companyName: new ArcFormControl('', [Validators.maxLength(150)]),
        legalFormId: new ArcFormControl<OptionalType<number>>(undefined),
        salutationId: new ArcFormControl<OptionalType<number>>(undefined),
        salutationTitleId: new ArcFormControl<OptionalType<number>>(undefined),
        firstname: new ArcFormControl('', Validators.maxLength(50)),
        name: new ArcFormControl('', [Validators.maxLength(150)]),
        street: new ArcFormControl('', Validators.maxLength(100)),
        streetNumber: new ArcFormControl('', Validators.maxLength(25)),
        streetSupplement: new ArcFormControl('', Validators.maxLength(100)),
        isPoBox: new ArcFormControl(false, Validators.required),
        poBox: new ArcFormControl('', Validators.maxLength(50)),
        zip: new ArcFormControl('', Validators.required),
        city: new ArcFormControl('', Validators.required),
        countryIsoCode: new ArcFormControl('', Validators.required),
        birthdate: new ArcFormControl<OptionalType<Date>>(undefined)
    });

    constructor() {
        super();

        this.formGroup.controls.isPoBox.valueChanges.subscribe(value => this.formGroup.controls.poBox.setDisabled(!value));
        this.formGroup.controls.isCompany.valueChanges.subscribe(isCompany => {
            this.formGroup.controls.companyName.setDisabled(!isCompany);
            this.formGroup.controls.legalFormId.setDisabled(!isCompany);

            if (isCompany) {
                this.formGroup.controls.companyName.addValidators(Validators.required);
                this.formGroup.controls.name.removeValidators(Validators.required);
            } else {
                this.formGroup.controls.companyName.removeValidators(Validators.required);
                this.formGroup.controls.name.addValidators(Validators.required);
            }

            // need to update validity on controls, updating whole group doesn't work
            this.formGroup.controls.companyName.updateValueAndValidity();
            this.formGroup.controls.name.updateValueAndValidity();
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<CustomerEditRequestModel> {
        const value = this.formGroup.value;
        return {
            personNumber: value.personNumber,
            isCompany: value.isCompany,
            companyName: value.isCompany ? value.companyName : undefined,
            legalFormId: value.isCompany ? value.legalFormId : undefined,
            salutationId: value.salutationId,
            salutationTitleId: value.salutationTitleId,
            firstname: value.firstname,
            name: value.name,
            street: value.street,
            streetNumber: value.streetNumber,
            streetSupplement: value.streetSupplement,
            isPoBox: value.isPoBox,
            poBox: value.isPoBox ? value.poBox : undefined,
            zip: value.zip,
            city: value.city,
            countryIsoCode: value.countryIsoCode,
            birthdate: value.birthdate
        };
    }
}
