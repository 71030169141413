import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-settings-prefixes',
    templateUrl: './settings-settings-prefixes.component.html',
    styleUrl: './settings-settings-prefixes.component.scss'
})
export class SettingsSettingsPrefixesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        priceEANPrefixes: new ArcFormControl<OptionalType<string>>(undefined),
        weightEANPrefixes: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixCustomer: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixTransaction: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixReservation: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixSubscription: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixDiscount: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixBoncard: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixAppBasket: new ArcFormControl<OptionalType<string>>(undefined),
        scanPrefixUserSwitch: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            priceEANPrefixes: value.priceEANPrefixes,
            weightEANPrefixes: value.weightEANPrefixes,
            scanPrefixCustomer: value.scanPrefixCustomer,
            scanPrefixTransaction: value.scanPrefixTransaction,
            scanPrefixReservation: value.scanPrefixReservation,
            scanPrefixSubscription: value.scanPrefixSubscription,
            scanPrefixDiscount: value.scanPrefixDiscount,
            scanPrefixBoncard: value.scanPrefixBoncard,
            scanPrefixAppBasket: value.scanPrefixAppBasket,
            scanPrefixUserSwitch: value.scanPrefixUserSwitch
        };
    }
}
