import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountingExportRecordListModel } from '../../models/responses/accounting-export-record-list.model';
import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { AccountingExportRecordModel } from '../../models/accounting-export-record.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { AccountingRecordMultiCreateModel } from '../../models/requests/accounting-record-multi-create.model';

@Injectable({
    providedIn: 'root'
})
export class AccountingExportRecordsStore extends BaseCrudStore<AccountingExportRecordModel, AccountingExportRecordListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'accounting-export-records' }));
    }

    multiCreate(requestModel: AccountingRecordMultiCreateModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl('multi-create'), requestModel);
    }
}
