import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-invoices-qr-bill',
    templateUrl: './settings-invoices-qr-bill.component.html',
    styleUrl: './settings-invoices-qr-bill.component.scss'
})
export class SettingsInvoicesQrBillComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        invoiceIban: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceCompanyName: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceStreet: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceStreetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceStreetSupplement: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceZip: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceCity: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceCountryIsoCode: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            invoiceIban: value.invoiceIban,
            invoiceCompanyName: value.invoiceCompanyName,
            invoiceStreet: value.invoiceStreet,
            invoiceStreetNumber: value.invoiceStreetNumber,
            invoiceStreetSupplement: value.invoiceStreetSupplement,
            invoiceZip: value.invoiceZip,
            invoiceCity: value.invoiceCity,
            invoiceCountryIsoCode: value.invoiceCountryIsoCode
        };
    }
}
