<div class="grid flex-col grid-flow-row relative" [ngStyle]="gridStyle()" [style.gap.px]="gapSizePx">
    @for (cell of grid1d(); track cell.index; let i = $index) {
        @if (cell.column < 1 || !grid1d()[i - 1].command?.isDoubleSize) {
            <div class="overflow-hidden size-full" [class.col-span-2]="cell.command?.isDoubleSize">
                @if (cell.command) {
                    <arc-pos-layout-edit-command-cell [posLayoutGroup]="posLayoutGroup()" [posLayoutGroupCommand]="cell.command"
                        (edit)="editCommand(cell.command.id)" (remove)="removeCommand(cell.command)"
                        (reload)="reload()" (dragover)="dragOver($event)" (drop)="drop($event, cell.command, cell.index + 1)"
                        (dragstart)="dragStart($event, cell.command)">
                    </arc-pos-layout-edit-command-cell>
                } @else {
                    <div matRipple (click)="addCommand(cell.index)" (dragover)="dragOver($event)"
                        (drop)="drop($event, undefined, cell.index + 1)"
                        class="grid rounded-lg cursor-pointer bg-element-selected text-element-disabled size-full place-items-center">
                        <arc-icon icon="add" [size]="48"></arc-icon>
                    </div>
                }
            </div>
        }
    }

    @if(isLoading()) {
        <div class="absolute z-10 bg-overlay inset-0 grid place-items-center rounded-lg">
            <mat-spinner class="!text-on-app" [diameter]="64"></mat-spinner>
        </div>
    }
</div>
