<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color" [arcErrorTooltip]="_formControl"
    [arcPortalTooltip]="label" [isTranslated]="isLabelTranslated" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <textarea [style.height.px]="height()" matInput [formControl]="internalControl" (input)="onInput()"
        [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)" [attr.maxlength]="maxLength()"
        [attr.minlength]="minLength()">
    </textarea>
    @if (!isAllowed) {
        <span matSuffix>
            <arc-no-permission-lock [missingPermission]="permission">
            </arc-no-permission-lock>
        </span>
    }
</mat-form-field>
