<div class="flex flex-col gap-4">
    <arc-input class="grow" [control]="formGroup.controls.extText" labelKey="ExtAccountingExport.Edit.AccountingRecord.ExtText"></arc-input>
    <div class="flex gap-8 mt-4">
        <div class="flex flex-col gap-4 w-[30%]">
            <span>{{'ExtAccountingExport.Edit.AccountingRecord.Debit' | transloco}}</span>
            <arc-input [control]="formGroup.controls.extDebitAccount"
                labelKey="ExtAccountingExport.Edit.AccountingRecord.Account"></arc-input>
            <arc-input [control]="formGroup.controls.extDebitCc"
                labelKey="ExtAccountingExport.Edit.AccountingRecord.CostCenter"></arc-input>
            <arc-input class="mt-4" [control]="formGroup.controls.extTaxCode"
                labelKey="ExtAccountingExport.Edit.AccountingRecord.ExtTaxCode"></arc-input>
        </div>
        <div class="flex flex-col gap-4 w-[30%]">
            <span>{{'ExtAccountingExport.Edit.AccountingRecord.Credit' | transloco}}</span>
            <arc-input class="grow-0" [control]="formGroup.controls.extCreditAccount"
                labelKey="ExtAccountingExport.Edit.AccountingRecord.Account"></arc-input>
            <arc-input class="grow-0" [control]="formGroup.controls.extCreditCc"
                labelKey="ExtAccountingExport.Edit.AccountingRecord.CostCenter"></arc-input>
        </div>
        <div class="flex items-center">
            <arc-checkbox class="mt-4" labelKey="ExtAccountingExport.Edit.AccountingRecord.InvertAmount"
                [control]="formGroup.controls.invertAmount"></arc-checkbox>
        </div>
    </div>
</div>
