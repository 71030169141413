import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { UserModel } from '../../models/user.model';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { ResetPasswordRequestModel } from '../../models/requests/reset-password-request.model';
import { LayoutSettingsModel } from '../../models/layout-settings.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { DashboardConfigurationModel } from '../../../core/models/dashboard-configuration.model';
import { ListViewSettingsModel } from '../../../core/models/list-view-settings.model';
import { TableSettingsModel } from '../../../core/models/table-settings.model';
import { UserListModel } from '../../models/responses/user-list.model';
import { EnvironmentService } from '../../../core/services/environment.service';
import { UserEditModel } from '../../models/user-edit.model';

@Injectable()
export class UsersStore extends BaseCrudStore<UserModel, UserListModel, UserEditModel> {
    private readonly environmentService = inject(EnvironmentService);

    constructor() {
        super(new StoreConfig({ baseController: 'users' }));
    }

    requestPasswordChange(email: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(
            this.getUrl('request-password-change'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new KeyValueModel({
                key: 'email',
                value: email
            }),
            new KeyValueModel({
                key: 'subdomain',
                value: this.environmentService.getSubdomain()
            })
        );
    }

    changeLanguage(userId: string, languageId: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${userId}/change-language/${languageId}`));
    }

    resetPassword(userId: number, token: string, model: ResetPasswordRequestModel): Observable<ApiResponseModel<boolean>> {
        model.subdomain = this.environmentService.getSubdomain();
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${userId}/reset-password/${token}`), model);
    }

    isTokenValid(userId: number, token: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeGet<ApiResponseModel<boolean>>(
            this.getUrl(`${userId}/is-token-valid/${token}`),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new KeyValueModel({
                key: 'subdomain',
                value: this.environmentService.getSubdomain()
            })
        );
    }

    /**
     * Saves a specific set settings for the logged user.
     * @param key Identifier of the settings.
     * @param data Settings to be saved.
     */
    saveSettings<T>(key: string, data: T): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`current/settings/${key}`), data);
    }

    /**
     * Retrieves a specific set settings for the logged user.
     * @param key Identifier of the settings.
     */
    getSettings<T>(key: string): Observable<ApiResponseModel<OptionalType<T>>> {
        return this._requestService.makeGet<ApiResponseModel<string>>(this.getUrl(`current/settings/${key}`)).pipe(
            map(r => ({
                ...r,
                value: r.value ? (JSON.parse(r.value) as T) : undefined
            }))
        );
    }

    /**
     * Saves the logged user's dashboard settings.
     */
    saveDashboardSettings(data: DashboardConfigurationModel): Observable<ApiResponseModel<boolean>> {
        return this.saveSettings('dashboard', data);
    }

    /**
     * Retrieves the logged user's dashboard settings.
     */
    getDashboardSettings(): Observable<ApiResponseModel<OptionalType<DashboardConfigurationModel>>> {
        return this.getSettings<DashboardConfigurationModel>('dashboard');
    }

    /**
     * Saves the logged user's specific list view settings.
     */
    saveListViewSettings(data: ListViewSettingsModel, listName: string): Observable<ApiResponseModel<boolean>> {
        return this.saveSettings(`list-view-${listName}`, data);
    }

    /**
     * Retrieves the logged user's specific list view settings.
     */
    getListViewSettings(listName: string): Observable<ApiResponseModel<OptionalType<ListViewSettingsModel>>> {
        return this.getSettings<ListViewSettingsModel>(`list-view-${listName}`);
    }

    /**
     * Saves the logged user's specific table settings.
     */
    saveTableSettings(data: TableSettingsModel, tableName: string): Observable<ApiResponseModel<boolean>> {
        return this.saveSettings(`table-${tableName}`, data);
    }

    /**
     * Retrieves the logged user's specific table settings.
     */
    getTableSettings(tableName: string): Observable<ApiResponseModel<OptionalType<TableSettingsModel>>> {
        return this.getSettings<TableSettingsModel>(`table-${tableName}`);
    }

    /**
     * Saves user's layout settings.
     */
    saveLayoutSettings(data: LayoutSettingsModel): Observable<ApiResponseModel<boolean>> {
        return this.saveSettings('layout', data);
    }

    /**
     *  Retrieves user's layout settings.
     */
    getLayoutSettings(): Observable<ApiResponseModel<OptionalType<LayoutSettingsModel>>> {
        return this.getSettings<LayoutSettingsModel>('layout');
    }

    /**
     * Remove user's layout settings.
     */
    removeLayoutSettings(): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(this.getUrl('current/settings/layout'), '');
    }

    requestEmailVerificationCode(email: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`email/${email}`));
    }

    verifyEmailCode(email: string, code: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`email/${email}/${code}`));
    }
}
