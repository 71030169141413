import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TagModel } from '../../../../../models/tag.model';
import { TagEditModel } from '../../../../../models/requests/tag-edit.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { SearchResponseModel } from '../../../../../models/responses/search-response.model';
import { RelatedEntityRequestModel } from '../../../../../models/requests/tag-articles-request.model';
import { ApiResponseModel } from '../../../../../models/responses/api-response.model';
import { RelatedEntityUpdateModel } from '../../../../../models/requests/related-entity-update.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { ArticlesStore } from '../../../../../services/stores/articles.store';

@Component({
    selector: 'arc-tag-edit-articles',
    templateUrl: './tag-edit-articles.component.html',
    styleUrls: ['./tag-edit-articles.component.scss']
})
export class TagEditArticlesComponent extends BaseEditSidebarItemComponent<TagModel, TagEditModel> {
    ComplexDataTypeEnum = ComplexDataTypesEnum;
    PermissionsEnum = PermissionsEnum;
    updatedArticles = new RelatedEntityUpdateModel();
    getDataFn: (id: number, requestModel: RelatedEntityRequestModel) => Observable<ApiResponseModel<SearchResponseModel<ArticleListModel>>>;

    private readonly articlesStore = inject(ArticlesStore);

    constructor() {
        super();
        this.getDataFn = this.articlesStore.getArticlesByTagId.bind(this.articlesStore);
    }

    override onItemSet(): void {
    }

    override hasUnsavedChanges(): boolean {
        return this.updatedArticles.hasChanges();
    }

    override prepareSaveModel(): Partial<TagEditModel> {
        if (this.updatedArticles.hasChanges()) {
            return { relatedArticleChanges: this.updatedArticles };
        }

        return {};
    }

    updateRelatedEntityChanges(event: RelatedEntityUpdateModel): void {
        this.updatedArticles = event;
    }
}
