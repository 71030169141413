import { MatDialog } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Observable, tap, map, of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { InventoryListModel } from '../../../models/responses/inventory-list.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { InventoryModel } from '../../../models/inventory.model';
import { InventoriesStore } from '../../../services/stores/inventories.store';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { InventoryStatusColumnComponent } from './components/inventory-status-column/inventory-status-column.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { InventoryTypeColumnComponent } from './components/inventory-type-column/inventory-type-column.component';
import { InventoryCreateDialogComponent } from './inventory-create-dialog/inventory-create-dialog.component';
import { InventoryCreateModel } from '../../../models/requests/inventory-create.model';
import { InventoryEditBaseDataComponent } from './inverntory-edit-items/inventory-edit-base-data/inventory-edit-base-data.component';
import { InventoryUpdateModel } from '../../../models/requests/inventory-update.model';
import { InventoryEditArticlesComponent } from './inverntory-edit-items/inventory-edit-articles/inventory-edit-articles.component';
import { InventoryTypeEnum } from '../../../models/enums/inventory-type.enum';
import { InventoryEditHeaderAdditionalInfoComponent } from './inventory-edit-header-additional-info/inventory-edit-header-additional-info.component';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { ButtonColumnModel } from '../../../../components/dynamic-table/models/column-types/button-column.model';
import { TranslationService } from '../../../../core/services/translation.service';

@Component({
    selector: 'arc-inventories',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './inventories.component.scss'
})
export class InventoriesComponent
    extends PageListViewComponent<InventoryListModel, InventoryModel, InventoryCreateModel, InventoryUpdateModel> {
    private readonly matDialog = inject(MatDialog);
    private readonly inventoriesStore = inject(InventoriesStore);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super('Inventories');

        this.config = new TableListViewConfigModel<InventoryListModel, InventoryModel, InventoryCreateModel, InventoryUpdateModel>({
            entityName: 'Inventories',
            availableColumns: {
                type: new CustomColumnModel({
                    columnTitleKey: 'Inventories.List.Type',
                    customComponent: InventoryTypeColumnComponent,
                    widthPixels: 150
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'Inventories.List.Title',
                    propertyName: 'title',
                    widthPixels: 250
                }),
                warehouse: new StringColumnModel({
                    columnTitleKey: 'Inventories.List.Store',
                    propertyName: 'store',
                    widthPixels: 250
                }),
                startDate: new DateColumnModel({
                    columnTitleKey: 'Inventories.List.StartDate',
                    propertyName: 'startDate',
                    format: 'short',
                    widthPixels: 150
                }),
                finishedDate: new DateColumnModel({
                    columnTitleKey: 'Inventories.List.FinishedDate',
                    propertyName: 'finishedDate',
                    format: 'short',
                    widthPixels: 150
                }),
                articlesValue: new CurrencyColumnModel({
                    columnTitleKey: 'Inventories.List.ArticlesValue',
                    propertyName: 'articlesValue',
                    widthPixels: 150
                }),
                articlesCount: new ButtonColumnModel({
                    columnTitleKey: 'Inventories.List.ArticlesCount',
                    propertyName: 'articlesCount',
                    suffix: (item: InventoryListModel) => this.getButtonSuffix(item),
                    icon: () => 'launch',
                    bgColor: 'var(--btn-dark)',
                    fontColor: 'var(--on-primary)',
                    onClickFunction: (item: InventoryListModel) => this.navigateToInventoryArticles(item.id),
                    hideButton: (item: InventoryListModel) => this.hideButton(item),
                    widthPixels: 200
                }),
                status: new CustomColumnModel({
                    columnTitleKey: 'Inventories.List.Status',
                    propertyName: 'status',
                    customComponent: InventoryStatusColumnComponent,
                    widthPixels: 160
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'title',
                direction: SortDirectionEnum.Asc
            }),
            defaultColumnOrder: ['type', 'title', 'warehouse', 'startDate', 'finishedDate', 'articlesValue', 'articlesCount', 'status'],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            handleCreateButtonClick: this.handleCreate.bind(this),
            editSidebarConfig: {
                editComponents: [
                    { component: InventoryEditBaseDataComponent, titleKey: 'Inventories.Edit.BaseData.ComponentTitle' },
                    { component: InventoryEditArticlesComponent, titleKey: 'Inventories.Edit.Articles.ComponentTitle' }
                ],
                customMaxWidth: '1200px',
                customHeaderTitle: inventory => of(`${this.getInventoryTypeTitle(inventory)}`),
                headerSubtitle: inventory =>
                    of(`${inventory.title}, ${translate('Inventories.Edit.BaseData.Store')}: ${inventory.storeName}`),
                headerAdditionalInfoComponent: InventoryEditHeaderAdditionalInfoComponent
            },
            store: this.inventoriesStore
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService.updateIsHidden(
            ActionButtonsService.deleteButtonKey,
            (btn, data) => !!data && !data.canBeCompleted
        );
    }

    private navigateToInventoryArticles(id: number): void {
        this._router.navigate(['inventory-articles'], { queryParams: { InventoryId: [id] } }).then();
    }

    private hideButton(item: InventoryListModel): boolean {
        return item.articlesTotalCount <= 0;
    }

    private getButtonSuffix(item: InventoryListModel): string {
        if (item.inventoryType !== InventoryTypeEnum.Continuous) {
            return this.translationService.getText('Inventories.List.OfArticles', {
                numberOfArticles: item.articlesTotalCount
            });
        }

        return 'Inventories.List.Articles';
    }

    private getInventoryTypeTitle(inventory: InventoryModel): string {
        switch (inventory.inventoryType) {
            case InventoryTypeEnum.Complete:
                return translate('Enums.InventoryTypeEnum.Complete');
            case InventoryTypeEnum.Continuous:
                return translate('Enums.InventoryTypeEnum.Continuous');
            case InventoryTypeEnum.Partial:
                return translate('Enums.InventoryTypeEnum.Partial');
        }
    }

    private handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(InventoryCreateDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw',
                minWidth: '25vw'
            })
            .afterClosed()
            .pipe(
                tap<any>(result => {
                    if (!result?.id) {
                        return;
                    }
                    this.listView.openEditSidebar(result.id).subscribe(shouldReload => {
                        if (shouldReload) {
                            this.listView.refresh();
                        }
                    });
                }),
                map(result => result?.shouldReload ?? false)
            );
    }
}
