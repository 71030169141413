import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';

@Component({
    selector: 'arc-settings-settings-booking-types',
    templateUrl: './settings-settings-booking-types.component.html',
    styleUrl: './settings-settings-booking-types.component.scss'
})
export class SettingsSettingsBookingTypesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        posTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        invoiceTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        warehouseTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        posTakeoutTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        posEndOfDayTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        userEndOfDayTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        voucherTransactionTypeId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            posTransactionTypeId: value.posTransactionTypeId,
            invoiceTransactionTypeId: value.invoiceTransactionTypeId,
            warehouseTransactionTypeId: value.warehouseTransactionTypeId,
            posTakeoutTransactionTypeId: value.posTakeoutTransactionTypeId,
            posEndOfDayTransactionTypeId: value.posEndOfDayTransactionTypeId,
            userEndOfDayTransactionTypeId: value.userEndOfDayTransactionTypeId,
            voucherTransactionTypeId: value.voucherTransactionTypeId
        };
    }
}
