import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArticleGroupEditModel } from '../../../../../models/responses/article-group-edit.model';
import { ArticleGroupModel } from '../../../../../models/article-group.model';

@Component({
    selector: 'arc-article-group-edit',
    templateUrl: './article-group-edit.component.html',
    styleUrl: './article-group-edit.component.scss'
})
export class ArticleGroupEditComponent extends BaseEditSidebarItemComponent<ArticleGroupModel, ArticleGroupEditModel> {
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        articleGroupNumber: new ArcFormControl<OptionalType<string>>(undefined),
        isInoperative: new ArcFormControl<boolean>(false)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ArticleGroupEditModel> {
        const value = this.formGroup.value;
        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            articleGroupNumber: value.articleGroupNumber,
            isInoperative: value.isInoperative
        };
    }
}
