<mat-form-field [floatLabel]="hasFloatingLabel || !!prefixText ? 'always' : 'auto'" class="w-full" [color]="color" [arcErrorTooltip]="control"
    [arcPortalTooltip]="labelKey || label" [isTranslated]="!!label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="label || labelKey">{{ label || (labelKey | transloco) }}</mat-label>
    @if (!!prefixText) {
        <span matTextPrefix>
            {{ prefixText }}
        </span>
    }
    <input matInput [id]="tagId" [type]="type" (blur)="onBlur()" (input)="onChange($event)" [attr.step]="step"
        [placeholder]="placeholder || (placeholderKey | transloco)" [formControl]="castedControl" [attr.maxlength]="maxLength"
        [attr.minlength]="minLength" [attr.size]="inputSize" [mask]="mask" [showMaskTyped]="showMaskTyped" [ngClass]="inputClasses()">
    @if(showTextCount) {
        <mat-hint align="end">{{ castedControl.value.length }} / {{maxLength}}</mat-hint>
    }
    <div matSuffix class="flex gap-2 items-center">
        <arc-no-permission-lock *ngIf="!isAllowed" [missingPermission]="permission">
        </arc-no-permission-lock>
        <button *ngIf="!isActionLoading && !!actionIcon" type="button" mat-icon-button (click)="executeAction()"
            [disabled]="control.disabled">
            <mat-icon>{{ actionIcon }}</mat-icon>
        </button>
        <div *ngIf="isActionLoading" class="w-[40px] h-[40px] grid place-items-center">
            <mat-spinner diameter="22"></mat-spinner>
        </div>
        <ng-content select="[suffix]"></ng-content>
    </div>
</mat-form-field>
