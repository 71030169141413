<form [formGroup]="formGroup" class="grid items-center grid-cols-2 gap-4">
    <arc-input labelKey="PointOfSales.Edit.BaseData.Name" [control]="formGroup.controls.name"></arc-input>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches" [formControl]="formGroup.controls.branchId"
        label="PointOfSales.Edit.BaseData.Branch"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PosLayouts" [formControl]="formGroup.controls.posLayoutId"
        label="PointOfSales.Edit.BaseData.Layout"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Taxes" [formControl]="formGroup.controls.overrideTaxId"
        label="PointOfSales.Edit.BaseData.OverrideTax"></arc-general-data-select>
    <arc-datepicker labelKey="PointOfSales.Edit.BaseData.DeactivationDate" [control]="formGroup.controls.deactivationDate"></arc-datepicker>
</form>
