@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} 
@else if(!!selectedArticle()) {
    <arc-selected-article [selectedArticle]="selectedArticle()!" [showRelatedArticles]="false"></arc-selected-article>
} @else if(!!createTicketResponse()){
    <div class="flex flex-col gap-4 ml-2 mt-4">
        <div class="flex flex-col gap-2">
            <h2 class="text-accent font-bold">{{'Tickets.Response.Solution' | transloco}}</h2>
            <p>{{createTicketResponse()!.recommendedSolution}}</p>
        </div>

        @if(recommendedArticles().length > 0){
            <h2 class="text-accent font-bold mt-8">{{'Help.RecommendedArticles' | transloco}}</h2>
            <div class="flex flex-col gap-4 mt-4 mb-10">
                @for(article of recommendedArticles(); track article.id) {
                    <arc-article-list-item [article]="article" (articleSelected)="selectArticle($event)"></arc-article-list-item>
                }
            </div>
        }
    </div>
}