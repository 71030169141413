import { Component, computed } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionPaymentListModel } from '../../../../../models/responses/transaction-payment-list.model';
import { TransactionPaymentModel } from '../../../../../models/responses/transaction-payment.model';
import { TransactionPaymentTypeEnum } from '../../../../../models/enums/transaction-payment-type.enum';

@Component({
    selector: 'arc-transaction-payment-details',
    templateUrl: './transaction-payment-details.detail-widget.html',
    styleUrls: ['./transaction-payment-details.detail-widget.scss']
})
export class TransactionPaymentDetailsDetailWidget extends BaseDetailWidget<TransactionPaymentListModel, TransactionPaymentModel> {
    readonly TransactionPaymentTypeEnum = TransactionPaymentTypeEnum;
    readonly icon = computed(() => {
        switch (this.entityModel().type) {
            case TransactionPaymentTypeEnum.Card:
                return 'credit_card';
            case TransactionPaymentTypeEnum.Voucher:
                return 'card_giftcard';
            case TransactionPaymentTypeEnum.Cash:
                return 'payments';
            default:
                return 'list_alt';
        }
    });
}
