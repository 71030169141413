<form [formGroup]="formGroup" *ngIf="!isLoading; else loading">
    <div class="flex gap-4">
        <div class="flex grow flex-col gap-4">
            <arc-currency-input *ngFor="let priceLevel of priceLevels; let i = index" [label]="priceLevel.value" [isLabelTranslated]="true"
                [requiredPermission]="PermissionsEnum.PriceLevels" [requiredPermissionType]="PermissionTypeEnum.Update"
                [formControl]="formGroup.controls[pricePrefix+priceLevel.key] | formControl">
            </arc-currency-input>
        </div>
        <div class="flex grow flex-col gap-4">
            <arc-number-input label="Articles.Edit.PriceLevels.Margin" *ngFor="let priceLevel of priceLevels; let i = index"
                [decimalPlaces]="2" [formControl]="marginFormGroup.controls[marginPrefix+priceLevel.key] | formControl"
                [unit]="UnitsEnum.Percent" [requiredPermission]="PermissionsEnum.Margins"
                [requiredPermissionType]="PermissionTypeEnum.Update">
            </arc-number-input>
        </div>
    </div>
</form>
<ng-template #loading>
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
</ng-template>
