<div cdkDropList class="w-full" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let col of reportColumns" cdkDrag class="bg-app flex items-center pl-2 rounded w-full mb-2">
        <mat-icon cdkDragHandle class="cursor-pointer mr-2">drag_indicator</mat-icon>
        {{col.column.title}}
        <button class="ml-auto" mat-icon-button (click)="remove(col)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <arc-button (clicked)="loadColumns()" [isLoading]="isLoadingColumns" [matMenuTriggerFor]="colsMenu" type="stroked"
        customClasses="w-full">
        + {{'QueryDesigner.Edit.View.AddDataToView' | transloco}}
    </arc-button>
</div>

<mat-menu #colsMenu="matMenu">
    <ng-container *ngIf="isLoadingColumns; else contentTpl">
        <span class="p-4">{{'QueryDesigner.Edit.View.LoadingAvailableColumns' | transloco}}</span>
    </ng-container>
    <ng-template #contentTpl>
        <ng-container *ngIf="availableColumns.length > 0; else noAvailableColumnsTpl">
            <button *ngFor="let col of availableColumns" (click)="addColumn(col)" mat-menu-item>{{col.title}}</button>
        </ng-container>
        <ng-template #noAvailableColumnsTpl>
            <span class="p-4">{{'QueryDesigner.Edit.View.NoAvailableColumns' | transloco}}</span>
        </ng-template>
    </ng-template>
</mat-menu>
