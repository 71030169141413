import { Component, inject, OnInit, signal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { UserListModel } from '../../../../../models/responses/user-list.model';
import { UserModel } from '../../../../../models/user.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';

@Component({
    selector: 'arc-user-qr-code',
    templateUrl: './user-qr-code.detail-widget.html',
    styleUrl: './user-qr-code.detail-widget.scss'
})
export class UserQrCodeDetailWidget extends BaseDetailWidget<UserListModel, UserModel> implements OnInit {
    qrCodeUrl = new FormControl('');
    qrCodeDownloadLink = signal<SafeUrl>('');
    qrCodeTypeItems = signal<ButtonToggleModel[]>([]);

    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        const qrCodeUrlValue = this.entityModel().hasPin
            ? this.entityModel().encryptedCodeWithoutPin
            : this.entityModel().encryptedCodeWithPin;
        this.qrCodeUrl.setValue(qrCodeUrlValue);

        this.qrCodeTypeItems.set([
            { value: this.entityModel().encryptedCodeWithoutPin, label: this.translationService.getText('Users.Detail.QR.PinNeeded') },
            { value: this.entityModel().encryptedCodeWithPin, label: this.translationService.getText('Users.Detail.QR.PinNotNeeded') }
        ]);
    }

    onChangeURL(url: SafeUrl): void {
        this.qrCodeDownloadLink.set(url);
    }
}
