import { Component, computed, inject, OnDestroy, OnInit, signal, viewChild, viewChildren } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatChipListbox } from '@angular/material/chips';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { finalize, forkJoin, map, tap } from 'rxjs';

import { BaseComponent } from '../../../../components/abstractions/base.component';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { CustomerFormConfigModel } from '../../../models/customer-form-config.model';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { environment } from '../../../../environments/environment';
import { CustomerFormFieldTypesEnum } from '../../../models/enums/customer-form-field-types.enum';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../core/models/enums/general-data-type.enum';
import { SelectOptionModel } from '../../../../core/models/select-option.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { CustomerFormCreateModel } from '../../../models/requests/customer-form-create.model';
import { CustomerFormStore } from '../../../services/stores/customer-form.store';
import { KeyValueModel } from '../../../../core/models/key-value.model';
import { TranslationLanguageModel } from '../../../../core/models/translation-language.model';
import { CustomerFormCreateResponseModel } from '../../../models/responses/customer-form-create-response.model';
import { ErrorsService } from '../../../../core/services/errors.service';
import { CustomerGroupModel } from '../../../models/customer-group.model';
import { Tools } from '../../../../core/utils/tools';
import { AddressPickerComponent } from '../../../../components/form/address-picker/address-picker.component';
import { CustomerGroupCategoryModel } from './models/customer-group-category.model';

@Component({
    selector: 'arc-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrl: './customer-form.component.scss'
})
export class CustomerFormComponent extends BaseComponent implements OnInit, OnDestroy {
    readonly addressPicker = viewChild(AddressPickerComponent);
    readonly customerGroupChipListboxes = viewChildren<MatChipListbox>('customerGroupChipListbox');

    readonly GeneralDataTypeEnum = GeneralDataTypeEnum;
    readonly CustomerFormFieldTypesEnum = CustomerFormFieldTypesEnum;
    readonly serverBaseUrl = environment.baseUrl;

    readonly languageCode = signal<string>('de');

    readonly logoSrc = environment.baseUrl;
    readonly hasLogoLoadFailed = signal(false);
    readonly isLoadingConfig = signal(true);
    readonly config = signal<OptionalType<CustomerFormConfigModel>>(undefined);
    readonly isCreating = signal(false);
    readonly errorMessage = signal<OptionalType<string>>(undefined);
    readonly createResult = signal<OptionalType<CustomerFormCreateResponseModel>>(undefined);
    readonly customerCardDownloadUrl = computed(() => {
        const createResult = this.createResult();
        if (!createResult?.customerCardDownloadUri) {
            return undefined;
        }
        return environment.baseUrl + createResult.customerCardDownloadUri;
    });
    readonly isDownloadingCustomerCard = signal(false);

    readonly availableSiteLanguages: TranslationLanguageModel[];

    readonly isLoadingCustomerGroups = signal(true);
    readonly availableCustomerGroups = signal<CustomerGroupModel[]>([]);
    readonly customerGroupCategories = signal<CustomerGroupCategoryModel[]>([]);
    readonly defaultCustomerGroupIds = signal<number[]>([]);

    formGroup = new FormGroup<{
        companyName?: AbstractControl<string>;
        legalFormId?: AbstractControl<number>;
        salutationId?: AbstractControl<number>;
        salutationTitleId?: AbstractControl<number>;
        firstname?: AbstractControl<string>;
        name?: AbstractControl<string>;
        address?: FormGroup<{
            street?: AbstractControl<string>;
            streetNumber?: AbstractControl<string>;
            streetSupplement?: AbstractControl<string>;
            poBox?: AbstractControl<string>;
            place: FormGroup<{
                zip?: AbstractControl<string>;
                city?: AbstractControl<string>;
                countryIsoCode?: AbstractControl<string>;
            }>;
        }>;
        birthdate?: AbstractControl<Date>;
        customerProfileId?: AbstractControl<number>;
        languageId?: AbstractControl<string>;
        contactEmail?: AbstractControl<string>;
        noCommunication?: AbstractControl<boolean>;
        contactPhone?: AbstractControl<string>;
        contactMobile?: AbstractControl<string>;
        customerGroupIds?: AbstractControl<number[]>;
    }>({});
    readonly allowedLanguages: SelectOptionModel<string>[];

    readonly isLoadingGeneralData = signal(false);
    readonly legalForms = signal<SelectOptionModel[]>([]);
    readonly salutations = signal<SelectOptionModel[]>([]);
    readonly salutationTitles = signal<SelectOptionModel[]>([]);
    readonly countries = signal<SelectOptionModel[]>([]);
    readonly customerProfiles = signal<SelectOptionModel[]>([]);

    private recaptcha?: ReCaptchaInstance;

    private readonly subdomain: string;

    private readonly environmentService = inject(EnvironmentService);
    private readonly customerFormStore = inject(CustomerFormStore);
    private readonly translationService = inject(TranslationService);
    private readonly errorsService = inject(ErrorsService);

    constructor() {
        super();

        this.errorsService.shouldDisplayAlertOnError = false;

        const onBusinessExceptionSub = this.errorsService.onBusinessException.subscribe(response => {
            this.errorMessage.set(response.message ?? this.translationService.getText('CustomerForm.SaveFailed'));
            const brokenRules = response.brokenRules;
            for (const brokenRule of brokenRules ?? []) {
                const controlName = `${brokenRule.property?.charAt(0).toLowerCase()}${brokenRule.property?.slice(1)}`;
                const control = this.formGroup.get(controlName);
                if (!!control) {
                    control.setErrors({ businessError: brokenRule.message });
                }
            }
        });
        this.addSubscriptions(onBusinessExceptionSub);

        this.subdomain = this.environmentService.getSubdomain();
        this.logoSrc = `${environment.baseUrl}/auth/logo?subdomain=${this.subdomain}`;
        this.loadGeneralData();
        this.customerFormStore
            .getCustomerFormConfig(this.subdomain)
            .subscribe(result => {
                if (!!result.value) {
                    this.setupForm(result.value);
                    this.config.set(result.value);
                }
            })
            .add(() => this.isLoadingConfig.set(false));

        this.availableSiteLanguages = this.translationService.availableLanguages;
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
        this.languageCode.set(this.translationService.current.code);
    }

    async ngOnInit(): Promise<void> {
        this.recaptcha = await load(environment.recaptchaSiteKey);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.errorsService.shouldDisplayAlertOnError = true;
    }

    async onSubmit(): Promise<void> {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.invalid) {
            return;
        }

        this.errorMessage.set(undefined);

        const token = await this.recaptcha!.execute('create_customer');
        const value = this.formGroup.getRawValue();
        const requestModel: CustomerFormCreateModel = {
            acceptTerms: this.formGroup.get('acceptTerms')?.value ?? false,
            reCaptcha: token,
            companyName: value.companyName,
            legalFormId: value.legalFormId,
            salutationId: value.salutationId,
            salutationTitleId: value.salutationTitleId,
            firstname: value.firstname,
            name: value.name,
            street: value.address?.street,
            streetNumber: value.address?.streetNumber,
            streetSupplement: value.address?.streetSupplement,
            poBox: value.address?.poBox,
            zip: value.address?.place?.zip,
            city: value.address?.place?.city,
            countryIsoCode: value.address?.place?.countryIsoCode,
            birthdate: value.birthdate,
            customerProfileId: value.customerProfileId,
            languageId: value.languageId,
            contactEmail: value.contactEmail,
            noCommunication: value.noCommunication,
            contactPhone: value.contactPhone,
            contactMobile: value.contactMobile,
            customerGroupIds: value.customerGroupIds
        };

        this.isCreating.set(true);
        this.customerFormStore
            .createCustomer(this.subdomain, requestModel)
            .subscribe(result => {
                this.createResult.set(result.value);
            })
            .add(() => this.isCreating.set(false));
    }

    reloadPage(): void {
        window.scrollTo(0, 0);
        window.location.reload();
    }

    clearForm(restoreData?: any): void {
        const config = this.config();

        if (!config) {
            return;
        }

        this.config.set(undefined);
        this.isLoadingConfig.set(true);

        // setTimeout to allow the UI to update to loading status and remove form from view
        setTimeout(() => {
            this.formGroup = new FormGroup<typeof this.formGroup.controls>({});
            this.setupForm(config);
            this.config.set(config);
            this.isLoadingConfig.set(false);

            if (!!restoreData) {
                this.formGroup.patchValue(restoreData);
            }
        });
    }

    setSiteLanguage(languageCode: string): void {
        const dataBackup = this.formGroup.getRawValue();

        this.translationService.switch(languageCode, false);
        this.languageCode.set(languageCode);
        // Since the form is dynamically created, we need to reset it, so the validators are properly referenced with the correct texts.
        this.clearForm(dataBackup);
        this.addressPicker()?.reloadData();
        this.loadGeneralData();

        // update customer language to site language
        if (this.formGroup.contains('languageId') && this.translationService.allowedLanguages.find(l => l.code === languageCode)) {
            this.formGroup.patchValue({ languageId: languageCode });
        }
    }

    toFormGroup(value: AbstractControl): FormGroup {
        return value as FormGroup;
    }

    handleCustomerGroupChange(): void {
        const selectedGroupIds = this.customerGroupChipListboxes()
            .map(c => (c.value ?? []) as number[])
            .flat();
        this.formGroup.patchValue({ customerGroupIds: selectedGroupIds });
    }

    async downloadCustomerCard(url: string): Promise<void> {
        this.isDownloadingCustomerCard.set(true);
        try {
            const result = await fetch(url);
            if (!result.ok) {
                this.errorMessage.set(this.translationService.getText('CustomerForm.ErrorDownloadingCustomerCard'));
                return;
            }

            const blob = await result.blob();
            const fileName = Tools.Utils.getFileNameFromResponse(result);
            Tools.Utils.saveFile(blob, fileName);
        } catch (error) {
            this.errorMessage.set(this.translationService.getText('CustomerForm.ErrorDownloadingCustomerCard'));
        } finally {
            this.isDownloadingCustomerCard.set(false);
        }
    }

    private loadGeneralData(): void {
        const toOptions = <T>(keyValueModels: KeyValueModel<T, string>[]): SelectOptionModel<T>[] =>
            keyValueModels.map(kv => ({
                value: kv.key!,
                label: kv.value!
            }));

        this.isLoadingGeneralData.set(true);
        forkJoin({
            legalForms: this.customerFormStore.getLegalForms(this.subdomain).pipe(map(x => toOptions(x.value ?? []))),
            salutations: this.customerFormStore.getSalutations(this.subdomain).pipe(map(x => toOptions(x.value ?? []))),
            salutationTitles: this.customerFormStore.getSalutationTitles(this.subdomain).pipe(map(x => toOptions(x.value ?? []))),
            countries: this.customerFormStore.getCountries(this.subdomain).pipe(map(x => toOptions(x.value ?? []))),
            customerProfiles: this.customerFormStore.getCustomerProfiles(this.subdomain).pipe(map(x => toOptions(x.value ?? [])))
        })
            .subscribe(result => {
                this.legalForms.set(result.legalForms);
                this.salutations.set(result.salutations);
                this.salutationTitles.set(result.salutationTitles);
                this.countries.set(result.countries);
                this.customerProfiles.set(result.customerProfiles);
            })
            .add(() => this.isLoadingGeneralData.set(false));
    }

    private setupForm(config: CustomerFormConfigModel): void {
        this.addControl('companyName', config.companyNameFieldType, config.companyNameDefaultValue);
        this.addControl('legalFormId', config.companyLegalFormIdFieldType, config.companyLegalFormIdDefaultValue);
        this.addControl('salutationId', config.salutationIdFieldType, config.salutationIdDefaultValue);
        this.addControl('salutationTitleId', config.salutationTitleIdFieldType, config.salutationTitleIdDefaultValue);
        this.addControl('firstname', config.nameFieldType, config.firstNameDefaultValue, Validators.maxLength(50));
        this.addControl('name', config.nameFieldType, config.nameDefaultValue, Validators.maxLength(150));
        this.addControl('birthdate', config.birthdateFieldType, config.birthdateDefaultValue);
        this.addControl('customerProfileId', config.customerProfileIdFieldType, config.customerProfileIdDefaultValue);
        const languageDefaultValue = this.translationService.current.code;
        this.addControl('languageId', config.languageIdFieldType, languageDefaultValue);
        this.addControl('contactEmail', config.emailAdressFieldType, config.emailAdressDefaultValue, [
            Validators.email,
            Validators.maxLength(50)
        ]);
        this.addControl('noCommunication', config.noCommunicationFieldType, config.noCommunicationDefaultValue);
        this.addControl('contactPhone', config.contactPhoneFieldType, config.contactPhoneDefaultValue, Validators.maxLength(20));
        this.addControl('contactMobile', config.contactMobileFieldType, config.contactMobileDefaultValue, Validators.maxLength(20));
        this.addControl('customerGroupIds', config.customerGroupsFieldType, config.customerGroupsDefaultValue);
        this.defaultCustomerGroupIds.set(config.customerGroupsDefaultValue ?? []);
        this.addControl('acceptTerms', config.termsFieldType, false, Validators.requiredTrue);

        if (config.zipPlaceFieldType !== CustomerFormFieldTypesEnum.Invisible) {
            const addressFormGroup = new FormGroup({});
            this.addControl('street', config.streetFieldType, config.streetDefaultFieldValue, Validators.maxLength(100), addressFormGroup);
            this.addControl(
                'streetNumber',
                config.streetNumberFieldType,
                config.streetNumberDefaultFieldValue,
                Validators.maxLength(25),
                addressFormGroup
            );
            this.addControl(
                'streetSupplement',
                config.streetSupplementFieldType,
                config.streetSupplementDefaultFieldValue,
                Validators.maxLength(100),
                addressFormGroup
            );
            this.addControl('poBox', config.poBoxFieldType, config.poBoxDefaultValue, Validators.maxLength(50), addressFormGroup);
            this.addControl('isPoBox', config.poBoxFieldType, !!config.poBoxDefaultValue, undefined, addressFormGroup);
            const placeFormGroup = new FormGroup({});
            this.addControl('zip', config.zipPlaceFieldType, config.zipDefaultValue, Validators.maxLength(20), placeFormGroup);
            this.addControl('city', config.zipPlaceFieldType, config.placeDefaultValue, Validators.maxLength(50), placeFormGroup);
            this.addControl('countryIsoCode', config.countryFieldType, config.countryDefaultValue, Validators.maxLength(2), placeFormGroup);

            addressFormGroup.addControl('place', placeFormGroup);
            this.formGroup.addControl('address', addressFormGroup);
        }

        if (config.customerGroupsFieldType !== CustomerFormFieldTypesEnum.Invisible) {
            this.customerFormStore
                .getCustomerGroups(this.subdomain)
                .pipe(
                    map(result => result?.value ?? []),
                    tap(groups => {
                        const customerGroups = groups.sort((a, b) => a.sort - b.sort);
                        this.availableCustomerGroups.set(customerGroups);

                        const categories: OptionalType<string>[] = [];
                        for (const group of customerGroups) {
                            if (!categories.includes(group.category)) {
                                categories.push(group.category);
                            }
                        }

                        const customerGroupCategories = categories.map(category => {
                            const categoryGroups = customerGroups.filter(g => g.category === category);
                            const selectedGroupIds = (config.customerGroupsDefaultValue ?? []).filter(id =>
                                categoryGroups.find(g => g.id === id)
                            );
                            return {
                                value: category,
                                title: category || '-',
                                groups: categoryGroups,
                                selectedGroupIds
                            };
                        });
                        this.customerGroupCategories.set(customerGroupCategories);
                    }),
                    finalize(() => this.isLoadingCustomerGroups.set(false))
                )
                .subscribe();
        }
    }

    private addControl<T>(
        name: string,
        type: CustomerFormFieldTypesEnum,
        defaultValue?: T,
        additionalValidators?: ValidatorFn | ValidatorFn[],
        formGroup?: FormGroup
    ): void {
        if (type === CustomerFormFieldTypesEnum.Invisible) {
            return;
        }

        const validators = type === CustomerFormFieldTypesEnum.Mandatory ? [Validators.required] : [];
        if (!!additionalValidators) {
            if (Array.isArray(additionalValidators)) {
                validators.push(...additionalValidators);
            } else {
                validators.push(additionalValidators);
            }
        }
        (formGroup ?? this.formGroup).addControl(name, new ArcFormControl<T>(defaultValue, validators));
    }
}
