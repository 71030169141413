<div class="flex flex-col gap-4">
    <arc-rich-text-editor-form class="min-h-[100px]" [formControl]="formGroup.controls[descriptionKey] | formControl"
        label="ArticleVariants.Edit.Texts.Description"
        [placeholder]="'Components.RichTextEditor.LabelEnterTextHere' | transloco:  {label: ('ArticleVariants.Edit.Texts.Description' | transloco)}"
        [isPlaceholderTranslated]="true">
    </arc-rich-text-editor-form>
    <arc-rich-text-editor-form [formControl]="formGroup.controls[warrantyTextKey] | formControl" label="ArticleVariants.Edit.Texts.WarrantyText"
        [placeholder]="'Components.RichTextEditor.LabelEnterTextHere' | transloco:  {label: ('ArticleVariants.Edit.Texts.WarrantyText' | transloco)}"
        [isPlaceholderTranslated]="true">
    </arc-rich-text-editor-form>
</div>
