import { Component, ElementRef, inject, OnInit, viewChild, signal } from '@angular/core';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';

import { BaseControlComponent } from '../base-control/base-control.component';
import { TranslationService } from '../../../core/services/translation.service';

@Component({
    selector: 'arc-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrl: './date-time-picker.component.scss'
})
export class DateTimePickerComponent extends BaseControlComponent implements OnInit {
    flatPicker = viewChild.required('flatPicker', { read: ElementRef });

    locale = signal<object | string>('en');
    dateFormat = signal<string>('');

    private readonly translationService = inject(TranslationService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.locale.set(this.getLocale());
        this.dateFormat.set(this._translationService.current.dateFormatFlatPicker);
    }

    openCalendar(): void {
        this.flatPicker().nativeElement._flatpickr.open();
    }

    private getLocale(): object | string {
        switch (this.translationService.current.code) {
            case 'de':
                return German;
            case 'fr':
                return French;
            case 'it':
                return Italian;
            default:
                return 'en';
        }
    }
}
