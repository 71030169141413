import { Component, inject, WritableSignal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArticleImageModel } from '../../../../../models/responses/article-image.model';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ArticleModel } from '../../../../../models/article.model';
import { MainArticlesStore } from '../../../../../services/stores/main-articles.store';
import { VariantEditModel } from '../../../../../models/requests/variant-edit.model';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';

@Component({
    selector: 'arc-article-variants-edit-base-data',
    templateUrl: './article-variants-edit-base-data.component.html',
    styleUrl: './article-variants-edit-base-data.component.scss'
})
export class ArticleVariantsEditBaseDataComponent extends BaseEditSidebarItemComponent<ArticleModel, VariantEditModel> {
    static readonly buyingPriceSignal = 'buyingPrice';

    override formGroup = new FormGroup({
        ean: new ArcFormControl('', Validators.required),
        orderNumber: new ArcFormControl<OptionalType<string>>(undefined),
        articleImages: new ArcFormControl<ArticleImageModel[]>([]),
        price: new ArcFormControl(0, Validators.required),
        buyingPrice: new ArcFormControl(0, Validators.required)
    });

    PermissionTypeEnum = PermissionTypeEnum;
    PermissionsEnum = PermissionsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly buyingPrice: WritableSignal<OptionalType<number>>;

    constructor() {
        super();
        this.buyingPrice = this.sharedDataService.getOrCreateSignal(ArticleVariantsEditBaseDataComponent.buyingPriceSignal);

        const buyingPriceChangedSub = this.formGroup.controls.buyingPrice.valueChanges.subscribe(bp => {
            this.buyingPrice.set(bp);
        });
        this.addSubscriptions(buyingPriceChangedSub);
    }


    override onItemSet(): void {
        this.formGroup.patchValue({
            ...this.item(),
            ean: this.item().identifications[0]?.identification,
            orderNumber: this.item().articleSuppliers.find(s => s.isMainSupplier)?.orderNumber
        });
    }

    override prepareSaveModel(): Partial<VariantEditModel> {
        const value = this.formGroup.value;
        const identifications = this.item().identifications;

        if (identifications.length > 0) {
            identifications[0].identification = value.ean || '';
        } else {
            identifications.push({
                id: 0,
                identification: value.ean || ''
            });
        }

        return {
            mainArticleId: this.item().mainArticleId,
            colorId: this.item().colorId,
            sizeId: this.item().sizeId,
            identifications: identifications,
            orderNumber: value.orderNumber,
            buyingPrice: value.buyingPrice,
            price: value.price,
            articleImages: value.articleImages
        };
    }

    generateNewIdentificationAction(): Observable<any> {
        return this.mainArticlesStore.generateNewArticlesIdentifications().pipe(
            tap(r => {
                if (!!r.value && r.value.length > 0) {
                    this.formGroup.patchValue({ ean: r.value[0] });
                }
            })
        );
    }
}
