<form [formGroup]="formGroup" class="grid items-center grid-cols-2 ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-input labelKey="Articles.Edit.BaseData.ArticleNumber" [control]="formGroup.controls.articleNumber"></arc-input>
    <div></div>
    <arc-translation-input labelKey="Articles.Edit.BaseData.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it">
    </arc-translation-input>
    <div></div>
    <arc-tree-autocomplete label="Articles.Edit.BaseData.ArticleGroup" [formControl]="formGroup.controls.articleGroupId"
        [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfig">
    </arc-tree-autocomplete>
    <div></div>
    <arc-currency-input label="Articles.Edit.BaseData.Price" [formControl]="formGroup.controls.price"></arc-currency-input>
    <arc-general-data-select label="Articles.Edit.BaseData.Unit" [generalDataType]="GeneralDataTypeEnum.Units"
        [formControl]="formGroup.controls.unitId">
    </arc-general-data-select>
    <div class="flex">
        <arc-number-input class="w-0 grow" label="Articles.Edit.BaseData.BuyingPrice" [requiredPermission]="PermissionsEnum.BuyingPrice"
            [requiredPermissionType]="PermissionTypeEnum.Update" [formControl]="formGroup.controls.buyingPrice" [unit]="buyingPriceSuffix()"
            [isUnitTranslated]="true">
        </arc-number-input>
        @if (hasBuyingPriceEditPermission || item().isBuyingPriceCalculated) {
            <div class="w-[48px] flex items-center justify-center bg-gray-200 rounded-r-md">
                @if (item().isBuyingPriceCalculated) {
                    <arc-icon [size]="20" icon="info_outline"
                        [matTooltip]="'Articles.Edit.BaseData.CalculateBuyingPriceTooltip' | transloco" />
                } @else if (!mainSupplier()) {
                    <!-- no main supplier, cannot bind to main supplier price -->
                    <arc-icon [size]="20" icon="link_off" [matTooltip]="'Articles.Edit.BaseData.BuyingPriceNotLinkedTooltip' | transloco" />
                } @else if (isBuyingPriceLinked()) {
                    <button type="button" mat-icon-button color="accent" (click)="unlinkBuyingPriceFromMainSupplier()"
                        class="grid place-items-center" [matTooltip]="'Articles.Edit.BaseData.BuyingPriceLinkedTooltip' | transloco">
                        <arc-icon [size]="20" icon="link" />
                    </button>
                } @else {
                    <button type="button" mat-icon-button (click)="linkBuyingPriceToMainSupplier()" class="grid place-items-center"
                        [matTooltip]="'Articles.Edit.BaseData.BuyingPriceNotLinkedTooltip' | transloco">
                        <arc-icon [size]="20" icon="link_off" />
                    </button>
                }
            </div>
        }
    </div>
    <arc-number-input label="Articles.Edit.BaseData.Margin" [formControl]="marginFormControl" [unit]="UnitsEnum.Percent" [decimalPlaces]="2"
        [requiredPermission]="PermissionsEnum.Margins" [requiredPermissionType]="PermissionTypeEnum.Update">
    </arc-number-input>
    <div class="flex align-baseline gap-4" arcPortalTooltip="Articles.Edit.BaseData.VAT">
        <span class="mt-3">{{'Articles.Edit.BaseData.VAT' | transloco}}</span>
        <arc-button-toggle-group [control]="formGroup.controls.taxId" [items]="articleTaxItems"></arc-button-toggle-group>
    </div>
</form>
