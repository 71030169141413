import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { BranchModel } from '../../../../../models/branch.model';
import { BranchEditModel } from '../../../../../models/branch-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-branch-edit-receipt',
    templateUrl: './branch-edit-receipt.component.html',
    styleUrl: './branch-edit-receipt.component.scss'
})
export class BranchEditReceiptComponent extends BaseEditSidebarItemComponent<BranchModel, BranchEditModel> {
    override formGroup = new FormGroup({
        taxNumber: new ArcFormControl<OptionalType<string>>(undefined),
        receiptFooter: new ArcFormControl<OptionalType<string>>(undefined),
        receiptHeader: new ArcFormControl<OptionalType<string>>(undefined)
    });

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<BranchEditModel> {
        const value = this.formGroup.value;
        return {
            taxNumber: value.taxNumber,
            receiptFooter: value.receiptFooter,
            receiptHeader: value.receiptHeader
        };
    }
}
