import { Component, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { SubscriptionModel } from '../../../../../models/subscription.model';

@Component({
    selector: 'arc-customer-subscriptions',
    templateUrl: './customer-subscriptions.detail-widget.html',
    styleUrls: ['./customer-subscriptions.detail-widget.scss']
})
export class CustomerSubscriptionsDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    readonly isLoading = signal(true);
    readonly subscriptions = signal<SubscriptionModel[]>([]);

    private readonly customersStore = inject(CustomersStore);
    private readonly router = inject(Router);

    ngOnInit(): void {
        this.customersStore.getSubscriptions(this.entityModel().id).subscribe(r => {
            this.subscriptions.set(r.value || []);
            this.isLoading.set(false);
        });
    }

    goToSubscriptionsPage(): void {
        this.router.navigate(['subscriptions'], { queryParams: { 'PersonId': this.entityModel().id } }).then();
    }
}
