<table class="w-full">
    @for (item of extJsonEntires(); track $index) {
        @if (item.key !== 'StackTrace') {
            <tr>
                <td>{{ item.key }}</td>
                <td>{{ item.value }}</td>
            </tr>
        }
    }
</table>
