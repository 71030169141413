@if (!menuItem().isDisabled) {
    <a mat-list-item class="left-sidebar-item shrink-0 hover:bg-element-hover" routerLinkActive="!bg-element-selected"
        [routerLinkActiveOptions]="routerLinkActiveOptions" [routerLink]="menuItem().path" [queryParams]="menuItem().startingQueryParams">
        <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem().labelKey | transloco }}</span>
        @if(favoritesPermission === true && (isFavouriteButtonVisible() || (isCurrentRoute() && !canHover()))) {
            <!-- favourite button -->
            <button mat-icon-button class="!mr-[3px] !ml-2" (click)="addOrRemoveMenuFavourites($event)" matListItemMeta
                [matTooltip]="(menuItem().favouriteId !== undefined ? 'Layout.Menu.Favourites.RemoveFavourite' : 'Layout.Menu.Favourites.AddFavourite') | transloco">
                @if (!isFavouriteButtonLoading()) {
                    <mat-icon class="text-icon-light">
                        {{ menuItem().favouriteId !== undefined ? 'bookmark' : 'bookmark_border' }}
                    </mat-icon>
                } @else {
                    <mat-spinner [diameter]="24" />
                }
            </button>
        }
    </a>
} @else {
    @if (missingPermission(); as missingPermission) {
        <mat-list-item class="left-sidebar-item" (click)="showUpgradeModal(missingPermission)">
            <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem().labelKey | transloco }}</span>
            <div matListItemMeta [arcPermissionTooltip]="menuItem().permission">
                <arc-icon icon="lock" [size]="20" class="text-on-app" />
            </div>
        </mat-list-item>
    }
    @else {
        <mat-list-item class="left-sidebar-item" [matTooltip]="'General.ComingSoon' | transloco" disabled>
            <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem().labelKey | transloco }}</span>
        </mat-list-item>
    }
}
