@if (!isLoading) {
    <form [formGroup]="formGroup">
        <div class="flex gap-4">
            <div class="flex grow flex-col gap-4">
                @for (priceLevel of priceLevels; track $index) {
                    <arc-currency-input [label]="priceLevel.value" [isLabelTranslated]="true"
                        [requiredPermission]="PermissionsEnum.PriceLevels" [requiredPermissionType]="PermissionTypeEnum.Update"
                        [formControl]="formGroup.controls[pricePrefix+priceLevel.key] | formControl">
                    </arc-currency-input>
                }
            </div>
            <div class="flex grow flex-col gap-4">
                @for (priceLevel of priceLevels; track $index) {
                    <arc-number-input label="ArticleVariants.Edit.PriceLevels.Margin" [decimalPlaces]="2"
                        [formControl]="marginFormGroup.controls[marginPrefix+priceLevel.key] | formControl" [unit]="UnitsEnum.Percent"
                        [requiredPermission]="PermissionsEnum.Margins" [requiredPermissionType]="PermissionTypeEnum.Update">
                    </arc-number-input>
                }
            </div>
        </div>
    </form>
}
@else {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
}
