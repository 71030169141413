<div class=" h-full w-full grid grid-cols-2 !pb-0">
    <div class="h-full flex flex-col overflow-auto">
        <p class="text-on-app-light text-sm">{{ 'Customers.Detail.Description.Notes' | transloco }}</p>
        <div #content class="relative" [class.overflow-hidden]="this.showReadMoreButton()"
            [class.overflow-auto]="!this.showReadMoreButton()">
            <div class="arc-markdown" markdown [innerHTML]="description()"></div>
            @if (showReadMoreButton()) {
                <div class="absolute bottom-0 left-0 right-0 h-[30px] bg-gradient-to-b from-transparent to-element">
                </div>
            }
        </div>

        @if (showReadMoreButton()) {
            <button mat-button class="mb-0 mt-1 w-full !py-2" (click)="openDialog()">
                <mat-icon>launch</mat-icon>
                {{ 'Customers.Detail.Description.ReadMore' | transloco }}
            </button>
        }
    </div>
    <div class="ml-4 mr-8">
        <p class="text-on-app-light text-sm">{{ 'Customers.Detail.Description.DisplayWarning' | transloco }}</p>
        <div>{{ entityModel().displayWarning }}</div>
    </div>
</div>
