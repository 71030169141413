import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';

@Component({
    selector: 'arc-customer-payments',
    templateUrl: './customer-payments.detail-widget.html',
    styleUrls: ['./customer-payments.detail-widget.scss']
})
export class CustomerPaymentsDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> {
    private readonly router = inject(Router);

    goToTransactionPaymentsPage(): void {
        this.router.navigate(['transaction-payments'], { queryParams: { PersonId: this.entityModel().id } }).then();
    }
}
