<div>
    <div class="flex flex-row gap-4">
        <div class="flex flex-col">
            <span>{{ entityModel().isCompany ? entityModel().displayName : entityModel().firstname + ' ' + entityModel().name }}</span>
            <span>{{ entityModel().street }} {{ entityModel().streetNumber }}</span>
            <span>{{ entityModel().zip }} {{ entityModel().city }}</span>
        </div>
        <div class="flex flex-col">
            <div class="flex items-center">
                <arc-icon icon="language" [size]="14"></arc-icon>
                <span>Deutsch</span>
            </div>
            <span>{{ entityModel().contactPhone }}</span>
            <span>{{ entityModel().contactMobile }}</span>
            <a href="mailto:{{ entityModel().contactEmail }}">{{ entityModel().contactEmail }}</a>
        </div>
    </div>
</div>
