import { Component, OnInit, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { finalize, map, Observable, tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomerEditRequestModel } from '../../../../../models/requests/customer-edit-request.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { CustomerGroupModel } from '../../../../../models/customer-group.model';
import { CustomerGroupsStore } from '../../../../../services/stores/customer-groups.store';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { CustomerGroupCategoryModel } from './models/customer-group-category.model';

@Component({
    selector: 'arc-customer-edit-marketing',
    templateUrl: './customer-edit-marketing.component.html',
    styleUrls: ['./customer-edit-marketing.component.scss']
})
export class CustomerEditMarketingComponent extends BaseEditSidebarItemComponent<CustomerModel, CustomerEditRequestModel>
    implements OnInit {
    static readonly undefinedCategoryTitleKey = 'Customers.Edit.Marketing.UndefinedCustomerGroupCategoryTitle';

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    PermissionsEnum = PermissionsEnum;
    PermissionTypeEnum = PermissionTypeEnum;

    override formGroup = new FormGroup({
        cardNumber: new ArcFormControl('', Validators.maxLength(20)),
        customerProfileId: new ArcFormControl<OptionalType<number>>(undefined),
        bonusPoints: new ArcFormControl(0, Validators.required),
        customerGroups: new ArcFormControl<CustomerGroupModel[]>([])
    });

    readonly isLoadingCustomerGroups = signal(true);
    readonly availableCustomerGroups = signal<CustomerGroupModel[]>([]);
    readonly customerGroupCategories = signal<CustomerGroupCategoryModel[]>([]);

    private readonly permissionService = inject(PermissionService);
    private readonly customersStore = inject(CustomersStore);
    private readonly customerGroupsStore = inject(CustomerGroupsStore);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        if (this.permissionService.hasPermission(PermissionsEnum.CustomerProfiles) === true) {
            this.formGroup.controls.customerProfileId.addValidators(Validators.required);
            this.formGroup.controls.customerProfileId.updateValueAndValidity();
        }

        this.customerGroupsStore
            .getAll()
            .pipe(
                map(result => result?.value ?? []),
                tap(groups => {
                    const customerGroups = groups.sort((a, b) => a.sort - b.sort);
                    this.availableCustomerGroups.set(customerGroups);

                    const categories: OptionalType<string>[] = [];
                    for (const group of customerGroups) {
                        if (!categories.includes(group.category)) {
                            categories.push(group.category);
                        }
                    }

                    const customerGroupCategories: CustomerGroupCategoryModel[] = [];
                    for (const category of categories) {
                        const formControl = new ArcFormControl<number[]>([]);
                        formControl.patchValue(this.item().customerGroups.filter(g => g.category === category).map(g => g.id));
                        customerGroupCategories.push({
                            value: category,
                            title: category ?? this.translationService.getText(CustomerEditMarketingComponent.undefinedCategoryTitleKey),
                            groups: customerGroups.filter(g => g.category === category).map(g => ({ value: g.id, label: g.title })),
                            formControl
                        });
                    }
                    this.customerGroupCategories.set(customerGroupCategories);
                }),
                finalize(() => this.isLoadingCustomerGroups.set(false))
            )
            .subscribe();
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());

        if (!this.item().cardNumber) {
            this.updateCardNumber().subscribe();
        }
    }

    override prepareSaveModel(): Partial<CustomerEditRequestModel> {
        const value = this.formGroup.value;
        return {
            cardNumber: value.cardNumber,
            customerProfileId: value.customerProfileId,
            bonusPoints: value.bonusPoints,
            customerGroups: value.customerGroups
        };
    }

    handleGroupChange(): void {
        const categoryControls = this.customerGroupCategories().map(c => c.formControl);
        const selectedGroupIds: number[] = [];
        Object.values(categoryControls).forEach(control => {
            selectedGroupIds.push(...control.value);
        });

        const selectedGroups = selectedGroupIds.map(id => this.availableCustomerGroups().find(g => g.id === id)).filter(g => !!g);
        this.formGroup.patchValue({ customerGroups: selectedGroups as CustomerGroupModel[] });
    }

    updateCardNumber(): Observable<any> {
        return this.customersStore.generateCardNumbers().pipe(
            tap(r => {
                if (!!r.value && r.value.length > 0) {
                    this.formGroup.patchValue({ cardNumber: r.value[0] });
                }
            })
        );
    }
}
