import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { TagModel } from '../../../../../models/tag.model';
import { TagEditModel } from '../../../../../models/requests/tag-edit.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-tag-edit-base-data',
    templateUrl: './tag-edit-base-data.component.html',
    styleUrls: ['./tag-edit-base-data.component.scss']
})
export class TagEditBaseDataComponent extends BaseEditSidebarItemComponent<TagModel, TagEditModel> {
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<TagEditModel> {
        const value = this.formGroup.value;
        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it
        };
    }
}
