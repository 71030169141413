<form [formGroup]="formGroup" class="grid items-center grid-cols-2 ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-currency-input label="Suppliers.Edit.DeliveryConditions.MinimumOrderAmount" [formControl]="formGroup.controls.minOrderAmount">
    </arc-currency-input>
    <arc-input labelKey="Suppliers.Edit.DeliveryConditions.CustomerNumber"
        [control]="formGroup.controls.supplierCustomerNumber"></arc-input>
    <arc-number-input label="Suppliers.Edit.DeliveryConditions.DeliveryTime" [unit]="UnitsEnum.Days"
        [formControl]="formGroup.controls.supplierDeliveryDays">
    </arc-number-input>
    <div></div>
    <arc-select [formControl]="formGroup.controls.weekOfMonth" class="col-span-2" label="Suppliers.Edit.DeliveryConditions.Week"
        [options]="weekOfMonthOpts">
    </arc-select>
    <div class="col-span-2">
        <div class="grid items-center grid-cols-3 grid-rows-3">
            <arc-checkbox labelKey="General.Monday" [control]="formGroup.controls.monday"></arc-checkbox>
            <arc-checkbox labelKey="General.Tuesday" [control]="formGroup.controls.tuesday"></arc-checkbox>
            <arc-checkbox labelKey="General.Wednesday" [control]="formGroup.controls.wednesday"></arc-checkbox>
            <arc-checkbox labelKey="General.Thursday" [control]="formGroup.controls.thursday"></arc-checkbox>
            <arc-checkbox labelKey="General.Friday" [control]="formGroup.controls.friday"></arc-checkbox>
            <arc-checkbox labelKey="General.Saturday" [control]="formGroup.controls.saturday"></arc-checkbox>
            <arc-checkbox labelKey="General.Sunday" [control]="formGroup.controls.sunday"></arc-checkbox>
        </div>
    </div>
    <arc-general-data-select class="col-span-2" label="Suppliers.Edit.DeliveryConditions.SendOrderImportDefinition"
        [generalDataType]="GeneralDataTypeEnum.OrderExportScripts"
        [formControl]="formGroup.controls.sendOrderImportDefinitionId"></arc-general-data-select>
</form>
