import { Component, inject, OnInit, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PaymentTypeModel } from '../../../../../models/payment-type.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { CurrenciesStore } from '../../../../../services/stores/currencies.store';
import { PaymentProcessingTypeEnum } from '../../../../../models/enums/payment-processing-type.enum';

@Component({
    selector: 'arc-payment-type-base-data',
    templateUrl: './payment-type-base-data.component.html',
    styleUrl: './payment-type-base-data.component.scss'
})
export class PaymentTypeBaseDataComponent extends BaseEditSidebarItemComponent<PaymentTypeModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    readonly currencyItems = signal<SelectOptionModel<number>[]>([]);
    readonly paymentProcessingOptions: SelectOptionModel<PaymentProcessingTypeEnum>[] = [
        { label: 'Enums.PaymentProcessingTypeEnum.None', value: PaymentProcessingTypeEnum.None },
        { label: 'Enums.PaymentProcessingTypeEnum.Eft', value: PaymentProcessingTypeEnum.Eft },
        { label: 'Enums.PaymentProcessingTypeEnum.AliPay', value: PaymentProcessingTypeEnum.AliPay },
        { label: 'Enums.PaymentProcessingTypeEnum.Boncard', value: PaymentProcessingTypeEnum.Boncard },
        { label: 'Enums.PaymentProcessingTypeEnum.InLane', value: PaymentProcessingTypeEnum.InLane },
        { label: 'Enums.PaymentProcessingTypeEnum.Legic', value: PaymentProcessingTypeEnum.Legic },
        { label: 'Enums.PaymentProcessingTypeEnum.Twint', value: PaymentProcessingTypeEnum.Twint },
        { label: 'Enums.PaymentProcessingTypeEnum.Wechat', value: PaymentProcessingTypeEnum.Wechat }
    ];
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        titleDebit_de: new ArcFormControl<OptionalType<string>>(undefined),
        titleDebit_fr: new ArcFormControl<OptionalType<string>>(undefined),
        titleDebit_en: new ArcFormControl<OptionalType<string>>(undefined),
        titleDebit_it: new ArcFormControl<OptionalType<string>>(undefined),
        openCashDrawer: new ArcFormControl<boolean>(undefined),
        isCash: new ArcFormControl<boolean>(undefined),
        currencyId: new ArcFormControl<number>(undefined, Validators.required),
        accountTypeId: new ArcFormControl<number>(undefined, Validators.required),
        paymentProcessing: new ArcFormControl<number>(undefined, Validators.required),
        sort: new ArcFormControl<number>(undefined, Validators.required),
        roundTo: new ArcFormControl<number>(undefined, Validators.required),
        requiredRoleId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    private readonly currenciesStore = inject(CurrenciesStore);

    ngOnInit(): void {
        this.currenciesStore.getAll().subscribe(items =>
            this.currencyItems.set((items.value || []).map(item => ({ label: item.currencyIsoCode!, value: item.id })))
        );
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<PaymentTypeModel> {
        const value = this.formGroup.value;
        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            titleDebit_de: value.titleDebit_de,
            titleDebit_fr: value.titleDebit_fr,
            titleDebit_en: value.titleDebit_en,
            titleDebit_it: value.titleDebit_it,
            openCashDrawer: value.openCashDrawer,
            isCash: value.isCash,
            currencyId: value.currencyId,
            accountTypeId: value.accountTypeId,
            paymentProcessing: value.paymentProcessing,
            sort: value.sort,
            roundTo: value.roundTo,
            requiredRoleId: value.requiredRoleId
        };
    }
}
