import { Component, computed, inject, input } from '@angular/core';

import { CustomerModel } from '../../../../app/models/customer.model';
import { DateService } from '../../../../core/services/date.service';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { OptionalType } from '../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-customer-data',
    templateUrl: './customer-data.component.html',
    styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent {
    readonly model = input.required<OptionalType<CustomerModel>>();

    /**
     * removes the protocol from the url like 'http://' or 'https://'
     */
    readonly websiteUrl = computed(() => {
        const url = this.model()?.contactUrl ?? '';
        return url.slice(url.indexOf('//') + 1);
    });

    readonly customerProfilePermission: true | AuthPermissionModel = true;
    readonly dateService = inject(DateService);
    readonly permissionService = inject(PermissionService);

    constructor() {
        this.customerProfilePermission = this.permissionService.hasPermission(PermissionsEnum.CustomerProfiles);
    }
}
