<arc-editable-table [config]="suppliersCardConfig" [template]="cardTemplate" [formControl]="formGroup.controls.articleSuppliers">
    <ng-template #cardTemplate let-formGroup>
        <div class="grid grid-cols-3 grid-rows-3 gap-4 items-center">
            <arc-quick-search [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig" [formControl]="formGroup.controls.personId"
                class="col-span-2" label="Articles.Edit.Suppliers.Supplier">
            </arc-quick-search>
            <arc-input labelKey="Articles.Edit.Suppliers.OrderNumber" [control]="formGroup.controls.orderNumber" />
            <arc-number-input label="Articles.Edit.Suppliers.UnitQuantity" [formControl]="formGroup.controls.unitQuantity"
                [unit]="item().unitShortTitle" [isUnitTranslated]="true">
            </arc-number-input>
            <arc-number-input label="Articles.Edit.Suppliers.MinOrderQuantity" [formControl]="formGroup.controls.minOrderQuantity"
                [unit]="item().unitShortTitle" [isUnitTranslated]="true">
            </arc-number-input>

            <arc-checkbox labelKey="Articles.Edit.Suppliers.IsMainSupplier" [control]="formGroup.controls.isMainSupplier" />
            <div class="relative col-span-2">
                <arc-currency-input label="Articles.Edit.Suppliers.BuyingPrice" [formControl]="formGroup.controls.buyingPrice" />
                @if (formGroup.value.isMainSupplier && isArticleBuyingPriceLinked()) {
                    <arc-icon icon="link" [size]="24" class="absolute -top-[10px] -right-[6px] text-accent bg-element"
                        [matTooltip]="'Articles.Edit.BuyingPriceLinkedTooltip' | transloco">
                    </arc-icon>
                }
            </div>
            <arc-checkbox labelKey="Articles.Edit.Suppliers.BuyingPriceExclusive" [control]="formGroup.controls.buyingPriceExclusive" />
        </div>
    </ng-template>
</arc-editable-table>
