@if (formGroup(); as formGroup) {
    <div class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4 relative">
        <arc-autocomplete class="col-span-2 smd:col-span-5" label="General.Address.Zip" [formControl]="formGroup.controls.zip"
            [searchFn]="zipSearchFn.bind(this)" valueAccessor="zip" [optionDisplayAccessor]="placeOptionDisplayFn.bind(this)"
            [allowArbitraryValues]="true" [shouldSkipDistinctCheck]="true" [shouldPanelWidthFitContent]="true"
            (blurred)="checkIfCurrentPlaceExists()" (optionSelected)="onPlaceSelected($event)">
        </arc-autocomplete>

        <arc-autocomplete [class.col-span-5]="!!formGroup.controls.countryIsoCode" [class.col-span-10]="!formGroup.controls.countryIsoCode"
            class="smd:col-span-7" label="General.Address.City" [formControl]="formGroup.controls.city" [searchFn]="citySearchFn.bind(this)"
            valueAccessor="city" [optionDisplayAccessor]="placeOptionDisplayFn.bind(this)" [allowArbitraryValues]="true"
            [shouldSkipDistinctCheck]="true" (blurred)="checkIfCurrentPlaceExists()" (optionSelected)="onPlaceSelected($event)">
        </arc-autocomplete>

        @if (formGroup.controls.countryIsoCode) {
            <arc-select class="col-span-5 smd:col-span-12" label="General.Address.Country" [options]="countries()"
                [isLoading]="isLoadingCountries()" [formControl]="formGroup.controls.countryIsoCode" (optionSelected)="onCountryChanged()"
                [areOptionsTranslated]="true">
            </arc-select>
        }

        @if (isChecking()) {
            <div class="absolute left-0 right-0 -bottom-2">
                <mat-progress-bar mode="indeterminate" />
            </div>
        }
    </div>
    @if (isValid() && !isChecking() && !isExistingPlace()) {
        <div class="w-full mt-1 text-warn">
            {{ 'Components.PlaceSelect.NewPlaceWillBeCreated' | transloco }}
        </div>
    }
}
