import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../../environments/environment';
import { LoginComponent } from '../views/security/login/login.component';
import { DefaultLayoutComponent } from '../../components/layouts/default-layout/default-layout.component';
import { HomeComponent } from '../views/home/home.component';
import { onlyLoggedUsersGuard } from '../../core/services/guards/only-logged-users.guard';
import { permissionGuard } from '../../core/services/guards/permission.guard';
import { checkIsLocked } from '../../core/services/guards/check-locked-status.guard';
import { RequestPasswordTokenComponent } from '../views/security/request-pwd-token/request-password-token.component';
import { ResetPasswordComponent } from '../views/security/reset-password/reset-password.component';
import { ReportsDesignerComponent } from '../views/transactions/reports/designer/reports-designer.component';
import { ControlsComponent } from '../views/controls/controls.component';
import { ReportsViewerComponent } from '../views/transactions/reports/reports-viewer/reports-viewer.component';
import { NoPaddingLayoutComponent } from '../../components/layouts/no-padding-layout/no-padding-layout.component';
import { articlesRoutes } from './articles.routes';
import { storesRoutes } from './stores.routes';
import { customerRoutes } from './customer.routes';
import { transactionRoutes } from './transactions.routes';
import { settingsRoutes } from './settings.routes';
import { LoginLayoutComponent } from '../../components/layouts/login-layout/login-layout.component';
import { NoPermissionComponent } from '../views/security/no-permission/no-permission.component';
import { PosLayoutEditComponent } from '../views/settings/poslayouts/pos-layout-edit/pos-layout-edit.component';
import { checkEmailVerifiedGuard } from '../../core/services/guards/check-email-verified.guard';
import { CustomerFormComponent } from '../views/customers/customer-form/customer-form.component';

export const routes: Routes = [
    // Main redirect.
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    // No layout
    { path: 'customers/register', component: CustomerFormComponent },

    // Full screen views without layout.
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'request-password-reset', component: RequestPasswordTokenComponent },
            { path: 'reset-password/:userId', component: ResetPasswordComponent }
        ]
    },

    // Default layout.
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [onlyLoggedUsersGuard, checkIsLocked, checkEmailVerifiedGuard],
        canActivateChild: [permissionGuard],
        children: [
            ...articlesRoutes,
            ...storesRoutes,
            ...customerRoutes,
            ...transactionRoutes,
            ...settingsRoutes,
            {
                path: 'no-permission',
                component: NoPermissionComponent
            }
        ]
    },

    // No padding layout.
    {
        path: '',
        component: NoPaddingLayoutComponent,
        canActivate: [onlyLoggedUsersGuard, checkIsLocked, checkEmailVerifiedGuard],
        children: [
            { path: 'home', component: HomeComponent, data: { subMenu: 'home' } },
            {
                path: 'reports',
                children: [
                    { path: 'view/:id', component: ReportsViewerComponent, data: { subMenu: 'transactions' } },
                    { path: 'edit/:id', component: ReportsDesignerComponent, data: { subMenu: 'transactions' } }
                ]
            },
            {
                path: 'pos-reports',
                children: [
                    { path: 'view/:id', component: ReportsViewerComponent, data: { subMenu: 'settings' } },
                    { path: 'edit/:id', component: ReportsDesignerComponent, data: { subMenu: 'settings' } }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: 'pos-layouts',
                        children: [
                            { path: 'edit/:id', component: PosLayoutEditComponent, data: { subMenu: 'settings' } }
                        ]
                    }
                ]
            }
        ]
    },

    // TODO: 404 page
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

if (!environment.production) {
    routes[2].children!.push({ path: 'controls', component: ControlsComponent });
}

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
