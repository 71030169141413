<form [formGroup]="formGroup">
    <div class="grid grid-cols-2 ssm:grid-cols-1 gap-4">
        <div class="flex items-center gap-2" arcPortalTooltip="ArticleVariants.Edit.Properties.Status">
            <p>{{'ArticleVariants.Edit.Properties.Status' | transloco}}</p>
            <arc-button-toggle-group [control]="formGroup.controls.status" [items]="statusItems" class="grow"
                toggleGroupClasses="w-full"></arc-button-toggle-group>
        </div>
        <div></div>
        <div class="grow flex flex-col gap-4">
            <arc-input labelKey="ArticleVariants.Edit.Properties.WarrantyDays" [control]="formGroup.controls.warrantyDays" type="number">
            </arc-input>
            <arc-number-input label="ArticleVariants.Edit.Properties.WeightGrams" [formControl]="formGroup.controls.weightGrams"
                [unit]="UnitsEnum.Grams" [decimalPlaces]="0">
            </arc-number-input>
        </div>
        <div class="flex flex-col">
            <arc-checkbox labelKey="ArticleVariants.Edit.Properties.IgnoreStock" [control]="formGroup.controls.ignoreStock"
                [requiredPermission]="PermissionsEnum.ArticleStocks">
            </arc-checkbox>
            <arc-checkbox labelKey="ArticleVariants.Edit.Properties.ShowOnWebApi" [control]="formGroup.controls.showOnWebApi"
                [requiredPermission]="PermissionsEnum.WebAPI">
            </arc-checkbox>
        </div>
            <arc-general-data-select label="ArticleVariants.Edit.Properties.Season" [generalDataType]="GeneralDataTypeEnum.Seasons"
                [formControl]="formGroup.controls.seasonId">
            </arc-general-data-select>
        <arc-chip-selector class="col-span-2" label="ArticleVariants.Edit.Properties.ArticleTags" [formControl]="formGroup.controls.tags"
            [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)" [uniqueIdFn]="getTagKey">
        </arc-chip-selector>
    </div>
</form>
