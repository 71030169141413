import { ChangeDetectorRef, Component, OnInit, inject, input } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { BaseSearchStore } from '../../../core/abstractions/base-search.store';
import { ColumnFilterModel } from '../../../core/models/column-filter.model';
import { OperandTypeEnum } from '../../../core/models/enums/operand-type.enum';
import { FilterItemModel } from '../../../core/models/filter-item.model';
import { BaseComponent } from '../../abstractions/base.component';
import { TranslationService } from '../../../core/services/translation.service';
import { FilterService } from '../../../core/services/filter.service';
import { ComparisonOperatorsEnumExtensions } from '../../../core/models/enums/comparison-operators.enum';
import { OptionalType } from '../../../core/models/types/optional.type';
import { SaveFilterDialogComponent } from './save-filter-dialog/save-filter-dialog.component';
import { SavedFilterModel } from '../../../core/models/saved-filter.model';
import { RouteService } from '../../../core/services/route.service';
import { getFilterOperator } from './filter-operators';
import { FilterItemTypeEnum } from '../../../core/models/enums/filter-item-type.enum';
import { Identifyable } from '../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent<TId = number> extends BaseComponent implements OnInit {
    readonly store = input.required<BaseSearchStore<Identifyable<TId>, TId>>();

    currentFilters: ColumnFilterModel[] = [];
    currentSavedFilter?: SavedFilterModel;
    readOnlyFilterLabels: string[] = [];
    selectableFilterItems: FilterItemModel[] = [];
    isValid = false;
    isLoading = false;
    hasUnappliedChanges = false;
    resultCount?: number;
    // saving / loading filters
    savedFilters: SavedFilterModel[] = [];
    hasUnsavedChanges = false;
    canSave = false;
    isSaving = false;
    isDeleting = false;
    get canApply(): boolean {
        return this.isValid && !this.isLoading && this.hasUnappliedChanges;
    }

    private filterItems: FilterItemModel[] = [];

    private readonly matDialog = inject(MatDialog);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly routeService = inject(RouteService);
    private readonly translationService = inject(TranslationService);
    private readonly filterService = inject(FilterService);

    constructor() {
        super();
        this.filterService.resetService();
    }

    ngOnInit(): void {
        const currentFiltersSub = this.filterService.currentFilters$.subscribe(filters => this.currentFilters = filters);
        const currentReadonlyFiltersSub = this.filterService.currentReadonlyFilters$.subscribe(readonlyFilters => {
            this.readOnlyFilterLabels = readonlyFilters
                .map(f => {
                    const filterItem = this.filterItems.find(item => item.columnName === f.column);
                    return [f, filterItem] as [ColumnFilterModel, OptionalType<FilterItemModel>];
                })
                .filter(([, filterItem]) => !!filterItem)
                .map(
                    ([f, filterItem]) =>
                        `${filterItem!.label || filterItem!.columnName} ${this.translationService.getText(
                            getFilterOperator(filterItem!.type, f.comparisonOperator)?.labelKey ?? ''
                        )} ${f.values[0]}`
                );
        });
        const selectableFiltersSub = this.filterService.filterItems$.subscribe(filterItems => {
            this.filterItems = filterItems;
            this.selectableFilterItems = filterItems.filter(f => !f.isReadOnly);
        });
        const filterStateSub = this.filterService.state$.subscribe(state => {
            this.isLoading = state.isLoading;
            this.isValid = state.isValid;
            this.hasUnappliedChanges = state.hasUnappliedChanges;
            this.resultCount = state.resultCount;
            this.canSave = state.canSave;
            this.isSaving = state.isSaving;
            this.isDeleting = state.isDeleting;
            this.hasUnsavedChanges = state.hasUnsavedChanges;
            this.changeDetectorRef.detectChanges();
        });
        const savedFiltersSub = this.filterService.savedFilters$.subscribe(savedFilters => this.savedFilters = savedFilters);
        const currentSavedFilterSub = this.filterService.currentSavedFilter$.subscribe(currFilter => this.currentSavedFilter = currFilter);

        this.addSubscriptions(
            currentFiltersSub,
            currentReadonlyFiltersSub,
            selectableFiltersSub,
            filterStateSub,
            savedFiltersSub,
            currentSavedFilterSub
        );
        this.filterService.initService(this.store());

        const initSub = this.filterService.initialized$.subscribe(() => {
            this.setupQueryParamFilters();
            this.setupRouteListener();
            initSub.unsubscribe();
        });
    }

    applyFilters(): void {
        this.hasUnappliedChanges = false;
        this.filterService.apply();
    }

    addFilter(): void {
        this.filterService.addFilter(
            new ColumnFilterModel({
                operand: OperandTypeEnum.And
            })
        );
    }

    removeReadOnlyFilter(index: number): void {
        this.filterService.removeReadonlyFilter(index);
        this.filterService.apply();
    }

    reset(): void {
        this.filterService.resetSavedFilter();
        this.filterService.resetAllFilters();
    }

    applySavedFilter(id: string): void {
        this.filterService.applySavedFilter(id);
    }

    saveCurrentFilters(): void {
        const dialogRef = this.matDialog.open(SaveFilterDialogComponent);
        dialogRef.afterClosed().subscribe((name?: string) => {
            if (!!name) {
                this.filterService.saveAndApplyCurrentFilter(name);
            }
        });
    }

    updateCurrentSavedFilter(): void {
        this.filterService.updateSavedFilter();
    }

    deleteCurrentSavedFilter(): void {
        if (!!this.currentSavedFilter?.id) {
            this.filterService.deleteSavedFilter(this.currentSavedFilter.id);
        }
    }

    private setupQueryParamFilters(): void {
        const queryParamsObject = this.routeService.getQueryParams();
        const queryParams = Object.entries(queryParamsObject);

        for (const [columnName, param] of queryParams) {
            const filterItem = this.filterItems.find(f => f.columnName === columnName);

            if (!filterItem) {
                continue;
            }

            if (Array.isArray(param)) {
                param.forEach(p => this.setFilter(p, filterItem, true));
            } else {
                this.setFilter(param, filterItem);
            }
        }

        this.filterService.apply();
    }

    private setupRouteListener(): void {
        const sub = this.routeService.routerEvent$.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.reset();
                this.setupQueryParamFilters();
            }
        });
        this.addSubscriptions(sub);
    }

    private setFilter(param: any, filterItem: FilterItemModel, isArray = false): void {
        const stringParam = param.toString();
        let [value, operator = '='] = stringParam.split('|');

        if (filterItem.type === FilterItemTypeEnum.Boolean) {
            value = undefined;
            operator = stringParam;
        }

        const columnFilter = new ColumnFilterModel({
            column: filterItem.columnName,
            dataType: filterItem.type,
            values: [value],
            comparisonOperator: ComparisonOperatorsEnumExtensions.operatorToEnum(operator),
            operand: isArray ? OperandTypeEnum.Or : OperandTypeEnum.And
        });

        if (filterItem.isReadOnly) {
            this.filterService.addReadonlyFilter(columnFilter);
        } else {
            this.filterService.addFilter(columnFilter);
        }
    }
}
