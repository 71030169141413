import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { BingApiStore } from './stores/bing-api.store';
import { environment } from '../../environments/environment';
import { AddressModel } from '../models/address.model';
import { PlacesStore } from '../../app/services/stores/places.store';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class BingApiService {
    private readonly searchMaxResults = 5;
    private readonly bingApiStore = inject(BingApiStore);
    private readonly placesStore = inject(PlacesStore);

    private readonly environmentService = inject(EnvironmentService);
    private readonly subdomain: string;

    constructor() {
        this.subdomain = this.environmentService.getSubdomain();
    }

    async getSuggestions(query: string, countryIsoCode?: string): Promise<AddressModel[]> {
        try {
            const response = await firstValueFrom(
                this.bingApiStore.getSuggestions(query, environment.bingMapsApiKey, countryIsoCode ?? 'CH', this.searchMaxResults)
            );
            const bingAddressSuggestions =
                response.resourceSets
                    ?.at(0)
                    ?.resources?.at(0)
                    ?.value?.map(r => r.address) ?? [];

            const suggestions: AddressModel[] = [];
            for (const suggestion of bingAddressSuggestions) {
                suggestions.push({
                    street: suggestion.streetName,
                    streetNumber: suggestion.houseNumber,
                    zip: suggestion.postalCode,
                    city: await this.getCity(suggestion.countryRegionIso2, suggestion.postalCode, suggestion.locality),
                    countryIsoCode: suggestion.countryRegionIso2
                });
            }
            return suggestions;
        } catch (e) {
            return [];
        }
    }

    /**
     * Tries to match zip code with city from our db instead of what bing returned
     */
    private async getCity(countryIsoCode: string, zip: string, suggestedCity: string): Promise<string> {
        const result = await firstValueFrom(this.placesStore.search(countryIsoCode, zip, undefined, this.subdomain));
        const foundPlaces = result.value ?? [];

        if (foundPlaces.length === 0) {
            return suggestedCity;
        }

        const bestMatch = foundPlaces.find(p => p.city.toLowerCase() === suggestedCity.toLowerCase());
        return bestMatch?.city ?? foundPlaces[0].city;
    }
}
