import { Component, ElementRef, HostListener, forwardRef, inject, input, signal, viewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { ToasterService } from '../../../core/services/toaster.service';
import { AddImageFromUrlDialogComponent } from './add-url-dialog/add-image-from-url-dialog.component';
import { Tools } from '../../../core/utils/tools/index';
import { OptionalType } from '../../../core/models/types/optional.type';

/**
 * File upload component.
 * If adding from URL, the return will be a File object with the name filled with the URL. Everything else will be default.
 */
@Component({
    selector: 'arc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent extends BaseControlValueAccessor<File> {
    fileInputElement = viewChild.required<ElementRef<HTMLInputElement>>('fileInputElement');

    // comma separated list, e.g.: ".xls,.csv,.xml"
    fileTypes = input<string>();
    shouldForceMinHeight = input<boolean>(true);
    shouldUseCompactStyle = input<boolean>(false);
    allowUrlUpload = input<boolean>(false);
    maxFileSizeInKb = input<number>();

    localId = Tools.Utils.newGuid();
    isDragging = signal(false);
    base64ImageUrl = signal<OptionalType<string>>(undefined);

    get customClasses(): string[] {
        const cClasses: string[] = [];

        if (this.shouldForceMinHeight()) {
            cClasses.push('min-h-[200px]');
        }

        if (!this.shouldUseCompactStyle()) {
            cClasses.push('min-w-[500px]');
        }

        return cClasses;
    }

    private readonly matDialog = inject(MatDialog);
    private readonly toasterService = inject(ToasterService);

    @HostListener('dragenter', ['$event'])
    @HostListener('dragover', ['$event'])
    onDragEnter(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging.set(true);
    }

    @HostListener('dragleave', ['$event'])
    @HostListener('dragend', ['$event'])
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging.set(false);
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.isDragging.set(false);
        const file = event.dataTransfer?.files?.item(0) ?? undefined;

        this.fileChanged(file);
    }

    override writeValue(value?: File | undefined): void {
        super.writeValue(value);
        this.setBase64ImageUrl(value);
    }

    onFileInputChanged(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.fileChanged(target.files?.item(0) ?? undefined);
        // reset file input
        target.value = '';
    }

    addFromUrl(): void {
        const dialogRef = this.matDialog.open(AddImageFromUrlDialogComponent, {
            height: '200px',
            maxHeight: '98svh',
            width: '400px',
            maxWidth: '98vw'
        });
        dialogRef.afterClosed().subscribe(url => {
            if (!!url) {
                this.valueChanged(new File([], url));
            }
        });
    }

    removeFile(): void {
        this.fileInputElement().nativeElement.value = '';
        this.fileChanged(undefined);
    }

    protected override isValueValid(value?: File): boolean {
        return this.isCorrectFileType(value) && this.hasCorrectFileSize(value);
    }

    private isCorrectFileType(file?: File): boolean {
        const fileTypes = this.fileTypes();
        if (!file || !fileTypes) {
            return true;
        }

        const fileTypesList = fileTypes.split(',').map(t => t.toLowerCase().trim());
        const fileNameParts = file.name.split('.');
        const fileExt = fileNameParts.length > 1 ? `.${fileNameParts[fileNameParts.length - 1].toLowerCase()}` : '';

        // check if the file extension is in fileTypes
        if (fileTypesList.includes(fileExt)) {
            return true;
        }

        const fileMimeType = file.type.toLowerCase();

        // check if the file MIME type matches one in fileTypes (could have a placeholder, e.g. video/*)
        return fileTypesList.some(t => !!t.replace('*', '.*').match(fileMimeType));
    }

    private fileChanged(file?: File): void {
        if (!this.isCorrectFileType(file)) {
            this.toasterService.showError('Components.FileUpload.WrongFileType');
            this.valueChanged(undefined);
            this.setBase64ImageUrl(undefined);

            return;
        } else if (!this.hasCorrectFileSize(file)) {
            this.toasterService.showError('Components.FileUpload.IncorrectFileSize',
                undefined,
                undefined,
                undefined,
                { maxSize: Tools.Utils.fileSizeToHumanReadable(this.maxFileSizeInKb()! * 1024) }
            );
            this.valueChanged(undefined);
            this.setBase64ImageUrl(undefined);

            return;
        }

        this.valueChanged(file);
        this.setBase64ImageUrl(file);
    }

    private hasCorrectFileSize(file?: File): boolean {
        const maxFileSizeInKb = this.maxFileSizeInKb();
        return !file || !maxFileSizeInKb || file.size <= maxFileSizeInKb * 1024;
    }

    /**
     * If the file is an image, a preview should be displayed in the file selector.
     */
    private setBase64ImageUrl(file?: File): void {
        if (!file) {
            this.base64ImageUrl.set(undefined);
            return;
        }

        Tools.Utils.arrayBufferToBase64(file.arrayBuffer()).subscribe(base64 => {
            const url = Tools.Utils.getBase64ImageSrc(base64);
            // test if file is an image
            const testImage = new Image();
            testImage.onerror = () => this.base64ImageUrl.set(undefined);
            testImage.onload = () => this.base64ImageUrl.set(url);
            testImage.src = url;
        });
    }
}
