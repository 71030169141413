import { Component } from '@angular/core';

import { DefaultValuesEnum } from '../../../../../../core/models/enums/default-values.enum';
import { SettingsDefaultValuesBaseComponentComponent } from '../settings-default-values-base-component/settings-default-values-base-component.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-default-values-articles',
    templateUrl: '../settings-default-values-base-component/settings-default-values-base-component.component.html',
    styleUrl: './settings-default-values-articles.component.scss'
})
export class SettingsDefaultValuesArticlesComponent extends SettingsDefaultValuesBaseComponentComponent {
    constructor() {
        super(DefaultValuesEnum.Articles);
    }

    override onItemSet(): void {
        const data = this.item().defaultArticles;
        this.formGroup.controls.defaultValues.setValue(data);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { defaultArticles: this.formGroup.value.defaultValues };
    }
}
