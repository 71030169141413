import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SubscriptionTypeModel } from '../../../../../models/subscription-type.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { SubscriptionArticleModel } from '../../../../../models/subscription-article.model';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { EditableTableButtonModel } from '../../../../../../components/form/editable-table/models/editable-table-button.model';
import { DataSelectionDialogComponent } from '../../../../../../components/dialogs/data-selection-dialog/data-selection-dialog.component';
import { DataSelectionDialogDataModel } from '../../../../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ToasterService } from '../../../../../../core/services/toaster.service';

@Component({
    selector: 'arc-subscription-type-edit-articles',
    templateUrl: './subscription-type-edit-articles.component.html',
    styleUrls: ['./subscription-type-edit-articles.component.scss']
})
export class SubscriptionTypeEditArticlesComponent extends BaseEditSidebarItemComponent<SubscriptionTypeModel> {
    override formGroup = new FormGroup({
        subscriptionArticles: new ArcFormControl<SubscriptionArticleModel[]>([])
    });
    identificationsTableConfig: EditableTableConfigModel<SubscriptionArticleModel>;

    private readonly matDialog = inject(MatDialog);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        super();
        this.identificationsTableConfig = new EditableTableConfigModel<SubscriptionArticleModel>({
            formGroupGeneratorFn: () => new FormGroup({
                id: new ArcFormControl(0),
                subscriptionTypeArticleId: new ArcFormControl(0),
                articleId: new ArcFormControl('', Validators.required),
                articleTitle: new ArcFormControl(),
                articleNumber: new ArcFormControl(),
                newPrice: new ArcFormControl(0, [Validators.required, CustomValidators.number()])
            }),
            showPaging: true,
            columns: [
                new StackedColumnModel({
                    propertyName: 'articleTitle',
                    propertyName2: 'articleNumber',
                    widthPixels: 360,
                    columnTitleKey: 'SubscriptionTypes.Edit.Articles.SubscriptionArticleArticle'
                }),
                new CurrencyColumnModel({
                    propertyName: 'newPrice',
                    isEditable: true,
                    widthPixels: 220,
                    columnTitleKey: 'SubscriptionTypes.Edit.Articles.SubscriptionArticleNewPrice'
                })
            ],
            shouldHideAddButton: true,
            additionalButtons: [
                new EditableTableButtonModel({
                    labelKey: 'General.Actions.Add',
                    action: current => {
                        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
                            data: new DataSelectionDialogDataModel({
                                type: ComplexDataTypesEnum.SubscriptionTypeArticle,
                                isMultiSelect: true
                            }),
                            width: '800px',
                            maxWidth: '98vw',
                            height: '800px',
                            maxHeight: '98svh'
                        });

                        return dialogRef.afterClosed().pipe(
                            tap((result: ArticleListModel[]) => {
                                if (!result || result.length === 0) {
                                    return;
                                }

                                for (const article of result) {
                                    // eslint-disable-next-line eqeqeq
                                    if (current.some(a => a.articleId == article.id)) {
                                        this.toasterService.showWarning('SubscriptionTypes.Edit.Articles.ArticlesAlreadyAdded');
                                        continue;
                                    }

                                    const subscriptionArticle = {
                                        id: 0,
                                        subscriptionTypeArticleId: this.item().id,
                                        articleId: article.id,
                                        articleTitle: article.title,
                                        articleNumber: article.articleNumber
                                    } as SubscriptionArticleModel;
                                    const currentData = this.formGroup.value.subscriptionArticles ?? [];

                                    currentData.push(subscriptionArticle);
                                    this.formGroup.controls.subscriptionArticles.setValue(currentData);
                                    this.formGroup.controls.subscriptionArticles.markAsDirty();
                                }
                            })
                        );
                    }
                })
            ]
        });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<SubscriptionTypeModel> {
        return { subscriptionArticles: this.formGroup.value.subscriptionArticles };
    }

    override onBrokenRulesLoad(): string[] {
        return this.identificationsTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }
}
