export enum OrderStatusEnum {
    Draft = 0,
    Sent = 1,
    Delayed = 2,
    Delivered = 3,
    Suggestion = 4,
    Declined = 5
}

export class OrderStatusEnumExtensions {
    static getColor(value: OrderStatusEnum): string {
        switch (value) {
            case OrderStatusEnum.Draft:
                return 'bg-element-selected text-on-app';
            case OrderStatusEnum.Sent:
                return 'bg-warn-light text-on-warn-light';
            case OrderStatusEnum.Delayed:
                return 'bg-error-light text-on-error-light';
            case OrderStatusEnum.Delivered:
                return 'bg-success-light text-on-success-light';
            case OrderStatusEnum.Suggestion:
                return 'bg-info text-on-info';
            case OrderStatusEnum.Declined:
                return 'bg-element-selected text-on-app';
        }
    }
}
