<div class="h-full">
    @if (subscriptions().length > 0) {
        <header class="absolute top-0 right-0 flex justify-center">
            <button mat-icon-button (click)="goToSubscriptionsPage()">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </header>
    }
    <div class="h-full w-full">
        @if (isLoading()) {
            <div class="w-full h-full grid place-items-center">
                <mat-spinner [diameter]="32" />
            </div>
        } @else if (subscriptions().length > 0) {
            <div class="grid grid-cols-3 gap-4">
                @for (sub of subscriptions(); track sub.id) {
                    <div class="p-2 bg-on-app text-app">
                        <div class="mb-[-10px] text-xs">{{ sub.id }}</div>
                        <div class="text-xs">{{ sub.title }}</div>
                        <div class="float-right">
                            @if (!!sub.subscriptionQuantity) {
                                <span class="text-xl">
                                    {{ sub.remainingQuantity }}/{{ sub.subscriptionQuantity }}
                                </span>
                            } @else {
                                <span class="text-xl">{{ sub.validThru | arcDate }}</span>
                            }
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="absolute inset-0 grid place-items-center">
                {{ 'Customers.Detail.Subscriptions.NoSubscriptions' | transloco }}
            </div>
        }
    </div>
</div>
