<form [formGroup]="formGroup">
    <div class="grid items-center grid-cols-3 ssm:grid-cols-2 gap-4 mb-8">
        <arc-input class="ssm:col-span-2" labelKey="Customers.Edit.Marketing.CardNumber" [control]="formGroup.controls.cardNumber"
            actionIcon="rotate_left" [action]="updateCardNumber.bind(this)">
        </arc-input>
        <arc-general-data-select label="Customers.Edit.Marketing.CustomerProfile" [generalDataType]="GeneralDataTypeEnum.CustomerProfiles"
            [formControl]="formGroup.controls.customerProfileId" [requiredPermission]="PermissionsEnum.CustomerProfiles"
            [requiredPermissionType]="PermissionTypeEnum.Update">
        </arc-general-data-select>
        <arc-input type="number" labelKey="Customers.Edit.Marketing.BonusPoints" [control]="formGroup.controls.bonusPoints"
            [requiredPermission]="PermissionsEnum.CustomerBonusPrograms" [requiredPermissionType]="PermissionTypeEnum.Read">
        </arc-input>
    </div>

    @if (!isLoadingCustomerGroups()) {
        <div class="flex flex-col gap-4">
            @for (category of customerGroupCategories(); track category.value) {
                <arc-select [label]="category.title" [isLabelTranslated]="true" [options]="category.groups" [areOptionsTranslated]="true"
                    [isMultiselect]="true" [formControl]="category.formControl" (optionSelected)="handleGroupChange()">
                </arc-select>
            }
        </div>
    } @else {
        <div class="flex items-center">
            <mat-spinner [diameter]="32" />
        </div>
    }
</form>
