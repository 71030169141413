import { Component, computed, effect, inject, signal } from '@angular/core';

import { UserListModel } from '../../../../../models/responses/user-list.model';
import { UserModel } from '../../../../../models/user.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { BlobsStore } from '../../../../../services/stores/blobs.store';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-user-data',
    templateUrl: './user-data.detail-widget.html',
    styleUrl: './user-data.detail-widget.scss'
})
export class UserDataDetailWidget extends BaseDetailWidget<UserListModel, UserModel> {
    imageSrc = signal<OptionalType<string>>(undefined);
    base64ImgSrc = computed(() => {
        const src = this.imageSrc();
        return !!src ? Utils.getBase64ImageSrc(src) : undefined;
    });

    private readonly blobsStore = inject(BlobsStore);

    constructor() {
        super();

        effect(() => {
            const entityModel = this.entityModel();
            if (!entityModel.profileImageBlobFileName) {
                return;
            }

            this.blobsStore.getFileByName(entityModel.profileImageBlobFileName).subscribe(
                res => this.imageSrc.set(res.value)
            );
        });
    }
}
