import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { IconColumnModel } from '../../../../components/dynamic-table/models/column-types/icon-column.model';
import { IconModel } from '../../../../core/models/icon.model';
import { ArticleVariantListModel } from '../../../models/responses/article-variant-list.model';
import { ArticleVariantsStore } from '../../../services/stores/article-variants.store';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { ArticleVariantPicturesDetailWidget } from './article-variants-detail-widgets/article-variant-pictures/article-variant-pictures.detail-widget';
import { ArticleVariantSalesNumbersDetailWidget } from './article-variants-detail-widgets/article-variant-sales-numbers/article-variant-sales-numbers.detail-widget';
import { ArticleVariantStockDataDetailWidget } from './article-variants-detail-widgets/article-variant-stock-data/article-variant-stock-data.detail-widget';
import { ArticleVariantDescriptionDetailWidget } from './article-variants-detail-widgets/article-variant-description/article-variant-description.detail-widget';
import { ArticleVariantDetailsDetailWidget } from './article-variants-detail-widgets/article-variant-details/article-variant-details.detail-widget';
import { ArticleVariantsEditBaseDataComponent } from './aritilce-variants-edit-items/article-variants-edit-base-data/article-variants-edit-base-data.component';
import { ArticleModel } from '../../../models/article.model';
import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { VariantEditModel } from '../../../models/requests/variant-edit.model';
import { ArticleVariantsEditPropertiesComponent } from './aritilce-variants-edit-items/article-variants-edit-properties/article-variants-edit-properties.component';
import { ArticleVariantsEditTextsComponent } from './aritilce-variants-edit-items/article-variants-edit-texts/article-variants-edit-texts.component';
import { ArticleVariantsEditPricesComponent } from './aritilce-variants-edit-items/article-variants-edit-prices/article-variants-edit-prices.component';

@Component({
    selector: 'arc-article-variants',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./article-variants.component.scss']
})
export class ArticleVariantsComponent extends PageListViewComponent<ArticleVariantListModel, ArticleModel, VariantEditModel> {
    private readonly articleVariantsStore = inject(ArticleVariantsStore);

    constructor() {
        super('Articles');
        this.config = new TableListViewConfigModel<ArticleVariantListModel, ArticleModel, VariantEditModel>({
            entityName: 'ArticleVariants',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'ArticleVariants.List.Article',
                    propertyName: 'title',
                    propertyName2: 'articleNumber',
                    widthPixels: 400
                }),
                size: new StringColumnModel({
                    columnTitleKey: 'ArticleVariants.List.Size',
                    propertyName: 'size',
                    widthPixels: 150,
                    requiredPermission: PermissionsEnum.Sizes
                }),
                color: new ColoredStackedColumnModel({
                    columnTitleKey: 'ArticleVariants.List.Color',
                    propertyName2: 'color',
                    color: 'colorValue',
                    isColorInHex: true,
                    widthPixels: 300,
                    requiredPermission: PermissionsEnum.Colors
                }),
                price: new CurrencyColumnModel({
                    columnTitleKey: 'ArticleVariants.List.Price',
                    propertyName: 'price',
                    widthPixels: 130
                }),
                unit: new StringColumnModel({ columnTitleKey: 'ArticleVariants.List.Unit', propertyName: 'unit', widthPixels: 120 }),
                groups: new StackedColumnModel({
                    columnTitleKey: 'ArticleVariants.List.Group',
                    propertyName: 'articleGroup',
                    propertyName2: 'mainAndTradeGroup',
                    widthPixels: 400
                }),
                mainSupplier: new StringColumnModel({
                    columnTitleKey: 'ArticleVariants.List.MainSupplier',
                    propertyName: 'mainSupplier',
                    widthPixels: 200,
                    requiredPermission: PermissionsEnum.ArticleSuppliers
                }),
                buyingPrice: new CurrencyColumnModel({
                    columnTitleKey: 'ArticleVariants.List.BuyingPrice',
                    propertyName: 'buyingPrice',
                    widthPixels: 120,
                    requiredPermission: PermissionsEnum.BuyingPrice
                }),
                totalStock: new StringColumnModel({
                    columnTitleKey: 'ArticleVariants.List.TotalStock',
                    propertyName: 'totalStock',
                    widthPixels: 120,
                    requiredPermission: PermissionsEnum.ArticleStocks
                }),
                isOnSale: new IconColumnModel({
                    columnTitleKey: 'ArticleVariants.List.IsOnSale',
                    shouldHideColumnTitle: true,
                    propertyName: 'isOnSale',
                    widthPixels: 25,
                    iconMapping: new Map([
                        [true, new IconModel({ name: 'percent', tooltip: 'ArticleVariants.List.IsOnSale' })],
                        [false, new IconModel()]
                    ])
                })
            },
            defaultColumnOrder: [
                'title',
                'size',
                'color',
                'price',
                'unit',
                'groups',
                'mainSupplier',
                'buyingPrice',
                'totalStock',
                'isOnSale'
            ],
            availableDetailWidgets: {
                pictures: new TableListDetailWidgetModel({
                    name: 'ArticleVariants.Detail.Pictures.ComponentTitle',
                    component: ArticleVariantPicturesDetailWidget
                }),
                turnover: new TableListDetailWidgetModel({
                    name: 'ArticleVariants.Detail.Turnover.ComponentTitle',
                    component: ArticleVariantSalesNumbersDetailWidget,
                    requiredPermission: PermissionsEnum.HomeStatistics
                }),
                stock: new TableListDetailWidgetModel({
                    name: 'ArticleVariants.Detail.Stock.ComponentTitle',
                    component: ArticleVariantStockDataDetailWidget,
                    requiredPermission: PermissionsEnum.ArticleStocks
                }),
                description: new TableListDetailWidgetModel({
                    name: 'ArticleVariants.Detail.Description.ComponentTitle',
                    component: ArticleVariantDescriptionDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'ArticleVariants.Detail.Details.ComponentTitle',
                    component: ArticleVariantDetailsDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['pictures', 'turnover', 'stock', 'description', 'details'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'ArticleVariants.Edit.BaseData.ComponentTitle', component: ArticleVariantsEditBaseDataComponent },
                    { titleKey: 'ArticleVariants.Edit.Properties.ComponentTitle', component: ArticleVariantsEditPropertiesComponent },
                    { titleKey: 'ArticleVariants.Edit.Texts.ComponentTitle', component: ArticleVariantsEditTextsComponent },
                    { titleKey: 'ArticleVariants.Edit.PriceLevels.ComponentTitle', component: ArticleVariantsEditPricesComponent }
                ]
            },
            store: this.articleVariantsStore,
            buttonsVisibility: new ButtonsVisibilityModel({
                hasCreate: false
            })
        });
    }
}
