import { Component, inject } from '@angular/core';

import { TransactionsListWidgetComponent } from '../../../../../../components/widgets/shared-detail-widgets/transactions-list-widget/transactions-list-widget.component';
import { ColoredStackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { ButtonColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-column.model';
import { TransactionsStore } from '../../../../../services/stores/transactions.store';

@Component({
    selector: 'arc-pos-transactions-list-widget',
    templateUrl:
        '../../../../../../components/widgets/shared-detail-widgets/transactions-list-widget/transactions-list-widget.component.html',
    styleUrls: [
        '../../../../../../components/widgets/shared-detail-widgets/transactions-list-widget/transactions-list-widget.component.scss'
    ]
})
export class PosTransactionsListWidgetComponent extends TransactionsListWidgetComponent {
    private readonly transactionsStore = inject(TransactionsStore);

    constructor() {
        super();

        this.tableConfig = {
            defaultPageSize: 4,
            availableColumns: [
                new DateColumnModel({
                    columnTitleKey: 'Transactions.List.Date',
                    propertyName: 'saleTime',
                    widthPixels: 120,
                    isCompact: true,
                    format: 'short'
                }),
                new ColoredStackedColumnModel({
                    columnTitleKey: 'Transactions.List.User',
                    propertyName: 'userUsername',
                    propertyName2: 'userEmail',
                    color: 'userColor',
                    widthPixels: 240,
                    isColorInHex: true,
                    isCompact: true
                }),
                new ButtonColumnModel({
                    columnTitleKey: 'Transactions.List.DownloadReceipt',
                    actionButtonKey: 'TransactionDownloadReceiptsContextAction',
                    contextActionsStore: this.transactionsStore,
                    icon: () => 'print',
                    isIcon: true,
                    widthPixels: 50,
                    allowSort: false
                })
            ]
        };
    }
}
