<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToArticlesPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        @if (isLoading()) {
            <mat-spinner [diameter]="32"></mat-spinner>
        } @else {
            <arc-static-table [config]="tableConfig" [staticData]="tableData()" [shouldUseCompactStyle]="true" [isSingleLineRow]="true" />
        }
    </div>
</div>
