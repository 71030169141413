import { Component, Input, ViewChild, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ArcFormControl } from '../../core/utils/arc-form-control';
import { KeyValueModel } from '../../core/models/key-value.model';
import { MarkdownEditorComponent } from '../markdown-editor/markdown-editor.component';
import { TranslationService } from '../../core/services/translation.service';
import { BaseComponent } from '../abstractions/base.component';

@Component({
    selector: 'arc-bulk-send-editor',
    templateUrl: './bulk-send-editor.component.html',
    styleUrl: './bulk-send-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BulkSendEditorComponent),
            multi: true
        }
    ]
})
export class BulkSendEditorComponent extends BaseComponent implements ControlValueAccessor {
    @Input() options?: KeyValueModel[];
    @Input() additionalData?: any[];
    @ViewChild(MarkdownEditorComponent) arcMarkdownEditor?: MarkdownEditorComponent;

    formGroup = new FormGroup({
        title: new ArcFormControl(''),
        text: new ArcFormControl(''),
        saveTemplate: new ArcFormControl(false),
        canSaveTemplate: new ArcFormControl(false)
    });

    editLanguage = new FormControl('de');
    previousLanguage = 'de';
    currentPage = 0;
    isTouched = false;
    isPreviewMode = true;
    internalValue: any;
    originalValue: any;

    readonly translationService = inject(TranslationService);
    readonly languageItems = [
        { value: 'de', label: this.translationService.getText('Components.BulkSendEditor.Languages.De') },
        { value: 'en', label: this.translationService.getText('Components.BulkSendEditor.Languages.En') },
        { value: 'fr', label: this.translationService.getText('Components.BulkSendEditor.Languages.Fr') },
        { value: 'it', label: this.translationService.getText('Components.BulkSendEditor.Languages.It') }
    ];

    constructor() {
        super();
        this.addSubscriptions(this.editLanguage.valueChanges.subscribe(this.onLanguageChange.bind(this)));
    }

    onTouched: () => void = () => {};
    onChange: (value?: string) => void = () => {};


    get previewText(): string {
        const additionalDataLanguage = this.additionalData?.[this.currentPage]['languageCode'];
        const { text } = this.getInternalValues(additionalDataLanguage);
        return text;
    }

    get previewTitle(): string {
        const additionalDataLanguage = this.additionalData?.[this.currentPage]['languageCode'];
        const { title } = this.getInternalValues(additionalDataLanguage);
        return title;
    }

    get canGoPrevious(): boolean {
        return this.currentPage > 0;
    }
    get canGoNext(): boolean {
        return this.currentPage < this.totalPages - 1;
    }

    get totalPages(): number {
        return this.additionalData?.length ?? 0;
    }

    get tags(): KeyValueModel[] {
        return this.options?.filter(item => !!item.value) ?? [];
    }

    writeValue(value: any): void {
        this.internalValue = value;
        this.originalValue = value;

        const { title, text, canSaveTemplate, shouldSaveTemplate } = this.getInternalValues(this.editLanguage.value!);

        this.formGroup.patchValue({ title, text, canSaveTemplate, saveTemplate: shouldSaveTemplate });
    }
    registerOnChange(fn: (value?: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    showEdit(event: any): void {
        event.preventDefault();
        this.isPreviewMode = false;
    }

    handleTagClick(tag: KeyValueModel<string, string>): void {
        const tagValue = '[' + tag.key + ']';
        this.arcMarkdownEditor?.insertTextAtCursor(tagValue);
    }

    nextPage(event: Event): void {
        event.preventDefault();
        if (this.canGoNext) {
            this.currentPage++;
        }
    }

    previousPage(event: Event): void {
        event.preventDefault();
        if (this.canGoPrevious) {
            this.currentPage--;
        }
    }

    cancel(event: Event): void {
        event.preventDefault();
        this.isPreviewMode = true;

        // reset the value
        const { title, text } = this.getInternalValues(this.editLanguage.value!);
        this.formGroup.patchValue({ title, text });
    }

    save(event: Event): void {
        event.preventDefault();

        this.isPreviewMode = true;
        this.saveEditValues();
        this.onChange(this.internalValue);
    }

    onLanguageChange(): void {
        const { title, text } = this.getInternalValues(this.editLanguage.value!);
        this.saveInternalValues(this.previousLanguage);
        this.previousLanguage = this.editLanguage.value!;

        this.formGroup.patchValue({ title, text });
    }

    private getInternalValues(language: string): { title: string; text: string; canSaveTemplate: boolean; shouldSaveTemplate: boolean } {
        const titleKey = `title_${language}`;
        const textKey = `text_${language}`;

        return {
            title: this.internalValue[titleKey],
            text: this.internalValue[textKey],
            canSaveTemplate: this.internalValue['canSaveTemplate'],
            shouldSaveTemplate: this.internalValue['saveTemplate']
        };
    }

    private saveEditValues(): void {
        this.internalValue[`title_${this.editLanguage.value}`] = this.formGroup.controls.title.value;
        this.internalValue[`text_${this.editLanguage.value}`] = this.formGroup.controls.text.value;
        this.internalValue['saveTemplate'] = this.formGroup.controls.saveTemplate.value;
        this.internalValue['canSaveTemplate'] = this.formGroup.controls.canSaveTemplate.value;

        this.originalValue[`title_${this.editLanguage.value}`] = this.formGroup.controls.title.value;
        this.originalValue[`text_${this.editLanguage.value}`] = this.formGroup.controls.text.value;
        this.originalValue['saveTemplate'] = this.formGroup.controls.saveTemplate.value;
        this.originalValue['canSaveTemplate'] = this.formGroup.controls.canSaveTemplate.value;
    }

    private saveInternalValues(language: string): void {
        this.internalValue[`title_${language}`] = this.formGroup.controls.title.value;
        this.internalValue[`text_${language}`] = this.formGroup.controls.text.value;
        this.internalValue['saveTemplate'] = this.formGroup.controls.saveTemplate.value;
        this.internalValue['canSaveTemplate'] = this.formGroup.controls.canSaveTemplate.value;
    }
}
