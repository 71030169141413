import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { LayoutsModule } from '../components/layouts/layouts.module';
import { TranslocoRootModule } from './transloco-root.module';
import { HttpInterceptorProviders } from '../core/services/interceptors/interceptors';
import { UsersStore } from './services/stores/users.store';
import { StatisticsStore } from './services/stores/statistics.store';
import { DirectivesModule } from '../core/directives/directives.module';
import { LowerCaseUrlSerializer } from '../core/utils/tools/lowercase-url-serializer';

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslocoRootModule,
        AppRoutingModule,
        LayoutsModule,
        DirectivesModule,
        ViewsModule
    ],
    providers: [
        HttpInterceptorProviders,
        UsersStore,
        StatisticsStore,
        provideHttpClient(withInterceptorsFromDi()),
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
    ]
})
export class AppModule { }
