<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToTransactionArticlesPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        @if (isLoading()) {
            <div class="w-full h-full grid place-items-center">
                <mat-spinner [diameter]="32" />
            </div>
        } @else {
            @for (transactionArticle of topTransactionArticles(); track transactionArticle.articleNumber) {
                <div class="relative mb-1 h-[43px]">
                    <div class="flex h-full w-full bg-info-light text-on-app overflow-visible items-center"
                        [style.width.%]="widths()[transactionArticle.quantity]">
                        <div class="ml-3 grow">
                            <div class="text-xs mb-[-5px]">{{ transactionArticle.articleNumber }}</div>
                            <span class="text-sm whitespace-nowrap">{{ transactionArticle.article }}</span>
                        </div>
                        <div class="ml-4 float-right mr-3">
                            <span class="text-2xl">{{ transactionArticle.quantity }}</span>
                        </div>
                    </div>
                </div>
            }
        }
    </div>
</div>
