import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TagListModel } from '../../../models/responses/tag-list.model';
import { TagModel } from '../../../models/tag.model';
import { TagsStore } from '../../../services/stores/tags.store';
import { TagEditBaseDataComponent } from './tag-edit-items/tag-edit-base-data/tag-edit-base-data.component';
import { TagEditArticlesComponent } from './tag-edit-items/tag-edit-articles/tag-edit-articles.component';
import { TagEditModel } from '../../../models/requests/tag-edit.model';

@Component({
    selector: 'arc-tags',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends PageListViewComponent<TagListModel, TagModel, TagModel, TagEditModel> {
    private readonly tagsStore = inject(TagsStore);

    constructor() {
        super('Tags');
        this.config = new TableListViewConfigModel<TagListModel, TagModel, TagModel>({
            entityName: 'Tags',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Tags.List.Tag',
                    propertyName: 'title',
                    propertyName2: 'id',
                    widthPixels: 236
                }),
                articlesCount: new StringColumnModel({
                    columnTitleKey: 'Tags.List.ArticleCount',
                    propertyName: 'articleCount',
                    widthPixels: 250
                })
            },
            defaultColumnOrder: ['title', 'articlesCount'],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            store: this.tagsStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Tags.Edit.BaseData.ComponentTitle', component: TagEditBaseDataComponent },
                    { titleKey: 'Tags.Edit.Articles.ComponentTitle', component: TagEditArticlesComponent }
                ],
                headerSubtitle: currentItem => of(currentItem.title!)
            }
        });
    }
}
