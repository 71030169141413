import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { AddressPickerFormGroupType } from '../../../../../../components/form/address-picker/types/address-picker-form-group.type';
import { PlaceSelectFormGroupType } from '../../../../../../components/form/place-select/types/place-select-form-group.type';

@Component({
    selector: 'arc-settings-invoices-qr-bill',
    templateUrl: './settings-invoices-qr-bill.component.html',
    styleUrl: './settings-invoices-qr-bill.component.scss'
})
export class SettingsInvoicesQrBillComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        invoiceIban: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceCompanyName: new ArcFormControl<OptionalType<string>>(undefined),
        invoiceAddress: new FormGroup<AddressPickerFormGroupType>({
            street: new ArcFormControl<string>(undefined),
            streetNumber: new ArcFormControl<string>(undefined),
            streetSupplement: new ArcFormControl<string>(undefined),
            place: new FormGroup<PlaceSelectFormGroupType>({
                zip: new ArcFormControl<string>(undefined),
                city: new ArcFormControl<string>(undefined),
                countryIsoCode: new ArcFormControl<string>(undefined)
            })
        })
    });

    override onItemSet(): void {
        const item = this.item();
        this.formGroup.patchValue({
            ...item,
            invoiceAddress: {
                street: item.invoiceStreet,
                streetNumber: item.invoiceStreetNumber,
                streetSupplement: item.invoiceStreetSupplement,
                place: {
                    zip: item.invoiceZip,
                    city: item.invoiceCity,
                    countryIsoCode: item.invoiceCountryIsoCode
                }
            }
        });
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.getRawValue();
        return {
            invoiceIban: value.invoiceIban,
            invoiceCompanyName: value.invoiceCompanyName,
            invoiceStreet: value.invoiceAddress.street,
            invoiceStreetNumber: value.invoiceAddress.streetNumber,
            invoiceStreetSupplement: value.invoiceAddress.streetSupplement,
            invoiceZip: value.invoiceAddress.place.zip,
            invoiceCity: value.invoiceAddress.place.city,
            invoiceCountryIsoCode: value.invoiceAddress.place.countryIsoCode
        };
    }
}
