import { Component, inject, OnInit, input, computed, signal } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { BlobsStore } from '../../../../app/services/stores/blobs.store';
import { ApiResponseModel } from '../../../../app/models/responses/api-response.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { ArticleImageModel } from '../../../../app/models/responses/article-image.model';

@Component({
    selector: 'arc-images-widget',
    templateUrl: './images-widget.component.html',
    styleUrls: ['./images-widget.component.scss']
})
export class ImagesWidgetComponent implements OnInit {
    readonly images = input.required<ArticleImageModel[]>();

    readonly isLoading = signal(true);
    readonly imagesContents = signal<string[]>([]);
    readonly imagesUrls = computed(() => this.images().filter(ai => !!ai.path).map(ai => ai.path!));

    private readonly blobsStore = inject(BlobsStore);

    ngOnInit(): void {
        const requests: Observable<ApiResponseModel<OptionalType<string>>>[] = [];
        const images = this.images().filter(ai => !!ai.blobFileName);

        if (images.length === 0) {
            this.isLoading.set(false);
            return;
        }

        images.forEach(ai =>
            requests.push(this.blobsStore.getFileByName(ai.blobFileName!))
        );
        forkJoin(requests).subscribe(r => {
            const lImagesContents: string[] = [];
            r.forEach(resp => {
                if (!!resp.value) {
                    lImagesContents.push(resp.value);
                }
            });

            this.imagesContents.set(lImagesContents);
            this.isLoading.set(false);
        });
    }
}
