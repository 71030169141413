@if (model(); as customer) {
    <div class="h-full w-full overflow-auto grid grid-cols-12 gap-4 break-words">
        <!-- first column -->
        <div class="flex flex-col gap-3 col-span-7">
            <div class="min-h-[100px]">
                <p class="text-on-app-light text-sm">{{ 'General.Address.Address' | transloco }}</p>
                @if (customer.isCompany) {
                    <p>{{ customer.displayName }}</p>
                }
                <p>
                    {{ customer.salutation }} {{ customer.salutationTitle }}
                    {{ customer.isCompany ? customer.companyContactName : customer.firstname + ' ' + customer.name }}
                </p>
                <p>{{ customer.street }} {{ customer.streetNumber }}</p>
                <p>{{ customer.streetSupplement }}</p>
                @if (customer.isPoBox) {
                    <p>{{ 'General.Address.IsPoBox' | transloco }} {{ customer.poBox }}</p>
                }
                <p>{{ customer.zip }} {{ customer.city }}</p>
            </div>
            <div class="min-h-[100px]">
                <p class="text-on-app-light text-sm">{{ 'General.Contact' | transloco }}</p>
                @if (!!customer.contactEmail) {
                    <p class="flex gap-2">
                        <arc-icon icon="mail_outline" [size]="14" />
                        <a href="mailto:{{ customer.contactEmail }}" class="line-clamp-1">{{ customer.contactEmail }}</a>
                    </p>
                }
                @if (!!customer.contactPhone) {
                    <p class="flex gap-2">
                        <arc-icon icon="phone" [size]="14" />
                        <a href="tel:{{ customer.contactPhone }}">{{ customer.contactPhone }}</a>
                    </p>
                }
                @if (!!customer.contactMobile) {
                    <p class="flex gap-2">
                        <arc-icon icon="smartphone" [size]="14" />
                        <a href="tel:{{ customer.contactMobile }}">{{ customer.contactMobile }}</a>
                    </p>
                }
                @if (!!customer.contactFax) {
                    <p class="flex gap-2">
                        <arc-icon icon="fax" [size]="14" />
                        <a href="fax:{{ customer.contactFax }}">{{ customer.contactFax }}</a>
                    </p>
                }
                @if (!!customer.contactUrl) {
                    <p class="flex gap-2">
                        <arc-icon icon="language" [size]="14" />
                        <a class="line-clamp-1" target="_blank" href="//{{ websiteUrl() }}">{{ customer.contactUrl }}</a>
                    </p>
                }
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-3 col-span-5">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Customers.List.CustomerProfile' | transloco }}</p>
                @if(customerProfilePermission === true) {
                    <p>{{ customer.customerProfile }}</p>
                }
                @else{
                    <div class="flex items-start">
                        <arc-no-permission-lock class="mt-1" [missingPermission]="customerProfilePermission" />
                    </div>
                }
            </div>
            <div>
                <p class="text-on-app-light text-sm">{{ 'Customers.Edit.BaseData.BirthDate' | transloco }}</p>
                <p>{{ dateService.format(customer.birthdate) }}</p>
            </div>
            <div>
                <p class="text-on-app-light text-sm">{{ 'Customers.Detail.ContactData.CardNumber' | transloco }}</p>
                <p>{{ customer.cardNumber }}</p>
            </div>
            <div>
                <p class="text-on-app-light text-sm">{{ 'General.Language' | transloco }}</p>
                <p>{{ customer.language }}</p>
            </div>
            @if (customer.noCommunication) {
                <div>
                    <p class="flex gap-2">
                        <arc-icon icon="block" [size]="14" fontWeight="bold" />
                        <span class="font-bold">{{ 'Customers.Detail.ContactData.DoNotAdvertise' | transloco }}</span>
                    </p>
                </div>
            }
        </div>
    </div>
} @else {
    <div class="flex items-center justify-center h-full">
        <span class="text-sm">
            {{ 'Customers.Detail.ContactData.NoCustomerSelected' | transloco }}
        </span>
    </div>
}
