<mat-toolbar class="!pl-0 !pr-2 !text-on-top-bar-hover">
    <mat-toolbar-row>
        <!-- logo & "hamburger" menu -->
        <div class="grid place-items-center">
            <button #hamburgerButton mat-icon-button (click)="toggleSidebar()"
                [ngStyle]="{ transform: 'rotate(' + currentRotation() + 'deg)' }"
                class="transition-transform !duration-[300ms] ease-in-out hover:!text-inherit">
                <mat-icon svgIcon="arcavis_icon" class="scale-75"></mat-icon>
            </button>
        </div>
        <a routerLink="/home" class="mr-8" [ngClass]="{ 'smd:!hidden': !isSearchbarCollapsed() }">
            <div id="logo"></div>
        </a>

        <!-- main navigation -->
        <div class="flex gap-2 ml-4 smd:hidden">
            <ng-container *ngFor="let menu of mainNavigation()">
                <a mat-button routerLinkActive="!text-on-top-bar-selected" [routerLink]="menu.path"
                    class="!text-on-top-bar hover:!text-on-top-bar-hover" [ngClass]="{ '!text-accent': menu.subMenu === activeSubMenu() }">
                    {{ menu.labelKey | transloco }}
                </a>
            </ng-container>
        </div>

        <arc-global-search (searchbarCollapsed)="isSearchbarCollapsed.set($event)"></arc-global-search>

        <button mat-icon-button (click)="openHelp()">
            <mat-icon>help</mat-icon>
        </button>

        @if(hasOpenTickets()) {
            <button mat-icon-button (click)="openTickets()">
                <mat-icon [matBadge]="actionNeededCount() > 0 ? actionNeededCount() : null" matBadgeColor="warn" aria-hidden="false">
                    chat_bubble
                </mat-icon>
            </button>
        }

        <button mat-icon-button [matMenuTriggerFor]="userMenu">
            <mat-icon>person</mat-icon>
        </button>

        <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="toggleTheme()">
                <mat-icon color="accent">{{ isDarkMode() ? 'light_mode' : 'dark_mode' }}</mat-icon>
                {{
                    (isDarkMode() ? 'Layout.TopBar.ThemeSwitcher.SwitchToLightTheme' : 'Layout.TopBar.ThemeSwitcher.SwitchToDarkTheme')
                        | transloco
                }}
            </button>
            <button mat-menu-item routerLink="settings/my-profile">
                <mat-icon color="accent">person</mat-icon>
                {{ 'Layout.Menu.Settings.MyProfile' | transloco }}
            </button>
            <button mat-menu-item routerLink="settings/base-settings">
                <mat-icon color="accent">settings</mat-icon>
                {{ 'Layout.TopBar.Settings' | transloco }}
            </button>
            @if (messagesPermission() === true) {
                <button mat-menu-item routerLink="settings/messages">
                    <mat-icon color="accent">inbox</mat-icon>
                    {{ 'Layout.Menu.Settings.Messages' | transloco }}
                </button>
            }
            <button mat-menu-item [matMenuTriggerFor]="langMenu">
                <mat-icon color="accent">translate</mat-icon>
                {{ selectedLanguage()?.name }}
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon color="accent">logout</mat-icon>
                {{ 'Login.Logout' | transloco }}
            </button>
        </mat-menu>

        <mat-menu #langMenu="matMenu">
            <button mat-menu-item *ngFor="let lang of allowedLanguages()" (click)="switchLanguage(lang.code)">
                <span>{{ lang.name }}</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
    <mat-toolbar-row class="gap-4 md:!hidden overflow-x-auto">
        <div class="grid grid-cols-4 gap-2 grow">
            <ng-container *ngFor="let menu of mainNavigation()">
                <button mat-button routerLinkActive="tab-active" [routerLink]="menu.path" class="!min-w-fit px-2 !text-on-top-bar-hover">
                    {{ menu.labelKey | transloco }}
                </button>
            </ng-container>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
