import { Component, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { TopTransactionArticleModel } from '../../../../../models/responses/top-transaction-article.model';
import { DictionaryType } from '../../../../../../core/models/types/dictionary.type';

@Component({
    selector: 'arc-customer-top-transaction-articles',
    templateUrl: './customer-top-transaction-articles.detail-widget.html',
    styleUrls: ['./customer-top-transaction-articles.detail-widget.scss']
})
export class CustomerTopTransactionArticlesDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    isLoading = signal(true);
    topTransactionArticles = signal<TopTransactionArticleModel[]>([]);
    widths = signal<DictionaryType<number>>({});

    private readonly customersStore = inject(CustomersStore);
    private readonly router = inject(Router);

    ngOnInit(): void {
        this.customersStore.getTopTransactionArticles(this.entityModel().id).subscribe(r => {
            const topTransactionArticles = r.value || [];
            this.topTransactionArticles.set(topTransactionArticles);
            const total = topTransactionArticles.length > 0 ? topTransactionArticles[0].quantity : 0;
            const widths: DictionaryType<number> = {};
            topTransactionArticles.forEach(ta => widths[ta.quantity] = ta.quantity / total * 100);
            this.widths.set(widths);
            this.isLoading.set(false);
        });
    }

    goToTransactionArticlesPage(): void {
        this.router.navigate(['transaction-articles'], { queryParams: { PersonId: this.entityModel().id } }).then();
    }
}
