<h1 class="!text-accent" mat-dialog-title>
    {{ "Components.CamtFileUpload.Title" | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-full !flex flex-col overflow-y-auto">
    @if (!fileControl.value && !isLoading()) {
        <arc-file-upload class="block w-full h-[550px] pt-2" [formControl]="fileControl" fileTypes=".camt,.xml"
            labelKey="Components.CamtFileUpload.ImportFile">
        </arc-file-upload>
    }
    @if (previewData().length > 0 && !isLoading()) {
        <div class="h-full relative">
            <arc-static-table [config]="previewTableConfig" [staticData]="previewData()" />
        </div>
    }
    @if (previewData().length === 0 && fileControl.value && !isLoading()) {
        <span>{{'Components.CamtFileUpload.NoInvoices' | transloco}}</span>
    }
    @if (isLoading()) {
        <div class=" w-full h-[550px] grid place-items-center">
            <mat-spinner [diameter]="50" />
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-stroked-button mat-dialog-close>{{"General.Actions.Cancel" | transloco}}</button>
    <arc-button (clicked)="uploadCamtFile()" type="flat" color="accent" [isDisabled]="getPayableInvoicesCount() === 0"
        [isLoading]="isLoading()">
        {{ 'Components.CamtFileUpload.PayXInvoices' | transloco:{ x: getPayableInvoicesCount() } }}
    </arc-button>
</mat-dialog-actions>
