import { Component, ComponentRef, inject } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { Observable, tap, map } from 'rxjs';

import { SidebarService } from '../../../../core/services/sidebar.service';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BaseSidebarComponent } from '../../../sidebar-components/base-sidebar/base-sidebar.component';
import { RightSidebarConfigModel } from '../../../../core/models/right-sidebar-config.model';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';

@Component({
    selector: 'arc-right-sidebar',
    templateUrl: './right-sidebar.component.html',
    styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent {
    componentPortalSubject: Observable<OptionalType<ComponentPortal<any>>>;
    componentRef?: ComponentRef<BaseSidebarComponent>;
    config?: RightSidebarConfigModel;
    width = 'unset';
    maxWidth = 'unset';

    readonly sidebarHeaderService = inject(SidebarHeaderService);
    readonly sidebarService = inject(SidebarService);

    constructor() {
        this.componentPortalSubject = this.sidebarService.rightOpenSubject.pipe(
            tap(isOpen => isOpen ? this.handleOpen() : this.handleClose()),
            map(isOpen => {
                if (!isOpen || !this.sidebarService.rightConfig?.component) {
                    return undefined;
                }

                return new ComponentPortal(this.sidebarService.rightConfig?.component);
            })
        );
    }

    handleRightBackdropClick(): void {
        if (this.sidebarService.rightConfig?.shouldCloseOnBackdropClick) {
            this.sidebarService.closeRight();
        }
    }

    componentAttached(ref: CdkPortalOutletAttachedRef): void {
        this.componentRef = ref as ComponentRef<BaseSidebarComponent>;
        const componentInstance = this.componentRef?.instance;
        if (!!componentInstance && !!this.sidebarService.rightConfig?.data) {
            componentInstance.data = this.sidebarService.rightConfig.data;
        }
    }

    private handleOpen(): void {
        this.config = this.sidebarService.rightConfig;
        this.width = this.config?.width === 'full'
            ? '100%'
            : !!this.config?.width && this.config?.width !== 'auto'
                ? this.config.width
                : 'unset';
        this.maxWidth = this.config?.maxWidth ?? 'unset';
    }

    private handleClose(): void {
        this.componentRef?.instance.onClose();
        this.componentRef?.destroy();

        this.componentRef = undefined;
        this.config = undefined;
    }
}
