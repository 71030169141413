import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { AccountingExportRecordModel } from '../../../../../models/accounting-export-record.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-accounting-export-records-edit-accounting-record',
    templateUrl: './accounting-export-records-edit-accounting-record.component.html',
    styleUrl: './accounting-export-records-edit-accounting-record.component.scss'
})
export class AccountingExportRecordsEditAccountingRecordComponent extends BaseEditSidebarItemComponent<AccountingExportRecordModel> {
    override formGroup = new FormGroup({
        extText: new ArcFormControl<string>(undefined, Validators.required),
        extDebitAccount: new ArcFormControl<string>(undefined, Validators.required),
        extCreditAccount: new ArcFormControl<string>(undefined, Validators.required),
        extDebitCc: new ArcFormControl<OptionalType<string>>(undefined),
        extCreditCc: new ArcFormControl<OptionalType<string>>(undefined),
        invertAmount: new ArcFormControl<boolean>(false),
        extTaxCode: new ArcFormControl<OptionalType<string>>(undefined)
    });

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    prepareSaveModel(): Partial<AccountingExportRecordModel> {
        const value = this.formGroup.value;
        return {
            extText: value.extText,
            extDebitAccount: value.extDebitAccount,
            extCreditAccount: value.extCreditAccount,
            extDebitCc: value.extDebitCc,
            extCreditCc: value.extCreditCc,
            invertAmount: value.invertAmount,
            extTaxCode: value.extTaxCode
        };
    }
}
