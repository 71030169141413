<form [formGroup]="formGroup" class="grid items-center grid-cols-2 grid-rows-4 grid-flow-col ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-input type="email" labelKey="Suppliers.Edit.Contact.ContactEmail" [control]="formGroup.controls.contactEmail"></arc-input>
    <arc-input labelKey="Suppliers.Edit.Contact.ContactPhone" [control]="formGroup.controls.contactPhone"></arc-input>
    <arc-input labelKey="Suppliers.Edit.Contact.ContactMobile" [control]="formGroup.controls.contactMobile"></arc-input>
    <arc-input labelKey="Suppliers.Edit.Contact.ContactFax" [control]="formGroup.controls.contactFax"></arc-input>
    <arc-input labelKey="Suppliers.Edit.Contact.ContactUrl" [control]="formGroup.controls.contactUrl"></arc-input>
    <arc-select label="Suppliers.Edit.Contact.Language" [options]="allowedLanguages"
        [formControl]="formGroup.controls.languageId"></arc-select>
    <arc-general-data-select label="Suppliers.Edit.Contact.ResponsibleUser" [generalDataType]="GeneralDataTypeEnum.Users"
        [formControl]="formGroup.controls.responsibleUserId"></arc-general-data-select>
</form>
