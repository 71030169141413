import { Component, computed } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../../../models/transaction.model';

@Component({
    selector: 'arc-transaction-details',
    templateUrl: './transaction-details.detail-widget.html',
    styleUrls: ['./transaction-details.detail-widget.scss']
})
export class TransactionDetailsDetailWidget extends BaseDetailWidget<TransactionListModel, TransactionModel> {
    orderNumber = computed(() => {
        const entityModel = this.entityModel();
        let result = entityModel.reservationId?.toString() || '';
        if (!!entityModel.reservationExternalReference) {
            result += (!!result ? `${result} / ` : '') + entityModel.reservationExternalReference;
        }

        return result;
    });
}
