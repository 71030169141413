import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { SubscriptionTypeListModel } from '../../../models/responses/subscription-type-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { SubscriptionTypeModel } from '../../../models/subscription-type.model';
import { SubscriptionTypesStore } from '../../../services/stores/subscription-types.store';
import { SubscriptionTypeCardComponent } from './subscription-type-card/subscription-type-card.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { SubscriptionTypeEditBaseDataComponent } from './subscription-type-card/subscription-type-card-edit-items/subscription-type-edit-base-data/subscription-type-edit-base-data.component';
import { SubscriptionTypeEditArticlesComponent } from './subscription-type-edit-items/subscription-type-edit-articles/subscription-type-edit-articles.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-subscription-types',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./subscription-types.component.scss']
})
export class SubscriptionTypesComponent extends PageListViewComponent<SubscriptionTypeListModel> {
    private readonly subscriptionTypesStore = inject(SubscriptionTypesStore);

    constructor() {
        super('SubscriptionTypes');
        this.config = new CardListViewConfigModel<SubscriptionTypeModel, SubscriptionTypeListModel>({
            entityName: 'SubscriptionTypes',
            store: this.subscriptionTypesStore,
            cardComponent: SubscriptionTypeCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({ column: 'IsActive', direction: SortDirectionEnum.Desc }),
            cardTitle: currentItem => currentItem.subscriptionTypeArticleTitle,
            cardSubtitle: currentItem => currentItem.subscriptionTypeArticleArticleNumber,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'SubscriptionTypes.Edit.BaseData.ComponentTitle', component: SubscriptionTypeEditBaseDataComponent },
                    { titleKey: 'SubscriptionTypes.Edit.Articles.ComponentTitle', component: SubscriptionTypeEditArticlesComponent }
                ]
            },
            mainButton: new MainCardButtonModel({
                text: 'SubscriptionTypes.Card.ShowSubscriptions',
                clickFn: data => this._router.navigate(['subscriptions'], { queryParams: { SubscriptionTypeArticleId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }
}
