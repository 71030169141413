<div class="flex flex-row gap-8">
    <div class="flex flex-col gap-4">
        <span class="text-base">{{ 'CustomerProfiles.Edit.BonusPrograms.CollectPoints' | transloco }}</span>
        <arc-number-input label="CustomerProfiles.Edit.BonusPrograms.BonusFactor" [decimalPlaces]="2"
            [formControl]="formGroup.controls.bonusFactor">
        </arc-number-input>
    </div>
    <div class="flex flex-col gap-4">
        <span class="text-base">{{ 'CustomerProfiles.Edit.BonusPrograms.RedeemPoints' | transloco }}</span>
        <div class="flex flex-row">
            <arc-button-toggle-group [control]="formGroup.controls.isDiscount" [items]="voucherPermissionItems"></arc-button-toggle-group>
            @if (!formGroup.value.isDiscount) {
                <arc-checkbox class="m-auto" labelKey="CustomerProfiles.Edit.BonusPrograms.CashbackInPercent"
                    [control]="formGroup.controls.cashbackInPercent">
                </arc-checkbox>
            }
        </div>
    </div>
</div>

@if (formGroup.value.isDiscount) {
    <arc-editable-table [config]="discountTableConfig" [formControl]="formGroup.controls.customerDiscounts">
    </arc-editable-table>
}

@if (!formGroup.value.isDiscount && !formGroup.value.cashbackInPercent) {
    <arc-editable-table [config]="cashbackTableConfig" [formControl]="formGroup.controls.customerCashbacks">
    </arc-editable-table>
}

@if (!formGroup.value.isDiscount && formGroup.value.cashbackInPercent) {
    <arc-editable-table [config]="cashbackInPercentTableConfig" [formControl]="formGroup.controls.customerCashbacksInPercent">
    </arc-editable-table>
}

<arc-checkbox labelKey="CustomerProfiles.Edit.BonusPrograms.NoRemainingBonusPoints" [control]="formGroup.controls.noRemainingBonusPoints">
</arc-checkbox>
