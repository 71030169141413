import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-settings-invoices-customer-account',
    templateUrl: './settings-invoices-customer-account.component.html',
    styleUrl: './settings-invoices-customer-account.component.scss'
})
export class SettingsInvoicesCustomerAccountComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        accountPaymentDays: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number()),
        accountMinAmount: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number())
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            accountPaymentDays: value.accountPaymentDays,
            accountMinAmount: value.accountMinAmount
        };
    }
}
