<h1 mat-dialog-title>
    {{'BonusRuns.Create.Title' | transloco}}
</h1>
<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="w-full flex items-center gap-4 h-[55px]">
        <div class="grow">
            <arc-datepicker [control]="formGroup.controls.perDate" />
        </div>
        <div class="grow">
            <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.CustomerProfiles" label="BonusRuns.Create.CustomerProfile"
                [formControl]="formGroup.controls.customerProfileId">
            </arc-general-data-select>
        </div>
        <div>
            <arc-button type="stroked" [isDisabled]="!canCalculate" [isLoading]="isCalculating()" (clicked)="calculate()">
                {{ 'BonusRuns.Create.Calculate' | transloco }}
            </arc-button>
        </div>
    </div>
    <div class="w-full flex mt-8">
        <arc-static-table #table class="w-full" [config]="bonusRunsTableConfig" [staticData]="staticTableData()"
            (selectionChanged)="onSelectionChanged($event)">
        </arc-static-table>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <arc-button type="stroked" [isDisabled]="isLoading() || isCalculating()" (clicked)="cancel()">
        {{ 'General.Actions.Cancel' | transloco }}
    </arc-button>
    <arc-button type="flat" color="accent" [isLoading]="isLoading()" [isDisabled]="isCalculating() || !canExecute" (clicked)="execute()">
        {{ 'BonusRuns.Create.PayOut' | transloco }}
    </arc-button>
</mat-dialog-actions>
