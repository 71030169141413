import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { GeneralDataStore } from '../../app/services/stores/general-data.store';
import { KeyValueModel } from '../models/key-value.model';
import { GeneralDataTypeEnum } from '../models/enums/general-data-type.enum';

@Injectable({
    providedIn: 'root'
})
export class GeneralDataService {
    private readonly generalDataStore = inject(GeneralDataStore);

    getGeneralData<T = string>(type: GeneralDataTypeEnum): Observable<KeyValueModel<T, string>[]> {
        return this.generalDataStore.getGeneralData<T>(type).pipe(
            map(data => data.value ?? [])
        );
    }
}
