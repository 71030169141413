import { Component, computed, input } from '@angular/core';

import { TransactionUsedPaymentModel } from '../../../../app/models/transaction-used-payment.model';

@Component({
    selector: 'arc-payments-breakdown',
    templateUrl: './payments-breakdown.component.html',
    styleUrls: ['./payments-breakdown.component.scss']
})
export class PaymentsBreakdownComponent {
    readonly items = input.required<TransactionUsedPaymentModel[]>();
    readonly shouldDisplayTotal = input(false);

    readonly total = computed(() => this.items().reduce((acc, item) => acc + item.amount, 0));
    readonly itemWidths = computed(() => this.items().map(i => this.getWidthForItem(i)));

    readonly colors = {
        0: 'bg-accent text-on-app',
        1: 'bg-success-light text-on-app'
    };

    private getWidthForItem(item: TransactionUsedPaymentModel): number {
        return item.amount / this.total() * 100;
    }
}
