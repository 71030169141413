@if(columnModel.isEditable && !!control) {
    <arc-number-input [formControl]="control" [unit]="unitTranslated()" [isUnitTranslated]="true"
        [decimalPlaces]="castedColumnModel.decimalPlaces">
    </arc-number-input>
} @else {
    <span [class.text-sm]="castedColumnModel.isCompact" [class.font-bold]="isBold(castedColumnModel.propertyName)">
        @if(isNumber) {
            {{ value | arcNumber : castedColumnModel.decimalPlaces : '' : unitTranslated() }}
        } @else {
            {{ value }}
        }
    </span>
}
