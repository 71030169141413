import { Component, ElementRef, Signal, computed, viewChild } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { PosLayoutListModel } from '../../../../models/responses/pos-layout-list.model';

@Component({
    selector: 'arc-pos-layout-card',
    templateUrl: './pos-layout-card.component.html',
    styleUrl: './pos-layout-card.component.scss'
})
export class PosLayoutCardComponent extends BaseCardComponent<PosLayoutListModel> {
    private static readonly buttonsGapPx = 2;

    readonly container: Signal<ElementRef<HTMLDivElement>> = viewChild.required('container', { read: ElementRef });

    readonly topGridStyle = computed(() => {
        const item = this.item();
        return {
            'grid-template-columns': `repeat(${item.topGroup.columns}, ${this.buttonSizePx()}px)`,
            'grid-template-rows': `repeat(${item.topGroup.rows}, ${this.buttonSizePx()}px)`,
            gap: `${PosLayoutCardComponent.buttonsGapPx}px`
        };
    });

    readonly bottomGridStyle = computed(() => {
        const item = this.item();
        return {
            'grid-template-columns': `repeat(${item.bottomGroup?.columns}, ${this.buttonSizePx()}px)`,
            'grid-template-rows': `repeat(${item.bottomGroup?.rows}, ${this.buttonSizePx()}px)`,
            gap: `${PosLayoutCardComponent.buttonsGapPx}px`
        };
    });

    private readonly buttonSizePx = computed(() => {
        const item = this.item();
        const container = this.container().nativeElement;

        const maxColumnsAmount = Math.max(item.bottomGroup?.columns ?? 0, item.topGroup.columns);
        const containerWidthPx = container.getBoundingClientRect().width;
        const maxButtonsSizePxWidth = (
            containerWidthPx / maxColumnsAmount -
            PosLayoutCardComponent.buttonsGapPx +
            PosLayoutCardComponent.buttonsGapPx / maxColumnsAmount
        );

        if (!item.bottomGroup) {
            const rowsAmount = item.topGroup.rows;
            const containerHeightPx = container.getBoundingClientRect().height;
            return Math.min(maxButtonsSizePxWidth, (
                containerHeightPx / rowsAmount -
                PosLayoutCardComponent.buttonsGapPx +
                PosLayoutCardComponent.buttonsGapPx / rowsAmount
            ));
        }

        return maxButtonsSizePxWidth;
    });

    transformBackgroundColor(bgColor?: string): string {
        return !!bgColor && bgColor !== '#ffffff' ? bgColor : '#eee';
    }
}
