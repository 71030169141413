<mat-card-header class="items-center mb-2">
    <mat-card-title>
        @if (!!currentSavedFilter) {
            <span>{{ 'Components.SearchFilter.AppliedFilter' | transloco }}: {{ currentSavedFilter.name }}</span>
        } @else {
            <span>{{ 'Components.SearchFilter.Filter' | transloco }}</span>
        }
    </mat-card-title>

    <!-- saving / loading of filters -->
    <div class="ml-auto flex items-center gap-4">
        @if (!currentSavedFilter && !isSaving) {
            <button mat-icon-button (click)="saveCurrentFilters()" [disabled]="!canSave"
                [matTooltip]="'Components.SearchFilter.SaveFilter' | transloco">
                <mat-icon>save</mat-icon>
            </button>
        }

        @if (!!currentSavedFilter && !isSaving) {
            <button mat-icon-button (click)="updateCurrentSavedFilter()" [disabled]="!hasUnsavedChanges || !isValid"
                [matTooltip]="'Components.SearchFilter.SaveFilter' | transloco">
                <mat-icon>save_as</mat-icon>
            </button>
        }

        @if (!!currentSavedFilter && !isDeleting) {
            <button [disabled]="isDeleting" mat-icon-button (click)="deleteCurrentSavedFilter()"
                [matTooltip]="'Components.SearchFilter.DeleteSavedFilter' | transloco">
                <mat-icon>delete</mat-icon>
            </button>
        }

        @if (isDeleting || isSaving) {
            <mat-spinner diameter="20"></mat-spinner>
        }

        <button mat-icon-button [matMenuTriggerFor]="savedFilterSelection" [disabled]="savedFilters.length === 0"
            [matTooltip]="'Components.SearchFilter.SavedFilters' | transloco">
            <mat-icon>topic</mat-icon>
        </button>
    </div>
</mat-card-header>

<mat-menu #savedFilterSelection="matMenu">
    @for (savedFilter of savedFilters; track savedFilter.name) {
        <button mat-menu-item (click)="applySavedFilter(savedFilter.id)">{{ savedFilter.name }}</button>
    }
</mat-menu>

<mat-card-content>
    <div class="flex flex-col">
        <!-- readonly filters -->
        @if (readOnlyFilterLabels.length > 0) {
            <div class="gap-4">
                <mat-chip-listbox>
                    @for (filter of readOnlyFilterLabels; track filter; let i = $index) {
                        <mat-chip-option>
                            {{ filter }}
                            <button matChipRemove (click)="removeReadOnlyFilter(i)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-option>
                    }
                </mat-chip-listbox>
            </div>
        }

        <!-- editable filters -->
        @for (filter of currentFilters; track $index; let i = $index) {
            <div class="flex flex-col gap-2">
                @if (i !== 0) {
                    <mat-divider class="!mt-4 !mb-2"></mat-divider>
                }

                <arc-filter-part [index]="i" [filterProperties]="selectableFilterItems">
                </arc-filter-part>
            </div>
        }
    </div>

    <div class="mt-2 flex items-center gap-2">
        <button mat-button (click)="addFilter()" [disabled]="selectableFilterItems.length === 0">
            <mat-icon>add</mat-icon>
            <span>{{ 'Components.SearchFilter.AddFilter' | transloco }}</span>
        </button>

        <div class="grow"></div>

        <button mat-icon-button (click)="reset()" [matTooltip]="'General.Actions.Reset' | transloco">
            <mat-icon>replay</mat-icon>
        </button>
        <button mat-flat-button color="primary" [disabled]="!canApply" (click)="applyFilters()">
            <span class="flex gap-2">
                @if (isLoading) {
                    <mat-spinner diameter="20"></mat-spinner>
                }

                {{ 'Components.SearchFilter.ShowXResults' | transloco: { value: canApply ? resultCount : '' } }}
            </span>
        </button>
    </div>
</mat-card-content>
