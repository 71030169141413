import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { GridsterModule } from 'angular-gridster2';
import { QRCodeModule } from 'angularx-qrcode';

import { MaterialModule } from '../../core/utils/material.module';
import { ComponentsModule } from '../../components/components.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './security/login/login.component';
import { RequestPasswordTokenComponent } from './security/request-pwd-token/request-password-token.component';
import { ResetPasswordComponent } from './security/reset-password/reset-password.component';
import { ArticlesComponent } from './articles/articles/articles.component';
import { ReportsComponent } from './transactions/reports/reports.component';
import { ReportCardComponent } from './transactions/reports/report-card/report-card.component';
import { CustomersComponent } from './customers/customers/customers.component';
import { CustomerEditBaseDataComponent } from './customers/customers/customer-edit-items/customer-edit-base-data/customer-edit-base-data.component';
import { ReportsDesignerComponent } from './transactions/reports/designer/reports-designer.component';
import { ControlsComponent } from './controls/controls.component';
import { PipesModule } from '../../core/pipes/pipes.module';
import { ReportsViewerComponent } from './transactions/reports/reports-viewer/reports-viewer.component';
import { TransactionsComponent } from './transactions/transactions/transactions.component';
import { TransactionPaymentsComponent } from './transactions/transaction-payments/transaction-payments.component';
import { CustomerContactInformationDetailWidget } from './customers/customers/customer-detail-widgets/customer-contact-information/customer-contact-information.detail-widget';
import { CustomerTransactionsDetailWidget } from './customers/customers/customer-detail-widgets/customer-transactions/customer-transactions.detail-widget';
import { CustomerBaseDataDetailWidget } from './customers/customers/customer-detail-widgets/customer-base-data/customer-base-data.detail-widget';
import { CustomerPaymentsDetailWidget } from './customers/customers/customer-detail-widgets/customer-payments/customer-payments.detail-widget';
import { CustomerTurnoverDetailWidget } from './customers/customers/customer-detail-widgets/customer-turnover/customer-turnover.detail-widget';
import TransactionArticlesComponent from './transactions/transaction-articles/transaction-articles.component';
import { CustomerEditContactComponent } from './customers/customers/customer-edit-items/customer-edit-contact/customer-edit-contact.component';
import { ArticlePicturesDetailWidget } from './articles/articles/article-detail-widgets/article-pictures/article-pictures.detail-widget';
import { ArticleEditTextsComponent } from './articles/articles/article-edit-items/article-edit-texts/article-edit-texts.component';
import { ArticleEditBaseDataComponent } from './articles/articles/article-edit-items/article-edit-base-data/article-edit-base-data.component';
import { UsersComponent } from './settings/users/users.component';
import { PosComponent } from './settings/pos/pos.component';
import { PosTypeColumnComponent } from './settings/pos/components/pos-type-column/pos-type-column.component';
import { BranchesComponent } from './settings/branches/branches.component';
import { RegionsComponent } from './settings/regions/regions.component';
import { PosLayoutsComponent } from './settings/poslayouts/poslayouts.component';
import { PosCustomerScreenLayoutsComponent } from './settings/poscustomer-screen-layouts/poscustomer-screen-layouts.component';
import { ConfigurationComponent } from './settings/configuration/configuration.component';
import { RolesComponent } from './settings/roles/roles.component';
import { TransactionTypesComponent } from './settings/transaction-types/transaction-types.component';
import { DefaultValuesComponent } from './settings/default-values/default-values.component';
import { AccountTypesComponent } from './settings/account-types/account-types.component';
import { ResourcesComponent } from './settings/resources/resources.component';
import { SystemLogComponent } from './settings/system-log/system-log.component';
import { DirectivesModule } from '../../core/directives/directives.module';
import { ArticleVariantsComponent } from './articles/article-variants/article-variants.component';
import { ArticleSalesNumbersDetailWidget } from './articles/articles/article-detail-widgets/article-sales-numbers/article-sales-numbers.detail-widget';
import { ArticleArticleStockDataDetailWidget } from './articles/articles/article-detail-widgets/article-stock-data/article-article-stock-data.detail-widget';
import { ArticleDescriptionDetailWidget } from './articles/articles/article-detail-widgets/article-description/article-description.detail-widget';
import { TransactionCustomerDetailWidget } from './transactions/transactions/transaction-detail-widgets/transaction-customer/transaction-customer.detail-widget';
import { TransactionArticlesDetailWidget } from './transactions/transactions/transaction-detail-widgets/transaction-articles/transaction-articles.detail-widget';
import { ArticleEditPropertiesComponent } from './articles/articles/article-edit-items/article-edit-properties/article-edit-properties.component';
import { TransactionPaymentCustomerDetailWidget } from './transactions/transaction-payments/transaction-payments-detail-widgets/transaction-payment-customer/transaction-payment-customer.detail-widget';
import { TransactionDetailsDetailWidget } from './transactions/transactions/transaction-detail-widgets/transaction-details/transaction-details.detail-widget';
import { TransactionPaymentsDetailWidget } from './transactions/transactions/transaction-detail-widgets/transaction-payments/transaction-payments.detail-widget';
import { CustomerEditMarketingComponent } from './customers/customers/customer-edit-items/customer-edit-marketing/customer-edit-marketing.component';
import { CustomerDescriptionDetailWidget } from './customers/customers/customer-detail-widgets/customer-description/customer-description.detail-widget';
import { CustomerDescriptionDialogComponent } from './customers/customers/customer-detail-widgets/customer-description/customer-description-dialog/customer-description-dialog.component';
import { InvoicesComponent } from './customers/invoices/invoices.component';
import { AmountsColumnComponent } from './customers/invoices/components/amounts-column/amounts-column.component';
import { CustomerSubscriptionsDetailWidget } from './customers/customers/customer-detail-widgets/customer-subscriptions/customer-subscriptions.detail-widget';
import { CustomerVouchersDetailWidget } from './customers/customers/customer-detail-widgets/customer-vouchers/customer-vouchers.detail-widget';
import { CustomerTopTransactionArticlesDetailWidget } from './customers/customers/customer-detail-widgets/customer-top-transaction-articles/customer-top-transaction-articles.detail-widget';
import { TransactionArticleCustomerDetailWidget } from './transactions/transaction-articles/transaction-articles-detail-widgets/transaction-article-customer/transaction-article-customer.detail-widget';
import { TransactionArticlePicturesDetailWidget } from './transactions/transaction-articles/transaction-articles-detail-widgets/transaction-article-pictures/transaction-article-pictures.detail-widget';
import { TransactionArticleDescriptionDetailWidget } from './transactions/transaction-articles/transaction-articles-detail-widgets/transaction-article-description/transaction-article-description.detail-widget';
import { InvoiceCreateDialogComponent } from './customers/invoices/invoice-create-dialog/invoice-create-dialog.component';
import { CustomerEditTextsDetailWidget } from './customers/customers/customer-edit-items/customer-edit-texts/customer-edit-texts.detail-widget';
import { ArticleEditIdentificationsComponent } from './articles/articles/article-edit-items/article-edit-identifications/article-edit-identifications.component';
import { CustomerEditAccountComponent } from './customers/customers/customer-edit-items/customer-edit-account/customer-edit-account.component';
import { CustomerAccountBookingDialogComponent } from './customers/customers/customer-edit-items/customer-edit-account/customer-account-booking-dialog/customer-account-booking-dialog.component';
import { TransactionPaymentDetailsDetailWidget } from './transactions/transaction-payments/transaction-payments-detail-widgets/transaction-payment-details/transaction-payment-details.detail-widget';
import { VouchersTypesComponent } from './articles/vouchers-types/vouchers-types.component';
import { ReportEditBaseDataComponent } from './transactions/reports/report-edit-items/report-edit-base-data/report-edit-base-data.component';
import { VoucherTypeCardComponent } from './articles/vouchers-types/voucher-type-card/voucher-type-card.component';
import { VoucherTypeEditBaseDataComponent } from './articles/vouchers-types/voucher-type-edit-items/voucher-type-edit-base-data/voucher-type-edit-base-data.component';
import { TagsComponent } from './articles/tags/tags.component';
import { SalesComponent } from './articles/sales/sales.component';
import { SaleCardComponent } from './articles/sales/sale-card/sale-card.component';
import { TagEditBaseDataComponent } from './articles/tags/tag-edit-items/tag-edit-base-data/tag-edit-base-data.component';
import { TagEditArticlesComponent } from './articles/tags/tag-edit-items/tag-edit-articles/tag-edit-articles.component';
import { ArticleStocksComponent } from './stores/article-stocks/article-stocks.component';
import { VouchersComponent } from './articles/vouchers/vouchers.component';
import { VoucherTypeColumnComponent } from './articles/vouchers/components/voucher-type-column/voucher-type-column.component';
import { VoucherIdQrCodeDetailWidget } from './articles/vouchers/voucher-detail-widgets/voucher-id-qr-code/voucher-id-qr-code.detail-widget';
import { VoucherCustomerDetailWidget } from './articles/vouchers/voucher-detail-widgets/voucher-customer/voucher-customer.detail-widget';
import { VoucherTypeDetailWidget } from './articles/vouchers/voucher-detail-widgets/voucher-type/voucher-type.detail-widget';
import { VoucherCreateDialogComponent } from './articles/vouchers/voucher-create-dialog/voucher-create-dialog.component';
import { ArticleStockEditBaseDataComponent } from './stores/article-stocks/article-stock-edit-items/article-stock-edit-base-data/article-stock-edit-base-data.component';
import { InvoiceEditBaseDataComponent } from './customers/invoices/invoice-edit-items/invoice-edit-base-data/invoice-edit-base-data.component';
import { InvoiceCustomerDetailsDetailWidget } from './customers/invoices/invoice-detail-widgets/invoice-customer-details/invoice-customer-details.detail-widget';
import { InvoiceRemarksDetailWidget } from './customers/invoices/invoice-detail-widgets/invoice-remarks/invoice-remarks.detail-widget';
import { InvoiceTransactionsDetailWidget } from './customers/invoices/invoice-detail-widgets/invoice-transactions/invoice-transactions.detail-widget';
import { ArticleEditPricesComponent } from './articles/articles/article-edit-items/article-edit-prices/article-edit-prices.component';
import { SuppliersComponent } from './stores/suppliers/suppliers.component';
import { OrdersComponent } from './stores/orders/orders.component';
import { ReservationsComponent } from './customers/reservations/reservations.component';
import { SubscriptionTypesComponent } from './articles/subscription-types/subscription-types.component';
import { SubscriptionTypeCardComponent } from './articles/subscription-types/subscription-type-card/subscription-type-card.component';
import { SubscriptionTypeEditBaseDataComponent } from './articles/subscription-types/subscription-type-card/subscription-type-card-edit-items/subscription-type-edit-base-data/subscription-type-edit-base-data.component';
import { SubscriptionTypeEditArticlesComponent } from './articles/subscription-types/subscription-type-edit-items/subscription-type-edit-articles/subscription-type-edit-articles.component';
import { ArticleEditSuppliersComponent } from './articles/articles/article-edit-items/article-edit-suppliers/article-edit-suppliers.component';
import { InvoiceDetailWidget } from './customers/invoices/invoice-detail-widgets/invoice/invoice.detail-widget';
import { ArticleEditSetArticlesComponent } from './articles/articles/article-edit-items/article-edit-set-articles/article-edit-set-articles.component';
import { SubscriptionsComponent } from './articles/subscriptions/subscriptions.component';
import { ArticleDetailsDetailWidget } from './articles/articles/article-detail-widgets/article-details/article-details.detail-widget';
import { PosReportsComponent } from './settings/pos-reports/pos-reports.component';
import { PosReportCardComponent } from './settings/pos-reports/pos-report-card/pos-report-card.component';
import { PosReportEditBaseDataComponent } from './settings/pos-reports/pos-report-edit-items/pos-report-edit-base-data/pos-report-edit-base-data.component';
import { WarehouseTransfersComponent } from './stores/warehouse-transfers/warehouse-transfers.component';
import { QueryDesignerComponent } from './transactions/query-designer/query-designer.component';
import { QueryDesignerCardComponent } from './transactions/query-designer/components/card/query-designer-card.component';
import { QueryDesignerEditBaseDataComponent } from './transactions/query-designer/edit-items/query-designer-edit-base-data/query-designer-edit-base-data.component';
import { QueryDesignerEditViewComponent } from './transactions/query-designer/edit-items/query-designer-edit-view/query-designer-edit-view.component';
import { SupplierEditBaseDataComponent } from './stores/suppliers/supplier-edit-items/supplier-edit-base-data/supplier-edit-base-data.component';
import { SupplierEditContactComponent } from './stores/suppliers/supplier-edit-items/supplier-edit-contact/supplier-edit-contact.component';
import { SupplierEditTextComponent } from './stores/suppliers/supplier-edit-items/supplier-edit-text/supplier-edit-text.component';
import { SupplierEditDeliveryConditionsComponent } from './stores/suppliers/supplier-edit-items/supplier-edit-delivery-conditions/supplier-edit-delivery-conditions.component';
import { MessagesComponent } from './settings/messages/messages.component';
import { MessagePriorityColumnComponent } from './settings/messages/components/message-priority-column/message-priority-column.component';
import { MessageBodyDetailWidget } from './settings/messages/detail-widgets/message-body/message-body.detail-widget';
import { OrderArticlesComponent } from './stores/order-articles/order-articles.component';
import { OrderCreateDialogComponent } from './stores/orders/order-create-dialog/order-create-dialog.component';
import { SupplierBaseDataDetailWidget } from './stores/suppliers/detail-widgets/supplier-base-data/supplier-base-data.detail-widget';
import { OrderSupplierBaseDataDetailWidget } from './stores/orders/detail-widgets/order-supplier-base-data/order-supplier-base-data.detail-widget';
import { OrderDataDetailWidget } from './stores/orders/detail-widgets/order-data/order-data.detail-widget';
import { OrderEditBaseDataComponent } from './stores/orders/order-edit-items/order-edit-base-data/order-edit-base-data.component';
import { OrderEditArticlesComponent } from './stores/orders/order-edit-items/order-edit-articles/order-edit-articles.component';
import { OrderArticleQuickAddComponent } from './stores/orders/order-edit-items/order-edit-articles/order-article-quick-add/order-article-quick-add.component';
import { PosItemOverviewComponent } from './settings/pos/pos-detail-widgets/pos-item-overview/pos-item-overview.detail-widget';
import { PosTransactionsComponent } from './settings/pos/pos-detail-widgets/pos-transactions/pos-transactions.component';
import { PosTransactionsListWidgetComponent } from './settings/pos/pos-detail-widgets/pos-transactions-list-widget/pos-transactions-list-widget.component';
import { ImportDefinitionsComponent } from './transactions/import-definitions/import-definitions.component';
import { ImportDefinitionCardComponent } from './transactions/import-definitions/components/card/import-definition-card.component';
import { PosEditBaseDataComponent } from './settings/pos/pos-edit-items/pos-edit-base-data/pos-edit-base-data.component';
import { CustomerProfilesComponent } from './customers/customer-profiles/customer-profiles.component';
import { CustomerProfileCardComponent } from './customers/customer-profiles/customer-profile-card/customer-profiles-card.component';
import { PosEditBalanceComponent } from './settings/pos/pos-edit-items/pos-edit-balance/pos-edit-balance.component';
import { CustomerProfilesBaseDataComponent } from './customers/customer-profiles/customer-profiles-edit-items/customer-profiles-base-data/customer-profiles-base-data.component';
import { ArticleEditImagesComponent } from './articles/articles/article-edit-items/article-edit-images/article-edit-images.component';
import { ArticleGroupsComponent } from './settings/article-groups/article-groups.component';
import { ArticleGroupEditComponent } from './settings/article-groups/components/article-group-edit/article-group-edit.component';
import { CustomerProfilesDiscountsComponent } from './customers/customer-profiles/customer-profiles-edit-items/customer-profiles-discounts/customer-profiles-discounts.component';
import { TreeAutocompleteColumnComponent } from '../../components/dynamic-table/column-components/tree-autocomplete-column/tree-autocomplete-column.component';
import { CustomerBonusProgramsComponent } from './customers/customer-profiles/customer-profiles-edit-items/customer-bonus-programs/customer-bonus-programs.component';
import { WarehouseTranserCreateDialogComponent } from './stores/warehouse-transfers/warehouse-transer-create-dialog/warehouse-transer-create-dialog.component';
import { ReportCreateSourceSelectionComponent } from './transactions/reports/report-create-items/report-create-source-selection/report-create-source-selection.component';
import { WarehouseTransferEditBaseDataComponent } from './stores/warehouse-transfers/edit-items/warehouse-transfer-edit-base-data/warehouse-transfer-edit-base-data.component';
import { WarehouseTransferEditArticlesComponent } from './stores/warehouse-transfers/edit-items/warehouse-transfer-edit-articles/warehouse-transfer-edit-articles.component';
import { WarehouseTransferArticleQuickAddComponent } from './stores/warehouse-transfers/edit-items/warehouse-transfer-edit-articles/warehouse-transfer-article-quick-add/warehouse-transfer-article-quick-add.component';
import { SystemLogMessageDetailWidget } from './settings/system-log/system-log-detail-widgets/system-log-message/system-log-message.detail-widget';
import { SystemLogStackTraceDetailWidget } from './settings/system-log/system-log-detail-widgets/system-log-stacktrace/system-log-stacktrace.detail-widget';
import { SystemLogDetailsTableDetailWidget } from './settings/system-log/system-log-detail-widgets/system-log-details-table/system-log-details-table.detail-widget';
import { RegionCardComponent } from './settings/regions/region-card/region-card.component';
import { RegionEditBaseDataComponent } from './settings/regions/region-edit-items/region-edit-base-data/region-edit-base-data.component';
import { SubscriptionTransactionsComponent } from './articles/subscriptions/subscription-detail-widgets/subscription-transactions/subscription-transactions.component';
import { AccountingExportRecordsComponent } from './settings/accounting-export-records/accounting-export-records.component';
import { TransactionTypeCardComponent } from './settings/transaction-types/transaction-type-card/transaction-type-card.component';
import { TransactionTypeBasicDataComponent } from './settings/transaction-types/transaction-types-edit/transaction-type-basic-data/transaction-type-basic-data.component';
import { TransactionTypePaymentPurposesComponent } from './settings/transaction-types/transaction-types-edit/transaction-type-payment-purposes/transaction-type-payment-purposes.component';
import { TransactionTypePaymentTypeDebitComponent } from './settings/transaction-types/transaction-types-edit/transaction-type-payment-type-debit/transaction-type-payment-type-debit.component';
import { TransactionTypePaymentTypeCreditComponent } from './settings/transaction-types/transaction-types-edit/transaction-type-payment-type-credit/transaction-type-payment-type-credit.component';
import { UnitsComponent } from './settings/units/units.component';
import { UnitEditBaseDataComponent } from './settings/units/unit-edit-items/unit-edit-base-data/unit-edit-base-data.component';
import { UnitCardComponent } from './settings/units/unit-card/unit-card.component';
import { NoPermissionComponent } from './security/no-permission/no-permission.component';
import { BranchEditBaseDataComponent } from './settings/branches/branch-edit-items/branch-edit-base-data/branch-edit-base-data.component';
import { BranchEditReceiptComponent } from './settings/branches/branch-edit-items/branch-edit-receipt/branch-edit-receipt.component';
import { OrderArticlePicturesDetailWidget } from './stores/order-articles/order-article-pictures/order-article-pictures.detail-widget';
import { OrderArticleStocksDetailWidget } from './stores/order-articles/order-article-stocks/order-article-stocks.detail-widget';
import { OrderArticleDescriptionDetailWidget } from './stores/order-articles/order-article-description/order-article-description.detail-widget';
import { OrderArticleDetailsDetailWidget } from './stores/order-articles/order-article-details/order-article-details.detail-widget';
import { ReservationCustomerDetailWidget } from './customers/reservations/reservation-customer/reservation-customer.detail-widget';
import { ReservationArticlesDetailWidget } from './customers/reservations/reservation-articles/reservation-articles.detail-widget';
import { InventoriesComponent } from './stores/inventories/inventories.component';
import { InventoryStatusColumnComponent } from './stores/inventories/components/inventory-status-column/inventory-status-column.component';
import { ArticleStockPicturesDetailWidget } from './stores/article-stocks/article-stocks-detail-widgets/article-stock-pictures/article-stock-pictures.detail-widget';
import { ArticleStockDescriptionDetailWidget } from './stores/article-stocks/article-stocks-detail-widgets/article-stock-description/article-stock-description.detail-widget';
import { ArticleStockArticleDetailsDetailWidget } from './stores/article-stocks/article-stocks-detail-widgets/article-stock-article-details/article-stock-article-details.detail-widget';
import { ArticleStockDataDetailWidget } from './stores/article-stocks/article-stocks-detail-widgets/article-stock-data/article-stock-data.detail-widget';
import { ReservationEditBaseDataComponent } from './customers/reservations/reservation-edit-items/reservation-edit-base-data/reservation-edit-base-data.component';
import { ReservationEditArticlesComponent } from './customers/reservations/reservation-edit-items/reservation-edit-articles/reservation-edit-articles.component';
import { ReservationEditArticleEntryComponent } from './customers/reservations/reservation-edit-items/reservation-edit-articles/reservation-edit-article-entry/reservation-edit-article-entry.component';
import { ReservationEditHeaderAdditionalInfoComponent } from './customers/reservations/reservation-edit-header-additional-info/reservation-edit-header-additional-info.component';
import { InventoryTypeColumnComponent } from './stores/inventories/components/inventory-type-column/inventory-type-column.component';
import { UserDataDetailWidget } from './settings/users/user-detail-widgets/user-data/user-data.detail-widget';
import { UserQrCodeDetailWidget } from './settings/users/user-detail-widgets/user-qr-code/user-qr-code.detail-widget';
import { SettingsComponent } from './settings/settings/settings.component';
import { SettingsCustomizeBaseDataComponent } from './settings/settings/settings-edit-items/settings-customize-base-data/settings-customize-base-data.component';
import { SettingsCustomizeColorsComponent } from './settings/settings/settings-edit-items/settings-customize-colors/settings-customize-colors.component';
import { SettingsCustomizeLogoComponent } from './settings/settings/settings-edit-items/settings-customize-logo/settings-customize-logo.component';
import { SettingsInvoicesBankAccountComponent } from './settings/settings/settings-edit-items/settings-invoices-bank-account/settings-invoices-bank-account.component';
import { SettingsInvoicesQrBillComponent } from './settings/settings/settings-edit-items/settings-invoices-qr-bill/settings-invoices-qr-bill.component';
import { SettingsInvoicesRemindersComponent } from './settings/settings/settings-edit-items/settings-invoices-reminders/settings-invoices-reminders.component';
import { SettingsInvoicesCustomerAccountComponent } from './settings/settings/settings-edit-items/settings-invoices-customer-account/settings-invoices-customer-account.component';
import { SettingsSecurityPasswordComponent } from './settings/settings/settings-edit-items/settings-security-password/settings-security-password.component';
import { SettingsSecurityPinComponent } from './settings/settings/settings-edit-items/settings-security-pin/settings-security-pin.component';
import { SettingsSettingsFormatsComponent } from './settings/settings/settings-edit-items/settings-settings-formats/settings-settings-formats.component';
import { SettingsSettingsPrefixesComponent } from './settings/settings/settings-edit-items/settings-settings-prefixes/settings-settings-prefixes.component';
import { SettingsSettingsArticlePricesComponent } from './settings/settings/settings-edit-items/settings-settings-article-prices/settings-settings-article-prices.component';
import { SettingsSettingsBookingTypesComponent } from './settings/settings/settings-edit-items/settings-settings-booking-types/settings-settings-booking-types.component';
import { SettingsWarehouseBuyingPriceComponent } from './settings/settings/settings-edit-items/settings-warehouse-buying-price/settings-warehouse-buying-price.component';
import { SettingsWarehouseStoresComponent } from './settings/settings/settings-edit-items/settings-warehouse-stores/settings-warehouse-stores.component';
import { SettingsFinanceCurrenciesComponent } from './settings/settings/settings-edit-items/settings-finance-currencies/settings-finance-currencies.component';
import { SettingsFinanceTaxRatesComponent } from './settings/settings/settings-edit-items/settings-finance-taxes/settings-finance-tax-rates.component';
import { SettingsDefaultValuesArticlesComponent } from './settings/settings/settings-edit-items/settings-default-values-articles/settings-default-values-articles.component';
import { SettingsDefaultValuesSuppliersComponent } from './settings/settings/settings-edit-items/settings-default-values-suppliers/settings-default-values-suppliers.component';
import { SettingsDefaultValuesCustomersComponent } from './settings/settings/settings-edit-items/settings-default-values-customers/settings-default-values-customers.component';
import { SettingsDefaultValuesVoucherTypesComponent } from './settings/settings/settings-edit-items/settings-default-values-voucher-types/settings-default-values-voucher-types.component';
import { SettingsDefaultValuesSubscriptionTypesComponent } from './settings/settings/settings-edit-items/settings-default-values-subscription-types/settings-default-values-subscription-types.component';
import { SettingsAccountTypesComponent } from './settings/settings/settings-edit-items/settings-account-types/settings-account-types.component';
import { SettingsPriceLevelsComponent } from './settings/settings/settings-edit-items/settings-price-levels/settings-price-levels.component';
import { MyProfileComponent } from './settings/my-profile/my-profile.component';
import { UserBaseDataComponent } from './settings/users/users-edit-items/user-base-data/user-base-data.component';
import { UserProfilePhotoComponent } from './settings/users/users-edit-items/user-profile-photo/user-profile-photo.component';
import { UserPosCashRegistersComponent } from './settings/users/users-edit-items/user-pos-cash-registers/user-pos-cash-registers.component';
import { UserEmploymentDatesComponent } from './settings/users/users-edit-items/user-employment-dates/user-employment-dates.component';
import { UserWorkingTimesComponent } from './settings/users/users-edit-items/user-working-times/user-working-times.component';
import { ArticleVariantDescriptionDetailWidget } from './articles/article-variants/article-variants-detail-widgets/article-variant-description/article-variant-description.detail-widget';
import { ArticleVariantDetailsDetailWidget } from './articles/article-variants/article-variants-detail-widgets/article-variant-details/article-variant-details.detail-widget';
import { ArticleVariantPicturesDetailWidget } from './articles/article-variants/article-variants-detail-widgets/article-variant-pictures/article-variant-pictures.detail-widget';
import { ArticleVariantSalesNumbersDetailWidget } from './articles/article-variants/article-variants-detail-widgets/article-variant-sales-numbers/article-variant-sales-numbers.detail-widget';
import { ArticleVariantStockDataDetailWidget } from './articles/article-variants/article-variants-detail-widgets/article-variant-stock-data/article-variant-stock-data.detail-widget';
import { ArticleEditVariantsComponent } from './articles/articles/article-edit-items/article-edit-variants/article-edit-variants.component';
import { ArticleVariantEditModalComponent } from './articles/articles/article-edit-items/article-edit-variants/components/article-variant-edit-modal/article-variant-edit-modal.component';
import { ArticleImagesEditorComponent } from './articles/articles/shared-components/article-images-editor/article-images-editor.component';
import { ArticleCreateDialogComponent } from './articles/articles/article-create-dialog/article-create-dialog.component';
import { ArticleOnSaleColumnComponent } from './articles/articles/article-on-sale-column/article-on-sale-column.component';
import { TransactionArticleDetailWidget } from './transactions/transaction-articles/transaction-articles-detail-widgets/transaction-article/transaction-article.detail-widget';
import { RecordChangesComponent } from './settings/record-changes/record-changes.component';
import { BonusRunsComponent } from './customers/bonus-runs/bonus-runs.component';
import { InventoryCreateDialogComponent } from './stores/inventories/inventory-create-dialog/inventory-create-dialog.component';
import { InventoryEditBaseDataComponent } from './stores/inventories/inverntory-edit-items/inventory-edit-base-data/inventory-edit-base-data.component';
import { InventoryEditArticlesComponent } from './stores/inventories/inverntory-edit-items/inventory-edit-articles/inventory-edit-articles.component';
import { InventoryArticleStatusColumnComponent } from './stores/inventories/inverntory-edit-items/inventory-edit-articles/inventory-article-status-column/inventory-article-status-column.component';
import { InventoryEditHeaderAdditionalInfoComponent } from './stores/inventories/inventory-edit-header-additional-info/inventory-edit-header-additional-info.component';
import { PosLayoutCardComponent } from './settings/poslayouts/pos-layout-card/pos-layout-card.component';
import { PosLayoutEditComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit.component';
import { PosLayoutEditTopLeftComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-top-left/pos-layout-edit-top-left.component';
import { PosLayoutEditCommandGridComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-command-grid/pos-layout-edit-command-grid.component';
import { PosLayoutEditCommandDialogComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-command-dialog/pos-layout-edit-command-dialog.component';
import { PosLayoutEditCommandCellComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-command-cell/pos-layout-edit-command-cell.component';
import { PosLayoutEditTabsComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-tabs/pos-layout-edit-tabs.component';
import { PosLayoutEditTabDialogComponent } from './settings/poslayouts/pos-layout-edit/pos-layout-edit-tab-dialog/pos-layout-edit-tab-dialog.component';
import { BonusRunCreateDialogComponent } from './customers/bonus-runs/bonus-run-create-dialog/bonus-run-create-dialog.component';
import { InventoryArticlesComponent } from './stores/inventory-articles/inventory-articles.component';
import { InventoryArticleScansDetailWidgetComponent } from './stores/inventory-articles/inventory-articles-detail-widgets/inventory-article-scans.detail-widget/inventory-article-scans.detail-widget';
import { ArticleVariantsDetailWidget } from './articles/articles/article-detail-widgets/article-variants/article-variants.detail-widget';
import { RecycleBinComponent } from './settings/recycle-bin/recycle-bin.component';
import { ArticleVariantsEditBaseDataComponent } from './articles/article-variants/aritilce-variants-edit-items/article-variants-edit-base-data/article-variants-edit-base-data.component';
import { SettingsFashionSeasonComponent } from './settings/settings/settings-edit-items/settings-fashion-season/settings-fashion-season.component';
import { RoleCardComponent } from './settings/roles/role-card/role-card.component';
import { AccountingExportRecordsEditDataComponent } from './settings/accounting-export-records/accounting-export-records-edit-items/accounting-export-records-edit-data/accounting-export-records-edit-data.component';
import { AccountingExportRecordsEditFilterComponent } from './settings/accounting-export-records/accounting-export-records-edit-items/accounting-export-records-edit-filter/accounting-export-records-edit-filter.component';
import { AccountingExportRecordsEditAccountingRecordComponent } from './settings/accounting-export-records/accounting-export-records-edit-items/accounting-export-records-edit-accounting-record/accounting-export-records-edit-accounting-record.component';
import { RoleEditBaseDataComponent } from './settings/roles/roles-edit-items/role-edit-base-data/role-edit-base-data.component';
import { RoleEditItemPermissionComponent } from './settings/roles/roles-edit-items/role-edit-item-permission/role-edit-item-permission.component';
import { RoleEditPosPermissionsComponent } from './settings/roles/roles-edit-items/role-edit-pos-permissions/role-edit-pos-permissions.component';
import { PaymentTypesComponent } from './settings/payment-types/payment-types.component';
import { PaymentTypeCardComponent } from './settings/payment-types/payment-type-card/payment-type-card.component';
import { CustomerGroupsComponent } from './settings/customer-groups/customer-groups.component';
import { CustomerGroupEditBaseDataComponent } from './settings/customer-groups/customer-group-edit-items/customer-group-edit-base-data/customer-group-edit-base-data.component';
import { PaymentTypeBaseDataComponent } from './settings/payment-types/payment-types-edit/payment-type-base-data/payment-type-base-data.component';
import { ExtensionCardComponent } from './settings/extensions/extensions-card/extensions-card.component';
import { ExtensionsComponent } from './settings/extensions/extensions.component';
import { ExtensionsEditBaseDataComponent } from './settings/extensions/extension-edit-items/extensions-edit-base-data/extensions-edit-base-data.component';
import { SaleEditBaseDataComponent } from './articles/sales/sale-card-edit-items/sale-edit-base-data/sale-edit-base-data.component';
import { SaleEditValidityComponent } from './articles/sales/sale-card-edit-items/sale-edit-validity/sale-edit-validity.component';
import { SaleEditArticlesComponent } from './articles/sales/sale-card-edit-items/sale-edit-articles/sale-edit-articles.component';
import { ArticleVariantsEditPropertiesComponent } from './articles/article-variants/aritilce-variants-edit-items/article-variants-edit-properties/article-variants-edit-properties.component';
import { ArticleVariantsEditPricesComponent } from './articles/article-variants/aritilce-variants-edit-items/article-variants-edit-prices/article-variants-edit-prices.component';
import { ArticleVariantsEditTextsComponent } from './articles/article-variants/aritilce-variants-edit-items/article-variants-edit-texts/article-variants-edit-texts.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MaterialModule,
        ComponentsModule,
        GridsterModule,
        PipesModule,
        DirectivesModule,
        QRCodeModule,
        NgOptimizedImage,
        RouterLink
    ],
    declarations: [
        HomeComponent,
        LoginComponent,
        RequestPasswordTokenComponent,
        ResetPasswordComponent,
        ReportsComponent,
        ReportsDesignerComponent,
        ResetPasswordComponent,
        ArticlesComponent,
        ReportsComponent,
        ReportCardComponent,
        CustomersComponent,
        CustomerEditBaseDataComponent,
        ControlsComponent,
        CustomerContactInformationDetailWidget,
        CustomerTransactionsDetailWidget,
        ReportsViewerComponent,
        TransactionsComponent,
        TransactionPaymentsComponent,
        CustomerBaseDataDetailWidget,
        CustomerPaymentsDetailWidget,
        CustomerTurnoverDetailWidget,
        TransactionArticlesComponent,
        CustomerEditContactComponent,
        ArticlePicturesDetailWidget,
        ArticleEditTextsComponent,
        ArticleEditBaseDataComponent,
        UsersComponent,
        PosComponent,
        PosItemOverviewComponent,
        PosTransactionsComponent,
        PosTransactionsListWidgetComponent,
        PosTypeColumnComponent,
        BranchesComponent,
        RegionsComponent,
        PosLayoutsComponent,
        PosCustomerScreenLayoutsComponent,
        ConfigurationComponent,
        RolesComponent,
        TransactionTypesComponent,
        DefaultValuesComponent,
        AccountTypesComponent,
        ResourcesComponent,
        SystemLogComponent,
        ArticleVariantsComponent,
        ArticleSalesNumbersDetailWidget,
        ArticleArticleStockDataDetailWidget,
        ArticleDescriptionDetailWidget,
        TransactionCustomerDetailWidget,
        TransactionArticlesDetailWidget,
        ArticleEditPropertiesComponent,
        TransactionPaymentCustomerDetailWidget,
        TransactionDetailsDetailWidget,
        TransactionPaymentsDetailWidget,
        CustomerEditMarketingComponent,
        CustomerDescriptionDetailWidget,
        CustomerDescriptionDialogComponent,
        InvoicesComponent,
        AmountsColumnComponent,
        CustomerSubscriptionsDetailWidget,
        CustomerVouchersDetailWidget,
        CustomerTopTransactionArticlesDetailWidget,
        TransactionArticleCustomerDetailWidget,
        TransactionArticlePicturesDetailWidget,
        TransactionArticleDescriptionDetailWidget,
        InvoiceCreateDialogComponent,
        CustomerEditTextsDetailWidget,
        ArticleEditIdentificationsComponent,
        CustomerEditAccountComponent,
        CustomerAccountBookingDialogComponent,
        TransactionPaymentDetailsDetailWidget,
        VouchersTypesComponent,
        ReportEditBaseDataComponent,
        VoucherTypeCardComponent,
        VoucherTypeEditBaseDataComponent,
        TagsComponent,
        TagEditBaseDataComponent,
        TagEditArticlesComponent,
        SalesComponent,
        SaleCardComponent,
        SaleEditBaseDataComponent,
        SaleEditValidityComponent,
        SaleEditArticlesComponent,
        ArticleStocksComponent,
        VouchersComponent,
        VoucherTypeColumnComponent,
        VoucherIdQrCodeDetailWidget,
        VoucherCustomerDetailWidget,
        VoucherTypeDetailWidget,
        VoucherCreateDialogComponent,
        ArticleStockEditBaseDataComponent,
        InvoiceEditBaseDataComponent,
        ArticleEditPricesComponent,
        InvoiceCustomerDetailsDetailWidget,
        InvoiceRemarksDetailWidget,
        InvoiceTransactionsDetailWidget,
        OrdersComponent,
        SuppliersComponent,
        ReservationsComponent,
        SubscriptionTypesComponent,
        SubscriptionTypeCardComponent,
        SubscriptionTypeEditBaseDataComponent,
        SubscriptionTypeEditArticlesComponent,
        ArticleEditSuppliersComponent,
        InvoiceDetailWidget,
        ArticleEditSetArticlesComponent,
        SubscriptionsComponent,
        ArticleDetailsDetailWidget,
        WarehouseTransfersComponent,
        QueryDesignerComponent,
        QueryDesignerCardComponent,
        QueryDesignerEditBaseDataComponent,
        QueryDesignerEditViewComponent,
        SupplierEditBaseDataComponent,
        SupplierEditContactComponent,
        SupplierEditTextComponent,
        SupplierEditDeliveryConditionsComponent,
        PosReportsComponent,
        PosReportsComponent,
        PosReportCardComponent,
        PosReportEditBaseDataComponent,
        MessagesComponent,
        MessagePriorityColumnComponent,
        MessageBodyDetailWidget,
        OrderArticlesComponent,
        OrderCreateDialogComponent,
        SupplierBaseDataDetailWidget,
        OrderSupplierBaseDataDetailWidget,
        OrderDataDetailWidget,
        OrderEditBaseDataComponent,
        OrderEditArticlesComponent,
        OrderArticleQuickAddComponent,
        ImportDefinitionsComponent,
        ImportDefinitionCardComponent,
        PosEditBaseDataComponent,
        CustomerProfilesComponent,
        CustomerProfileCardComponent,
        PosEditBalanceComponent,
        ArticleEditImagesComponent,
        ArticleGroupsComponent,
        ArticleGroupEditComponent,
        CustomerProfilesBaseDataComponent,
        CustomerProfilesDiscountsComponent,
        CustomerBonusProgramsComponent,
        TreeAutocompleteColumnComponent,
        ReportCreateSourceSelectionComponent,
        WarehouseTranserCreateDialogComponent,
        WarehouseTransferEditBaseDataComponent,
        WarehouseTransferEditArticlesComponent,
        WarehouseTransferArticleQuickAddComponent,
        SystemLogMessageDetailWidget,
        SystemLogStackTraceDetailWidget,
        SystemLogDetailsTableDetailWidget,
        RegionCardComponent,
        RegionEditBaseDataComponent,
        SubscriptionTransactionsComponent,
        AccountingExportRecordsComponent,
        TransactionTypeCardComponent,
        TransactionTypeBasicDataComponent,
        TransactionTypePaymentPurposesComponent,
        TransactionTypePaymentTypeDebitComponent,
        TransactionTypePaymentTypeCreditComponent,
        UnitsComponent,
        UnitEditBaseDataComponent,
        UnitCardComponent,
        NoPermissionComponent,
        BranchEditBaseDataComponent,
        BranchEditReceiptComponent,
        OrderArticlePicturesDetailWidget,
        OrderArticleStocksDetailWidget,
        OrderArticleDescriptionDetailWidget,
        OrderArticleDetailsDetailWidget,
        ReservationCustomerDetailWidget,
        ReservationArticlesDetailWidget,
        InventoriesComponent,
        InventoryStatusColumnComponent,
        ArticleStockPicturesDetailWidget,
        ArticleStockDescriptionDetailWidget,
        ArticleStockArticleDetailsDetailWidget,
        ArticleStockDataDetailWidget,
        ReservationEditBaseDataComponent,
        ReservationEditArticlesComponent,
        ReservationEditArticleEntryComponent,
        ReservationEditHeaderAdditionalInfoComponent,
        InventoryTypeColumnComponent,
        UserDataDetailWidget,
        UserQrCodeDetailWidget,
        SettingsComponent,
        SettingsCustomizeBaseDataComponent,
        SettingsCustomizeColorsComponent,
        SettingsCustomizeLogoComponent,
        SettingsInvoicesBankAccountComponent,
        SettingsInvoicesQrBillComponent,
        SettingsInvoicesRemindersComponent,
        SettingsInvoicesCustomerAccountComponent,
        SettingsSecurityPasswordComponent,
        SettingsSecurityPinComponent,
        SettingsSettingsFormatsComponent,
        SettingsSettingsPrefixesComponent,
        SettingsSettingsArticlePricesComponent,
        SettingsSettingsBookingTypesComponent,
        SettingsWarehouseBuyingPriceComponent,
        SettingsWarehouseStoresComponent,
        SettingsFinanceCurrenciesComponent,
        SettingsFinanceTaxRatesComponent,
        SettingsDefaultValuesArticlesComponent,
        SettingsDefaultValuesSuppliersComponent,
        SettingsDefaultValuesCustomersComponent,
        SettingsDefaultValuesVoucherTypesComponent,
        SettingsDefaultValuesSubscriptionTypesComponent,
        SettingsAccountTypesComponent,
        SettingsPriceLevelsComponent,
        MyProfileComponent,
        UserBaseDataComponent,
        UserProfilePhotoComponent,
        UserPosCashRegistersComponent,
        UserEmploymentDatesComponent,
        UserWorkingTimesComponent,
        ArticleVariantDescriptionDetailWidget,
        ArticleVariantDetailsDetailWidget,
        ArticleVariantPicturesDetailWidget,
        ArticleVariantSalesNumbersDetailWidget,
        ArticleVariantStockDataDetailWidget,
        ArticleEditVariantsComponent,
        ArticleVariantEditModalComponent,
        ArticleImagesEditorComponent,
        ArticleCreateDialogComponent,
        ArticleOnSaleColumnComponent,
        TransactionArticleDetailWidget,
        RecordChangesComponent,
        BonusRunsComponent,
        InventoryCreateDialogComponent,
        InventoryEditBaseDataComponent,
        InventoryEditArticlesComponent,
        InventoryArticleStatusColumnComponent,
        InventoryEditHeaderAdditionalInfoComponent,
        PosLayoutCardComponent,
        PosLayoutEditComponent,
        PosLayoutEditTopLeftComponent,
        PosLayoutEditCommandGridComponent,
        PosLayoutEditCommandDialogComponent,
        PosLayoutEditCommandCellComponent,
        PosLayoutEditTabsComponent,
        PosLayoutEditTabDialogComponent,
        BonusRunCreateDialogComponent,
        ArticleVariantsDetailWidget,
        InventoryArticlesComponent,
        InventoryArticleScansDetailWidgetComponent,
        RecycleBinComponent,
        ArticleVariantsEditBaseDataComponent,
        SettingsFashionSeasonComponent,
        RoleCardComponent,
        AccountingExportRecordsEditDataComponent,
        AccountingExportRecordsEditFilterComponent,
        AccountingExportRecordsEditAccountingRecordComponent,
        RoleEditBaseDataComponent,
        RoleEditItemPermissionComponent,
        RoleEditPosPermissionsComponent,
        PaymentTypesComponent,
        PaymentTypeCardComponent,
        CustomerGroupsComponent,
        CustomerGroupEditBaseDataComponent,
        PaymentTypeBaseDataComponent,
        ExtensionsComponent,
        ExtensionCardComponent,
        ExtensionsEditBaseDataComponent,
        ArticleVariantsEditPropertiesComponent,
        ArticleVariantsEditPricesComponent,
        ArticleVariantsEditTextsComponent
    ],
    exports: [
        HomeComponent,
        LoginComponent,
        RequestPasswordTokenComponent,
        ResetPasswordComponent,
        ReportsComponent,
        ReportsDesignerComponent,
        ResetPasswordComponent,
        ArticlesComponent,
        ReportsViewerComponent,
        TransactionsComponent,
        TransactionPaymentsComponent,
        ArticleEditTextsComponent,
        ArticleEditBaseDataComponent,
        CustomerBaseDataDetailWidget,
        CustomerPaymentsDetailWidget,
        CustomerTurnoverDetailWidget,
        TransactionArticlesComponent,
        ArticleSalesNumbersDetailWidget,
        ArticleArticleStockDataDetailWidget,
        ArticleDescriptionDetailWidget,
        TransactionCustomerDetailWidget,
        TransactionArticlesDetailWidget,
        TransactionPaymentCustomerDetailWidget,
        TransactionDetailsDetailWidget,
        TransactionPaymentsDetailWidget,
        InvoicesComponent,
        CustomerSubscriptionsDetailWidget,
        CustomerVouchersDetailWidget,
        CustomerTopTransactionArticlesDetailWidget,
        TransactionArticleCustomerDetailWidget,
        TransactionArticlePicturesDetailWidget,
        TransactionArticleDescriptionDetailWidget,
        CustomerEditTextsDetailWidget,
        TransactionPaymentDetailsDetailWidget,
        ReportEditBaseDataComponent,
        VoucherTypeCardComponent,
        VouchersComponent,
        VoucherCustomerDetailWidget,
        VoucherTypeDetailWidget,
        InvoiceCustomerDetailsDetailWidget,
        InvoiceRemarksDetailWidget,
        InvoiceTransactionsDetailWidget,
        OrdersComponent,
        InvoiceDetailWidget,
        ArticleDetailsDetailWidget,
        PosReportsComponent,
        PosReportEditBaseDataComponent,
        ArticleEditImagesComponent,
        ArticleVariantsDetailWidget
    ]
})
export class ViewsModule { }
