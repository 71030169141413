<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'VoucherTypes.List.VouchersQuantity' | transloco }}</p>
        <p class="text-xl">{{ item().vouchersQuantity }}</p>
    </div>
    @if (item().isDiscountVoucher) {
        <div class="mb-4">
            <p class="text-on-app-light">{{ 'VoucherTypes.List.Validity' | transloco }}</p>
            <p class="text-xl">
                <span>{{ item().validFrom | arcDate }}</span>
                @if (!!item().validThru) {
                    <span> - {{ item().validThru | arcDate }}</span>
                }
            </p>
        </div>
    } @else {
        <div>
            <p class="text-on-app-light">{{ 'VoucherTypes.List.TotalValue' | transloco }}</p>
            <p class="text-xl">{{ item().totalValue | arcCurrency }}</p>
        </div>
    }
</div>
