import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RoleModel } from '../../../../../models/role.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { RoleEditModel } from '../../../../../models/role-edit.model';

@Component({
    selector: 'arc-role-edit-base-data',
    templateUrl: './role-edit-base-data.component.html',
    styleUrl: './role-edit-base-data.component.scss'
})
export class RoleEditBaseDataComponent extends BaseEditSidebarItemComponent<RoleModel, RoleEditModel> {
    override formGroup = new FormGroup({
        roleName: new ArcFormControl<string>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<RoleEditModel> {
        return { roleName: this.formGroup.value.roleName };
    }
}
