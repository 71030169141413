<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-button-toggle-group [control]="sourceType" [items]="sourceTypeItems" toggleGroupClasses="w-full"></arc-button-toggle-group>
    @if (sourceType.value === ReportSourceTypesEnum.File) {
        <arc-file-upload [arcErrorTooltip]="formGroup.controls.reportBlobData" [formControl]="formGroup.controls.fileControl">
        </arc-file-upload>
    } @else {
        <arc-select label="Reports.Create.TableName" [options]="tableNames" [arcErrorTooltip]="formGroup.controls.tableName"
            [formControl]="formGroup.controls.tableName" fileTypes=".rdlx"></arc-select>
    }
</form>
