<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'SubscriptionTypes.Card.ActiveSubscriptionsCount' | transloco }}</p>
        <p class="text-xl">{{ item().validSubscriptionsCount }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'SubscriptionTypes.Card.ValidForTime' | transloco }}</p>
        <p class="text-xl">{{ item().validForPeriod }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'SubscriptionTypes.Card.SubscriptionQuantity' | transloco }}</p>
        <p class="text-xl">{{ item().subscriptionQuantityText }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'SubscriptionTypes.Card.SubscriptionTypeArticlePrice' | transloco }}</p>
        <p class="text-xl">{{ item().subscriptionTypeArticlePrice | arcCurrency }}</p>
    </div>
</div>
