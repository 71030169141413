import { DefaultUrlSerializer, UrlSegmentGroup, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    override parse(url: string): UrlTree {
        const urlTree = super.parse(url);
        this.lowerCaseSegments(urlTree.root);
        return urlTree;
    }

    /**
     * Recursively converts the path of each segment in the given UrlSegmentGroup to lowercase.
     * @param urlSegmentGroup The UrlSegmentGroup whose segments need to be converted to lowercase.
     */
    private lowerCaseSegments(urlSegmentGroup: UrlSegmentGroup): void {
        if (urlSegmentGroup.hasChildren()) {
            Object.entries(urlSegmentGroup.children).forEach(([, value]) => this.lowerCaseSegments(value));
        }

        urlSegmentGroup.segments.forEach(segment => (segment.path = segment.path.toLowerCase()));
    }
}
