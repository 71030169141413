<div class="grid place-items-center col-start-1">
    <arc-icon class="cursor-move" cdkDragHandle icon="drag_indicator" [size]="24" />
</div>

<div class="flex flex-col col-start-2 text-sm">
    <p>{{ article().articleNumber }}</p>
    <p class="truncate">{{ article().title }}</p>
</div>

<div class="flex items-center col-start-3">
    <arc-number-input class="w-full" [ngModel]="article().quantity" (ngModelChange)="updateQuantity($event)" [unit]="article().unitShort"
        [isUnitTranslated]="true" />
</div>

<div class="flex items-center col-start-4">
    x
</div>

<div class="flex items-center col-start-5">
    <arc-currency-input class="w-full" [ngModel]="article().unitPrice" (ngModelChange)="updateUnitPrice($event)" [min]="0" />
</div>

<p class="flex items-center col-start-6">
    <mat-button-toggle-group [ngModel]="article().discountPercent === undefined"
        (ngModelChange)="updateDiscountPercent($event ? undefined : 0)" [disabled]="article().isDiscountLocked"
        [matTooltip]="'Reservations.Edit.Article.ArticleHasDiscountLocked' | transloco" [matTooltipDisabled]="!article().isDiscountLocked">
        <mat-button-toggle [value]="true">{{ currencyIsoCode }}</mat-button-toggle>
        <mat-button-toggle [value]="false">%</mat-button-toggle>
    </mat-button-toggle-group>
</p>

<div class="flex gap-2 items-center col-start-7" [matTooltip]="'Reservations.Edit.Article.ArticleHasDiscountLocked' | transloco"
    [matTooltipDisabled]="!article().isDiscountLocked">
    @if (article().discountPercent === undefined) {
        <arc-currency-input class="w-full" [ngModel]="article().discount" (ngModelChange)="updateDiscount($event)" [min]="0"
            [max]="article().quantity * article().unitPrice" [disabled]="article().isDiscountLocked" />
    } @else {
        <arc-number-input class="w-full" [unit]="UnitsEnum.Percent" [decimalPlaces]="2" [ngModel]="article().discountPercent"
            (ngModelChange)="updateDiscountPercent($event || 0)" [min]="0" [max]="100" [disabled]="article().isDiscountLocked" />
    }
</div>


<div class="flex flex-col col-start-8 text-sm justify-center">
    <p>- {{ article().discount | arcCurrency }}</p>
    <p>= {{ article().total | arcCurrency }}</p>
</div>

<div class="flex items-center col-start-9">
    <mat-form-field subscriptSizing="dynamic">
        <input matInput [(ngModel)]="article().remarks" (ngModelChange)="updateRemarks($event)">
    </mat-form-field>
</div>

<div class="flex items-center col-start-10">
    <button mat-icon-button (click)="delete.emit()">
        <mat-icon>close</mat-icon>
    </button>
</div>
