import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';

@Pipe({ name: 'arcCurrency' })
export class CurrencyPipe implements PipeTransform {
    private static currencyCode = '';

    private readonly userService = inject(UserService);
    private readonly translationService = inject(TranslationService);

    transform(value: any, localize = true): string {
        if (!CurrencyPipe.currencyCode) {
            CurrencyPipe.currencyCode = this.userService.getUserInfo()?.currencyIsoCode ?? '';
        }

        const numberVal = Number(value);

        if (Number.isNaN(numberVal)) {
            return value;
        }

        if (localize) {
            value = numberVal.toLocaleString(this.translationService.current.culture, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 100
            });
        } else {
            value = numberVal.toFixed(2);
        }

        return `${CurrencyPipe.currencyCode} ` + value;
    }
}
