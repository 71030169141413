import { Component, OnInit, inject } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { CurrencyColumnModel } from '../../models/column-types/currency-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';

@Component({
    selector: 'arc-currency-column',
    templateUrl: './currency-column.component.html',
    styleUrls: ['./currency-column.component.scss']
})
export class CurrencyColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    permission: true | AuthPermissionModel = true;

    private readonly permissionService = inject(PermissionService);

    override ngOnInit(): void {
        super.ngOnInit();

        if (!!this.columnModel.requiredPermission) {
            this.permission = this.permissionService.hasPermission(
                this.columnModel.requiredPermission,
                this.columnModel.requiredPermissionType
            );
        }
    }

    get currColumnModel(): CurrencyColumnModel {
        return this.columnModel as CurrencyColumnModel;
    }
    get currencyId(): string | undefined {
        const currencyIdFn = this.currColumnModel.currencyId;
        return currencyIdFn ? currencyIdFn(this.item) : undefined;
    }
    get value(): any {
        return this.getItemValue(this.columnModel.propertyName);
    }
    get hasValue(): boolean {
        // Since JS treats null as objects and here (currency column) we can ignore objects (only number and string),
        // we prevent null as well.
        return typeof this.value !== 'undefined' && typeof this.value !== 'object';
    }
    get isNumber(): boolean {
        return !Number.isNaN(Number(this.value));
    }
}
