import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { BonusRunListModel } from '../../../models/responses/bonus-run-list.model';
import { BonusRunModel } from '../../../models/bonus-run.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { BonusRunsStore } from '../../../services/stores/bonus-runs.store';
import { BonusRunCreateDialogComponent } from './bonus-run-create-dialog/bonus-run-create-dialog.component';

@Component({
    selector: 'arc-bonus-runs',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './bonus-runs.component.scss'
})
export class BonusRunsComponent extends PageListViewComponent<BonusRunListModel, BonusRunModel> {
    private readonly bonusRunsStore = inject(BonusRunsStore);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super('BonusRuns');
        this.config = new TableListViewConfigModel<BonusRunListModel, BonusRunModel>({
            entityName: 'BonusRuns',
            availableColumns: {
                customerProfileTitle: new StackedColumnModel({
                    columnTitleKey: 'BonusRuns.List.BonusRun',
                    propertyName: 'customerProfileTitle',
                    propertyName2: 'id',
                    widthPixels: 286,
                    sortColumn: 'id'
                }),
                created: new DateColumnModel({
                    columnTitleKey: 'BonusRuns.List.Created',
                    propertyName: 'createdDate',
                    widthPixels: 200
                }),
                deadline: new DateColumnModel({
                    columnTitleKey: 'BonusRuns.List.Deadline',
                    propertyName: 'perDate',
                    widthPixels: 200
                }),
                finishedDate: new DateColumnModel({
                    columnTitleKey: 'BonusRuns.List.Sent',
                    propertyName: 'finishedDate',
                    widthPixels: 200
                }),
                numberOfCustomersInvolved: new NumberColumnModel({
                    columnTitleKey: 'BonusRuns.List.NumberOfCustomersInvolved',
                    propertyName: 'numberOfCustomersInvolved',
                    widthPixels: 150
                })
            },
            defaultColumnOrder: [
                'customerProfileTitle',
                'created',
                'deadline',
                'finishedDate',
                'numberOfCustomersInvolved'
            ],
            store: this.bonusRunsStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false, hasEdit: false, hasDelete: false }),
            handleCreateButtonClick: this.handleCreate.bind(this)
        });
    }

    private handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(BonusRunCreateDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw',
                minWidth: '800px',
                disableClose: true
            })
            .afterClosed();
    }
}
