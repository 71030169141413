/* eslint-disable no-null/no-null */
import { Component, ElementRef, Input, OnInit, ViewChild, computed, inject } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

import { QuickSearchConfigModel } from '../../../../../../../components/form/quick-search/models/quick-search-config.model';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { OrderArticleModel } from '../../../../../../models/order-article.model';
import { ArticleSupplierListModel } from '../../../../../../models/responses/article-supplier-list.model';
import { SupplierArticlesStore } from '../../../../../../services/stores/supplier-articles.store';
import { RequestService } from '../../../../../../../core/services/request.service';
import { OrderModel } from '../../../../../../models/order.model';
import { articleSupplierSelectionTableConfig } from '../../../../../../../components/dialogs/data-selection-dialog/data-selection-table-configs/article-supplier-selection-table.config';
import { DataSelectionDialogCustomDataModel } from '../../../../../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-custom-data.model';
import { OrderArticlesTableConfigService } from '../order-articles-table-config.service';

@Component({
    selector: 'arc-order-article-quick-add',
    templateUrl: './order-article-quick-add.component.html',
    styleUrl: './order-article-quick-add.component.scss'
})
export class OrderArticleQuickAddComponent implements OnInit {
    @ViewChild('quickAddArticleControl', { read: ElementRef }) quickAddArticleControl!: ElementRef;
    @ViewChild('quickAddQuantityControl', { read: ElementRef }) quickAddQuantityControl!: ElementRef;

    @Input({ required: true }) order!: OrderModel;
    @Input({ required: true }) orderArticlesControl!: AbstractControl<OrderArticleModel[]>;

    quickAddFormGroup = new FormGroup({
        articleSupplierId: new ArcFormControl<string | null>(null),
        orderArticle: new ArcFormControl<OrderArticleModel | null>(null),
        orderQuantity: new ArcFormControl<number | null>(null)
    });

    quickSearchConfig?: QuickSearchConfigModel<ArticleSupplierListModel>;
    isQuickAddLoading = false;

    quickAddFormGroupSignal = toSignal(this.quickAddFormGroup.valueChanges);
    quickAddOrderArticle = computed(() => this.quickAddFormGroupSignal()?.orderArticle);
    quickAddOrderQuantity = computed(() => this.quickAddFormGroupSignal()?.orderQuantity);
    quickAddQuantityUnit = computed(() => !!this.quickAddOrderArticle()
        ? `x ${this.quickAddOrderArticle()!.orderUnitQuantity} ${this.quickAddOrderArticle()!.articleUnitTitle}`
        : '');
    quickAddBuyingPrice = computed(() => this.quickAddOrderArticle()?.orderBuyingPrice ?? 0);
    quickAddBuyingPriceExclusive = computed(() => this.quickAddOrderArticle()?.orderBuyingPriceExclusive ?? false);
    quickAddTotalQuantity = computed(() => (this.quickAddOrderArticle()?.orderUnitQuantity ?? 0) * (this.quickAddOrderQuantity() ?? 0));
    quickAddTotal = computed(() => (this.quickAddOrderQuantity() ?? 0) * (this.quickAddBuyingPrice() ?? 0));

    private readonly requestService = inject(RequestService);
    private readonly orderArticlesTableConfigService = inject(OrderArticlesTableConfigService);

    ngOnInit(): void {
        const filteredStore = new SupplierArticlesStore(this.requestService, this.order.supplier.id);
        this.quickSearchConfig = {
            store: filteredStore,
            displayFn: articleSupplier => articleSupplier?.articleTitle ?? '',
            dataSelectionDialogConfig: new DataSelectionDialogCustomDataModel({
                store: filteredStore,
                dialogTitleKey: 'ArticleSuppliers.TitleSelectDialog',
                columnConfig: articleSupplierSelectionTableConfig
            })
        };
    }

    quickAddArticleChanged(value: ArticleSupplierListModel): void {
        if (!value) {
            this.quickAddFormGroup.patchValue({
                orderArticle: null,
                orderQuantity: null
            });
            return;
        }

        this.isQuickAddLoading = true;
        this.orderArticlesTableConfigService.articleSuppliersToOrderArticles(this.order, [value]).subscribe(result => {
            if (result.length > 0 && !result[0].addedDate) {
                result[0].addedDate = new Date();
            }

            if (result.length > 0) {
                this.quickAddFormGroup.patchValue({ orderArticle: result[0] });
            }
        }).add(() => this.isQuickAddLoading = false);

        setTimeout(() => this.quickAddQuantityControl.nativeElement.querySelector('input')?.focus());
    }

    quickAdd(): void {
        if (this.isQuickAddLoading) {
            return;
        }

        const value = this.quickAddFormGroup.value;

        if (!value.orderArticle || !value.orderQuantity) {
            return;
        }

        const orderArticle = value.orderArticle;
        orderArticle.orderQuantity = value.orderQuantity;

        if (!orderArticle.addedDate) {
            orderArticle.addedDate = new Date();
        }

        const currentValue = this.orderArticlesControl.value ?? [];
        // eslint-disable-next-line eqeqeq
        const existing = currentValue.find(oa => oa.articleId == orderArticle.articleId);

        if (!!existing) {
            const quantity = Number.isNaN(Number(existing.orderQuantity)) ? 0 : Number(existing.orderQuantity);
            existing.orderQuantity = quantity + value.orderQuantity;
            this.orderArticlesControl.setValue([...currentValue]);
        } else {
            this.orderArticlesControl.setValue([orderArticle, ...currentValue]);
        }

        this.quickAddFormGroup.setValue({
            articleSupplierId: null,
            orderArticle: null,
            orderQuantity: null
        });
        setTimeout(() => this.quickAddArticleControl.nativeElement.querySelector('input')?.focus());
    }
}
