import { Pipe, PipeTransform } from '@angular/core';

import { KeyValueModel } from '../models/key-value.model';

@Pipe({
    name: 'replacePlaceholder'
})
export class ReplacePlaceholderPipe implements PipeTransform {
    transform(text: string, placeholders?: KeyValueModel[], placeholderValues?: any): string {
        if (!!text) {
            placeholders?.forEach(item => {
                if (!!item.key) {
                    const lowerCasedItemKey = item.key.charAt(0).toLowerCase() + item.key.slice(1);
                    const value = placeholderValues[lowerCasedItemKey] ?? '';
                    const placeholder = `[${item.key}]`.replace(/[\[\]]/g, '\\$&');
                    text = text.replace(new RegExp(placeholder, 'g'), value);
                }
            });
        }

        return text;
    }
}
