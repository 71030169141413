<form [formGroup]="formGroup" class="grid items-center grid-cols-2 gap-4">
    <arc-input labelKey="CustomerProfiles.Edit.BaseData.Title" [control]="formGroup.controls.title"></arc-input>
    <arc-checkbox labelKey="CustomerProfiles.Edit.BaseData.IsDefault" [control]="formGroup.controls.isDefault"></arc-checkbox>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PriceLevels" [formControl]="formGroup.controls.priceLevel"
        label="CustomerProfiles.Edit.BaseData.PriceLevel"></arc-general-data-select>
    <div></div>
    <arc-number-input label="CustomerProfiles.Edit.BaseData.AccountMinAmount" [formControl]="formGroup.controls.accountMinAmount"
        [unit]="currencyCode" [isUnitTranslated]="true" [decimalPlaces]="2">
    </arc-number-input>
</form>
