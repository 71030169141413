<div class="flex flex-wrap gap-4">
    <mat-card class="w-full">
        <mat-card-content class="!flex gap-4 items-center">
            <mat-form-field class="grow" subscriptSizing="dynamic">
                <input matInput [(ngModel)]="searchText" (input)="searchTextChanged()" placeholder="{{ 'General.Search' | transloco }}..."/>
            </mat-form-field>
            <button class="ml-auto" [ngClass]="{ '!hidden': !canShowFilter }" mat-icon-button (click)="toggleShowFilterAndSearch()"
                [matTooltip]="'Components.SearchFilter.Filter' | transloco">
                <mat-icon [matBadge]="filterCount" matBadgeSize="small" [matBadgeHidden]="filterCount === 0" aria-hidden="false">
                    filter_alt
                </mat-icon>
            </button>
        </mat-card-content>
    </mat-card>
    <mat-card class="w-full" [ngClass]="{ '!hidden': !showFilter || !canShowFilter }">
        <arc-filter [store]="store" #filter></arc-filter>
    </mat-card>
</div>
