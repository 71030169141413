import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TransactionTypeEditModel } from '../../../../../models/requests/transaction-type-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { TransactionTypeConfigurationsEnum, TransactionTypeConfigurationsEnumExtensions } from '../../../../../models/enums/transaction-type-configurations.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { TransactionTypeModel } from '../../../../../models/transaction-type.model';

@Component({
    selector: 'arc-transaction-type-basic-data',
    templateUrl: './transaction-type-basic-data.component.html',
    styleUrl: './transaction-type-basic-data.component.scss'
})
export class TransactionTypeBasicDataComponent extends BaseEditSidebarItemComponent<TransactionTypeModel, TransactionTypeEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    allowedConfigurations: SelectOptionModel<TransactionTypeConfigurationsEnum>[] = [
        { label: 'Enums.TransactionTypeConfigurationsEnum.CashFlow', value: TransactionTypeConfigurationsEnum.CashFlow },
        { label: 'Enums.TransactionTypeConfigurationsEnum.FundsTransfer', value: TransactionTypeConfigurationsEnum.FundsTransfer },
        { label: 'Enums.TransactionTypeConfigurationsEnum.InventoryMovement', value: TransactionTypeConfigurationsEnum.InventoryMovement },
        { label: 'Enums.TransactionTypeConfigurationsEnum.NonRevenueSale', value: TransactionTypeConfigurationsEnum.NonRevenueSale },
        { label: 'Enums.TransactionTypeConfigurationsEnum.Sale', value: TransactionTypeConfigurationsEnum.Sale }
    ];

    override formGroup = new FormGroup({
        configurationType: new ArcFormControl(TransactionTypeConfigurationsEnum.None, Validators.required),
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        description_de: new ArcFormControl('', Validators.required),
        description_fr: new ArcFormControl<OptionalType<string>>(undefined),
        description_en: new ArcFormControl<OptionalType<string>>(undefined),
        description_it: new ArcFormControl<OptionalType<string>>(undefined),
        requiredRoleId: new ArcFormControl<OptionalType<number>>(undefined),
        sort: new ArcFormControl(0, Validators.required)
    });

    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();

        this.formGroup.controls.configurationType.valueChanges.subscribe(newConfiguration => {
            this.handleConfigurationTypeChange(newConfiguration);
        });
    }

    override onItemSet(): void {
        if (!this.isCreate() && this.allowedConfigurations.findIndex(x => x.value === this.item().configurationType) === -1) {
            this.allowedConfigurations.push({
                label: 'Enums.TransactionTypeConfigurationsEnum.' + TransactionTypeConfigurationsEnum[this.item().configurationType],
                value: this.item().configurationType
            });
        }
        this.formGroup.patchValue(this.item());

        this.handleConfigurationTypeChange(this.item().configurationType);
    }

    override prepareSaveModel(): Partial<TransactionTypeEditModel> {
        const value = this.formGroup.value;
        return {
            configurationType: value.configurationType,
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            description_de: value.description_de,
            description_fr: value.description_fr,
            description_en: value.description_en,
            description_it: value.description_it,
            requiredRoleId: value.requiredRoleId,
            sort: value.sort
        };
    }

    handleConfigurationTypeChange(newConfiguration: TransactionTypeConfigurationsEnum): void {
        this.sharedDataService.setOrUpdateSignal<boolean>(
            'shouldHideTransactionTypePaymentTypeComponent',
            TransactionTypeConfigurationsEnumExtensions.doesNotRequirePayment(newConfiguration) ||
            (this.item().configurationType === TransactionTypeConfigurationsEnum.Custom && !this.item().requiresPayment)
        );
    }

    getTypeDescription(): string {
        const type = this.formGroup.value.configurationType;
        return !!type ? `Enums.TransactionTypeConfigurationsEnum.${TransactionTypeConfigurationsEnum[type]}Description` : '';
    }
}
