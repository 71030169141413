import { Component, inject, OnDestroy, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { BaseComponent } from '../../../../../components/abstractions/base.component';
import { VoucherTypesStore } from '../../../../services/stores/voucher-types.store';
import { EditableTableConfigModel } from '../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { VoucherCreateModel } from '../../../../models/voucher-create.model';
import { QuickSearchColumnModel } from '../../../../../components/dynamic-table/models/column-types/quick-search-column.model';
import { ComplexDataTypesEnum } from '../../../../../core/models/complex-data-types.enum';
import { VouchersStore } from '../../../../services/stores/vouchers.store';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { CustomValidators } from '../../../../../core/utils/custom-validators';
import { StringColumnModel } from '../../../../../components/dynamic-table/models/column-types/string-column.model';
import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../core/models/types/optional.type';
import { BaseColumnModel } from '../../../../../components/dynamic-table/models/column-types/base-column.model';
import { CurrencyColumnModel } from '../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { SelectOptionModel } from '../../../../../core/models/select-option.model';
import { VoucherPaymentTypes } from '../../../../models/responses/voucher-payment-types.model';
import { VoucherTypeModel } from '../../../../models/voucher-type.model';
import { TranslationService } from '../../../../../core/services/translation.service';
import { ErrorsService } from '../../../../../core/services/errors.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { ApiResponseModel } from '../../../../models/responses/api-response.model';
import { Tools } from '../../../../../core/utils/tools';

@Component({
    selector: 'arc-voucher-create-dialog',
    templateUrl: './voucher-create-dialog.component.html',
    styleUrls: ['./voucher-create-dialog.component.scss']
})
export class VoucherCreateDialogComponent extends BaseComponent implements OnDestroy {
    isDiscountVoucher = signal(true);
    isPersonRequired = signal(false);
    formGroup = new FormGroup({
        type: new ArcFormControl<number>(undefined, Validators.required),
        quantity: new ArcFormControl(1, [Validators.required, CustomValidators.number({ min: 1, max: 100, isInteger: true })]),
        paymentTypeId: new ArcFormControl<OptionalType<number>>(undefined)
    });
    vouchersTableConfig: WritableSignal<OptionalType<EditableTableConfigModel<VoucherCreateModel>>> = signal(undefined);
    voucherTypes: VoucherTypeModel[] = [];
    voucherTypesSelectModel: SelectOptionModel<number>[] = [];
    voucherTransactionPaymentTypes: VoucherPaymentTypes[] = [];
    voucherTransactionPaymentTypesSelectModel: SelectOptionModel<OptionalType<number>>[] = [];
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    isUpdatingRows = false;
    isSaving = false;
    discountVoucherColumns: BaseColumnModel[] = [
        new StringColumnModel({
            propertyName: 'voucherId',
            isEditable: true,
            widthPixels: 350,
            columnTitleKey: 'Vouchers.Create.VoucherNumber'
        }),
        new QuickSearchColumnModel({
            propertyName: 'personId',
            isEditable: true,
            type: ComplexDataTypesEnum.Customer,
            widthPixels: 350,
            columnTitleKey: 'Vouchers.Create.IssuedFor'
        })
    ];
    valueVoucherColumns: BaseColumnModel[] = [
        new StringColumnModel({
            propertyName: 'voucherId',
            isEditable: true,
            widthPixels: 250,
            columnTitleKey: 'Vouchers.Create.VoucherNumber'
        }),
        new QuickSearchColumnModel({
            propertyName: 'personId',
            isEditable: true,
            type: ComplexDataTypesEnum.Customer,
            widthPixels: 250,
            columnTitleKey: 'Vouchers.Create.IssuedFor'
        }),
        new CurrencyColumnModel({
            propertyName: 'value',
            isEditable: true,
            widthPixels: 200,
            columnTitleKey: 'Vouchers.Create.Value'
        })
    ];

    readonly vouchersFormControl = new ArcFormControl<VoucherCreateModel[]>([]);
    readonly errorMessage = signal<OptionalType<string>>(undefined);

    private readonly voucherTypesStore = inject(VoucherTypesStore);
    private readonly vouchersStore = inject(VouchersStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);
    private readonly transaltionService = inject(TranslationService);
    private readonly errorsService = inject(ErrorsService);
    private readonly translationService = inject(TranslationService);
    private readonly alertService = inject(AlertService);

    constructor() {
        super();
        this.errorsService.shouldDisplayAlertOnError = false;
        const onBusinessExceptionSub = this.errorsService.onBusinessException.subscribe(response => {
            this.handleErrorDialog(response);
        });
        this.addSubscriptions(onBusinessExceptionSub);
        const getVoucherTypesSub = this.voucherTypesStore.getAll().subscribe(res => {
            if (!!res.value) {
                this.voucherTypes = res.value;
                this.voucherTypesSelectModel = res.value.map(r => ({ value: r.id, label: r.title })) || [];
            }
        });
        const getVoucherPaymentTypesSub = this.voucherTypesStore.getDefaultVoucherPaymentTypes().subscribe(resp => {
            if (!!resp.value) {
                this.voucherTransactionPaymentTypes = resp.value;
                this.voucherTransactionPaymentTypesSelectModel = resp.value.map(r => ({ value: r.id, label: r.title })) || [];
                this.voucherTransactionPaymentTypesSelectModel.unshift({
                    value: undefined,
                    label: this.transaltionService.getText('Vouchers.Create.PaymentOutOfArcavis')
                });
            }
        });
        this.addSubscriptions(onBusinessExceptionSub, getVoucherTypesSub, getVoucherPaymentTypesSub);

        this.formGroup.controls.type.valueChanges.subscribe(voucherTypeId => {
            this.isDiscountVoucher.set(this.voucherTypes.find(vt => vt.id === voucherTypeId)?.isDiscountVoucher ?? false);
            this.vouchersFormControl.setValue([]);
        });

        this.formGroup.controls.paymentTypeId.valueChanges.subscribe(paymentTypeId => {
            this.isPersonRequired.set(this.voucherTransactionPaymentTypes.find(pt => pt.id === paymentTypeId)?.requiresPerson ?? false);
        });
    }

    saveRecords(): void {
        this.vouchersFormControl.markAllAsTouched();
        this.vouchersFormControl.updateValueAndValidity();
        if (this.vouchersFormControl.invalid) {
            this.toasterService.showWarning('General.Alert.SaveFailedInvalid');
            return;
        }
        this.isSaving = true;
        this.vouchersStore
            .addInBatch(this.vouchersFormControl.value)
            .pipe(finalize(() => (this.isSaving = false)))
            .subscribe(resp => {
                if (resp.value) {
                    this.matDialogRef.close(true);
                } else {
                    this.toasterService.showError('General.Alert.UnexpectedError');
                }
            });
    }

    updateRows(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            this.toasterService.showWarning('General.Alert.SaveFailedInvalid');
            return;
        }
        this.formGroup.controls.type.disable();
        this.formGroup.controls.paymentTypeId.disable();
        this.isUpdatingRows = true;
        this.vouchersTableConfig.set(
            new EditableTableConfigModel<VoucherCreateModel>({
                formGroupGeneratorFn: () => {
                    const voucherFormGroup = new FormGroup({
                        voucherId: new FormControl(undefined, [Validators.required, this.duplicateVoucherIdValidator()]),
                        personId: new FormControl(undefined, this.isPersonRequired() ? Validators.required : undefined),
                        voucherTypeId: new FormControl(this.formGroup.value.type!),
                        value: new FormControl<OptionalType<number>>(
                            undefined,
                            !this.isDiscountVoucher() ? [Validators.required, CustomValidators.number({ min: 1, max: 999999 })] : undefined
                        ),
                        paymentTypeId: new FormControl<OptionalType<number>>(this.formGroup.getRawValue().paymentTypeId)
                    });

                    voucherFormGroup.valueChanges.subscribe(newValue => {
                        if (!newValue.value) {
                            return;
                        }
                        const roundedValue = Tools.Utils.roundTo(newValue.value, 0.05);
                        if (newValue.value !== roundedValue) {
                            setTimeout(() => {
                                voucherFormGroup.patchValue({ value: roundedValue });
                            });
                        }
                    });

                    return voucherFormGroup;
                },
                shouldHideAddButton: true,
                showPaging: true,
                rowHeightPx: 55,
                columns: this.isDiscountVoucher() ? this.discountVoucherColumns : this.valueVoucherColumns
            })
        );
        const data = this.vouchersFormControl.value ?? [];
        const currentAmount = data.length;
        const desiredAmount = this.formGroup.value.quantity || 0;

        if (currentAmount === desiredAmount) {
            this.isUpdatingRows = false;
            return;
        }

        if (currentAmount > desiredAmount) {
            data.splice(desiredAmount);
            this.vouchersFormControl.setValue(data);
            this.isUpdatingRows = false;
        } else {
            const qty = desiredAmount - currentAmount;
            this.vouchersStore.generateIds(this.formGroup.getRawValue().type!, qty).subscribe(resp => {
                resp.value?.forEach(vId =>
                    data.push({
                        id: undefined,
                        voucherId: vId,
                        personId: undefined,
                        voucherTypeId: this.formGroup.getRawValue().type!,
                        paymentTypeId: this.formGroup.getRawValue().paymentTypeId!
                    })
                );
                this.vouchersFormControl.setValue(data);
                this.isUpdatingRows = false;
            });
        }
    }

    override ngOnDestroy(): void {
        this.errorsService.shouldDisplayAlertOnError = true;
        super.ngOnDestroy();
    }

    // bef I know this is super ugly having HTML as strings in Typescript. Its just an overkill to have an own component.
    private handleErrorDialog(response: ApiResponseModel<any>): void {
        this.errorMessage.set(response.message ?? this.translationService.getText('Vouchers.Create.SaveFailedHeader'));
        const brokenRules = response.brokenRules;
        let content = `<div class="flex flex-col gap-4">
                <span>${this.translationService.getText('Vouchers.Create.SaveFailedText')}</span>
                <ul class="text-start list-disc list-outside pl-8 w-full">`;
        brokenRules?.forEach(e => (content += `<li>${e.message}</li>`));
        content += '</ul></div>';
        const dialog = this.alertService.showAlert(content, this.errorMessage(), true, true, 450, 400);
        dialog.afterClosed().subscribe(() => this.matDialogRef.close(true));
    }

    private duplicateVoucherIdValidator(): ValidatorFn {
        // eslint-disable-next-line no-null/no-null
        return (control: AbstractControl): { [key: string]: any } | null => {
            const currentVoucherId = control.value;

            if (
                this.vouchersFormControl.value.some(
                    v =>
                        // eslint-disable-next-line eqeqeq
                        (v.value != control.parent?.value.value || v.personId != control.parent?.value.personId) &&
                        // eslint-disable-next-line eqeqeq
                        v.voucherId == currentVoucherId
                )
            ) {
                return { customError: { key: 'Vouchers.Create.DuplicateVoucherId' } };
            }
            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }
}
