<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-datepicker labelKey="Invoices.Edit.BaseData.DueDate" [control]="formGroup.controls.dueDate"></arc-datepicker>
    <arc-datepicker *ngIf="shouldHaveReminderDate1" labelKey="Invoices.Edit.BaseData.ReminderDate1"
        [control]="formGroup.controls.reminderDate1"></arc-datepicker>
    <arc-datepicker *ngIf="shouldHaveReminderDate2" labelKey="Invoices.Edit.BaseData.ReminderDate2"
        [control]="formGroup.controls.reminderDate2"></arc-datepicker>
    <arc-datepicker *ngIf="shouldHaveDatePaid" labelKey="Invoices.Edit.BaseData.DatePaid"
        [control]="formGroup.controls.datePaid"></arc-datepicker>
    <arc-textarea label="Invoices.Edit.BaseData.Remarks" [formControl]="formGroup.controls.remarks"></arc-textarea>
</form>
