import { Component, HostBinding, OnInit, ViewEncapsulation, inject, signal } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { BaseSidebarComponent } from '../sidebar-components/base-sidebar/base-sidebar.component';
import { HelpStore } from '../../app/services/stores/help.store';
import { HelpArticleListModel } from '../../app/models/responses/help-article-list.model';
import { HelpArticleModel } from '../../app/models/responses/help-article.model';
import { SettingsSessionStorage } from '../../core/services/storages/settings.session-storage';
import { SidebarHeaderService } from '../../core/services/sidebar-header.service';
import { CreateTicketService } from '../../core/services/create-ticket.service';
import { CreateTicketStepEnum } from '../../app/models/enums/create-ticket-step.enum';
import { OptionalType } from '../../core/models/types/optional.type';

@Component({
    selector: 'arc-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HelpComponent extends BaseSidebarComponent implements OnInit {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2';

    readonly isLoading = signal(true);
    readonly isCreatingTicket = signal(false);
    readonly recommendedArticles = signal<HelpArticleListModel[]>([]);
    readonly otherArticles = signal<HelpArticleListModel[]>([]);
    readonly selectedArticle = signal<OptionalType<HelpArticleModel>>(undefined);
    readonly searchResults = signal<HelpArticleListModel[]>([]);

    private readonly helpStore = inject(HelpStore);
    private readonly router = inject(Router);
    private readonly settingsSessionStorage = inject(SettingsSessionStorage);
    private readonly headerService = inject(SidebarHeaderService);
    private readonly createTicketService = inject(CreateTicketService);

    private searchInputSubject = new Subject<string>();

    constructor() {
        super();

        this.searchInputSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(searchString => {
            this.helpStore.getHelpArticles(searchString).subscribe(r => {
                this.searchResults.set(r.value || []);
            });
        });
    }

    ngOnInit(): void {
        if (this.data) {
            this.selectArticle(this.data);
        } else {
            this.load();
        }

        this.addSubscriptions(this.createTicketService.completed.subscribe(() => this.backToStart()));
        this.addSubscriptions(this.headerService.backButtonClicked.subscribe(() => this.backToStart()));
    }

    selectArticle(articleId: number): void {
        this.clearSelectedArticle();
        this.isLoading.set(true);
        this.helpStore.getHelpArticle(articleId).subscribe(r => {
            this.selectedArticle.set(r.value);
            this.settingsSessionStorage.saveCurrentHelpArticle(this.selectedArticle());
            this.isLoading.set(false);
        });
    }

    createTicket(): void {
        this.isCreatingTicket.set(true);
    }

    backToStart(): void {
        if (this.createTicketService.currentStep() !== CreateTicketStepEnum.None) {
            return;
        }
        this.clearSelectedArticle();
        this.isCreatingTicket.set(false);
        this.load();
        this.headerService.hasCloseButton.set(true);
    }

    onSearch(e: Event): void {
        if (e?.target instanceof HTMLInputElement) {
            const searchValue = e.target.value;
            this.searchInputSubject.next(searchValue);
        }
    }

    onSearchResultSelected(e: MatAutocompleteSelectedEvent): void {
        const selectedOption = e.option.value;
        this.selectArticle(selectedOption?.id);
    }

    private clearSelectedArticle(): void {
        this.selectedArticle.set(undefined);
        this.settingsSessionStorage.saveCurrentHelpArticle(this.selectedArticle());
    }

    private load(): void {
        this.isLoading.set(true);
        this.selectedArticle.set(this.settingsSessionStorage.getCurrentHelpArticle());
        const currentContext = this.settingsSessionStorage.getCurrentHelpContext();
        const newContext = this.router.url.slice(1);

        if (currentContext !== newContext) {
            this.settingsSessionStorage.saveCurrentHelpContext(newContext);
            this.clearSelectedArticle();
        }

        if (!this.selectedArticle()) {
            const recomendedArticlesObservable = this.helpStore.getHelpArticles('', newContext);

            // featured articles have the context === 'featured'
            const otherArticlesObservable = this.helpStore.getHelpArticles('', 'featured');


            forkJoin([recomendedArticlesObservable, otherArticlesObservable]).subscribe(results => {
                const recommendedArticlesResult = results[0];
                const otherArticlesResult = results[1];

                this.recommendedArticles.set(recommendedArticlesResult.value || []);
                this.otherArticles.set(otherArticlesResult.value || []);

                this.isLoading.set(false);
            });
        } else {
            this.isLoading.set(false);
        }
    }
}
