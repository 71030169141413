import { Injectable } from '@angular/core';

import { PaymentTypeListModel } from '../../models/responses/payment-type-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { PaymentTypeModel } from '../../models/payment-type.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentTypesStore extends BaseCrudStore<PaymentTypeModel, PaymentTypeListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'payment-types' }));
    }
}
