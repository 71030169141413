import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { PaymentTypeListModel } from '../../../models/responses/payment-type-list.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PaymentTypesStore } from '../../../services/stores/payment-types.store';
import { PaymentTypeCardComponent } from './payment-type-card/payment-type-card.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { PaymentTypeBaseDataComponent } from './payment-types-edit/payment-type-base-data/payment-type-base-data.component';
import { PaymentTypeModel } from '../../../models/payment-type.model';

@Component({
    selector: 'arc-payment-types',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './payment-types.component.scss'
})
export class PaymentTypesComponent extends PageListViewComponent<PaymentTypeListModel, PaymentTypeModel> {
    cardConfig: CardListViewConfigModel<PaymentTypeModel, PaymentTypeListModel>;

    private readonly paymentTypesStore = inject(PaymentTypesStore);

    constructor() {
        super('PaymentTypes');

        this.config = new CardListViewConfigModel<PaymentTypeModel, PaymentTypeListModel>({
            entityName: 'PaymentTypes',
            store: this.paymentTypesStore,
            cardComponent: PaymentTypeCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'PaymentTypes.Edit.BaseData.ComponentTitle', component: PaymentTypeBaseDataComponent }
                ]
            },
            mainButton: new MainCardButtonModel({
                text: 'PaymentTypes.ShowPayments',
                clickFn: data => this._router.navigate(['transaction-payments'], { queryParams: { PaymentTypeId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
        this.cardConfig = this.config;
    }
}
