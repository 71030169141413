<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goTransactionsPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        @if (isLoading()) {
            <mat-spinner [diameter]="32" />
        } @else {
            <arc-static-table [config]="tableConfig" [staticData]="transactionArticles()" [shouldUseCompactStyle]="true"
                [isSingleLineRow]="true">
            </arc-static-table>
        }
    </div>
</div>
