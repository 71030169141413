import { Directive, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { BaseComponent } from '../../abstractions/base.component';
import { Tools } from '../../../core/utils/tools';
import { TranslationService } from '../../../core/services/translation.service';
import { PermissionsEnum } from '../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../core/models/enums/permission-type.enum';
import { PermissionService } from '../../../core/services/permission.service';
import { AuthPermissionModel } from '../../../app/models/auth-permission.model';
import { ModuleIdentificationsEnum } from '../../../app/models/enums/module-identifications.enum';

@Directive()
export abstract class BaseControlComponent<T = any> extends BaseComponent implements OnInit {
    @Input() control!: AbstractControl<T>;
    @Input() label?: string;
    @Input() labelKey?: string;
    @Input() placeholder?: string;
    @Input() placeholderKey?: string;
    @Input() tagId = '';
    @Input() color: ThemePalette = 'primary';
    @Input() hasFloatingLabel = false;
    @Input() hasFixedSubscript = false;
    @Input() requiredPermission?: PermissionsEnum;
    @Input() requiredPermissionType = PermissionTypeEnum.Read;
    @Input() requiredModule?: ModuleIdentificationsEnum;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly blur = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly change = new EventEmitter<T>();

    permission: true | AuthPermissionModel = true;

    protected readonly _translationService = inject(TranslationService);
    protected readonly _permissionService = inject(PermissionService);

    get castedControl(): FormControl {
        return this.control as FormControl;
    }

    get formGroup(): FormGroup {
        return this.control.parent as FormGroup;
    }

    get isAllowed(): boolean {
        return this.permission === true;
    }

    ngOnInit(): void {
        if (!this.tagId) {
            this.tagId = Tools.Utils.newGuid();
        }

        if (!!this.requiredPermission) {
            this.permission = this._permissionService.hasPermission(this.requiredPermission, this.requiredPermissionType);

            if (this.permission !== true) {
                this.control.disable();
            }
        } else if (!!this.requiredModule && !this._permissionService.hasModuleLicensed(this.requiredModule)) {
            this.permission = {
                isLicensed: false,
                requiredModuleId: this.requiredModule,
                permissionId: '',
                permit: false,
                permitCreate: false,
                permitDelete: false,
                permitUpdate: false
            };
            this.control.disable();
        }
    }

    onBlur(): void {
        this.blur.emit();
    }

    onChange(event: T): void {
        this.change.emit(event);
    }
}
