import { Component } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { PaymentTypeListModel } from '../../../../models/responses/payment-type-list.model';

@Component({
    selector: 'arc-payment-type-card',
    templateUrl: './payment-type-card.component.html',
    styleUrl: './payment-type-card.component.scss'
})
export class PaymentTypeCardComponent extends BaseCardComponent<PaymentTypeListModel> { }
