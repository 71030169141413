import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslationService } from '../services/translation.service';

@Pipe({ name: 'arcNumber' })
export class NumberPipe implements PipeTransform {
    private readonly translationService = inject(TranslationService);

    transform(value: any, decimalDigits?: number, prefix?: string, suffix?: string, localize = true): string {
        const numberVal = Number(value);

        if (Number.isNaN(numberVal)) {
            return value;
        }

        if (localize) {
            value = numberVal.toLocaleString(
                this.translationService.current.culture,
                { minimumFractionDigits: decimalDigits, maximumFractionDigits: decimalDigits }
            );
        } else {
            value = numberVal.toFixed(decimalDigits);
        }

        return (prefix ?? '') + value + (suffix ?? '');
    }
}
