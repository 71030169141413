<div [arcErrorTooltip]="_formControl"
    class="relative w-full h-full grid place-items-center outline-2 outline-dashed outline-on-app-light rounded-[4px] transition-all"
    [class.-outline-offset-8]="isDragging()" [class.bg-element-selected]="isDragging()"
    [class.!outline-error]="_formControl?.invalid && _formControl?.touched" [ngClass]="customClasses">
    <!-- actual html input -->
    <input type="file" #fileInputElement [accept]="fileTypes()" hidden [disabled]="isDisabled" (change)="onFileInputChanged($event)"
        [id]="localId" class="absolute inset-0 opacity-10 -z-10">

    @if (!!label) {
        <div class="mt-2 mb-2 flex">
            {{ isLabelTranslated ? label : (label | transloco) }}
        </div>
    }

    <div class="grid gap-2 place-items-center px-4 text-center">
        @if (!value) {
            <arc-icon icon="file_upload" [size]="24" />
            <div [class.ml-2]="shouldUseCompactStyle()" [class.text-error]="_formControl?.invalid && _formControl?.touched">
                <label [for]="localId" class="underline hover:cursor-pointer hover:text-accent">
                    {{ 'Components.FileUpload.ChooseFile' | transloco }}
                </label>
                @if (allowUrlUpload()) {
                    <span>,</span>
                    <a href="javascript:void(0)" (click)="addFromUrl()" class="underline hover:text-accent">
                        {{ 'Components.FileUpload.FromUrl' | transloco }}
                    </a>
                }
                <span>{{ 'Components.FileUpload.OrDropHere' | transloco }}</span>
                @if (isRequired) {
                    <span>*</span>
                }
            </div>
        } @else {
            @if (!!base64ImageUrl()) {
                <img [src]="base64ImageUrl()" class="size-[100px] p-4 object-contain bg-element-selected" />
            } @else {
                <arc-icon icon="description" [size]="24" />
            }
            <p>{{ value!.name }}</p>
        }

        <div class="flex text-on-app-light">
            @if (!value) {
                @if (!!placeholder) {
                    <span>
                        {{ isPlaceholderTranslated ? placeholder : (placeholder | transloco) }}
                    </span>
                }
            } @else {
                <a href="javascript:void(0)" (click)="removeFile()" class="hover:cursor-pointer hover:text-error flex gap-2 items-center">
                    <arc-icon icon="close" [size]="24" />
                    <span>{{ 'Components.FileUpload.RemoveFile' | transloco }}</span>
                </a>
            }
        </div>
    </div>
</div>
