import { Component, computed, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { TransactionArticleModel } from '../../../../../models/transaction-article.model';
import { UserService } from '../../../../../../core/services/user.service';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-transaction-article',
    templateUrl: './transaction-article.detail-widget.html',
    styleUrl: './transaction-article.detail-widget.scss'
})
export class TransactionArticleDetailWidget extends BaseDetailWidget<TransactionArticleListModel, TransactionArticleModel> {
    readonly isPricePerUnitDiff = computed(() => this.listModel().unitPrice !== this.entityModel().unitPrice);
    readonly unitPriceTimesQuantity = computed(() =>
        Tools.Utils.roundTo(this.entityModel().unitPrice * this.entityModel().quantity, this.userService.defaultRoundTo)
    );
    readonly isTotalDiff = computed(() => this.unitPriceTimesQuantity() !== this.entityModel().price);
    readonly isSale = computed(() => !!this.entityModel().saleTitle);
    readonly boxColorClasses = computed(() => (this.isSale() ? ['bg-warn', 'text-on-warn'] : ['bg-error', 'text-on-error']));

    private readonly userService = inject(UserService);
}
