import { Component, computed } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArcavisLogListModel } from '../../../../../models/responses/arcavis-log-list.model';
import { ArcavisLogModel } from '../../../../../models/arcavis-log.model';

@Component({
    selector: 'arc-system-log-details-table',
    templateUrl: './system-log-details-table.detail-widget.html',
    styleUrl: './system-log-details-table.detail-widget.scss'
})
export class SystemLogDetailsTableDetailWidget extends BaseDetailWidget<ArcavisLogListModel, ArcavisLogModel, string> {
    extJsonEntires = computed(() => {
        const entityModel = this.entityModel();
        const extJson: object = JSON.parse(entityModel.extJson ?? '{}');
        return Object.entries(extJson).map(([key, value]) => ({ key, value })).sort((a, b) => a.key.localeCompare(b.key));
    });
}
