import { Component, inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ReservationsStore } from '../../../services/stores/reservations.store';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ReservationListModel } from '../../../models/responses/reservation-list.model';
import { ReservationDetailsModel } from '../../../models/reservation-details.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { ReservationCustomerDetailWidget } from './reservation-customer/reservation-customer.detail-widget';
import { ReservationArticlesDetailWidget } from './reservation-articles/reservation-articles.detail-widget';
import { TranslationService } from '../../../../core/services/translation.service';
import { ReservationEditHeaderAdditionalInfoComponent } from './reservation-edit-header-additional-info/reservation-edit-header-additional-info.component';
import { ReservationEditBaseDataComponent } from './reservation-edit-items/reservation-edit-base-data/reservation-edit-base-data.component';
import { ReservationEditArticlesComponent } from './reservation-edit-items/reservation-edit-articles/reservation-edit-articles.component';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { ReservationEditModel } from '../../../models/reservation-edit.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-reservations',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent extends PageListViewComponent<ReservationListModel, ReservationDetailsModel, ReservationEditModel> {
    private readonly reservationsStore = inject(ReservationsStore);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super('Reservations');
        this.config = new TableListViewConfigModel<ReservationListModel, ReservationDetailsModel, ReservationEditModel>({
            entityName: 'Reservations',
            availableColumns: {
                reservationType: new ColoredStackedColumnModel({
                    columnTitleKey: 'Reservations.List.Type',
                    propertyName: 'id',
                    propertyName2: 'reservationTypeName',
                    color: 'reservationTypeColor',
                    isColorInHex: true,
                    widthPixels: 160
                }),
                externalReference: new StringColumnModel({
                    columnTitleKey: 'Reservations.List.Reference',
                    propertyName: 'externalReference',
                    widthPixels: 150
                }),
                customerName: new StackedColumnModel({
                    columnTitleKey: 'Reservations.List.CustomerName',
                    propertyName: 'customerName',
                    propertyName2: 'customerNumber',
                    suffixPropertyName: 'customerContactName',
                    widthPixels: 286
                }),
                createdTime: new DateColumnModel({
                    columnTitleKey: 'Reservations.List.CreatedOn',
                    propertyName: 'createdTime',
                    widthPixels: 150
                }),
                createdUser: new StringColumnModel({
                    columnTitleKey: 'Reservations.List.CreatedUser',
                    propertyName: 'createdUser',
                    widthPixels: 150
                }),
                completedTime: new DateColumnModel({
                    columnTitleKey: 'Reservations.List.BookedOn',
                    propertyName: 'completedTime',
                    widthPixels: 150
                }),
                completedUser: new StringColumnModel({
                    columnTitleKey: 'Reservations.List.CompletedUser',
                    propertyName: 'completedUser',
                    widthPixels: 150
                }),
                store: new StringColumnModel({
                    columnTitleKey: 'Reservations.List.Store',
                    propertyName: 'store',
                    widthPixels: 150
                }),
                reservationAmount: new CurrencyColumnModel({
                    columnTitleKey: 'Reservations.List.ReservationAmount',
                    propertyName: 'reservationAmount',
                    widthPixels: 156
                })
            },
            defaultColumnOrder: [
                'reservationType',
                'externalReference',
                'customerName',
                'createdTime',
                'createdUser',
                'completedUser',
                'reservationAmount'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                customer: new TableListDetailWidgetModel({
                    name: 'Reservations.Detail.Customer.ComponentTitle',
                    component: ReservationCustomerDetailWidget
                }),
                articles: new TableListDetailWidgetModel({
                    name: 'Reservations.Detail.Articles.ComponentTitle',
                    component: ReservationArticlesDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['customer', 'articles'],
            store: this.reservationsStore,
            editSidebarConfig: {
                customMaxWidth: '1200px',
                editComponents: [
                    { titleKey: 'Reservations.Edit.BaseData.ComponentTitle', component: ReservationEditBaseDataComponent },
                    { titleKey: 'Reservations.Edit.Article.ComponentTitle', component: ReservationEditArticlesComponent }
                ],
                // eslint-disable-next-line max-len
                customHeaderTitle: item => {
                    const entityName = item.id > 0
                        ? this.translationService.getText('Reservations.EntityName')
                        : this.translationService.getText('Reservations.NewEntity');
                    const entityId = item.id > 0 ? ` ${item.id}` : '';
                    const externalRef = !!item.externalReference ? ` / ${item.externalReference}` : '';

                    return of(`${entityName}${entityId}${externalRef}`);
                },
                headerSubtitle: item => of(item.customer?.displayName ?? ''),
                headerAdditionalInfoComponent: ReservationEditHeaderAdditionalInfoComponent
            },
            buttonsVisibility: new ButtonsVisibilityModel({ hasDuplicate: true }),
            propertiesExcludedFromDuplication: ['transactionsDetail.completedTime', 'canBeCompleted', 'isCompleted'],
            transformRecordForDuplication: this.transformRecordForDuplication.bind(this)
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService.updateIsHidden(
            ActionButtonsService.editButtonKey,
            (btn, data) => !!data && !!data.completedTime
        );
    }

    private transformRecordForDuplication(item: ReservationDetailsModel): Observable<ReservationDetailsModel> {
        item.reservationArticles.forEach(a => a.id = 0);
        return of(item);
    }
}
