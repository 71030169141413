import { Injectable, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map, switchMap, tap, filter, Observable, of, forkJoin } from 'rxjs';

import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { WarehouseRequestModel } from '../../../../../models/warehouse-request.model';
import { WarehouseRequestArticleModel } from '../../../../../models/warehouse-request-article.model';
import { WarehouseRequestsStore } from '../../../../../services/stores/warehouse-requests.store';
import { ArticleStocksStore } from '../../../../../services/stores/article-stocks.store';
import { StoreArticlesStore } from '../../../../../services/stores/store-articles.store';
import { RequestService } from '../../../../../../core/services/request.service';
import { ButtonColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-column.model';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { EditableTableButtonModel } from '../../../../../../components/form/editable-table/models/editable-table-button.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl, ArcFormControlInterface } from '../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { CustomColumnModel } from '../../../../../../components/dynamic-table/models/column-types/custom-column.model';
import { GeneralPromptDialogComponent } from '../../../../../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';
import {
    GeneralPromptDialogDataModel
} from '../../../../../../components/dialogs/general-prompt-dialog/models/general-prompt-dialog-data.model';
import {
    WarehouseTransferArticleStatusColumnComponent
} from './warehouse-transfer-article-status-column/warehouse-transfer-article-status-column.component';
import { GenericStatusEnum } from '../../../../../models/enums/generic-status.enum';
import {
    WarehouseTransferArticleEditDialogComponent
} from './warehouse-transfer-article-edit-dialog/warehouse-transfer-article-edit-dialog.component';
import { DataSelectionDialogComponent } from '../../../../../../components/dialogs/data-selection-dialog/data-selection-dialog.component';
import {
    DataSelectionDialogCustomDataModel
} from '../../../../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-custom-data.model';
import { ArticleStockListModel } from '../../../../../models/responses/article-stock-list.model';
import { Identifyable } from '../../../../../../core/abstractions/identifyable';
import { BaseSearchStore } from '../../../../../../core/abstractions/base-search.store';

@Injectable()
export class WarehouseTransferArticlesTableConfigService {
    private readonly warehouseRequestsStore = inject(WarehouseRequestsStore);
    private readonly articleStocksStore = inject(ArticleStocksStore);
    private readonly requestService = inject(RequestService);
    private readonly matDialog = inject(MatDialog);

    private columns = {
        articleTitle: new StackedColumnModel({
            propertyName: 'articleTitle',
            propertyName2: 'articleArticleNumber',
            columnTitleKey: 'WarehouseTransfers.Edit.Articles.Article'
        }),
        stockDeliveringStore: (warehouseRequest: WarehouseRequestModel) =>
            new NumberColumnModel({
                propertyName: 'stockDeliveringStore',
                columnTitleKey: 'WarehouseTransfers.Edit.Articles.Stock',
                columnTitleSuffix: warehouseRequest.deliveringStoreName,
                unit: record => record.articleUnitTitle,
                isUnitTranslated: true
            }),
        stockReceivingStore: (warehouseRequest: WarehouseRequestModel) =>
            new NumberColumnModel({
                propertyName: 'stockReceivingStore',
                columnTitleKey: 'WarehouseTransfers.Edit.Articles.Stock',
                columnTitleSuffix: warehouseRequest.receivingStoreName,
                unit: record => record.articleUnitTitle,
                isUnitTranslated: true
            }),
        quantity: (isEditable: boolean, status: GenericStatusEnum) =>
            new NumberColumnModel({
                propertyName: 'quantity',
                columnTitleKey: status === GenericStatusEnum.Draft
                    ? 'WarehouseTransfers.Edit.Articles.Quantity'
                    : 'WarehouseTransfers.Edit.Articles.QuantitySent',
                isEditable,
                unit: record => record.articleUnitTitle,
                isUnitTranslated: true
            }),
        deliveredQuantity: (isEditable: boolean) =>
            new NumberColumnModel({
                propertyName: 'deliveredQuantity',
                columnTitleKey: 'WarehouseTransfers.Edit.Articles.DeliveredQuantity',
                isEditable,
                unit: record => record.articleUnitTitle,
                isUnitTranslated: true
            }),
        deliveredStatus: new CustomColumnModel<WarehouseRequestArticleModel>({
            propertyName: 'deliveredStatus',
            shouldHideColumnTitle: true,
            customComponent: WarehouseTransferArticleStatusColumnComponent
        }),
        editDetails: new ButtonColumnModel<WarehouseRequestArticleModel>({
            propertyName: 'details',
            shouldHideColumnTitle: true,
            icon: () => 'edit',
            onClickFunction: (item, formGroup) => {
                this.matDialog
                    .open(WarehouseTransferArticleEditDialogComponent, {
                        data: { item },
                        width: '600px',
                        maxWidth: '90vw'
                    })
                    .afterClosed()
                    .subscribe((data?: Partial<WarehouseRequestArticleModel>) => {
                        if (!!data) {
                            formGroup?.patchValue(data);
                        }
                    });
            }
        })
    };

    getTableConfig(
        warehouseRequest: WarehouseRequestModel,
        formGroup: FormGroup<{
            warehouseRequestArticles: ArcFormControlInterface<WarehouseRequestArticleModel[]>;
        }>
    ): EditableTableConfigModel<WarehouseRequestArticleModel> {
        switch (warehouseRequest.status) {
            case GenericStatusEnum.Draft:
                return new EditableTableConfigModel<WarehouseRequestArticleModel>({
                    formGroupGeneratorFn: this.getFormGroupGeneratorFn(warehouseRequest.status).bind(this),
                    columns: [
                        this.columns.articleTitle,
                        this.columns.stockDeliveringStore(warehouseRequest),
                        this.columns.stockReceivingStore(warehouseRequest),
                        this.columns.quantity(true, warehouseRequest.status),
                        this.columns.editDetails
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    additionalButtons: [
                        new EditableTableButtonModel({
                            labelKey: 'WarehouseTransfers.Edit.Articles.FillTargetQuantity',
                            action: () => {
                                const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
                                    data: {
                                        promptKey: 'WarehouseTransfers.Edit.Articles.FillTargetQuantityPromptTitle',
                                        messageKey: 'WarehouseTransfers.Edit.Articles.FillTargetQuantityPromptMessage'
                                    } satisfies GeneralPromptDialogDataModel
                                });

                                return dialogRef.afterClosed().pipe(
                                    filter(result => result === true),
                                    switchMap(() => this.warehouseRequestsStore.calculateArticlesForWarehouseRequest(warehouseRequest.id)),
                                    map(result => result?.value ?? []),
                                    tap(warehouseRequestArticles => {
                                        formGroup.controls.warehouseRequestArticles.setValue(warehouseRequestArticles);
                                        formGroup.controls.warehouseRequestArticles.markAsDirty();
                                    })
                                );
                            }
                        }),
                        new EditableTableButtonModel({
                            labelKey: 'Orders.Edit.Articles.AddArticles',
                            action: current => this.openDataSelectionDialog(warehouseRequest, formGroup, current)
                        })
                    ]
                });
            case GenericStatusEnum.Sent:
                return new EditableTableConfigModel<WarehouseRequestArticleModel>({
                    formGroupGeneratorFn: this.getFormGroupGeneratorFn(warehouseRequest.status).bind(this),
                    columns: [
                        this.columns.articleTitle,
                        this.columns.stockDeliveringStore(warehouseRequest),
                        this.columns.stockReceivingStore(warehouseRequest),
                        this.columns.quantity(false, warehouseRequest.status),
                        this.columns.deliveredQuantity(true),
                        this.columns.deliveredStatus,
                        this.columns.editDetails
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    allowDelete: false
                });
            case GenericStatusEnum.Delivered:
                return new EditableTableConfigModel<WarehouseRequestArticleModel>({
                    formGroupGeneratorFn: this.getFormGroupGeneratorFn(warehouseRequest.status).bind(this),
                    columns: [
                        this.columns.articleTitle,
                        this.columns.stockDeliveringStore(warehouseRequest),
                        this.columns.stockReceivingStore(warehouseRequest),
                        this.columns.quantity(false, warehouseRequest.status),
                        this.columns.deliveredQuantity(false),
                        this.columns.deliveredStatus,
                        this.columns.editDetails
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    allowDelete: false
                });
        }
    }

    articleStocksToWarehouseRequestArticles(
        warehouseRequest: WarehouseRequestModel,
        articleStocks: ArticleStockListModel[]
    ): Observable<WarehouseRequestArticleModel[]> {
        return forkJoin(
            articleStocks.map(a =>
                forkJoin({
                    articleStock: of(a),
                    articleStockReceivingStore: this.articleStocksStore.getStock(
                        `${a.articleId}-${warehouseRequest.receivingStoreId}`
                    )
                })
            )
        ).pipe(
            map(results =>
                results
                    .filter(result => !!result.articleStock && result.articleStockReceivingStore?.value !== undefined)
                    .map(result => ({
                        articleStock: result.articleStock,
                        articleStockReceivingStore: result.articleStockReceivingStore.value ?? 0
                    }))
                    .map(
                        result =>
                            ({
                                id: 0,
                                warehouseRequestId: warehouseRequest.id,
                                articleId: result.articleStock.articleId,
                                articleArticleNumber: result.articleStock.articleNumber,
                                articleTitle: result.articleStock.articleTitle,
                                articleUnitTitle: result.articleStock.articleUnitTitle,
                                stockDeliveringStore: result.articleStock.stock,
                                stockReceivingStore: result.articleStockReceivingStore,
                                quantity: 1
                            })
                    )
            )
        );
    }

    getDataSelectionDialogConfig<T extends Identifyable<TId>, TId>(
        warehouseRequest: WarehouseRequestModel,
        isMultiSelect: boolean
    ): DataSelectionDialogCustomDataModel<T, TId> {
        const filteredStore = new StoreArticlesStore(this.requestService, warehouseRequest.deliveringStoreId);
        return new DataSelectionDialogCustomDataModel<T, TId>({
            dialogTitleKey: 'ArticleStocks.TitleSelectDialog',
            store: filteredStore as unknown as BaseSearchStore<T, TId>,
            columnConfig: [
                new StackedColumnModel({
                    propertyName: 'articleTitle',
                    propertyName2: 'articleNumber',
                    columnTitleKey: 'ArticleStocks.List.Article'
                }),
                new NumberColumnModel({
                    propertyName: 'stock',
                    unit: (record: ArticleStockListModel) => record.articleUnitTitle,
                    isUnitTranslated: true,
                    columnTitleKey: 'ArticleStocks.List.Stock',
                    columnTitleSuffix: warehouseRequest.deliveringStoreName
                })
            ],
            isMultiSelect
        });
    }

    private getFormGroupGeneratorFn(status: GenericStatusEnum): () => FormGroup {
        const deliveredQuantityValidators = status === GenericStatusEnum.Draft
            ? [CustomValidators.number({ min: 0 })]
            : [CustomValidators.number({ min: 0 }), Validators.required];
        return () => new FormGroup({
            id: new ArcFormControl(''),
            warehouseRequestId: new ArcFormControl(''),
            articleUnitTitle: new ArcFormControl(''),
            articleTitle: new ArcFormControl(''),
            articleArticleNumber: new ArcFormControl(''),
            articleId: new ArcFormControl(''),
            quantity: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, CustomValidators.number({ min: 0 })]),
            deliveredQuantity: new ArcFormControl<OptionalType<number>>(undefined, deliveredQuantityValidators),
            notes: new ArcFormControl<OptionalType<string>>(undefined),
            stockDeliveringStore: new ArcFormControl(''),
            stockReceivingStore: new ArcFormControl('')
        });
    }

    private openDataSelectionDialog(
        warehouseRequest: WarehouseRequestModel,
        formGroup: FormGroup<{
            warehouseRequestArticles: ArcFormControlInterface<WarehouseRequestArticleModel[]>;
        }>,
        currentData: WarehouseRequestArticleModel[]
    ): Observable<any> {
        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: this.getDataSelectionDialogConfig(warehouseRequest, true),
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        return dialogRef.afterClosed().pipe(
            switchMap((result?: ArticleStockListModel[]) => {
                // eslint-disable-next-line eqeqeq
                result = result?.filter(a => !currentData.some(c => c.articleId == a.articleId));

                if (!result || result.length === 0) {
                    return of([]);
                }

                return this.articleStocksToWarehouseRequestArticles(warehouseRequest, result);
            }),
            tap(warehouseRequestArticles => {
                const currentValue = formGroup.value.warehouseRequestArticles ?? [];
                formGroup.controls.warehouseRequestArticles.setValue([...currentValue, ...warehouseRequestArticles]);
                formGroup.controls.warehouseRequestArticles.markAsDirty();
            })
        );
    }
}
