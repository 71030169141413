import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SupplierEditRequestModel } from '../../../../../models/requests/supplier-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SupplierModel } from '../../../../../models/supplier.model';
import { AddressPickerFormGroupType } from '../../../../../../components/form/address-picker/types/address-picker-form-group.type';
import { PlaceSelectFormGroupType } from '../../../../../../components/form/place-select/types/place-select-form-group.type';

@Component({
    selector: 'arc-supplier-edit-base-data',
    templateUrl: './supplier-edit-base-data.component.html',
    styleUrls: ['./supplier-edit-base-data.component.scss']
})
export class SupplierEditBaseDataComponent extends BaseEditSidebarItemComponent<SupplierModel, SupplierEditRequestModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    get isCompany(): boolean {
        return !!this.formGroup?.value?.isCompany;
    }

    override formGroup = new FormGroup({
        personNumber: new ArcFormControl('', [Validators.required, Validators.maxLength(20)]),
        isCompany: new ArcFormControl(false, Validators.required),
        companyName: new ArcFormControl('', [Validators.maxLength(150)]),
        legalFormId: new ArcFormControl<OptionalType<number>>(undefined),
        salutationId: new ArcFormControl<OptionalType<number>>(undefined),
        salutationTitleId: new ArcFormControl<OptionalType<number>>(undefined),
        firstname: new ArcFormControl('', Validators.maxLength(50)),
        name: new ArcFormControl('', [Validators.maxLength(150)]),
        address: new FormGroup<AddressPickerFormGroupType>({
            street: new ArcFormControl('', Validators.maxLength(100)),
            streetNumber: new ArcFormControl('', Validators.maxLength(25)),
            streetSupplement: new ArcFormControl('', Validators.maxLength(100)),
            isPoBox: new ArcFormControl(false, Validators.required),
            poBox: new ArcFormControl('', Validators.maxLength(50)),
            place: new FormGroup<PlaceSelectFormGroupType>({
                zip: new ArcFormControl('', Validators.required),
                city: new ArcFormControl('', Validators.required),
                countryIsoCode: new ArcFormControl('', Validators.required)
            })
        })
    });

    constructor() {
        super();

        this.formGroup.controls.isCompany.valueChanges.subscribe(isCompany => {
            this.formGroup.controls.companyName.setDisabled(!isCompany);
            this.formGroup.controls.legalFormId.setDisabled(!isCompany);

            if (isCompany) {
                this.formGroup.controls.companyName.addValidators(Validators.required);
                this.formGroup.controls.name.removeValidators(Validators.required);
            } else {
                this.formGroup.controls.companyName.removeValidators(Validators.required);
                this.formGroup.controls.name.addValidators(Validators.required);
            }

            // need to update validity on controls, updating whole group doesn't work
            this.formGroup.controls.companyName.updateValueAndValidity();
            this.formGroup.controls.name.updateValueAndValidity();
        });
    }

    override onItemSet(): void {
        const item = this.item();
        this.formGroup.patchValue({
            ...item,
            address: {
                street: item.street,
                streetNumber: item.streetNumber,
                streetSupplement: item.streetSupplement,
                isPoBox: item.isPoBox,
                poBox: item.isPoBox ? item.poBox : undefined,
                place: {
                    zip: item.zip,
                    city: item.city,
                    countryIsoCode: item.countryIsoCode
                }
            }
        });
    }

    override prepareSaveModel(): Partial<SupplierEditRequestModel> {
        const value = this.formGroup.getRawValue();
        return {
            personNumber: value.personNumber,
            isCompany: value.isCompany,
            companyName: value.isCompany ? value.companyName : undefined,
            legalFormId: value.isCompany ? value.legalFormId : undefined,
            salutationId: value.salutationId,
            salutationTitleId: value.salutationTitleId,
            firstname: value.firstname,
            name: value.name,
            street: value.address.street,
            streetNumber: value.address.streetNumber,
            streetSupplement: value.address.streetSupplement,
            isPoBox: value.address.isPoBox,
            poBox: value.address.isPoBox ? value.address.poBox : undefined,
            zip: value.address.place.zip,
            city: value.address.place.city,
            countryIsoCode: value.address.place.countryIsoCode
        };
    }
}
