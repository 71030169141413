import { ComponentRef, Directive, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef, inject } from '@angular/core';

import { QuickFeedbackComponent } from '../../components/quick-feedback/quick-feedback.component';

@Directive({
    selector: '[arcQuickFeedback]'
})
export class QuickFeedbackDirective implements OnInit, OnChanges {
    @Input('arcQuickFeedback') featureId?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    @Input() quickFeedbackShouldShow?: boolean;
    @Input() quickFeedbackPosition: { top?: number; right?: number; bottom?: number; left?: number } = { top: 0, right: 0 };

    private readonly viewContainerRef = inject(ViewContainerRef);
    private componentRef?: ComponentRef<QuickFeedbackComponent>;

    ngOnInit(): void {
        if (this.featureId === undefined) {
            return;
        }

        this.componentRef = this.viewContainerRef.createComponent(QuickFeedbackComponent);
        this.componentRef.instance.featureId = this.featureId;

        const viewContainerHtmlElement = this.viewContainerRef.element.nativeElement as HTMLElement;
        const componentHtmlElement = this.componentRef.location.nativeElement as HTMLElement;

        this.setComponentDisplay(this.quickFeedbackShouldShow !== false);

        // move component to be a child of this directive's component
        viewContainerHtmlElement.appendChild(componentHtmlElement);
        if (!viewContainerHtmlElement.style.position || viewContainerHtmlElement.style.position === 'static') {
            viewContainerHtmlElement.style.position = 'relative';
        }

        // apply custom positioning
        let top = '';
        let bottom = '';
        let left = '';
        let right = '';

        if (this.quickFeedbackPosition.top !== undefined) {
            // top is defined, bottom may be defined
            top = `${this.quickFeedbackPosition.top}px`;
            bottom = this.quickFeedbackPosition.bottom ? `${this.quickFeedbackPosition.bottom}px` : '';
        } else if (this.quickFeedbackPosition.bottom !== undefined) {
            // bottom is defined, top may be defined
            bottom = `${this.quickFeedbackPosition.bottom}px`;
            top = this.quickFeedbackPosition.top ? `${this.quickFeedbackPosition.top}px` : '';
        } else {
            top = '0';
        }

        if (this.quickFeedbackPosition.right !== undefined) {
            // right is defined, left may be defined
            right = `${this.quickFeedbackPosition.right}px`;
            left = this.quickFeedbackPosition.left ? `${this.quickFeedbackPosition.left}px` : '';
        } else if (this.quickFeedbackPosition.left !== undefined) {
            // left is defined, right may be defined
            left = `${this.quickFeedbackPosition.left}px`;
            right = this.quickFeedbackPosition.right ? `${this.quickFeedbackPosition.right}px` : '';
        } else {
            right = '0';
        }

        componentHtmlElement.style.top = top;
        componentHtmlElement.style.bottom = bottom;
        componentHtmlElement.style.left = left;
        componentHtmlElement.style.right = right;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['quickFeedbackShouldShow'] && !!this.componentRef) {
            const shouldNotDisplay = changes['quickFeedbackShouldShow'].currentValue === false;
            this.setComponentDisplay(!shouldNotDisplay);
        }
    }

    private setComponentDisplay(isVisible: boolean): void {
        if (!!this.componentRef) {
            this.componentRef.instance.setVisibility(isVisible);
        }
    }
}
