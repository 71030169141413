@if (columnModel.isEditable && !!control) {
    <arc-currency-input [formControl]="control | formControl" [currencyId]="currencyId"></arc-currency-input>
} @else {
    <div class="w-full-h-full align-center">
        @if(hasValue) {
            <span class="truncate" [class.text-sm]="currColumnModel.isCompact" [class.font-bold]="isBold(currColumnModel.propertyName)">
                @if(isNumber) {
                    {{ value | arcCurrency }}
                } @else {
                    {{ value }}
                }
            </span>
        }
    </div>
    @if (permission !== true) {
        <arc-no-permission-lock [missingPermission]="permission"></arc-no-permission-lock>
    }
}
