import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { AddressPickerFormGroupType } from '../../../../../../components/form/address-picker/types/address-picker-form-group.type';
import { PlaceSelectFormGroupType } from '../../../../../../components/form/place-select/types/place-select-form-group.type';

@Component({
    selector: 'arc-settings-customize-base-data',
    templateUrl: './settings-customize-base-data.component.html',
    styleUrl: './settings-customize-base-data.component.scss'
})
export class SettingsCustomizeBaseDataComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        companyName: new ArcFormControl('', Validators.required),
        companyAddress: new FormGroup<AddressPickerFormGroupType>({
            street: new ArcFormControl<string>(undefined),
            streetNumber: new ArcFormControl<string>(undefined),
            place: new FormGroup<PlaceSelectFormGroupType>({
                zip: new ArcFormControl<string>(undefined),
                city: new ArcFormControl<string>(undefined),
                countryIsoCode: new ArcFormControl<string>(undefined)
            })
        }),
        companyVatNo: new ArcFormControl<OptionalType<string>>(undefined),
        companyUid: new ArcFormControl<OptionalType<string>>(undefined),
        companyPhoneNumber: new ArcFormControl<OptionalType<string>>(undefined),
        companyFaxNumber: new ArcFormControl<OptionalType<string>>(undefined),
        companyEmail: new ArcFormControl<OptionalType<string>>(undefined, Validators.email),
        companyWebsite: new ArcFormControl<OptionalType<string>>(undefined),
        companyHeadquartersPlace: new ArcFormControl<OptionalType<string>>(undefined),
        companyJurisdictionPlace: new ArcFormControl<OptionalType<string>>(undefined),
        companyCeoName: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        const item = this.item();
        this.formGroup.patchValue({
            ...item,
            companyAddress: {
                street: item.companyStreet,
                streetNumber: item.companyStreetNumber,
                place: {
                    zip: item.companyZip,
                    city: item.companyPlace,
                    countryIsoCode: item.companyCountry
                }
            }
        });
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.getRawValue();
        return {
            companyName: value.companyName,
            companyStreet: value.companyAddress.street,
            companyStreetNumber: value.companyAddress.streetNumber,
            companyZip: value.companyAddress.place.zip,
            companyPlace: value.companyAddress.place.city,
            companyCountry: value.companyAddress.place.countryIsoCode,
            companyVatNo: value.companyVatNo,
            companyUid: value.companyUid,
            companyPhoneNumber: value.companyPhoneNumber,
            companyFaxNumber: value.companyFaxNumber,
            companyEmail: value.companyEmail,
            companyWebsite: value.companyWebsite,
            companyHeadquartersPlace: value.companyHeadquartersPlace,
            companyJurisdictionPlace: value.companyJurisdictionPlace,
            companyCeoName: value.companyCeoName
        };
    }
}
