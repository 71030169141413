<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-button-toggle-group [control]="formGroup.controls.reportType" [items]="reportTypeItems()" />

    <arc-translation-input labelKey="PosReports.Edit.BaseData.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it">
    </arc-translation-input>

    <arc-number-input label="PosReports.Edit.BaseData.NumberOfCopies" [formControl]="formGroup.controls.copies"></arc-number-input>

    @if (formGroup.controls.reportType.value === PosReportTypeEnum.Receipt) {
        <h2>{{'PosReports.Edit.BaseData.PrintConditions' | transloco }}</h2>
        <arc-checkbox labelKey="PosReports.Edit.BaseData.AutoPrint" [control]="formGroup.controls.autoPrint" />
        <arc-checkbox labelKey="PosReports.Edit.BaseData.PrintPerArticle" [control]="formGroup.controls.printPerArticle" />
        <h2>{{'PosReports.Edit.BaseData.ForArticlesWithTags' | transloco }}</h2>
        <arc-chip-selector label="PosReports.Edit.BaseData.ArticleTags" [formControl]="formGroup.controls.tags" [uniqueIdFn]="getTagId"
            [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)">
        </arc-chip-selector>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
            [formControl]="formGroup.controls.transactionTypeId" label="PosReports.Edit.BaseData.TransactionType">
        </arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PaymentTypes" [formControl]="formGroup.controls.paymentTypeId"
            label="PosReports.Edit.BaseData.PaymentType">
        </arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.VoucherTypes" [formControl]="formGroup.controls.voucherTypeId"
            label="PosReports.Edit.BaseData.VoucherType" [requiredPermission]="PermissionsEnum.VoucherTypes">
        </arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches" [formControl]="formGroup.controls.branchId"
            label="PosReports.Edit.BaseData.Branch" [requiredPermission]="PermissionsEnum.Branches">
        </arc-general-data-select>
    
        <p>{{'PosReports.Edit.BaseData.PaymentDebitHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentDebit" [items]="paymentDebitItems" />
    
        <p>{{'PosReports.Edit.BaseData.PaymentRequiresSignatureHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentRequiresSignature"
            [items]="paymentRequiresSignatureItems">
        </arc-button-toggle-group>
    
        <p>{{'PosReports.Edit.BaseData.IsWarrantyHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.isWarranty" [items]="isWarrantyItems" />
    }

    @if (formGroup.controls.reportType.value === PosReportTypeEnum.Order) {
        <h2>{{'PosReports.Edit.BaseData.PrintConditions' | transloco }}</h2>
        <arc-chip-selector label="PosReports.Edit.BaseData.ArticleTags" [formControl]="formGroup.controls.tags" [uniqueIdFn]="getTagId"
            [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)">
        </arc-chip-selector>
        <arc-select [formControl]="formGroup.controls.resourceGroupName" [options]="resourceGroupNameOptions"
            label="PosReports.Edit.BaseData.Resource">
        </arc-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
            [formControl]="formGroup.controls.transactionTypeId" label="PosReports.Edit.BaseData.TransactionType">
        </arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches" [formControl]="formGroup.controls.branchId"
            label="PosReports.Edit.BaseData.Branch">
        </arc-general-data-select>
    }

    @if (!item().isTemplate) {
        <arc-file-upload [arcErrorTooltip]="formGroup.controls.reportBlobData"
            [formControl]="formGroup.controls.fileControl" fileTypes=".rdlx">
        </arc-file-upload>
    }
</form>
