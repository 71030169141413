import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserModel } from '../../../../../models/user.model';
import { UserEditModel } from '../../../../../models/user-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-user-base-data',
    templateUrl: './user-base-data.component.html',
    styleUrl: './user-base-data.component.scss'
})
export class UserBaseDataComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        username: new ArcFormControl('', Validators.required),
        password: new ArcFormControl(''),
        realName: new ArcFormControl<string>('', Validators.required),
        email: new ArcFormControl('', [Validators.required, Validators.email]),
        contactMobile: new ArcFormControl<OptionalType<string>>(undefined),
        contactPhone: new ArcFormControl<OptionalType<string>>(undefined),
        languageId: new ArcFormControl('', Validators.required),
        pin: new ArcFormControl<OptionalType<string>>(undefined),
        userColor: new ArcFormControl<OptionalType<string>>(undefined),
        isLeftHanded: new ArcFormControl(false),
        roleId: new ArcFormControl(0, Validators.required),
        branchId: new ArcFormControl<OptionalType<number>>(undefined),
        supplierId: new ArcFormControl<OptionalType<number>>(undefined),
        sendOrderNotificationEmail: new ArcFormControl<OptionalType<boolean>>(false),
        tourCompleted: new ArcFormControl<OptionalType<boolean>>(false),
        posLayoutId: new ArcFormControl<OptionalType<number>>(undefined),
        regionId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    allowedLanguages: SelectOptionModel<string>[] = [];
    showPasswordClearText = false;

    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));

        if (this.isCreate()) {
            this.formGroup.controls.password.setValidators(Validators.required);
        }
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<UserEditModel> {
        const value = this.formGroup.value;
        return {
            username: value.username,
            password: value.password,
            realName: value.realName,
            email: value.email,
            contactMobile: value.contactMobile,
            contactPhone: value.contactPhone,
            languageId: value.languageId,
            pin: value.pin,
            userColor: value.userColor,
            isLeftHanded: value.isLeftHanded,
            roleId: value.roleId,
            branchId: value.branchId,
            supplierId: value.supplierId,
            sendOrderNotificationEmail: value.sendOrderNotificationEmail,
            tourCompleted: value.tourCompleted,
            posLayoutId: value.posLayoutId,
            regionId: value.regionId
        };
    }
}
