import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { VoucherListModel } from '../../../models/responses/voucher-list.model';
import { VoucherModel } from '../../../models/voucher.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { VouchersStore } from '../../../services/stores/vouchers.store';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { VoucherTypeColumnComponent } from './components/voucher-type-column/voucher-type-column.component';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { VoucherIdQrCodeDetailWidget } from './voucher-detail-widgets/voucher-id-qr-code/voucher-id-qr-code.detail-widget';
import { VoucherCustomerDetailWidget } from './voucher-detail-widgets/voucher-customer/voucher-customer.detail-widget';
import { VoucherTypeDetailWidget } from './voucher-detail-widgets/voucher-type/voucher-type.detail-widget';
import { VoucherCreateDialogComponent } from './voucher-create-dialog/voucher-create-dialog.component';
import { Tools } from '../../../../core/utils/tools';
import { CurrencyPipe } from '../../../../core/pipes/currency.pipe';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-vouchers',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./vouchers.component.scss'],
    providers: [CurrencyPipe]
})
export class VouchersComponent extends PageListViewComponent<VoucherListModel, VoucherModel, VoucherModel> {
    private readonly vouchersStore = inject(VouchersStore);
    private readonly currencyPipe = inject(CurrencyPipe);
    private readonly matDialog = inject(MatDialog);
    private readonly defaultMaxYear = 2100;

    constructor() {
        super('Vouchers');
        this.config = new TableListViewConfigModel<VoucherListModel, VoucherModel, VoucherModel>({
            entityName: 'Vouchers',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Vouchers.List.Voucher',
                    propertyName: 'title',
                    propertyName2: 'voucherId',
                    widthPixels: 236
                }),
                value: new CurrencyColumnModel({
                    columnTitleKey: 'Vouchers.List.Value',
                    propertyName: 'value',
                    widthPixels: 110
                }),
                discount: new StringColumnModel({
                    columnTitleKey: 'Vouchers.List.Discount',
                    propertyName: 'discount',
                    widthPixels: 110,
                    customFormatter: (record, value) => (Tools.Utils.isNumber(value) ? this.currencyPipe.transform(value) : value)
                }),
                issuedFor: new StackedColumnModel({
                    columnTitleKey: 'Vouchers.List.IssuedFor',
                    propertyName: 'issuedFor',
                    propertyName2: 'personNumber',
                    suffixPropertyName: 'companyContactName',
                    widthPixels: 286
                }),
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Vouchers.List.CreatedOn',
                    propertyName: 'createdDate',
                    widthPixels: 126
                }),
                validUntil: new DateColumnModel({
                    columnTitleKey: 'Vouchers.List.ValidUntil',
                    propertyName: 'validThru',
                    widthPixels: 126,
                    customFormatter: (record, value) => new Date(value).getFullYear() === this.defaultMaxYear ? undefined : value
                }),
                type: new CustomColumnModel({
                    columnTitleKey: 'General.Type',
                    propertyName: 'isDiscountVoucher',
                    customComponent: VoucherTypeColumnComponent,
                    widthPixels: 160
                })
            },
            defaultSort: new ColumnSortModel({ column: 'createdDate', direction: SortDirectionEnum.Desc }),
            defaultColumnOrder: ['title', 'value', 'discount', 'issuedFor', 'createdDate', 'validUntil', 'type'],
            availableDetailWidgets: {
                qrCode: new TableListDetailWidgetModel({
                    name: 'Vouchers.Detail.QrCode.ComponentTitle',
                    component: VoucherIdQrCodeDetailWidget
                }),
                customer: new TableListDetailWidgetModel({
                    name: 'Vouchers.Detail.Customer.ComponentTitle',
                    component: VoucherCustomerDetailWidget,
                    requiredPermission: PermissionsEnum.Customers
                }),
                type: new TableListDetailWidgetModel({
                    name: 'Vouchers.Detail.VoucherType.ComponentTitle',
                    component: VoucherTypeDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['qrCode', 'customer', 'type'],
            store: this.vouchersStore,
            handleCreateButtonClick: this.handleCreate.bind(this),
            buttonsVisibility: new ButtonsVisibilityModel({ hasEdit: false })
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService.updateIsHidden(
            ActionButtonsService.deleteButtonKey,
            (btn, data) => !!data && !data.isDiscountVoucher
        );
    }

    handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(VoucherCreateDialogComponent, {
                minHeight: '600px',
                maxHeight: '98svh',
                width: '800px',
                maxWidth: '98vw',
                autoFocus: false,
                disableClose: true
            })
            .afterClosed();
    }
}
