import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ExtensionCardComponent } from './extensions-card/extensions-card.component';
import { ExtensionModel } from '../../../models/extension.model';
import { ExtensionListModel } from '../../../models/responses/extension-list-model';
import { ExtensionsStore } from '../../../services/stores/extensions.store';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { ExtensionsEditBaseDataComponent } from './extension-edit-items/extensions-edit-base-data/extensions-edit-base-data.component';

@Component({
    selector: 'arc-extensions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./extensions.component.scss']
})
export class ExtensionsComponent extends PageListViewComponent<ExtensionListModel> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _showTemplates = false;

    protected readonly _searchRequestService = inject(SearchRequestService);

    private readonly extensionsStore = inject(ExtensionsStore);
    constructor() {
        super('Extensions');

        this.config = new CardListViewConfigModel<ExtensionModel, ExtensionListModel>({
            entityName: 'Extensions',
            store: this.extensionsStore,
            cardComponent: ExtensionCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: currentItem =>
                currentItem.isInstalled && !currentItem.isActive
                    ? ['bg-element-disabled', 'text-on-element-disabled']
                    : ['bg-primary', 'text-on-primary'],
            cardBackgroundColor: currentItem => {
                if (currentItem.isTemplate && !currentItem.isInstalled) {
                    const style = getComputedStyle(document.body);
                    return style.getPropertyValue('--available-extensions');
                } else if (currentItem.isInstalled && !currentItem.isActive) {
                    return 'var(--element-selected)';
                }
                return '';
            },
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Extensions.Edit.BaseData.ComponentTitle', component: ExtensionsEditBaseDataComponent }]
            },
            customActionButtons: [
                new ActionButtonModel({
                    clickFn: (btn: ActionButtonModel) => {
                        this.toggleTemplates();

                        btn.icon = this._showTemplates ? 'edit_off' : 'edit';
                        btn.color = this._showTemplates ? 'accent' : 'primary';

                        this._actionButtonsService.updateBasicConfig(btn);
                    },
                    color: 'primary',
                    icon: 'edit',
                    min: 0,
                    max: 0,
                    isEnabled: true,
                    requiredPermission: PermissionsEnum.Extensions,
                    requiredPermissionType: PermissionTypeEnum.Update
                })
            ],
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false, hasCreate: false })
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService
            .updateIsHidden(ActionButtonsService.editButtonKey, (btn, data) => !!data && (!this._showTemplates || data.isTemplate))
            .updateIsHidden(
                ActionButtonsService.deleteButtonKey,
                (btn, data) => !!data && (!this._showTemplates || (data.isTemplate && !data.isInstalled))
            )
            .updateIsHidden(
                'ExtensionInstallContextAction',
                (btn, data) => !!data && !(this._showTemplates && data.isTemplate && !data.isInstalled)
            )
            .updateIsHidden('ExtensionEditSettingsContextAction', (btn, data) => !!data && !(this._showTemplates && data.isInstalled));
    }

    toggleTemplates(): void {
        this._showTemplates = !this._showTemplates;
        this.extensionsStore.showTemplates = this._showTemplates;

        this._searchRequestService.forceReload();
    }
}
