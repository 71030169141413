import { Component, effect, inject, signal, untracked, WritableSignal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { AccountingExportRecordModel } from '../../../../../models/accounting-export-record.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleAmountFieldEnum } from '../../../../../models/enums/article-amount-field.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { PaymentTypesStore } from '../../../../../services/stores/payment-types.store';
import { PaymentProcessingTypeEnum } from '../../../../../models/enums/payment-processing-type.enum';

@Component({
    selector: 'arc-accounting-export-records-edit-data',
    templateUrl: './accounting-export-records-edit-data.component.html',
    styleUrl: './accounting-export-records-edit-data.component.scss'
})
export class AccountingExportRecordsEditDataComponent extends BaseEditSidebarItemComponent<AccountingExportRecordModel> {
    static isPaymentSignal = 'isPayment';

    override formGroup = new FormGroup({
        isPayment: new ArcFormControl(false),
        transactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        articleAmountField: new ArcFormControl<OptionalType<ArticleAmountFieldEnum>>(undefined, Validators.required),
        paymentTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        cardType: new ArcFormControl<OptionalType<string>>(undefined, [Validators.minLength(4), Validators.maxLength(4)]),
        exclCardType: new ArcFormControl<OptionalType<string>>(undefined, [Validators.minLength(4), Validators.maxLength(4)])
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    ArticleAmountFieldEnum = ArticleAmountFieldEnum;

    isVATincl = signal(false);
    isPaymentTypeEft = signal(false);

    isPayment: WritableSignal<OptionalType<boolean>>;
    articleAmountOptions: SelectOptionModel<string>[] = [
        {
            label: 'ExtAccountingExport.Edit.Data.SellingPrice',
            value: ArticleAmountFieldEnum.Price
        },
        {
            label: 'ExtAccountingExport.Edit.Data.NetPrice',
            value: ArticleAmountFieldEnum.NetPrice
        },
        {
            label: 'ExtAccountingExport.Edit.Data.BuyingPrice',
            value: ArticleAmountFieldEnum.BuyingPrice
        },
        {
            label: 'ExtAccountingExport.Edit.Data.Discount',
            value: ArticleAmountFieldEnum.Discount
        },
        {
            label: 'ExtAccountingExport.Edit.Data.NetDiscount',
            value: ArticleAmountFieldEnum.NetDiscount
        }
    ];

    private readonly sharedDataService = inject(SharedDataService);
    private readonly paymentTypeStore = inject(PaymentTypesStore);

    constructor() {
        super();
        this.isPayment = this.sharedDataService.getOrCreateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal);
        this.formGroup.controls.paymentTypeId.valueChanges.subscribe(value => {
            if (value) {
                this.paymentTypeStore.get(value).subscribe(result => {
                    if (result) {
                        const isNewValueEft = result.value?.paymentProcessing === PaymentProcessingTypeEnum.Eft;
                        this.isPaymentTypeEft.set(isNewValueEft);
                        if (!isNewValueEft) {
                            // set values to undefined if paymentType isnt EFT otherwise the records will be wrong
                            this.formGroup.controls.cardType.setValue(undefined);
                            this.formGroup.controls.exclCardType.setValue(undefined);
                        }
                    }
                });
            } else {
                this.isPaymentTypeEft.set(false);
            }
        });

        effect(() => {
            const isPayment = this.isPayment();
            untracked(() => {
                // reset values of other type
                this.formGroup.controls.isPayment.setValue(isPayment!);
                if (isPayment) {
                    this.formGroup.controls.transactionTypeId.disable();
                    this.formGroup.controls.articleAmountField.disable();
                    this.formGroup.controls.paymentTypeId.enable();
                    this.formGroup.controls.cardType.enable();
                    this.formGroup.controls.exclCardType.enable();
                } else {
                    this.formGroup.controls.transactionTypeId.enable();
                    this.formGroup.controls.articleAmountField.enable();
                    this.formGroup.controls.paymentTypeId.disable();
                    this.formGroup.controls.cardType.disable();
                    this.formGroup.controls.exclCardType.disable();
                }
            });
        });
    }

    onItemSet(): void {
        this.sharedDataService.setOrUpdateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal, this.item().isPayment);
        this.formGroup.patchValue(this.item());
    }

    mapArticleAmountOnSet(articleAmount: ArticleAmountFieldEnum): ArticleAmountFieldEnum {
        if (articleAmount === ArticleAmountFieldEnum.Price) {
            this.isVATincl.set(true);
            return ArticleAmountFieldEnum.NetPrice;
        }
        if (articleAmount === ArticleAmountFieldEnum.Discount) {
            this.isVATincl.set(true);
            return ArticleAmountFieldEnum.NetDiscount;
        }

        return articleAmount;
    }

    mapArticleAmountOnSave(articleAmount?: ArticleAmountFieldEnum): OptionalType<ArticleAmountFieldEnum> {
        if (articleAmount === ArticleAmountFieldEnum.NetPrice && this.isVATincl()) {
            return ArticleAmountFieldEnum.Price;
        }
        if (articleAmount === ArticleAmountFieldEnum.NetDiscount && this.isVATincl()) {
            return ArticleAmountFieldEnum.Discount;
        }

        return articleAmount;
    }

    override prepareSaveModel(): Partial<AccountingExportRecordModel> {
        const value = this.formGroup.value;

        if (value.isPayment) {
            return {
                isPayment: value.isPayment,
                paymentTypeId: value.paymentTypeId,
                cardType: !!value.paymentTypeId ? value.cardType?.toString() : undefined,
                exclCardType: !!value.paymentTypeId ? value.exclCardType?.toString() : undefined
            };
        } else {
            return {
                isPayment: value.isPayment,
                transactionTypeId: value.transactionTypeId,
                articleAmountField: value.articleAmountField
            };
        }
    }

    changeIsPayment(event: MatButtonToggleChange): void {
        this.sharedDataService.setOrUpdateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal, event.value);
    }
}
