<div class="grid grid-cols-12 gap-4">

    <form [formGroup]="formGroup" clasS="¨h-full col-span-5 smd:col-span-full flex flex-col gap-4">
        <arc-textarea [formControl]="formGroup.controls.remarks" label="WarehouseTransfers.Edit.BaseData.Remarks" class="w-full h-full">
        </arc-textarea>
    </form>

    <div class="col-span-7 smd:col-span-full h-fit" [ngClass]="statusBgColorClass">
        <div class="px-8 py-4 grid grid-cols-3 gap-16">
            <div>
                <p class="text-sm">{{ 'WarehouseTransfers.Edit.BaseData.DateCreated' | transloco }}</p>
                <p class="text-xl">{{ item().dateRequested | arcDate }}</p>
            </div>
            <div>
                <p class="text-sm">{{ 'WarehouseTransfers.Edit.BaseData.DateDelivered' | transloco }}</p>
                <p class="text-xl">{{ !!item().dateDelivered ? (item().dateDelivered | arcDate) : '-' }}</p>
            </div>
            <div>
                <p class="text-sm">{{ 'WarehouseTransfers.Edit.BaseData.DateReceived' | transloco }}</p>
                <p class="text-xl">{{ !!item().dateReceived ? (item().dateReceived | arcDate) : '-' }}</p>
            </div>

            <div>
                <p class="text-sm">{{ 'WarehouseTransfers.Edit.BaseData.RequestedArticlesCount' | transloco }}</p>
                <p class="text-xl">{{ requestedArticlesCount() ?? '-' }}</p>
            </div>
            <div class="col-span-2">
                <p class="text-sm">{{ 'WarehouseTransfers.Edit.BaseData.ReceivedArticlesCount' | transloco }}</p>
                <p class="text-xl">{{ receivedArticlesCount() ?? '-' }}</p>
            </div>
        </div>
    </div>
</div>
