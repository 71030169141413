<h1 mat-dialog-title>
    {{ 'Orders.Create.ComponentTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="pt-2 grow w-full flex flex-col gap-4">
        <arc-quick-search [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig" [formControl]="formGroup.controls.supplierId"
            label="Orders.Create.Supplier">
        </arc-quick-search>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.BranchesWithOrderStore" [formControl]="formGroup.controls.branchId"
            label="Orders.Create.Branch">
        </arc-general-data-select>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">
        {{ 'General.Actions.Cancel' | transloco }}
    </button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating" (clicked)="create()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>
