<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <a mat-icon-button [href]="qrCodeDownloadLink()" download="qrcode">
            <mat-icon>download</mat-icon>
        </a>
    </header>
    <div class="h-full w-full">
        <div class="flex items-center flex-col">
            <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="listModel().voucherId" [width]="200" [margin]="1" />
            <b>{{ listModel().voucherId }}</b>
        </div>
    </div>
</div>
