<div class="w-full h-full grid justify-stretch">
    <div class="mt-4">
        <span class="text-on-app-light text-sm">
            {{ 'Articles.Detail.Variants.Sizes' | transloco }}
        </span>

        <mat-chip-set>
            @for (size of selectedSizes(); track $index) {
                <mat-chip>{{ size.description }}</mat-chip>
            }
        </mat-chip-set>
    </div>

    <div>
        <span class="text-on-app-light text-sm">
            {{ 'Articles.Detail.Variants.Colors' | transloco }}
        </span>

        <mat-chip-set>
            @for (color of selectedColors(); track $index) {
                <mat-chip [style.background-color]="color | transform : getColorValue">
                    <span [style.color]="color | transform : getForeColorValue">{{ color.description }}</span>
                </mat-chip>
            }
        </mat-chip-set>
    </div>
</div>
