import { Component, input, output, effect } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { SelectOptionModel } from '../../../core/models/select-option.model';
import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectComponent
        }
    ]
})
export class SelectComponent<T> extends BaseControlValueAccessor<T | T[]> {
    readonly options = input.required<SelectOptionModel<T>[]>();
    readonly areOptionsTranslated = input(false);
    readonly isMultiselect = input(false);
    readonly isLoading = input(false);

    readonly optionSelected = output<T | T[]>();

    constructor() {
        super();

        // effect triggers when options() changes and checks if current value is still valid
        effect(() => {
            // check if the current value is still valid
            if (!this.isValueValid(this.value)) {
                this.writeValue(undefined);
            }
        });
    }

    /**
     * Custom comparator which tries to cast the option value to a number so that it can be compared to the value
     * when it is a number. This is necessary, because when the values in the options and the value in the form
     * are not the same type the comparison fails. It also handles empty string as undefined.
     */
    customComparator(optionValue: any, formValue: any): boolean {
        // eslint-disable-next-line no-null/no-null
        if (formValue === null && optionValue !== null) {
            return false;
        }

        const isNumber = !isNaN(Number(optionValue));
        const optionValueCasted = isNumber ? Number(optionValue) : optionValue;
        const formValueCasted = isNumber ? Number(formValue) : formValue;

        return optionValueCasted === formValueCasted || (optionValueCasted === '' && formValueCasted === undefined);
    }

    handleSelect(change: MatSelectChange): void {
        this.valueChanged(change.value);
        this.optionSelected.emit(change.value);
    }

    /**
     * Only allow values that are in {@link options}.
     * Or allow all values if data is still empty. Once data is filled, this will be checked again.
     */
    protected override isValueValid(value?: any): boolean {
        if (value === undefined || this.options().length === 0) {
            return true;
        }

        // value could be array if it's multiSelect
        if (Array.isArray(value)) {
            for (const val of value) {
                if (!this.options().some(d => this.customComparator(d.value, val))) {
                    return false;
                }
            }
            return true;
        }

        return this.options().some(d => this.customComparator(d.value, value));
    }
}
