import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { IdentificationModel } from '../../../../../models/identification.model';
import { ButtonToggleColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-toggle-column.model';
import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { MainArticlesStore } from '../../../../../services/stores/main-articles.store';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { UserService } from '../../../../../../core/services/user.service';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-edit-identifications',
    templateUrl: './article-edit-identifications.component.html',
    styleUrls: ['./article-edit-identifications.component.scss']
})
export class ArticleEditIdentificationsComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    override formGroup = new FormGroup({
        identifications: new ArcFormControl<IdentificationModel[]>([])
    });

    identificationsTableConfig?: EditableTableConfigModel<IdentificationModel>;

    private readonly userService = inject(UserService);
    private readonly mainArticlesStore = inject(MainArticlesStore);

    onItemSet(): void {
        this.formGroup.patchValue({ identifications: this.item().variants.length > 0 ? [] : this.item().identifications });
        this.identificationsTableConfig = new EditableTableConfigModel<IdentificationModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    identification: new ArcFormControl('', [Validators.required, Validators.maxLength(20)]),
                    quantity: new ArcFormControl<OptionalType<number>>(
                        undefined,
                        CustomValidators.number({ min: 1, max: 10_000, isInteger: true })
                    ),
                    suppressPrint: new ArcFormControl(false),
                    discount: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number()),
                    isDiscountPercentage: new ArcFormControl(false)
                }),
            rowHeightPx: 63,
            shouldHideAddButton: this.item().variants.length > 0,
            columns: [
                new StringColumnModel({
                    propertyName: 'identification',
                    isEditable: true,
                    widthPixels: 200,
                    columnTitleKey: 'Articles.Edit.Identifications.Identification',
                    action: this.generateNewIdentificationCallback.bind(this),
                    actionIcon: 'rotate_left'
                }),
                new NumberColumnModel({
                    columnTitleKey: 'Articles.Edit.Identifications.IdentificationDiscount',
                    propertyName: 'discount',
                    widthPixels: 120,
                    isEditable: true,
                    unit: record => record.isDiscountPercentage ? ' % ' : this.getCurrencyText(),
                    isUnitTranslated: true
                }),
                new ButtonToggleColumnModel({
                    propertyName: 'isDiscountPercentage',
                    isEditable: true,
                    widthPixels: 70,
                    options: [
                        { key: true, value: '%' },
                        { key: false, value: this.getCurrencyText() }
                    ]
                }),
                new NumberColumnModel({
                    propertyName: 'quantity',
                    isEditable: true,
                    decimalPlaces: 0,
                    widthPixels: 32,
                    columnTitleKey: 'Articles.Edit.Identifications.IdentificationQuantity'
                }),
                new CheckboxColumnModel({
                    propertyName: 'suppressPrint',
                    isEditable: true,
                    widthPixels: 32,
                    columnTitleKey: 'Articles.Edit.Identifications.IdentificationSuppressPrint'
                })
            ]
        });
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return {
            identifications: this.formGroup.getRawValue().identifications
        };
    }

    override onBrokenRulesLoad(): string[] {
        return this.identificationsTableConfig?.setBrokenRulesOnFormGroups(this.brokenRules) ?? [];
    }

    private generateNewIdentificationCallback(control: FormControl): Observable<any> {
        return this.mainArticlesStore.generateNewArticlesIdentifications().pipe(
            tap(result => {
                if ((result.value?.length || 0) > 0) {
                    control.setValue(result.value![0]);
                }
            })
        );
    }

    private getCurrencyText(): string {
        return this.userService.getUserInfo()?.currencyIsoCode ?? 'CHF';
    }
}
