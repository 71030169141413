import { Injectable, inject } from '@angular/core';
import { TranslocoService, HashMap } from '@ngneat/transloco';
import { registerLocaleData } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import localeBritishEnglish from '@angular/common/locales/en-GB';
import localeGermanSwiss from '@angular/common/locales/de-CH';
import localeFrenchSwiss from '@angular/common/locales/fr-CH';
import localeItalianSwiss from '@angular/common/locales/it-CH';
import { Observable, firstValueFrom } from 'rxjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de-ch';

import { TranslationLanguageModel } from '../models/translation-language.model';
import { SettingsStorage } from './storages/settings.storage';
import { UsersStore } from '../../app/services/stores/users.store';
import dayjs from '../utils/dayjs';
import { DayjsDateAdapter } from '../utils/dayjs-date-adapter';
import { SecuritySessionStorage } from './storages/security.session-storage';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    readonly availableLanguages: TranslationLanguageModel[] = [
        {
            code: 'de',
            name: 'Deutsch',
            culture: 'de-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i',
            dateFormatFlatPickerNoTime: 'd.m.Y'
        },
        {
            code: 'en',
            name: 'English',
            culture: 'en',
            dayjsLocaleCode: 'en-gb',
            dateFormat: 'DD/MM/YYYY',
            dateFormatFlatPicker: 'd/m/Y H:i',
            dateFormatFlatPickerNoTime: 'd/m/Y'
        },
        {
            code: 'fr',
            name: 'Français',
            culture: 'fr-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i',
            dateFormatFlatPickerNoTime: 'd.m.Y'
        },
        {
            code: 'it',
            name: 'Italiano',
            culture: 'it-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i',
            dateFormatFlatPickerNoTime: 'd.m.Y'
        }
    ];

    get allowedLanguages(): TranslationLanguageModel[] {
        return this.availableLanguages;
    }
    /**
     * Retrieves current selected language.
     */
    get current(): TranslationLanguageModel {
        let culture = this.currentLanguage.culture;

        if (this.currentUser?.currencyIsoCode === 'EUR' && culture === 'de-CH') {
            culture = 'de-DE';
        }

        return { ...this.currentLanguage, culture };
    }

    private currentLanguage: TranslationLanguageModel;
    private defaultLanguageCode = 'de';
    private get currentLangSet(): TranslationLanguageModel[] {
        return this.allowedLanguages.length > 0 ? this.allowedLanguages : this.availableLanguages;
    }

    private readonly usersStore = inject(UsersStore);
    private readonly userService = inject(UserService);
    private readonly translocoService = inject(TranslocoService);
    private readonly securitySession = inject(SecuritySessionStorage);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly dateAdapter = inject(DateAdapter) as DayjsDateAdapter;
    private readonly currentUser = this.userService.getUserInfo();

    constructor() {
        let userSelectedLangCode: string | undefined;

        if (!!this.currentUser) {
            userSelectedLangCode = this.currentUser.languageId;
        } else if (this.settingsStorage.getNotLoggedLanguageCode()) {
            userSelectedLangCode = this.settingsStorage.getNotLoggedLanguageCode();
        }

        if (!userSelectedLangCode) {
            userSelectedLangCode = this.defaultLanguageCode;
        }

        this.currentLanguage = this.currentLangSet.find(l => l.code === userSelectedLangCode)!;

        this.translocoService.setActiveLang(this.currentLanguage.code);
        this.setup();
        this.setDateAdapterLocale();
    }

    setup(): void {
        registerLocaleData(localeGermanSwiss, 'de');
        registerLocaleData(localeBritishEnglish, 'en');
        registerLocaleData(localeFrenchSwiss, 'fr');
        registerLocaleData(localeItalianSwiss, 'it');
    }

    switch(code: string, reloadPage = true): void {
        if (this.currentLanguage?.code === code) {
            return;
        }

        let newLang = this.currentLangSet.find(l => l.code === code);

        if (!newLang) {
            newLang = this.currentLangSet.find(l => l.code === navigator.language) ||
                this.currentLangSet.find(l => l.code === this.defaultLanguageCode);
            code = newLang!.code;
        }

        this.translocoService.setActiveLang(code);

        this.currentLanguage = newLang!;
        const userInfo = this.userService.getUserInfo();

        if (!!userInfo) {
            userInfo.languageId = code;
            this.securitySession.saveUserInfo(userInfo);
            this.usersStore.changeLanguage(userInfo.id.toString(), code).subscribe();
        } else {
            this.settingsStorage.saveNotLoggedLanguageCode(code);
        }

        if (reloadPage) {
            this.reloadPage();
        }
    }

    /**
     * Retrieves a translated text based on the provided key.
     * @param key Translation text's key.
     * @param params Translation parameters
     */
    getText<T = string>(key: string, params?: HashMap): T {
        return this.translocoService.translate<T>(key, params);
    }

    /**
     * Retrieves an Observable for a translated text based on the provided key.
     * @param key Translation text's key.
     * @param params Translation parameters
     */
    getTextObservable<T = string>(key: string, params?: HashMap): Observable<T> {
        return this.translocoService.selectTranslate<T>(key, params);
    }

    async getTextAsync<T = string>(key: string, params?: HashMap): Promise<T> {
        return firstValueFrom(this.getTextObservable(key, params));
    }

    private setDateAdapterLocale(): void {
        this.dateAdapter.setLocale(this.currentLanguage.culture);
        dayjs.locale(this.currentLanguage.dayjsLocaleCode);
    }

    private reloadPage(): void {
        window.location.href = window.location.href.split('?')[0];
    }
}
