import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { ExtensionListModel } from '../../../../models/responses/extension-list-model';

@Component({
    selector: 'arc-extensions-card',
    templateUrl: './extensions-card.component.html',
    styleUrls: ['./extensions-card.component.scss']
})
export class ExtensionCardComponent extends BaseCardComponent<ExtensionListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
