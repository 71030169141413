import { Component, OnInit, inject, signal } from '@angular/core';

import { BaseDetailWidget } from '../../../../../components/abstractions/base.detail-widget';
import { ReservationListModel } from '../../../../models/responses/reservation-list.model';
import { ReservationDetailsModel } from '../../../../models/reservation-details.model';
import { StaticTableConfigModel } from '../../../../../components/list-views/static-table/models/static-table-config.model';
import { ReservationArticleListModel } from '../../../../models/responses/reservation-articles-list.model';
import { ReservationArticlesStore } from '../../../../services/stores/reservation-articles.store';
import { SearchRequestModel } from '../../../../models/requests/search-request.model';
import { ColumnFilterModel } from '../../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../core/models/enums/filter-item-type.enum';
import { ColumnSortModel } from '../../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../../components/dynamic-table/models/column-types/currency-column.model';

@Component({
    selector: 'arc-reservation-articles',
    templateUrl: './reservation-articles.detail-widget.html',
    styleUrl: './reservation-articles.detail-widget.scss'
})
export class ReservationArticlesDetailWidget extends BaseDetailWidget<ReservationListModel, ReservationDetailsModel> implements OnInit {
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 5,
        availableColumns: [
            new StringColumnModel({
                columnTitleKey: 'ReservationArticles.List.Quantity',
                propertyName: 'quantity',
                widthPixels: 40,
                isCompact: true
            }),
            new StringColumnModel({
                columnTitleKey: 'ReservationArticles.List.Article',
                propertyName: 'title',
                widthPixels: 180,
                isCompact: true
            }),
            new CurrencyColumnModel({
                columnTitleKey: 'ReservationArticles.List.Discount',
                propertyName: 'discount',
                widthPixels: 60,
                isCompact: true
            }),
            new CurrencyColumnModel({
                columnTitleKey: 'ReservationArticles.List.Total',
                propertyName: 'total',
                widthPixels: 60,
                isCompact: true
            })
        ]
    };
    readonly isLoading = signal(true);
    readonly staticData = signal<ReservationArticleListModel[]>([]);

    private readonly transactionArticlesStore = inject(ReservationArticlesStore);

    ngOnInit(): void {
        this.transactionArticlesStore
            .search(
                new SearchRequestModel({
                    pageSize: 999,
                    filters: [
                        new ColumnFilterModel({
                            column: 'ReservationId',
                            values: [this.listModel().id],
                            comparisonOperator: ComparisonOperatorsEnum.Equal,
                            dataType: FilterItemTypeEnum.Number
                        })
                    ],
                    sortings: [
                        new ColumnSortModel({
                            column: 'Sort',
                            direction: SortDirectionEnum.Asc
                        })
                    ]
                })
            )
            .subscribe(r => {
                this.staticData.set(r.value?.records || []);
                this.isLoading.set(false);
            });
    }
}
