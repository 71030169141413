import { Component, computed, inject, input, output, signal } from '@angular/core';

import { PosLayoutGroupModel } from '../../../../../models/pos-layout-group.model';
import { PosLayoutGroupCommandGridModel } from '../../../../../models/responses/pos-layout-group-command-grid.model';
import { PosLayoutGroupCommandsStore } from '../../../../../services/stores/pos-layout-group-commands.store';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-pos-layout-edit-command-cell',
    templateUrl: './pos-layout-edit-command-cell.component.html',
    styleUrl: './pos-layout-edit-command-cell.component.scss'
})
export class PosLayoutEditCommandCellComponent {
    posLayoutGroup = input.required<PosLayoutGroupModel>();
    posLayoutGroupCommand = input.required<PosLayoutGroupCommandGridModel>();

    readonly edit = output<void>();
    readonly remove = output<void>();
    readonly reload = output<void>();

    canExpand = computed(() => {
        const posLayoutGroupCommand = this.posLayoutGroupCommand();
        const posLayoutGroup = this.posLayoutGroup();

        if (posLayoutGroupCommand.position % posLayoutGroup.columns === 0) {
            // command is on the edge of the row
            return false;
        }

        const nextCommand = posLayoutGroup.posLayoutGroupCommands.find(c => c.position === posLayoutGroupCommand.position + 1);

        // can only be expanded if there is no next command
        return !nextCommand;
    });

    imageSrc = computed(() => {
        const command = this.posLayoutGroupCommand();
        if (!command.image) {
            return undefined;
        }

        return Tools.Utils.getBase64ImageSrc(command.image, 'png');
    });

    private readonly imageSize = signal({ width: 0, height: 0 });

    private readonly posLayoutGroupCommandsStore = inject(PosLayoutGroupCommandsStore);

    setIsDoubleSize(isDoubleSize: boolean): void {
        this.posLayoutGroupCommandsStore.setDoubleSize(this.posLayoutGroupCommand().id, isDoubleSize).subscribe(() => {
            this.reload.emit();
        });
    }
}
