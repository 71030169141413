<h1 mat-dialog-title>
    {{ 'Inventories.Create.ComponentTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="pt-2 grow w-full flex flex-col gap-4">
        <arc-input [control]="formGroup.controls.title" labelKey="Inventories.Create.Title"></arc-input>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Stores" [formControl]="formGroup.controls.storeId"
            label="Inventories.Create.Store">
        </arc-general-data-select>
        <mat-radio-group [formControl]="formGroup.controls.inventoryType" class="flex flex-col w-full gap-4">
            <mat-radio-button [value]="InventoryTypeEnum.Complete">
                <div class="font-semibold"> {{'Enums.InventoryTypeEnum.Complete' | transloco}}</div>
                <div> {{'Enums.InventoryTypeEnum.CompleteDescription' | transloco}}</div>
            </mat-radio-button>
            <mat-radio-button [value]="InventoryTypeEnum.Partial">
                <div class="font-semibold"> {{'Enums.InventoryTypeEnum.Partial' | transloco}}</div>
                <div> {{'Enums.InventoryTypeEnum.PartialDescription' | transloco}}</div>
            </mat-radio-button>
            <mat-radio-button [value]="InventoryTypeEnum.Continuous">
                <div class="font-semibold"> {{'Enums.InventoryTypeEnum.Continuous'| transloco}}</div>
                <div> {{'Enums.InventoryTypeEnum.ContinuousDescription' | transloco}}</div>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">
        {{ 'General.Actions.Cancel' | transloco }}
    </button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating" (clicked)="create()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>
