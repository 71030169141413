import { AfterViewInit, Component, computed, HostListener, inject, input, output, signal } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

import { SubMenuItemModel } from '../../../../../core/models/sub-menu-item.model';
import { BaseComponent } from '../../../../abstractions/base.component';
import { RouteService } from '../../../../../core/services/route.service';
import { FavouritesService } from '../../../../../core/services/favourites.service';
import { PermissionService } from '../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../core/models/enums/permission-type.enum';
import { AuthPermissionModel } from '../../../../../app/models/auth-permission.model';
import { OptionalType } from '../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-left-sidebar-item',
    templateUrl: './left-sidebar-item.component.html',
    styleUrl: './left-sidebar-item.component.scss'
})
export class LeftSidebarItemComponent extends BaseComponent implements AfterViewInit {
    readonly menuItem = input.required<SubMenuItemModel>();
    readonly favouriteIdChanged = output<OptionalType<number>>();
    readonly missingPermission = computed(() => {
        const permission = this.menuItem().permission;
        return permission === true ? undefined : permission;
    });

    readonly isCurrentRoute = signal(false);
    readonly canHover = signal(true);
    readonly isFavouriteButtonVisible = signal(false);
    readonly isFavouriteButtonLoading = signal(false);
    readonly favoritesPermission: true | AuthPermissionModel ;

    readonly routerLinkActiveOptions: IsActiveMatchOptions = {
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'exact',
        paths: 'exact'
    };

    private hoverTimeout?: NodeJS.Timeout;

    private readonly permissionService = inject(PermissionService);
    private readonly routeService = inject(RouteService);
    private readonly favouritesService = inject(FavouritesService);
    private readonly favouriteButtonShowDelayMs = 500;

    constructor() {
        super();

        this.favoritesPermission = this.permissionService.hasPermission(PermissionsEnum.Favourites, PermissionTypeEnum.Create);

        const routerEventsSub = this.routeService.routerEvent$.subscribe(() => {
            this.isCurrentRoute.set(this.routeService.isActiveRoute(this.menuItem().path));
        });
        this.addSubscriptions(routerEventsSub);
    }

    @HostListener('mouseenter')
    onHoverStart(): void {
        if (!!this.hoverTimeout) {
            clearTimeout(this.hoverTimeout);
        }
        this.hoverTimeout = setTimeout(() => (this.isFavouriteButtonVisible.set(true)), this.favouriteButtonShowDelayMs);
    }

    @HostListener('mouseleave')
    onHoverEnd(): void {
        this.isFavouriteButtonVisible.set(false);
        if (!!this.hoverTimeout) {
            clearTimeout(this.hoverTimeout);
        }
    }

    ngAfterViewInit(): void {
        this.canHover.set(matchMedia('(hover: hover)').matches);
    }

    addOrRemoveMenuFavourites(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        if (this.menuItem().favouriteId !== undefined) {
            this.removeMenuFromFavourites();
        } else {
            this.addMenuToFavourites();
        }
    }

    showUpgradeModal(permission: AuthPermissionModel): void {
        this.permissionService.showUpgradeModal(permission);
    }

    private removeMenuFromFavourites(): void {
        this.isFavouriteButtonLoading.set(true);
        this.favouritesService
            .remove(this.menuItem().favouriteId ?? 0)
            .subscribe()
            .add(() => {
                this.isFavouriteButtonLoading.set(false);
                this.favouriteIdChanged.emit(undefined);
            });
    }

    private addMenuToFavourites(): void {
        this.isFavouriteButtonLoading.set(true);
        this.favouritesService
            .add({ id: 0, title: this.menuItem().labelKey, path: this.menuItem().path })
            .subscribe(id => {
                this.favouriteIdChanged.emit(id);
            })
            .add(() => {
                this.isFavouriteButtonLoading.set(false);
            });
    }
}
