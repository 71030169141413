import { inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { HasUnsavedChanges } from '../../abstractions/has-unsaved-changes';
import { AlertService } from '../alert.service';

/**
 * Guard to help display unsaved changes dialog when using full screen edits e.g. MyProfile
 * https://medium.com/@aregawihaileyesus/block-leaving-the-route-and-reload-the-page-if-there-are-unsaved-drafts-33102edf6d7d
 */
@Injectable({
    providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {
    private readonly alertService = inject(AlertService);

    canDeactivate(component: HasUnsavedChanges): Observable<boolean> | boolean {
        if (component.hasUnsavedChanges()) {
            // Prompt the user to confirm leaving the page or perform other checks
            const sub = new Subject<boolean>();
            this.alertService.showConfirm('General.Alert.UnsavedChanges', undefined, (isConfirmed) =>{
                sub.next(isConfirmed);
                sub.complete();
            });
            return sub;
        }
        return true;
    }
}
