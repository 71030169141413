<form [formGroup]="formGroup">
    <div class="flex flex-col gap-4">
        <arc-translation-input translationDialogTitleKey="General.Actions.TranslateTitle" labelKey="ArticleGroups.Edit.BaseData.Name"
            [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
            [controlIt]="formGroup.controls.title_it">
        </arc-translation-input>
        <arc-input labelKey="ArticleGroups.Edit.BaseData.ArticleGroupNumber" [control]="formGroup.controls.articleGroupNumber"></arc-input>
        <arc-checkbox labelKey="ArticleGroups.Edit.BaseData.IsInoperative" [control]="formGroup.controls.isInoperative"></arc-checkbox>
    </div>
</form>
