import { Observable, from, map } from 'rxjs';
import Big from 'big.js';

/**
 * Utils.
 */
export class Utils {
    /**
     * Checks whether an item exists or not.
     * @param value Item to be checked.
     */
    static isDefined(value: any): boolean {
        // eslint-disable-next-line no-null/no-null
        return value !== undefined && value !== null;
    }

    /**
     * Checks whether an item is of type object.
     * @param value Item to be checked.
     */
    static isObject(value: any): boolean {
        return typeof value === 'object' && Utils.isDefined(value);
    }

    /**
     * Checks whether an item is of type Promise.
     * @param value Item to be checked.
     */
    static isPromise(value: any): boolean {
        return value instanceof Promise;
    }

    /**
     * Checks whether an item is of type function.
     * @param value Item to be checked.
     */
    static isFunction(value: any): boolean {
        return value instanceof Function;
    }

    /**
     * Checks whether two items or values are equal.
     *
     * Two items or values are considered equal, if at least one of these is true:
     *
     * * Return true for the `===` comparison.
     * * Are of the same type and all their properties, if any, are equal as well compared with `areEqual`.
     *
     * @param obj1 Object or value to be compared.
     * @param obj2 Object or value to be compared.
     * @param propertiesToSkip If an object, sets the properties that should not be checked. If not set, all properties will be checked.
     * @param emptyNullableAndMissingAreEquals If true, the comparison between a nullable property (null, undefined) and one that is empty
     * ('') or not existing in the model will be considered equal.
     */
    static areEqual(obj1: any, obj2: any, propertiesToSkip: string[] = [], emptyNullableAndMissingAreEquals = false): boolean {
        if (obj1 === obj2) {
            return true;
        }

        // eslint-disable-next-line no-null/no-null
        if (obj1 === null || obj2 === null) {
            return false;
        }

        if (obj1 !== obj1 && obj2 !== obj2) {
            return true; // NaN === NaN
        }

        if (obj1 instanceof Date && obj2 instanceof Date) {
            return obj1.getTime() === obj2.getTime();
        }

        const t1 = typeof obj1;
        const t2 = typeof obj2;

        if (t1 === t2 && t1 === 'object') {
            if (Array.isArray(obj1)) {
                if (!Array.isArray(obj2)) {
                    return false;
                }

                const length = obj1.length;

                if (length === obj2.length) {
                    for (let key = 0; key < length; key++) {
                        if (!Utils.areEqual(obj1[key], obj2[key], propertiesToSkip, emptyNullableAndMissingAreEquals)) {
                            return false;
                        }
                    }

                    return true;
                }
            } else {
                if (Array.isArray(obj2)) {
                    return false;
                }

                // eslint-disable-next-line no-null/no-null
                const keySet = Object.create(null);

                for (const obj1Key of Object.keys(obj1)) {
                    if (propertiesToSkip.includes(obj1Key)) {
                        continue;
                    }

                    const areDifferent = !Utils.areEqual(
                        obj1[obj1Key],
                        obj2[obj1Key],
                        typeof obj1[obj1Key] === 'object' || Array.isArray(obj1)
                            // +1 to account for the dot (customer.name)
                            ? propertiesToSkip.filter(p => p.startsWith(obj1Key)).map(p => p.substring(obj1Key.length + 1))
                            : propertiesToSkip,
                        emptyNullableAndMissingAreEquals
                    );

                    if (areDifferent && (!!obj1[obj1Key] || !!obj2[obj1Key] || !emptyNullableAndMissingAreEquals)) {
                        return false;
                    }

                    keySet[obj1Key] = true;
                }

                for (const key in obj2) {
                    if (!(key in keySet) && !propertiesToSkip.includes(key)
                        && (
                            (!emptyNullableAndMissingAreEquals && typeof obj2[key] !== 'undefined')
                            || (emptyNullableAndMissingAreEquals && !!obj2[key])
                        )
                    ) {
                        return false;
                    }
                }

                return true;
            }
        }

        return false;
    }

    /**
     * Generates a new GUID.
     */
    static newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (ch) => {
            const random = Math.random() * 16 | 0;
            const val = ch === 'x' ? random : (random & 0x3 | 0x8);

            return val.toString(16);
        });
    }

    /**
     * Saves a file locally.
     * @param blob File as blob.
     * @param name File name.
     * @param openInPopup Should open popup?
     */
    static saveFile(blob: Blob, name: string, openInPopup = false): void {
        const url = window.URL.createObjectURL(blob);

        if (openInPopup) {
            window.open(url, 'popup');
            return;
        }

        const linkElement = document.createElement('a');

        document.body.appendChild(linkElement);
        linkElement.setAttribute('style', 'display: none');

        linkElement.href = url;
        linkElement.download = name;

        linkElement.click();
        window.URL.revokeObjectURL(url);
        linkElement.remove();
    }

    /**
     * Converts an array buffer to a base64 string
     * @param arrayBufferPromise promise of array buffer to convert
     * @returns a base64 string observable
     */
    static arrayBufferToBase64(arrayBufferPromise: Promise<ArrayBuffer>): Observable<string> {
        return from(arrayBufferPromise).pipe(
            map(result => {
                let binary = '';
                const bytes = new Uint8Array(result);
                for (let i = 0; i < bytes.byteLength; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            })
        );
    }

    /**
     * Converts a blob to a base64 string
     * @param blob blob to convert
     * @returns a base64 string observable
     */
    static blobToBase64(blob: Blob): Observable<string> {
        return this.arrayBufferToBase64(blob.arrayBuffer());
    }

    static base64ToByteArray(base64Data: string): Uint8Array {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }

    /**
     * Converts an object to a FormData
     * @param obj object to convert
     * @returns a FormData
     */
    static objectToFormData(obj: object): FormData {
        const formData = new FormData();
        Object.keys(obj).forEach(key => formData.append(key, obj[key as keyof typeof obj]));
        return formData;
    }

    /**
     * Deep-copies an object so that no property will have the
     * same reference as the property in the source object.
     * @param object Object to copy
     * @returns A deep copy of the object
     */
    static deepCopy<T>(object: T): T {
        try {
            return structuredClone(object);
        } catch {
            return JSON.parse(JSON.stringify(object));
        }
    }

    /**
     * Retrieves the value of the property/key inside the provided object.
     */
    static getValueForProperty(obj: any, property: string): any {
        const value = (obj as { [key: string]: any })[property];

        if (Utils.isNumber(value)) {
            const numberValue = Number(value);
            return numberValue < Number.MAX_SAFE_INTEGER ? numberValue : value;
        }

        return value;
    }

    /**
     * Checks whether a value is a number.
     */
    static isNumber(value: any): boolean {
        return !isNaN(parseFloat(value)) && !isNaN(Number(value));
    }

    /**
     * Retrieves the file name from the response headers.
     * @param response
     */
    static getFileNameFromResponse(response: any): string {
        const disposition = response.headers?.get('Content-Disposition');

        if (!disposition) {
            return '';
        }

        const dispositionItems: string[] = disposition.split(';').map((di: string) => di.trim());
        const utf8FileName = dispositionItems.find(di => di.startsWith('filename*=UTF-8\'\''));
        const defaultFileName = dispositionItems.find(di => di.startsWith('filename='));
        let fileName = '';

        if (!!utf8FileName) {
            fileName = decodeURIComponent(utf8FileName.replace(/filename\*=utf-8''/i, ''));
        } else if (!!defaultFileName) {
            fileName = defaultFileName.replace('filename=', '');
        }

        return fileName;
    }

    /**
     * Retrieves the composite key as single string.
     * @param key
     */
    static getId(key: any): string {
        return typeof key === 'object' ? Object.values(key).join('-') : key.toString();
    }

    static regexEscape(value: string): string {
        return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    /**
     * Transforms bytes into a readable string for humans.
     * @param sizeInBytes Size in bytes.
     * @param decimalPlaces Number of decimal places to display.
     */
    static fileSizeToHumanReadable(sizeInBytes: number, decimalPlaces = 1): string {
        const limit = 1024;

        if (Math.abs(sizeInBytes) < limit) {
            return `${sizeInBytes} B`;
        }

        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let unitIndex = -1;
        const decimalConstant = Math.pow(10, decimalPlaces);
        let currentSize = 0;

        do {
            sizeInBytes /= limit;
            ++unitIndex;
            currentSize = Math.round(Math.abs(sizeInBytes) * decimalConstant) / decimalConstant;
        } while (currentSize >= limit && unitIndex < units.length - 1);

        return `${sizeInBytes.toFixed(decimalPlaces)} ${units[unitIndex]}`;
    }

    /**
     * Retrieves the image source of a base64 image to be used in the HTML img tag.
     * @param imageData Base 64 image data.
     * @param type Type of the image.
     */
    static getBase64ImageSrc(imageData: string, type: 'jpg' | 'jpeg' | 'gif' | 'png' = 'jpg'): string {
        return `data:image/${type};base64,${imageData}`;
    }

    static lowerCaseFirstLetter(value: string): string {
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    /**
     * Rounds the {@link value} to the nearest integer.
     * If the value is half way between two integers, it will round to the nearest even number (same as in backend).
     */
    static bankersRound(value: number): number {
        if (Number.isInteger(value)) {
            return value;
        }

        const floor = Math.floor(value);
        const ceiling = Math.ceil(value);
        const fraction = value - floor;

        if (fraction < 0.5) {
            return floor;
        } else if (fraction > 0.5) {
            return ceiling;
        } else {
            return floor % 2 === 0 ? floor : ceiling;
        }
    }

    /**
     * Rounds the {@link value} to the nearest multiple of {@link step}.
     * This method uses {@link bankersRound} for rounding to the nearest integer.
     */
    static roundTo(value: number, step: number): number {
        const stepVal = Big(step);
        return Big(Utils.bankersRound(Big(value).div(stepVal).toNumber())).times(stepVal).toNumber();
    }

    /**
     * Clamps the {@link value} between {@link min} and {@link max}.
     */
    static clamp(value: number, min: number, max: number): number {
        return Math.min(Math.max(value, min), max);
    }

    /**
     * Deletes a property from an object based on a given key path.
     * The key path can include dot notation to access nested properties.
     * If any level in the path (except the last one) is an array, the function will iterate over
     * every item in the array and continue traversing.
     *
     * @param obj - The object from which to delete the property.
     * @param key - The key path of the property to delete. Can use dot notation (e.g., 'parent.child.grandchild').
     * @param shouldClearIfArray - If true and it is an array, the property will be cleared instead of deleted.
     */
    static deleteUnwantedProperties(obj: any, key: string, shouldClearIfArray = false): void {
        const keys = key.split('.');
        let current = obj;

        for (let i = 0; i < keys.length - 1; i++) {
            const currentKey = keys[i];

            if (Array.isArray(current)) {
                current.forEach(item => this.deleteUnwantedProperties(item, keys.slice(i).join('.')));
                return;
            }

            if (current[currentKey] === undefined) {
                return;
            }

            current = current[currentKey];
        }

        const lastKey = keys[keys.length - 1];

        if (Array.isArray(current)) {
            current.forEach(item => {
                if (shouldClearIfArray && Array.isArray(item[lastKey])) {
                    item[lastKey] = [];
                } else {
                    delete item[lastKey];
                }
            });
        } else {
            if (shouldClearIfArray && Array.isArray(current[lastKey])) {
                current[lastKey] = [];
            } else {
                delete current[lastKey];
            }
        }
    }
}
