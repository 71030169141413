import { Component, OnInit, inject, input, signal, computed } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, debounceTime, distinctUntilChanged, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { BaseComponent } from '../../abstractions/base.component';
import { PlacesStore } from '../../../app/services/stores/places.store';
import { PlaceModel } from '../../../app/models/place.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { Utils } from '../../../core/utils/tools/utils.tools';
import { PlaceSelectFormGroupType } from './types/place-select-form-group.type';
import { EnvironmentService } from '../../../core/services/environment.service';
import { SelectOptionModel } from '../../../core/models/select-option.model';

@Component({
    selector: 'arc-place-select',
    templateUrl: './place-select.component.html',
    styleUrls: ['./place-select.component.scss']
})
export class PlaceSelectComponent extends BaseComponent implements OnInit {
    private static readonly defaultCountryIsoCode = 'CH';

    readonly formGroup = input.required<FormGroup<PlaceSelectFormGroupType>>();

    readonly placeSignal = signal<Partial<PlaceModel>>({});
    readonly isValid = computed(() => this.isValidPlace(this.placeSignal()));

    readonly isChecking = signal(false);
    readonly isExistingPlace = signal(false);

    readonly isLoadingCountries = signal(true);
    readonly countries = signal<SelectOptionModel<string>[]>([]);

    protected readonly _debounceTimeMs = 250;

    private readonly checkIfPlaceExistsSubject = new Subject<PlaceModel>();
    private readonly placesStore = inject(PlacesStore);

    private readonly environmentService = inject(EnvironmentService);
    private readonly subdomain: string;

    constructor() {
        super();

        this.subdomain = this.environmentService.getSubdomain();

        this.checkIfPlaceExistsSubject
            .pipe(
                distinctUntilChanged((prev, curr) => Utils.areEqual(prev, curr)),
                tap(() => {
                    this.isChecking.set(true);
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(place =>
                    forkJoin({
                        input: of(place),
                        result: this.placesStore.search(place.countryIsoCode, place.zip, place.city, this.subdomain)
                    })
                )
            )
            .subscribe(result => {
                this.isExistingPlace.set(
                    (result.result.value ?? []).some(
                        p => p.countryIsoCode === result.input.countryIsoCode && p.zip === result.input.zip && p.city === result.input.city
                    )
                );
                this.isChecking.set(false);
            });

        this.loadCountries();
    }

    ngOnInit(): void {
        const formGroupChangedSub = this.formGroup().valueChanges.subscribe(value => {
            this.checkIfCurrentPlaceExists();
            this.placeSignal.set(value);
        });
        this.addSubscriptions(formGroupChangedSub);
    }

    citySearchFn(query: string): Observable<PlaceModel[]> {
        if (!!query) {
            return this.placesStore
                .search(this.getCountryIsoCodeValue(), '', query, this.subdomain)
                .pipe(map(result => result.value ?? []));
        }

        return this.placesStore
            .search(this.getCountryIsoCodeValue(), this.formGroup().getRawValue().zip, '', this.subdomain)
            .pipe(map(result => result.value ?? []));
    }

    zipSearchFn(query: string): Observable<PlaceModel[]> {
        return this.placesStore.search(this.getCountryIsoCodeValue(), query, '', this.subdomain).pipe(map(result => result.value ?? []));
    }

    placeOptionDisplayFn(option: PlaceModel): string {
        return `${option.zip} - ${option.city}`;
    }

    checkIfCurrentPlaceExists(): void {
        const place = this.formGroup().getRawValue();

        if (!this.isValidPlace(place)) {
            return;
        }

        this.checkIfPlaceExistsSubject.next(place);
    }

    onPlaceSelected(place: OptionalType<PlaceModel>): void {
        this.formGroup().patchValue({
            zip: place?.zip,
            city: place?.city
        });
        this.isExistingPlace.set(place !== undefined);
    }

    onCountryChanged(): void {
        this.formGroup().patchValue({
            zip: undefined,
            city: undefined
        });
    }

    loadCountries(): void {
        this.isLoadingCountries.set(true);
        this.placesStore.getCountries(this.subdomain).subscribe(result => {
            this.countries.set((result.value ?? []).map(kv => ({
                value: kv.key ?? '',
                label: kv.value ?? ''
            })));
        }).add(() => this.isLoadingCountries.set(false));
    }

    private isValidPlace(place: Partial<PlaceModel>): place is PlaceModel {
        return !!place.zip && !!place.city && !!place.countryIsoCode;
    }

    private getCountryIsoCodeValue(): string {
        const formGroup = this.formGroup();
        return !!formGroup.controls.countryIsoCode ? formGroup.controls.countryIsoCode.value : PlaceSelectComponent.defaultCountryIsoCode;
    }
}
