<div class="!m-4">
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Sales.List.Discount' | transloco }}</p>
        <p class="text-xl">{{ !isCurrencyAmount() ? item().value : item().value | arcCurrency }}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Sales.List.PromotionCosts' | transloco }}</p>
        <p class="text-xl">{{ item().promotionCosts | arcCurrency }}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Sales.List.ValidFrom' | transloco }}</p>
        <p class="text-xl">{{ (item().fromDate | arcDate) + ' - ' + (item().toDate | arcDate) }}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Sales.List.ArticlesCount' | transloco }}</p>
        <p class="text-xl">{{ item().articlesCount }}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Sales.List.Branch' | transloco }}</p>
        <p class="text-xl">{{ item().validInBranch }}</p>
    </div>
</div>
