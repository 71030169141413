import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { SetArticleModel } from '../../../../../models/set-article.model';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArticleSetTypesEnum } from '../../../../../../core/models/enums/article-set-types.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';
import { Identifyable } from '../../../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-article-edit-set-articles',
    templateUrl: './article-edit-set-articles.component.html',
    styleUrls: ['./article-edit-set-articles.component.scss']
})
export class ArticleEditSetArticlesComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    override formGroup = new FormGroup({
        setArticles: new ArcFormControl<SetArticleModel[]>([])
    });
    setArticlesCardConfig: EditableTableConfigModel<SetArticleModel>;
    setArticleOptions: SelectOptionModel<ArticleSetTypesEnum>[] = [
        { label: 'Enums.ArticleSetTypesEnum.MandatoryFree', value: ArticleSetTypesEnum.MandatoryFree },
        { label: 'Enums.ArticleSetTypesEnum.Mandatory', value: ArticleSetTypesEnum.Mandatory },
        { label: 'Enums.ArticleSetTypesEnum.OptionalFree', value: ArticleSetTypesEnum.OptionalFree },
        { label: 'Enums.ArticleSetTypesEnum.Optional', value: ArticleSetTypesEnum.Optional },
        { label: 'Enums.ArticleSetTypesEnum.SelectionFree', value: ArticleSetTypesEnum.SelectionFree },
        { label: 'Enums.ArticleSetTypesEnum.Selection', value: ArticleSetTypesEnum.Selection },
        { label: 'Enums.ArticleSetTypesEnum.Ingredient', value: ArticleSetTypesEnum.Ingredient },
        { label: 'Enums.ArticleSetTypesEnum.Accessories', value: ArticleSetTypesEnum.Accessories }
    ];

    constructor() {
        super();
        this.setArticlesCardConfig = new EditableTableConfigModel<SetArticleModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    articleId: new ArcFormControl('', [Validators.required]),
                    setArticleId: new ArcFormControl(this.item().id),
                    quantity: new ArcFormControl(1, [Validators.required, CustomValidators.number()]),
                    articleSetType: new ArcFormControl(0, [Validators.required]),
                    groupName: new ArcFormControl<OptionalType<string>>(undefined),
                    requiredQuantity: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number()),
                    price: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number())
                })
        });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return { setArticles: this.formGroup.value.setArticles };
    }

    handleArticleSelected(selectedArticle: Identifyable, formGroup: FormGroup): void {
        formGroup.patchValue({ price: (selectedArticle as ArticleListModel)?.price });
    }

    override onBrokenRulesLoad(): string[] {
        return this.setArticlesCardConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }
}
