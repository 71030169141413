<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="control" [arcPortalTooltip]="labelKey || label" [isTranslated]="!!label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label>{{ label || (labelKey | transloco) }}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [formControl]="castedControl" [placeholder]="dateFormat" [min]="min" [max]="max"
            (dateChange)="onChange($event)" />
        <input matEndDate [formControl]="castedControlEnd" [placeholder]="dateFormat" [min]="min" [max]="max"
            (dateChange)="onChange($event)" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>