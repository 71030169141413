import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerGroupModel } from '../../../../../models/customer-group.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-customer-group-edit-base-data',
    templateUrl: './customer-group-edit-base-data.component.html',
    styleUrl: './customer-group-edit-base-data.component.scss'
})
export class CustomerGroupEditBaseDataComponent extends BaseEditSidebarItemComponent<CustomerGroupModel> {
    override formGroup = new FormGroup({
        sort: new ArcFormControl<number>(0, Validators.required),
        category: new ArcFormControl<OptionalType<string>>(undefined),
        title: new ArcFormControl<string>('', Validators.required)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<CustomerGroupModel> {
        const value = this.formGroup.getRawValue();
        return {
            sort: value.sort,
            category: value.category,
            title: value.title
        };
    }
}
