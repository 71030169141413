<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <div class="flex gap-2">
        <arc-input labelKey="Users.Edit.BaseData.UserName" [control]="formGroup.controls.username"></arc-input>
        <arc-general-data-select label="Users.Edit.BaseData.Role" [generalDataType]="GeneralDataTypeEnum.Roles"
            [formControl]="formGroup.controls.roleId">
        </arc-general-data-select>
    </div>
    <arc-input labelKey="Users.Edit.BaseData.RealName" [control]="formGroup.controls.realName"></arc-input>
    <arc-input labelKey="Users.Edit.BaseData.Email" [control]="formGroup.controls.email"></arc-input>
    <div class='relative'>
        <arc-input [control]="formGroup.controls.password" [type]="showPasswordClearText ? 'text' : 'password'"
            labelKey="Users.Edit.BaseData.Password" placeholder="********" autocomplete="off">
        </arc-input>
        <arc-icon class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
            [icon]="showPasswordClearText ? 'visibility_off' : 'visibility'"
            (click)='showPasswordClearText = !showPasswordClearText'></arc-icon>
    </div>
    <div class="flex gap-2">
        <arc-input labelKey="Users.Edit.BaseData.ContactPhone" [control]="formGroup.controls.contactPhone"></arc-input>
        <arc-input labelKey="Users.Edit.BaseData.ContactMobile"
            [control]="formGroup.controls.contactMobile"></arc-input>
    </div>
    <arc-input class="w-1/2" labelKey="Users.Edit.BaseData.Pin" [control]="formGroup.controls.pin"></arc-input>
    <arc-select label="Users.Edit.BaseData.Language" [options]="allowedLanguages"
        [formControl]="formGroup.controls.languageId"></arc-select>
    <arc-color-picker class="w-1/2" labelKey="Users.Edit.BaseData.UserColor"
        [control]="formGroup.controls.userColor"></arc-color-picker>
    <div class="flex gap-2">
        <arc-general-data-select label="Users.Edit.BaseData.PosLayout"
            [generalDataType]="GeneralDataTypeEnum.PosLayouts" [formControl]="formGroup.controls.posLayoutId">
        </arc-general-data-select>
        <arc-checkbox labelKey="Users.Edit.BaseData.IsLeftHanded"
            [control]="formGroup.controls.isLeftHanded"></arc-checkbox>
    </div>
    <div class="flex gap-2">
        <arc-general-data-select label="Users.Edit.BaseData.Branch" [generalDataType]="GeneralDataTypeEnum.Branches"
            [formControl]="formGroup.controls.branchId">
        </arc-general-data-select>
        <arc-general-data-select label="Users.Edit.BaseData.Region" [generalDataType]="GeneralDataTypeEnum.Regions"
            [formControl]="formGroup.controls.regionId">
        </arc-general-data-select>
    </div>
    <arc-general-data-select class="w-1/2" label="Users.Edit.BaseData.Supplier"
        [generalDataType]="GeneralDataTypeEnum.Suppliers" [formControl]="formGroup.controls.supplierId">
    </arc-general-data-select>
    <arc-checkbox labelKey="Users.Edit.BaseData.SendOrderNotificationEmail"
        [control]="formGroup.controls.sendOrderNotificationEmail"></arc-checkbox>
    <arc-checkbox labelKey="Users.Edit.BaseData.TourCompleted"
        [control]="formGroup.controls.tourCompleted"></arc-checkbox>
</form>