/* eslint-disable @typescript-eslint/indent */
import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { InventoryArticleListModel } from '../../../models/responses/inventory-article-list.model';
import { InventoryArticleModel } from '../../../models/inventory-article.model';
import { InventoryArticlesStore } from '../../../services/stores/inventory-articles.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { InventoryArticleStatusExtensions } from '../../../models/enums/inventory-article-status.enum';
import { InventoryArticleScansDetailWidgetComponent } from './inventory-articles-detail-widgets/inventory-article-scans.detail-widget/inventory-article-scans.detail-widget';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { InventoryTypeEnum } from '../../../models/enums/inventory-type.enum';
import { TranslationService } from '../../../../core/services/translation.service';

@Component({
    selector: 'arc-inventory-articles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './inventory-articles.component.scss'
})
export class InventoryArticlesComponent extends PageListViewComponent<
    InventoryArticleListModel, InventoryArticleModel, InventoryArticleListModel, InventoryArticleListModel, object
> {
    private readonly inventoryArticlesStore = inject(InventoryArticlesStore);
    private readonly translationsService = inject(TranslationService);

    constructor() {
        super('InventoryArticles');

        this.config =
            new TableListViewConfigModel<
                InventoryArticleListModel, InventoryArticleModel, InventoryArticleListModel, InventoryArticleListModel, object
            >({
                entityName: 'InventoryArticles',
                availableColumns: {
                    inventory: new StackedColumnModel({
                        columnTitleKey: 'InventoryArticles.List.Inventory',
                        propertyName: 'inventoryTitle',
                        propertyName2: 'inventoryId',
                        widthPixels: 150
                    }),
                    article: new StackedColumnModel({
                        columnTitleKey: 'InventoryArticles.List.Article',
                        propertyName: 'articleTitle',
                        propertyName2: 'articleNumber',
                        widthPixels: 300
                    }),
                    store: new StringColumnModel({
                        columnTitleKey: 'InventoryArticles.List.Store',
                        propertyName: 'storeName',
                        widthPixels: 150
                    }),
                    specifiedStock: new NumberColumnModel({
                        columnTitleKey: 'InventoryArticles.List.SpecifiedStock',
                        propertyName: 'specifiedStock',
                        widthPixels: 150
                    }),
                    actualStock: new NumberColumnModel({
                        columnTitleKey: 'InventoryArticles.List.ActualStock',
                        propertyName: 'actualStock',
                        customFormatter: (record, value) => {
                            if (!value && value !== 0) {
                                if (record.inventoryType === InventoryTypeEnum.Continuous) {
                                    value = this.translationsService.getText('InventoryArticles.List.NotCounted');
                                } else {
                                    value = '(0)';
                                }
                            }
                            return value;
                        },
                        widthPixels: 150
                    }),
                    totalBuyingPrice: new CurrencyColumnModel({
                        columnTitleKey: 'InventoryArticles.List.TotalBuyingPrice',
                        propertyName: 'totalBuyingPrice',
                        widthPixels: 150
                    }),
                    totalPrice: new CurrencyColumnModel({
                        columnTitleKey: 'InventoryArticles.List.TotalPrice',
                        propertyName: 'totalPrice',
                        widthPixels: 150
                    }),
                    buyingPrice: new CurrencyColumnModel({
                        columnTitleKey: 'InventoryArticles.List.BuyingPrice',
                        propertyName: 'buyingPrice',
                        widthPixels: 150
                    }),
                    price: new CurrencyColumnModel({
                        columnTitleKey: 'InventoryArticles.List.Price',
                        propertyName: 'price',
                        widthPixels: 150
                    }),
                    status: new GenericStatusColumnModel({
                        columnTitleKey: 'General.Status',
                        propertyName: 'status',
                        widthPixels: 200,
                        bgColor: value => InventoryArticleStatusExtensions.getColor(value)
                    })
                },
                defaultColumnOrder: [
                    'inventory',
                    'article',
                    'store',
                    'specifiedStock',
                    'actualStock',
                    'totalBuyingPrice',
                    'totalPrice',
                    'status'
                ],
                availableDetailWidgets: {
                    scans: new TableListDetailWidgetModel({
                        component: InventoryArticleScansDetailWidgetComponent,
                        name: 'InventoryArticles.Detail.Scans.ComponentTitle'
                    })
                },
                defaultDetailWidgetOrder: ['scans'],
                store: this.inventoryArticlesStore
            });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService
            .updateIsHidden(ActionButtonsService.deleteButtonKey, () => true)
            .updateIsHidden(ActionButtonsService.editButtonKey, () => true)
            .updateIsHidden(ActionButtonsService.createButtonKey, () => true);
    }
}
