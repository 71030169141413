<div class="flex flex-col gap-4">
    <div class="flex items-baseline gap-4">
        <span>{{'ExtAccountingExport.Edit.Data.Type' | transloco}}</span>
        <mat-button-toggle-group [ngModel]="isPayment()" (change)="changeIsPayment($event)" class="grow" [value]="false"
            [disabled]="!isCreate()">
            <mat-button-toggle [value]="false">
                <span>{{'ExtAccountingExport.Edit.Data.BookingArticles' | transloco}}</span>
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
                <span>{{'ExtAccountingExport.Edit.Data.Payments' | transloco}}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    @if(isPayment()){
        <arc-general-data-select [formControl]="formGroup.controls.paymentTypeId" label='ExtAccountingExport.Edit.Data.PaymentType'
            [generalDataType]="GeneralDataTypeEnum.PaymentTypes" [placeholder]="'General.All' | transloco"
            [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
        </arc-general-data-select>
        @if(isPaymentTypeEft()){
            <div class="flex gap-4 items-baseline">
                <arc-general-data-select class="grow" [formControl]="formGroup.controls.cardType"
                    label='ExtAccountingExport.Edit.Data.CardType' [generalDataType]="GeneralDataTypeEnum.CardTypes"
                    [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
                </arc-general-data-select>
                <span>{{'ExtAccountingExport.Edit.Data.Without' | transloco}}</span>
                <arc-general-data-select class="grow" [formControl]="formGroup.controls.exclCardType"
                    label='ExtAccountingExport.Edit.Data.CardType' [generalDataType]="GeneralDataTypeEnum.CardTypes" placeholder="-"
                    [hasFloatingLabel]="true">
                </arc-general-data-select>
            </div>
        }
    }
    @else {
        <arc-general-data-select [formControl]="formGroup.controls.transactionTypeId" label='ExtAccountingExport.Edit.Data.TransactionType'
            [generalDataType]="GeneralDataTypeEnum.TransactionTypes" [placeholder]="'General.All' | transloco"
            [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
        </arc-general-data-select>
        <arc-select [formControl]="formGroup.controls.articleAmountField" label="ExtAccountingExport.Edit.Data.Value"
            [options]="articleAmountOptions">
        </arc-select>
    }
</div>
