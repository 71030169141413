import { Injectable, signal, WritableSignal } from '@angular/core';

import { DictionaryType } from '../models/types/dictionary.type';
import { OptionalType } from '../models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    private data: DictionaryType<WritableSignal<any>> = {};

    reset(): void {
        for (const key of Object.keys(this.data)) {
            delete this.data[key];
        }
    }

    setOrUpdateSignal<T>(key: string, data: T): void {
        if (!!this.data[key]) {
            this.data[key].set(data);
        } else {
            this.data[key] = signal(data);
        }
    }

    getOrCreateSignal<T>(key: string): WritableSignal<OptionalType<T>> {
        if (!this.data[key]) {
            this.data[key] = signal<OptionalType<T>>(undefined);
        }

        return this.data[key];
    }

    getOrCreateSignalWithValue<T>(key: string, defaultValue: any): WritableSignal<T> {
        if (!this.data[key]) {
            this.data[key] = signal<T>(defaultValue);
        }

        return this.data[key];
    }
}
