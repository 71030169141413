<div [arcErrorTooltip]="_formControl" [arcPortalTooltip]="label" [isTranslated]="isLabelTranslated">
    @if (!!label) {
        <span class="text-sm">{{ isLabelTranslated ? label : (label | transloco) }}</span>
    }
    <div class="flex gap-4 items-center relative">
        <arc-rich-text-editor class="w-full" [maxLength]="maxLength || 0" [isDisabled]="isDisabled"
            [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)"
            [isInvalid]="!!_formControl?.invalid && isTouched" (valueChange)="valueChanged($event)" [value]="value">
        </arc-rich-text-editor>
        @if (!isAllowed) {
            <div class="absolute inset-0 bg-overlay z-50 grid place-items-center">
                <arc-no-permission-lock [missingPermission]="permission">
                </arc-no-permission-lock>
            </div>
        }
    </div>
</div>
