import { Component, inject, input, viewChild } from '@angular/core';

import { BaseSearchStore } from '../../core/abstractions/base-search.store';
import { FilterComponent } from './filter/filter.component';
import { SearchRequestService } from '../../core/services/search-request.service';
import { FilterService } from '../../core/services/filter.service';
import { BaseComponent } from '../abstractions/base.component';
import { FilterItemModel } from '../../core/models/filter-item.model';
import { Identifyable } from '../../core/abstractions/identifyable';

@Component({
    selector: 'arc-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent<TId = number> extends BaseComponent {
    readonly filter = viewChild('filter', { read: FilterComponent });

    readonly store = input.required<BaseSearchStore<Identifyable<TId>, TId>>();

    searchText = '';
    showFilter = false;
    filterCount = 0;
    get canShowFilter(): boolean {
        return this.selectableFilters.length > 0 || (this.filter()?.readOnlyFilterLabels.length ?? 0) > 0;
    }

    private selectableFilters: FilterItemModel[] = [];
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly filterService = inject(FilterService);

    constructor() {
        super();

        const searchRequestSub = this.searchRequestService.searchRequestChanged$.subscribe(request =>
            this.filterCount = request.filters.length
        );
        const selectableFiltersSub = this.filterService.filterItems$.subscribe(filterItems =>
            this.selectableFilters = filterItems.filter(f => !f.isReadOnly)
        );

        this.addSubscriptions(searchRequestSub, selectableFiltersSub);
    }

    toggleShowFilterAndSearch(): void {
        this.showFilter = !this.showFilter;
    }

    searchTextChanged(): void {
        this.searchRequestService.setSearchText(this.searchText);
    }
}
