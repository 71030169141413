import { Component, WritableSignal, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OrderModel } from '../../../../../models/order.model';
import { OrderUpdateModel } from '../../../../../models/requests/order-update.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { OrderStatusEnum, OrderStatusEnumExtensions } from '../../../../../models/enums/order-status.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { SuppliersStore } from '../../../../../services/stores/suppliers.store';

@Component({
    selector: 'arc-order-edit-base-data',
    templateUrl: './order-edit-base-data.component.html',
    styleUrls: ['./order-edit-base-data.component.scss']
})
export class OrderEditBaseDataComponent extends BaseEditSidebarItemComponent<OrderModel, OrderUpdateModel> {
    OrderStatusEnum = OrderStatusEnum;

    statusBgColorClass = OrderStatusEnumExtensions.getColor(OrderStatusEnum.Draft);

    override formGroup = new FormGroup({
        expectedDeliveryDate: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        discount: new ArcFormControl<OptionalType<string>>(undefined),
        remarks: new ArcFormControl<OptionalType<string>>(undefined)
    });

    readonly orderValue: WritableSignal<OptionalType<number>>;
    readonly orderedArticlesCount: WritableSignal<OptionalType<number>>;
    readonly deliveredArticlesCount: WritableSignal<OptionalType<number>>;

    private readonly sharedDataService = inject(SharedDataService);
    private readonly suppliersStore = inject(SuppliersStore);

    constructor() {
        super();

        this.orderValue = this.sharedDataService.getOrCreateSignal<number>('orderValue');
        this.orderedArticlesCount = this.sharedDataService.getOrCreateSignal<number>('orderedArticlesCount');
        this.deliveredArticlesCount = this.sharedDataService.getOrCreateSignal<number>('deliveredArticlesCount');
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
        this.statusBgColorClass = OrderStatusEnumExtensions.getColor(this.item().status);

        if (this.item().status === OrderStatusEnum.Delivered) {
            this.formGroup.disable();
        } else if (this.item().status === OrderStatusEnum.Suggestion) {
            this.suppliersStore.get(this.item().supplier.id).subscribe(resp => {
                const now = new Date();

                now.setDate(now.getDate() + (resp.value?.supplierDeliveryDays || 0));
                this.formGroup.patchValue({ expectedDeliveryDate: now });
            });
        }
    }

    override prepareSaveModel(): Partial<OrderUpdateModel> {
        const value = this.formGroup.value;
        return {
            expectedDeliveryDate: value.expectedDeliveryDate,
            discount: value.discount,
            remarks: value.remarks
        };
    }
}
