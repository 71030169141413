import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SupplierEditRequestModel } from '../../../../../models/requests/supplier-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { SupplierModel } from '../../../../../models/supplier.model';

@Component({
    selector: 'arc-supplier-edit-text',
    templateUrl: './supplier-edit-text.component.html',
    styleUrls: ['./supplier-edit-text.component.scss']
})
export class SupplierEditTextComponent extends BaseEditSidebarItemComponent<SupplierModel, SupplierEditRequestModel> {
    override formGroup = new FormGroup({
        notes: new ArcFormControl('')
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<SupplierEditRequestModel> {
        return { notes: this.formGroup.getRawValue().notes };
    }
}
