import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { SaleModel } from '../../../../../models/sale.model';

@Component({
    selector: 'arc-sale-edit-validity',
    templateUrl: './sale-edit-validity.component.html',
    styleUrls: ['./sale-edit-validity.component.scss']
})
export class SaleEditValidityComponent extends BaseEditSidebarItemComponent<SaleModel> {
    override formGroup = new FormGroup({
        fromDate: new ArcFormControl(new Date(), Validators.required),
        toDate: new ArcFormControl(new Date()),
        fromTime: new ArcFormControl(''),
        toTime: new ArcFormControl(''),
        monday: new ArcFormControl(true),
        tuesday: new ArcFormControl(true),
        wednesday: new ArcFormControl(true),
        thursday: new ArcFormControl(true),
        friday: new ArcFormControl(true),
        saturday: new ArcFormControl(true),
        sunday: new ArcFormControl(true)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<SaleModel> {
        const value = this.formGroup.value;
        return {
            fromDate: value.fromDate,
            toDate: value.toDate,
            fromTime: value.fromTime,
            toTime: value.toTime,
            monday: value.monday,
            tuesday: value.tuesday,
            wednesday: value.wednesday,
            thursday: value.thursday,
            friday: value.friday,
            saturday: value.saturday,
            sunday: value.sunday
        };
    }
}
