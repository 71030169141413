import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { SubscriptionListModel } from '../../../models/responses/subscription-list.model';
import { SubscriptionModel } from '../../../models/subscription.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { SubscriptionsStore } from '../../../services/stores/subscriptions.store';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { SubscriptionTransactionsComponent } from './subscription-detail-widgets/subscription-transactions/subscription-transactions.component';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-subscriptions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent extends PageListViewComponent<SubscriptionListModel, SubscriptionModel> {
    private readonly subscriptionStore = inject(SubscriptionsStore);

    constructor() {
        super('Subscriptions');

        this.config = new TableListViewConfigModel<SubscriptionListModel, SubscriptionModel>({
            entityName: 'Subscriptions',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Subscriptions.List.Subscription',
                    propertyName: 'title',
                    propertyName2: 'id',
                    widthPixels: 236
                }),
                personDisplayName: new StackedColumnModel({
                    columnTitleKey: 'Subscriptions.List.PersonDisplayName',
                    propertyName: 'personDisplayName',
                    propertyName2: 'personNumber',
                    suffixPropertyName: 'companyContactName',
                    widthPixels: 286
                }),
                validFrom: new DateColumnModel({
                    columnTitleKey: 'Subscriptions.List.ValidFrom',
                    propertyName: 'validFrom',
                    widthPixels: 126
                }),
                validThru: new DateColumnModel({
                    columnTitleKey: 'Subscriptions.List.ValidThru',
                    propertyName: 'validThru',
                    widthPixels: 126
                }),
                remainingQuantity: new StringColumnModel({
                    columnTitleKey: 'Subscriptions.List.RemainingQuantity',
                    propertyName: 'remainingQuantity',
                    widthPixels: 96,
                    // eslint-disable-next-line no-null/no-null
                    customFormatter: (record, value) => (value === undefined || value === null || value === '') ? '∞' : value
                }),
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Subscriptions.List.CreatedDate',
                    propertyName: 'createdDate',
                    widthPixels: 126
                })
            },
            defaultColumnOrder: ['title', 'personDisplayName', 'validFrom', 'validThru', 'remainingQuantity', 'createdDate'],
            availableDetailWidgets: {
                subscriptionTransactions: new TableListDetailWidgetModel({
                    name: 'Subscriptions.Detail.Transactions.ComponentTitle',
                    component: SubscriptionTransactionsComponent,
                    requiredPermission: PermissionsEnum.Transactions
                })
            },
            defaultDetailWidgetOrder: ['subscriptionTransactions'],
            store: this.subscriptionStore
        });
    }
}
