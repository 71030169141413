import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { ArcFormControl, ArcFormControlInterface } from '../../core/utils/arc-form-control';
import { DynamicFormField } from './models/dynamic-form-field';
import { DynamicFormControlTypeEnum } from './models/dynamic-form-control-type-enum';
import { ComplexDataTypesEnum } from '../../core/models/complex-data-types.enum';
import { OptionalType } from '../../core/models/types/optional.type';
import { TreeAutocompleteConfigsService } from '../../app/services/tree-autocomplete-configs.service';
import { TreeDataSelectionConfig } from '../form/tree-autocomplete/models/tree-data-selection.config';
import { Utils } from '../../core/utils/tools/utils.tools';
import { ArticleGroupListModel } from '../../app/models/responses/article-group-list.model';
import { KeyValueModel } from '../../core/models/key-value.model';

@Component({
    selector: 'arc-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {
    @Input() formFields: DynamicFormField[] = [];
    @Input() classLayout = 'flex flex-col';
    @Output() readonly formSubmitted = new EventEmitter<any>();

    DynamicFormControlTypeEnum = DynamicFormControlTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;

    formGroup!: FormGroup;
    items: { key: string; field: DynamicFormField; control: AbstractControl }[] = [];
    articleGroupConfig: TreeDataSelectionConfig<ArticleGroupListModel>;

    private readonly treeAutocompleteConfigService = inject(TreeAutocompleteConfigsService);
    private readonly formBuilder = inject(FormBuilder);
    private dateRangeFormFieldKey = 'DateRange';

    constructor() {
        this.articleGroupConfig = this.treeAutocompleteConfigService.articleGroupConfig;
    }

    asFormGroup(control: AbstractControl): FormGroup {
        return control as FormGroup;
    }

    ngOnInit(): void {
        this.formGroup = this.setupFormFields();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['formFields'] && !simpleChanges['formFields'].isFirstChange()) {
            this.formGroup = this.setupFormFields();
        }
    }

    getArticleGroupConfig(field: DynamicFormField): TreeDataSelectionConfig<ArticleGroupListModel> {
        if (!!field.treeAutocompleteConfig) {
            return new TreeDataSelectionConfig({
                ...this.articleGroupConfig,
                allowsInlineCreation: field.treeAutocompleteConfig.allowsInlineCreation,
                allowEmptySelection: field.treeAutocompleteConfig.allowEmptySelection,
                allowSelectionAtLevels:
                    field.treeAutocompleteConfig.selectableLevels.length > 0
                        ? field.treeAutocompleteConfig.selectableLevels
                        : this.articleGroupConfig.allowSelectionAtLevels
            });
        }

        return this.articleGroupConfig;
    }

    getSortedOptions(options: KeyValueModel<string, string>[]): KeyValueModel<string, string>[] {
        const sorted = options.sort((a, b) => {
            if (!a.value || !b.value) {
                return 0;
            }
            if (a.value?.toLowerCase() < b.value?.toLowerCase()) {
                return -1;
            }
            if (a.value?.toLowerCase() > b.value?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return sorted;
    }
    async submitForm(): Promise<void> {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {
            const data: { [key: string]: any } = {};

            for (const formField of this.formFields) {
                const key = formField.key;
                data[key] = await this.getControlValue(formField);
            }

            this.formSubmitted.emit(data);
        }
    }

    private setupFormFields(): FormGroup {
        const formGroupConfig: any = {};

        if (this.formFields.length > 0) {
            let fromDateControl: OptionalType<ArcFormControlInterface>;
            let fromDateDynamicFormField: DynamicFormField;

            for (let formField of this.formFields) {
                formField = new DynamicFormField(formField);
                const validators =
                    !formField.isNullable && formField.type !== DynamicFormControlTypeEnum.Label ? Validators.required : undefined;
                const formControl = new ArcFormControl(formField.value, validators);

                // API is not sending any DynamicFormControlTypeEnum.DateRange
                if (this.isPartOfDateRange(formField)) {
                    if (formField.key === 'FromDate') {
                        // save the fromDate to be used when the toDate is found.
                        fromDateControl = formControl;
                        fromDateDynamicFormField = formField;
                    } else {
                        const toDateControl = formControl;
                        const formFieldKey = this.dateRangeFormFieldKey;
                        const formGroup = new FormGroup({ FromDate: fromDateControl!, ToDate: toDateControl });
                        formGroupConfig[formFieldKey] = formGroup;
                        formField.key = formFieldKey;
                        formField.label = `${fromDateDynamicFormField!.label} - ${formField.label}`;
                        formField.type = DynamicFormControlTypeEnum.DateRange;

                        this.items.push({ key: formFieldKey, field: formField, control: formGroup });
                    }
                } else {
                    const formFieldKey = formField.key;
                    formGroupConfig[formFieldKey] = formControl;

                    if (formField.type === DynamicFormControlTypeEnum.ArticleGroup && formField.key.toLowerCase() === 'maingroupid') {
                        this.articleGroupConfig.allowSelectionAtLevels = [0];
                    }

                    if (formField.type === DynamicFormControlTypeEnum.Select && formField.isNullable) {
                        formField.selectOptions = !!formField.selectOptions
                            ? [{ value: undefined, label: '-' }, ...formField.selectOptions]
                            : [];
                    }

                    this.items.push({ key: formFieldKey, field: formField, control: formControl });
                }
            }
        }

        return this.formBuilder.group(formGroupConfig);
    }

    private async getControlValue(formField: DynamicFormField): Promise<OptionalType<any>> {
        let value = this.isPartOfDateRange(formField)
            ? (this.formGroup.controls[this.dateRangeFormFieldKey] as FormGroup).controls[formField.key].value
            : this.formGroup.controls[formField.key].value;

        if (formField.type === DynamicFormControlTypeEnum.Checkbox) {
            value = value === 'true' || value === true;
        } else if (formField.type === DynamicFormControlTypeEnum.Date || formField.type === DynamicFormControlTypeEnum.DateTime) {
            value = !value ? value : this.dateToISOString(value);
        } else if (formField.type === DynamicFormControlTypeEnum.Number || formField.type === DynamicFormControlTypeEnum.Decimal) {
            value = Number(value);
        } else if (formField.type === DynamicFormControlTypeEnum.FileUpload) {
            const file: File = value as File;

            if (!!file) {
                if (file.size === 0) {
                    // Is URL.
                    value = file.name;
                } else {
                    value = await lastValueFrom(Utils.blobToBase64(file));
                }
            }
        }

        return Promise.resolve(value);
    }

    private dateToISOString(value: any): string {
        const date = new Date(value);
        return date.toISOString();
    }

    private isPartOfDateRange(formField: DynamicFormField): boolean {
        return (
            (formField.type === DynamicFormControlTypeEnum.Date || formField.type === DynamicFormControlTypeEnum.DateRange) &&
            (((formField.key === 'FromDate' && this.formFields.find(f => f.key === 'ToDate')) ||
                (formField.key === 'ToDate' && this.formFields.find(f => f.key === 'FromDate'))) as boolean)
        );
    }
}
