import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CustomerGroupModel } from '../../../models/customer-group.model';
import { CustomerGroupsStore } from '../../../services/stores/customer-groups.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomerGroupEditBaseDataComponent } from './customer-group-edit-items/customer-group-edit-base-data/customer-group-edit-base-data.component';

@Component({
    selector: 'arc-customer-groups',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './customer-groups.component.scss'
})
export class CustomerGroupsComponent extends PageListViewComponent<CustomerGroupModel, CustomerGroupModel> {
    private readonly customerGroupsStore = inject(CustomerGroupsStore);

    constructor() {
        super('CustomerGroups');
        this.config = new TableListViewConfigModel<CustomerGroupModel, CustomerGroupModel>({
            entityName: 'CustomerGroups',
            availableColumns: {
                sort: new StringColumnModel({
                    columnTitleKey: 'CustomerGroups.List.Sort',
                    propertyName: 'sort',
                    widthPixels: 110
                }),
                category: new StringColumnModel({
                    columnTitleKey: 'CustomerGroups.List.Category',
                    propertyName: 'category',
                    widthPixels: 236
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'CustomerGroups.List.Title',
                    propertyName: 'title',
                    widthPixels: 236
                })
            },
            defaultColumnOrder: ['sort', 'category', 'title'],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            store: this.customerGroupsStore,
            defaultSort: new ColumnSortModel({ column: 'sort', direction: SortDirectionEnum.Asc }),
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'CustomerGroups.Edit.BaseData.ComponentTitle', component: CustomerGroupEditBaseDataComponent }
                ],
                headerSubtitle: currentItem => of(currentItem.title)
            }
        });
    }
}
