import { Component, OnInit, computed, inject, input, viewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';

import { TicketPrioritiesEnum } from '../../../../app/models/enums/ticket-priorities.enum';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { TicketStore } from '../../../../app/services/stores/ticket.store';
import { TicketCreateResponseModel } from '../../../../app/models/responses/ticket-create-response.model';
import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';
import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { TicketActionButtonModel } from '../../../../core/models/ticket-action-button.model';
import { CreateTicketStepEnum } from '../../../../app/models/enums/create-ticket-step.enum';
import { TranslationService } from '../../../../core/services/translation.service';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BlobModel } from '../../../../app/models/blob.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { ToasterService } from '../../../../core/services/toaster.service';
import { BaseComponent } from '../../../abstractions/base.component';

@Component({
    selector: 'arc-create-ticket',
    templateUrl: './create-ticket.component.html',
    styleUrl: './create-ticket.component.scss'
})
export class CreateTicketComponent extends BaseComponent implements OnInit {
    dynamicForm = viewChild<DynamicFormComponent>('dynamicForm');
    createTicketResponse = input.required<TicketCreateResponseModel>();

    formGroup = new FormGroup({
        message: new ArcFormControl('', Validators.required),
        priority: new ArcFormControl(TicketPrioritiesEnum.Low),
        attachments: new ArcFormControl<OptionalType<BlobModel[]>>(undefined)
    });

    titleControl = new FormControl('');
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);

    prioritySliderOptions = [
        { value: TicketPrioritiesEnum.Low, label: 'Tickets.Priority.Low', labelTitle: 'Tickets.Create.Priority.Low' },
        { value: TicketPrioritiesEnum.Medium, label: 'Tickets.Priority.Medium', labelTitle: 'Tickets.Create.Priority.Medium' },
        { value: TicketPrioritiesEnum.High, label: 'Tickets.Priority.High', labelTitle: 'Tickets.Create.Priority.High' },
        { value: TicketPrioritiesEnum.Immediate, label: 'Tickets.Priority.Immediate', labelTitle: 'Tickets.Create.Priority.Immediate' }
    ];

    sliderColor = computed(() => {
        switch (this.priority()) {
            case TicketPrioritiesEnum.Medium:
                return 'green';
            case TicketPrioritiesEnum.High:
                return 'yellow';
            case TicketPrioritiesEnum.Immediate:
                return 'red';
            default:
                return '';
        }
    });

    private readonly priority = toSignal(this.formGroup.controls.priority.valueChanges);

    private readonly ticketStore = inject(TicketStore);
    private readonly createTicketService = inject(CreateTicketService);
    private readonly translationService = inject(TranslationService);
    private readonly toasterService = inject(ToasterService);

    ngOnInit(): void {
        const createTicket = new TicketActionButtonModel({
            text: this.translationService.getText('General.Actions.Create'),
            step: CreateTicketStepEnum.CreateTicket,
            clickFn: () => this.createTicket()
        });
        this.createTicketService.addButtons(createTicket);

        this.fileControl.valueChanges.subscribe(async file => {
            if (!file) {
                return;
            }
            this.fileControl.reset();
            const newBlob = {
                id: 0,
                blobDataId: Utils.newGuid(),
                blobData: Array.from(new Uint8Array(await file.arrayBuffer())),
                fileMimeType: file.type,
                fileName: file.name
            };
            this.formGroup.controls.attachments.patchValue([...(this.formGroup.value.attachments || []), newBlob]);
        });
        this.titleControl.setValue(this.createTicketResponse().title);
        this.titleControl.disable();
    }

    removeFile(blobDataId?: string): void {
        if (!blobDataId) {
            return;
        }

        const filteredAttachments = (this.formGroup.value.attachments || []).filter(x => x.blobDataId !== blobDataId);
        this.formGroup.controls.attachments.patchValue(filteredAttachments);
    }

    onFormSubmitted(data: any): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid || this.dynamicForm()?.formGroup.invalid) {
            return;
        }

        this.createTicketService.isLoading.set(true);
        const requestId = this.createTicketResponse().requestId;
        const createTicketModel = {
            message: this.formGroup.value.message || '',
            priority: this.formGroup.value.priority || TicketPrioritiesEnum.Low,
            requestId: requestId,
            parameters: data,
            attachments: this.formGroup.value.attachments
        };

        this.ticketStore.createTicket(requestId, createTicketModel).subscribe({
            next: () => {
                this.toasterService.showSuccess('Tickets.Create.TicketCreateSuccess');
                this.createTicketService.reset();
            },
            error: () => {
                this.toasterService.showError('Tickets.Create.TicketCreateFailure');
                this.createTicketService.isLoading.set(false);
            }
        });
    }

    private createTicket(): void {
        this.dynamicForm()?.submitForm().then();
    }
}
