import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';

import { ComponentsModule } from '../../../../../../../components/components.module';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { MaterialModule } from '../../../../../../../core/utils/material.module';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { WarehouseRequestArticleModel } from '../../../../../../models/warehouse-request-article.model';

@Component({
    selector: 'arc-warehouse-transfer-article-edit-dialog',
    standalone: true,
    imports: [CommonModule, TranslocoModule, MaterialModule, ReactiveFormsModule, ComponentsModule],
    templateUrl: './warehouse-transfer-article-edit-dialog.component.html',
    styleUrl: './warehouse-transfer-article-edit-dialog.component.scss'
})
export class WarehouseTransferArticleEditDialogComponent implements OnInit {
    formGroup = new FormGroup({
        notes: new ArcFormControl<OptionalType<string>>(undefined)
    });

    private readonly data: { item: WarehouseRequestArticleModel } = inject(MAT_DIALOG_DATA);

    ngOnInit(): void {
        this.formGroup.patchValue(this.data.item);
    }
}
