<div class="h-full">
    <div class="h-full w-full overflow-auto">
        <div class="grid grid-rows-2 mt-4">
            <div class="min-h-[70px]">
                <span class="text-on-app-light text-sm">
                    {{ 'Invoices.Detail.Remarks.ResponsibleUser' | transloco }}
                </span>
                <br />
                <span>
                    {{ entityModel().responsibleUser }}
                </span>
            </div>
            <div>
                <span class="text-on-app-light text-sm">
                    {{ 'Invoices.Detail.Remarks.Remarks' | transloco }}
                </span>
                <br />
                <span>
                    {{ entityModel().remarks }}
                </span>
            </div>
        </div>
    </div>
</div>
