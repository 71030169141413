import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { VoucherTypeModel } from '../../models/voucher-type.model';
import { StoreConfig } from '../../../core/models/store.config';
import { VoucherTypeListModel } from '../../models/responses/voucher-type-list.model';
import { VoucherPaymentTypes } from '../../models/responses/voucher-payment-types.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class VoucherTypesStore extends BaseCrudStore<VoucherTypeModel, VoucherTypeListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'voucher-types' }));
    }

    getDefaultVoucherPaymentTypes(): Observable<ApiResponseModel<VoucherPaymentTypes[]>> {
        return this._requestService.makeGet<ApiResponseModel<VoucherPaymentTypes[]>>(this.getUrl('default-voucher-payment-types'));
    }
}
