<div class="h-full">
    @if (vouchers().length > 0) {
        <header class="absolute top-0 right-0 flex justify-center">
            <button mat-icon-button (click)="goToVouchersPage()">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </header>
    }
    <div class="h-full w-full">
        @if (isLoading()) {
            <div class="w-full h-full grid place-items-center">
                <mat-spinner [diameter]="32"></mat-spinner>
            </div>
        } @else if (vouchers().length > 0) {
            <div class="grid grid-cols-3 gap-4">
                @for (voucher of vouchers(); track voucher.id) {
                    <div class="p-2 bg-primary text-on-primary">
                        <div class="mb-[-10px] text-xs">{{ voucher.id }}</div>
                        <div class="text-xs">{{ voucher.title }}</div>
                        <div class="float-right">
                            @if (!!voucher.value) {
                                <span class="text-xl">{{ voucher.value | arcCurrency }}</span>
                            } @else {
                                @if (!!voucher.discount) {
                                    <div class="text-xl">{{ voucher.discount | arcCurrency }}</div>
                                }
                                <span [class.text-xl]="!voucher.discount" [class.text-xs]="!!voucher.discount">
                                    {{ voucher.validThru | arcDate }}
                                </span>
                            }
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="absolute inset-0 grid place-items-center">
                {{ 'Customers.Detail.Vouchers.NoVouchers' | transloco }}
            </div>
        }
    </div>
</div>
