<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Regions.Card.NumberOfBranches' | transloco }}</p>
        <p class="text-xl">{{ item().numberOfBranches }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Regions.Card.NumberOfPos' | transloco }}</p>
        <p class="text-xl">{{ item().numberOfPos }}</p>
    </div>
</div>
