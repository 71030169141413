import { Component, inject, input, OnInit, viewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { from, Observable, of } from 'rxjs';

import { BaseComponent } from '../../abstractions/base.component';
import { AddressModel } from '../../../core/models/address.model';
import { BingApiService } from '../../../core/services/bing-api.service';
import { OptionalType } from '../../../core/models/types/optional.type';
import { AddressPickerFormGroupType } from './types/address-picker-form-group.type';
import { PlaceSelectComponent } from '../place-select/place-select.component';

@Component({
    selector: 'arc-address-picker',
    templateUrl: './address-picker.component.html',
    styleUrls: ['./address-picker.component.scss']
})
export class AddressPickerComponent extends BaseComponent implements OnInit {
    readonly placeSelect = viewChild(PlaceSelectComponent);

    readonly formGroup = input.required<FormGroup<AddressPickerFormGroupType>>();
    readonly shouldShowQuickFeedback = input(true);

    readonly searchFn = this.search.bind(this);

    private readonly bingApiService = inject(BingApiService);

    ngOnInit(): void {
        const formGroup = this.formGroup();
        formGroup.controls.poBox?.setDisabled(!formGroup.controls.isPoBox?.value);
        const isPoBoxChangedSub = formGroup.controls.isPoBox?.valueChanges.subscribe(isPoBox => {
            formGroup.controls.poBox?.setDisabled(!isPoBox);
            if (!isPoBox) {
                formGroup.controls.poBox?.setValue('');
            }
        });
        if (!!isPoBoxChangedSub) {
            this.addSubscriptions(isPoBoxChangedSub);
        }
    }

    reloadData(): void {
        this.placeSelect()?.loadCountries();
    }

    optionDisplayFn(address: AddressModel): string {
        if (!address) {
            return '';
        }

        let str = address.street;
        if (!!address.streetNumber) {
            str += ` ${address.streetNumber}`;
        }

        str += ', ';

        if (address.zip) {
            str += `${address.zip} `;
        }

        str += `${address.city}`;

        return str;
    }

    onStreetControlBlur(): void {
        const streetControl = this.formGroup().controls.street;
        if (!streetControl) {
            return;
        }

        // check if street contains a street number
        const street = streetControl.value;
        if (!street) {
            return;
        }

        const matches = street.match(/^(.+?)(?:\s+([0-9]+[A-z]*))?$/);
        if (matches?.length === 3 && !!matches[2]) {
            const streetNumberControl = this.formGroup().controls.streetNumber;
            if (!!streetNumberControl) {
                streetControl.setValue(matches[1]);
                streetNumberControl.setValue(matches[2]);
            } else {
                streetControl.setValue(`${matches[1]} ${matches[2]}`);
            }
        } else if (matches?.length === 3 && !!matches[1]) {
            streetControl.setValue(matches[1]);
        }
    }

    handleAddressSelected(address: OptionalType<AddressModel>): void {
        if (!!address) {
            this.formGroup().patchValue({
                street: address.street,
                streetNumber: address.streetNumber,
                streetSupplement: address.streetSupplement,
                isPoBox: address.isPoBox ?? false,
                poBox: address.poBox,
                place: {
                    zip: address.zip,
                    city: address.city,
                    countryIsoCode: address.countryIsoCode
                }
            });
        }
    }

    private search(query: string): Observable<AddressModel[]> {
        if (!!query) {
            return from(this.bingApiService.getSuggestions(query, this.formGroup().getRawValue().place.countryIsoCode?.toUpperCase()));
        }
        return of([]);
    }
}
