import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { PriceLevelModel } from '../../../../../models/price-level.model';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';

@Component({
    selector: 'arc-settings-price-levels',
    templateUrl: './settings-price-levels.component.html',
    styleUrl: './settings-price-levels.component.scss'
})
export class SettingsPriceLevelsComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        priceLevels: new ArcFormControl<PriceLevelModel[]>([])
    });
    priceLevelsTableConfig: EditableTableConfigModel<PriceLevelModel>;

    constructor() {
        super();
        this.priceLevelsTableConfig = new EditableTableConfigModel<PriceLevelModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    title: new ArcFormControl('', Validators.required),
                    isActive: new ArcFormControl(false),
                    useForWebApi: new ArcFormControl(false)
                }),
            onFormGroupValueChanged: (newValue, prevValue, formGroups) => {
                if (newValue.useForWebApi && !prevValue.useForWebApi) {
                    formGroups.forEach(fg => {
                        if (fg.value.id === newValue.id) {
                            return;
                        }

                        fg.patchValue({ useForWebApi: false });
                    });
                }
            },
            rowHeightPx: 63,
            allowDelete: false,
            shouldHideAddButton: true,
            columns: [
                new NumberColumnModel({
                    propertyName: 'id',
                    isEditable: false,
                    columnTitleKey: 'Settings.Edit.PriceLevels.PriceLevels.Id'
                }),
                new StringColumnModel({
                    propertyName: 'title',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.PriceLevels.PriceLevels.Title'
                }),
                new CheckboxColumnModel({
                    propertyName: 'isActive',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.PriceLevels.PriceLevels.IsActive'
                }),
                new CheckboxColumnModel({
                    propertyName: 'useForWebApi',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.PriceLevels.PriceLevels.UseForWebApi'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.priceLevelsTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { priceLevels: this.formGroup.value.priceLevels };
    }
}
