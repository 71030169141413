import { Component, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { VoucherModel } from '../../../../../models/voucher.model';

@Component({
    selector: 'arc-customer-vouchers',
    templateUrl: './customer-vouchers.detail-widget.html',
    styleUrls: ['./customer-vouchers.detail-widget.scss']
})
export class CustomerVouchersDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    readonly isLoading = signal(true);
    readonly vouchers = signal<VoucherModel[]>([]);

    private readonly customersStore = inject(CustomersStore);
    private readonly router = inject(Router);

    ngOnInit(): void {
        this.customersStore.getVouchers(this.entityModel().id).subscribe(r => {
            this.vouchers.set(r.value || []);
            this.isLoading.set(false);
        });
    }

    goToVouchersPage(): void {
        this.router.navigate(['vouchers'], { queryParams: { 'IsActive': true, 'PersonId': this.entityModel().id } }).then();
    }
}
