<div class="!m-4 flex flex-col gap-4">
    <div>
        <p class="text-on-app-light">{{ 'PaymentTypes.Card.Debit' | transloco }}</p>
        <p class="text-xl">{{ item().titleDebit }}</p>
    </div>
    <div>
        <p class="text-on-app-light">{{ 'PaymentTypes.Card.Currency' | transloco }}</p>
        <p class="text-xl">{{ item().currency }}</p>
    </div>
    @if(item().requiredRole; as requiredRole){
        <div>
            <p class="text-on-app-light">{{ 'PaymentTypes.Card.RequiredRole' | transloco }}</p>
            <p class="text-xl">{{ requiredRole }}</p>
        </div>
    }
</div>
