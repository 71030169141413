import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-settings-customize-colors',
    templateUrl: './settings-customize-colors.component.html',
    styleUrl: './settings-customize-colors.component.scss'
})
export class SettingsCustomizeColorsComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        defaultBackColor: new ArcFormControl('', Validators.required),
        defaultForeColor: new ArcFormControl('', Validators.required),
        defaultHighlightColor: new ArcFormControl('', Validators.required)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            defaultBackColor: value.defaultBackColor,
            defaultForeColor: value.defaultForeColor,
            defaultHighlightColor: value.defaultHighlightColor
        };
    }
}
