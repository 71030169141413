import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SubscriptionTypeModel } from '../../../../../../models/subscription-type.model';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../../core/models/enums/general-data-type.enum';
import { TreeAutocompleteConfigsService } from '../../../../../../services/tree-autocomplete-configs.service';
import { GeneralDataStore } from '../../../../../../services/stores/general-data.store';
import { ButtonToggleModel } from '../../../../../../../core/models/button-toggle.model';
import { TranslationService } from '../../../../../../../core/services/translation.service';
import { SubscriptionTypeValidityEnum } from '../../../../../../models/enums/subscription-type-validity.enum';
import { SubscriptionTypeQuantityEnum } from '../../../../../../models/enums/subscription-type-quantity.enum';

@Component({
    selector: 'arc-subscription-type-edit-base-data',
    templateUrl: './subscription-type-edit-base-data.component.html',
    styleUrls: ['./subscription-type-edit-base-data.component.scss']
})
export class SubscriptionTypeEditBaseDataComponent extends BaseEditSidebarItemComponent<SubscriptionTypeModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        subscriptionTypeArticleArticleNumber: new ArcFormControl('', Validators.required),
        subscriptionTypeArticleTitle_de: new ArcFormControl('', Validators.required),
        subscriptionTypeArticleTitle_fr: new ArcFormControl<OptionalType<string>>(undefined),
        subscriptionTypeArticleTitle_en: new ArcFormControl<OptionalType<string>>(undefined),
        subscriptionTypeArticleTitle_it: new ArcFormControl<OptionalType<string>>(undefined),
        subscriptionTypeArticleArticleGroupId: new ArcFormControl(0, Validators.required),
        subscriptionTypeArticleUnitId: new ArcFormControl<number>(0, Validators.required),
        subscriptionTypeArticlePrice: new ArcFormControl(0, Validators.required),
        subscriptionTypeArticleTaxId: new ArcFormControl(0, Validators.required),
        validityDurationToggle: new ArcFormControl(SubscriptionTypeValidityEnum.Years),
        durationValue: new ArcFormControl(1, [Validators.required, Validators.min(1)], undefined, 'validForYears'),
        validForDays: new ArcFormControl<OptionalType<number>>(undefined),
        validForMonths: new ArcFormControl<OptionalType<number>>(undefined),
        validForYears: new ArcFormControl<OptionalType<number>>(undefined),
        subscriptionQuantityToggle: new ArcFormControl(SubscriptionTypeQuantityEnum.Unlimited),
        subscriptionQuantity: new ArcFormControl<OptionalType<number>>(undefined)
    });
    articleTaxes: ButtonToggleModel[] = [];
    subscriptionValidityUnit: OptionalType<string>;
    subscriptionQuantityUnit: OptionalType<string>;

    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    readonly translationService = inject(TranslationService);
    readonly validityDurationItems = [
        {
            value: SubscriptionTypeValidityEnum.Days,
            label: this.translationService.getText('SubscriptionTypes.Card.Edit.Days')
        },
        {
            value: SubscriptionTypeValidityEnum.Months,
            label: this.translationService.getText('SubscriptionTypes.Card.Edit.Months')
        },
        {
            value: SubscriptionTypeValidityEnum.Years,
            label: this.translationService.getText('SubscriptionTypes.Card.Edit.Years')
        }
    ];
    readonly subscriptionQuantityItems = [
        {
            value: SubscriptionTypeQuantityEnum.Unlimited,
            label: this.translationService.getText('SubscriptionTypes.Card.Edit.UnlimitedQuantity')
        },
        {
            value: SubscriptionTypeQuantityEnum.Fixed,
            label: this.translationService.getText('SubscriptionTypes.Card.Edit.FixedQuantity')
        }
    ];


    private readonly generalDataStore = inject(GeneralDataStore);

    ngOnInit(): void {
        this.formGroup.controls.validityDurationToggle.valueChanges.subscribe(selectedValue => {
            const durationValueControl = this.formGroup.controls.durationValue;
            if (selectedValue === SubscriptionTypeValidityEnum.Days) {
                this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Days';
                durationValueControl.validationName = 'validForDays';
            } else if (selectedValue === SubscriptionTypeValidityEnum.Months) {
                this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Months';
                durationValueControl.validationName = 'validForMonths';
            } else if (selectedValue === SubscriptionTypeValidityEnum.Years) {
                this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Years';
                durationValueControl.validationName = 'validForYears';
            }

            durationValueControl.updateValueAndValidity();
        });

        this.formGroup.controls.durationValue.valueChanges.subscribe(selectedValue => {
            this.setValidity(selectedValue);
        });

        this.formGroup.controls.subscriptionQuantityToggle.valueChanges.subscribe(selectedValue => {
            const subscriptionQuantityControl = this.formGroup.controls.subscriptionQuantity;

            if (selectedValue === SubscriptionTypeQuantityEnum.Unlimited) {
                subscriptionQuantityControl.disable();
                subscriptionQuantityControl.clearValidators();
                this.formGroup.controls.subscriptionQuantity.setValue(undefined);

                this.subscriptionQuantityUnit = undefined;
            } else {
                subscriptionQuantityControl.enable();
                subscriptionQuantityControl.setValidators(Validators.required);

                this.subscriptionQuantityUnit = 'SubscriptionTypes.Card.Edit.QuantityTimes';
            }

            subscriptionQuantityControl.updateValueAndValidity();
        });

        this.generalDataStore
            .getGeneralData(GeneralDataTypeEnum.ArticleTaxes)
            .subscribe(r =>
                r.value
                    ?.sort((kv1, kv2) => +kv1.value! - +kv2.value!)
                    .forEach(kv => this.articleTaxes.push({ label: `${+kv.value! * 1000 / 10}%`, value: kv.key }))
            );
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());

        if (!!this.item().subscriptionQuantity) {
            this.formGroup.controls.subscriptionQuantityToggle.setValue(SubscriptionTypeQuantityEnum.Fixed);
            this.formGroup.controls.subscriptionQuantity.enable();

            this.subscriptionQuantityUnit = 'SubscriptionTypes.Card.Edit.QuantityTimes';
        } else {
            this.formGroup.controls.subscriptionQuantity.disable();
            this.subscriptionQuantityUnit = undefined;
        }

        const durationValueControl = this.formGroup.controls.durationValue;
        const durationToggleControl = this.formGroup.controls.validityDurationToggle;

        const item = this.item();
        if (item.validForYears !== undefined && item.validForYears > 0) {
            durationToggleControl.setValue(SubscriptionTypeValidityEnum.Years);
            durationValueControl.setValue(item.validForYears);
            this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Years';
        } else if (item.validForMonths !== undefined && item.validForMonths > 0) {
            durationToggleControl.setValue(SubscriptionTypeValidityEnum.Months);
            durationValueControl.setValue(item.validForMonths);
            this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Months';
        } else if (item.validForDays !== undefined && item.validForDays > 0) {
            durationToggleControl.setValue(SubscriptionTypeValidityEnum.Days);
            durationValueControl.setValue(item.validForDays);
            this.subscriptionValidityUnit = 'SubscriptionTypes.Card.Edit.Days';
        }
    }

    override prepareSaveModel(): Partial<SubscriptionTypeModel> {
        this.setValidity(this.formGroup.controls.durationValue.value);
        const value = this.formGroup.value;
        const isUnlimited = value.subscriptionQuantityToggle === SubscriptionTypeQuantityEnum.Unlimited;
        return {
            subscriptionTypeArticleArticleNumber: value.subscriptionTypeArticleArticleNumber,
            subscriptionTypeArticleTitle_de: value.subscriptionTypeArticleTitle_de,
            subscriptionTypeArticleTitle_fr: value.subscriptionTypeArticleTitle_fr,
            subscriptionTypeArticleTitle_en: value.subscriptionTypeArticleTitle_en,
            subscriptionTypeArticleTitle_it: value.subscriptionTypeArticleTitle_it,
            subscriptionTypeArticleArticleGroupId: value.subscriptionTypeArticleArticleGroupId,
            subscriptionTypeArticleUnitId: value.subscriptionTypeArticleUnitId,
            subscriptionTypeArticlePrice: value.subscriptionTypeArticlePrice,
            subscriptionTypeArticleTaxId: value.subscriptionTypeArticleTaxId,
            validForDays: value.validForDays,
            validForMonths: value.validForMonths,
            validForYears: value.validForYears,
            subscriptionQuantity: isUnlimited ? undefined : value.subscriptionQuantity
        };
    }

    private setValidity(selectedValue: number): void {
        const durationToggleValue = this.formGroup.controls.validityDurationToggle.value;

        if (durationToggleValue === SubscriptionTypeValidityEnum.Days) {
            this.formGroup.controls.validForDays.setValue(selectedValue);
            this.formGroup.controls.validForMonths.setValue(0);
            this.formGroup.controls.validForYears.setValue(0);
        } else if (durationToggleValue === SubscriptionTypeValidityEnum.Months) {
            this.formGroup.controls.validForMonths.setValue(selectedValue);
            this.formGroup.controls.validForYears.setValue(0);
            this.formGroup.controls.validForDays.setValue(0);
        } else if (durationToggleValue === SubscriptionTypeValidityEnum.Years) {
            this.formGroup.controls.validForYears.setValue(selectedValue);
            this.formGroup.controls.validForMonths.setValue(0);
            this.formGroup.controls.validForDays.setValue(0);
        }
    }
}
