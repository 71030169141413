import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, forwardRef, inject, input, viewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import EasyMDE from 'easymde';

import { SecurityStorage } from '../../core/services/storages/security.storage';
import { Tools } from '../../core/utils/tools';
import { environment } from '../../environments/environment';
import { BaseControlValueAccessor } from '../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrl: './markdown-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        }
    ]
})
export class MarkdownEditorComponent extends BaseControlValueAccessor<string> implements AfterViewInit, OnDestroy {
    readonly markdownEditor = viewChild.required('arcMarkdownEditor', { read: ElementRef });

    styleClass = input<string>('mde-default');
    allowImageUpload = input<boolean>(true);
    imageUploadLocation = input<'clientdb' | 'portaldb'>('clientdb');

    @HostBinding('class')
    get hostClasses(): string {
        return this.styleClass();
    }

    easyMDE?: EasyMDE;

    private securityStorage = inject(SecurityStorage);

    ngAfterViewInit(): void {
        const token = this.securityStorage.getToken();
        const imageEndpointParams = new HttpParams().set('token', token || '');
        const imageUploadController = this.imageUploadLocation() === 'clientdb' ? 'blobs/image/upload' : 'portal/image/upload';
        this.easyMDE = new EasyMDE({
            element: this.markdownEditor().nativeElement,
            status: false,
            uploadImage: this.allowImageUpload(),
            imagePathAbsolute: true,
            imageMaxSize: 1024 * 1024 * 2,
            imageUploadEndpoint: `${Tools.Url.getUrl(imageUploadController, environment.baseUrl)}?${imageEndpointParams.toString()}`
        });

        if (!!this.value) {
            this.easyMDE?.value(this.value);
        }

        this.easyMDE.codemirror.on('change', () => {
            this.valueChanged(this.easyMDE?.value());
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.easyMDE?.cleanup();
    }

    override writeValue(value: string): void {
        super.writeValue(value);
        this.easyMDE?.value(value);
    }

    insertTextAtCursor(text: string): void {
        if (this.easyMDE) {
            const cursor = this.easyMDE.codemirror.getCursor();
            this.easyMDE.codemirror.replaceSelection(text);

            // Adjust cursor position after insertion
            this.easyMDE.codemirror.setCursor(cursor.line, cursor.ch + text.length);
        }
    }

    focus(): void {
        this.easyMDE?.codemirror.focus();
    }
}
