import { Component, OnInit, computed, effect, inject, signal } from '@angular/core';

import { SidebarService } from '../../../../core/services/sidebar.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { SubMenuItemModel } from '../../../../core/models/sub-menu-item.model';
import { FavouritesService } from '../../../../core/services/favourites.service';
import { AuthenticatedUserModel } from '../../../../app/models/authenticated-user.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { UserService } from '../../../../core/services/user.service';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { ModuleIdentificationsEnum } from '../../../../app/models/enums/module-identifications.enum';
import { OptionalType } from '../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-left-sidebar',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent extends BaseComponent implements OnInit {
    readonly currentSubMenu = signal<SubMenuItemModel[]>([]);
    readonly isEmptyFavouritesList = signal(false);
    readonly user = signal<OptionalType<AuthenticatedUserModel>>(undefined);
    readonly hasSysAdminSection = signal(false);
    readonly isMobile = computed(() => this.layoutService.isMobile());

    readonly hasFavoritePermission: true | AuthPermissionModel;
    readonly hasFashionModuleLicensed: boolean;

    readonly sidebarWidthPx = 230;
    readonly quickFeedbacks: DictionaryType<string> = {
        reports: '35',
        tags: '53'
    };

    readonly sidebarService = inject(SidebarService);
    readonly layoutService = inject(LayoutService);

    private readonly homeDirectory = '/home';
    private readonly favouritesService = inject(FavouritesService);
    private readonly permissionService = inject(PermissionService);
    private readonly routeService = inject(RouteService);
    private readonly userService = inject(UserService);

    constructor() {
        super();

        this.hasFavoritePermission = this.permissionService.hasPermission(PermissionsEnum.Favourites);
        this.hasFashionModuleLicensed = this.permissionService.hasModuleLicensed(ModuleIdentificationsEnum.Fashion);

        effect(() => {
            if (this.isMobile()) {
                this.sidebarService.closeLeft();
            } else {
                this.applyLeftSidebarDesktopSetting();
            }
        });

        // regenerate submenus on route change
        const subMenuItemsSub = this.routeService.subMenuItemsSub.subscribe(subMenuItems => {
            this.currentSubMenu.set(subMenuItems);
            if (this.hasFavoritePermission === true) {
                this.favouritesService.getAll().subscribe(result => {
                    this.currentSubMenu.update(subMenu => {
                        subMenu.forEach(menuItem => {
                            menuItem.favouriteId = result.find(f => f.path === menuItem.path)?.id;
                        });
                        return subMenu;
                    });
                });
            }
            this.setupSubMenu();
        });

        this.addSubscriptions(subMenuItemsSub);
    }

    async ngOnInit(): Promise<void> {
        this.user.set(await this.userService.getUserInfoAsync());
        this.hasSysAdminSection.set(!!this.user()?.databaseName || !!this.user()?.databaseVersion || !!this.user()?.serverName);
    }

    favouriteIdChanged(index: number, favouriteId?: number): void {
        this.currentSubMenu.update(subMenu => {
            subMenu[index].favouriteId = favouriteId;
            return subMenu;
        });

        if (this.routeService.isActiveRoute(this.homeDirectory)) {
            this.currentSubMenu.set(this.currentSubMenu().filter(el => el.favouriteId !== undefined));
        }
        this.setupSubMenu();
    }

    handleLeftBackdropClick(): void {
        this.sidebarService.closeLeft();
    }

    private setupSubMenu(): void {
        if (this.currentSubMenu().length === 0) {
            if (this.routeService.isActiveRoute(this.homeDirectory)) {
                this.isEmptyFavouritesList.set(true);
            } else {
                this.sidebarService.closeLeft();
                this.sidebarService.isLeftDisabled = true;
            }
        } else {
            const variantsIndex = this.currentSubMenu().findIndex(s => !!s.path && s.path === 'variations');
            if (variantsIndex > -1 && !this.hasFashionModuleLicensed) {
                this.currentSubMenu.update(subMenu => subMenu.filter((x, index) => index !== variantsIndex));
            }
            this.sidebarService.isLeftDisabled = false;
            if (!this.isMobile()) {
                this.applyLeftSidebarDesktopSetting();
            } else {
                this.sidebarService.closeLeft();
            }
        }
    }

    private applyLeftSidebarDesktopSetting(): void {
        this.layoutService.getLayoutSettings().subscribe(settings => {
            if (settings.isLeftSidebarOpenDesktop) {
                this.sidebarService.openLeft();
            } else {
                this.sidebarService.closeLeft();
            }
        });
    }
}
