<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-date-range-picker [control]="formGroup.controls.fromDate" [controlEnd]="formGroup.controls.toDate"
        labelKey="Sales.Edit.Validity.FromToDate"></arc-date-range-picker>

    <arc-checkbox [control]="formGroup.controls.monday" labelKey="General.Monday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.tuesday" labelKey="General.Tuesday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.wednesday" labelKey="General.Wednesday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.thursday" labelKey="General.Thursday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.friday" labelKey="General.Friday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.saturday" labelKey="General.Saturday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.sunday" labelKey="General.Sunday"></arc-checkbox>

    <div class="flex flex-row gap-4">
        <arc-input type="time" [step]="1" [control]="formGroup.controls.fromTime" labelKey="Sales.Edit.Validity.FromTime"></arc-input>
        <arc-input type="time" [step]="1" [control]="formGroup.controls.toTime" labelKey="Sales.Edit.Validity.ToTime"></arc-input>
    </div>
</form>
