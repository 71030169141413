import { Component, inject, WritableSignal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ReservationDetailsModel } from '../../../../../models/reservation-details.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ReservationEditArticlesComponent } from '../reservation-edit-articles/reservation-edit-articles.component';
import { ReservationEditModel } from '../../../../../models/reservation-edit.model';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';

@Component({
    selector: 'arc-reservation-edit-base-data',
    templateUrl: './reservation-edit-base-data.component.html',
    styleUrl: './reservation-edit-base-data.component.scss'
})
export class ReservationEditBaseDataComponent extends BaseEditSidebarItemComponent<ReservationDetailsModel, ReservationEditModel> {
    PermissionsEnum = PermissionsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    override formGroup = new FormGroup({
        reservationTypeId: new ArcFormControl<number>(undefined, [Validators.required]),
        personId: new ArcFormControl<OptionalType<number>>(undefined),
        storeId: new ArcFormControl<OptionalType<number>>(undefined),
        remarks: new ArcFormControl<OptionalType<string>>(undefined),
        externalReference: new ArcFormControl<OptionalType<string>>(undefined)
    });
    totalPriceSignal: WritableSignal<number>;
    totalDiscountSignal: WritableSignal<number>;
    totalSignal: WritableSignal<number>;
    numberOfArticlesSignal: WritableSignal<number>;
    numberOfPositionsSignal: WritableSignal<number>;
    reservationTypeIdSignal: WritableSignal<OptionalType<number>>;
    customerIdSignal: WritableSignal<OptionalType<number>>;

    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();

        this.totalPriceSignal = this.sharedDataService.getOrCreateSignalWithValue<number>(
            ReservationEditArticlesComponent.totalPriceSignal,
            0
        );
        this.totalDiscountSignal = this.sharedDataService.getOrCreateSignalWithValue<number>(
            ReservationEditArticlesComponent.totalDiscountSignal,
            0
        );
        this.totalSignal = this.sharedDataService.getOrCreateSignalWithValue<number>(
            ReservationEditArticlesComponent.totalSignal,
            0
        );
        this.numberOfArticlesSignal = this.sharedDataService.getOrCreateSignalWithValue<number>(
            ReservationEditArticlesComponent.numberOfArticlesSignal,
            0
        );
        this.numberOfPositionsSignal = this.sharedDataService.getOrCreateSignalWithValue<number>(
            ReservationEditArticlesComponent.numberOfPositionsSignal,
            0
        );
        this.reservationTypeIdSignal = this.sharedDataService.getOrCreateSignalWithValue<OptionalType<number>>(
            ReservationEditArticlesComponent.reservationTypeIdSignal,
            undefined
        );
        this.customerIdSignal = this.sharedDataService.getOrCreateSignalWithValue<OptionalType<number>>(
            ReservationEditArticlesComponent.customerIdSignal,
            undefined
        );

        const reservationTypeIdChangedSub = this.formGroup.controls.reservationTypeId.valueChanges.subscribe((value) => {
            this.reservationTypeIdSignal.set(value);
        });

        const customerIdChangedSub = this.formGroup.controls.personId.valueChanges.subscribe((value) => {
            this.customerIdSignal.set(value);
        });

        this.addSubscriptions(reservationTypeIdChangedSub, customerIdChangedSub);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
        this.totalPriceSignal.set(this.item().transactionsDetail.price);
        this.totalDiscountSignal.set(this.item().transactionsDetail.discount);
        this.totalSignal.set(this.item().transactionsDetail.total);
        this.numberOfArticlesSignal.set(this.item().transactionsDetail.numberOfArticles);
    }

    override prepareSaveModel(): Partial<ReservationDetailsModel> {
        const value = this.formGroup.value;
        return {
            reservationTypeId: value.reservationTypeId,
            personId: value.personId,
            storeId: value.storeId,
            remarks: value.remarks,
            externalReference: value.externalReference
        };
    }

    onCustomerChanged(customer?: CustomerListModel): void {
        this.customerIdSignal.set(customer?.id);
    }
}
