<mat-sidenav-container class="h-full" autosize [hasBackdrop]="isMobile()" (backdropClick)="handleLeftBackdropClick()">
    <mat-sidenav-content class="!flex flex-col">
        <ng-content />
    </mat-sidenav-content>

    <mat-sidenav [mode]="isMobile() ? 'over' : 'side'" [opened]="sidebarService.leftOpenSubject | async" class="!border-r-0">
        <div class="h-full flex flex-col" [style.width.px]="sidebarWidthPx">
            <div class="grow overflow-y-auto">
                <mat-nav-list class="h-full !flex flex-col !p-0">
                    @if (!isEmptyFavouritesList()) {
                        @for (menuItem of currentSubMenu(); track $index; let idx = $index) {
                            <arc-left-sidebar-item [menuItem]="menuItem" [arcQuickFeedback]="quickFeedbacks[menuItem.path]"
                                [quickFeedbackShouldShow]="!!quickFeedbacks[menuItem.path]" [quickFeedbackPosition]="{ top: 8, right: 40 }"
                                (favouriteIdChanged)="favouriteIdChanged(idx, $event)">
                            </arc-left-sidebar-item>
                        }
                    } @else {
                        <p class="mt-10 mx-4 break-words text-center text-base text-on-app-light">
                            {{ 'Layout.Menu.Favourites.NoFavourites' | transloco }}
                        </p>
                    }
                </mat-nav-list>
            </div>

            <!-- footer -->
            <div class="px-4 py-4 text-on-app-light text-xs">
                <div>
                    <p>{{ user()?.licenseId }}</p>
                    <p>{{ user()?.accessCode }}</p>
                    @if (hasSysAdminSection()) {
                        <p>
                            {{ user()?.serverName }} | {{ user()?.databaseName }} ({{ user()?.databaseVersion }})
                        </p>
                        }
                </div>
                <div>&copy; Arcavis AG</div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
