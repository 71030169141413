import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { QueryDesignerListModel } from '../../../models/responses/query-designer-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { QueryDesignerCardComponent } from './components/card/query-designer-card.component';
import { QueryDesignerModel } from '../../../models/query-designer.model';
import { QueryDesignerStore } from '../../../services/stores/query-designer.store';
import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { QueryDesignerEditBaseDataComponent } from './edit-items/query-designer-edit-base-data/query-designer-edit-base-data.component';
import { QueryDesignerEditViewComponent } from './edit-items/query-designer-edit-view/query-designer-edit-view.component';
import { QueryDesignerEditModel } from '../../../models/query-designer-edit.model';
import { ReportsStore } from '../../../services/stores/reports.store';
import { ReportModel } from '../../../models/report.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-query-designer',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./query-designer.component.scss']
})
export class QueryDesignerComponent extends PageListViewComponent<QueryDesignerListModel, QueryDesignerModel, QueryDesignerEditModel> {
    private readonly queryDesignerStore = inject(QueryDesignerStore);
    private readonly reportsStore = inject(ReportsStore);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);

    constructor() {
        super('QueryDesignerReports');
        this.config =
            new CardListViewConfigModel<QueryDesignerModel, QueryDesignerListModel, QueryDesignerEditModel, QueryDesignerEditModel>({
                entityName: 'QueryDesigner',
                store: this.queryDesignerStore,
                cardComponent: QueryDesignerCardComponent,
                cardWidth: '307px',
                cardHeight: '465px',
                actionButtonsCustomClasses: 'max-h-[52px]',
                cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
                cardImage: currentItem => {
                    const token = this.securityStorage.getToken();
                    const baseUrl = this.environmentService.baseUrl;

                    return `${baseUrl}/query-designer/${currentItem.id}/preview?token=${token}`;
                },
                editSidebarConfig: {
                    editComponents: [
                        { titleKey: 'QueryDesigner.Edit.BaseData.ComponentTitle', component: QueryDesignerEditBaseDataComponent },
                        { titleKey: 'QueryDesigner.Edit.View.ComponentTitle', component: QueryDesignerEditViewComponent }
                    ],
                    headerSubtitle: currentItem => of(currentItem.title)
                },
                customActionButtons: [
                    new ActionButtonModel({
                        text: 'QueryDesigner.OpenNewReportInReportDesigner',
                        min: 1,
                        max: 1,
                        icon: 'design_services',
                        clickFn: (btn, data) => {
                            data = Array.isArray(data) ? data : [data];
                            this.handleOpenInEditorButtonClick(data);
                        }
                    })
                ],
                buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
            });
    }

    handleOpenInEditorButtonClick(items: QueryDesignerListModel[]): void {
        this.reportsStore.add(new ReportModel({
            title: items[0].title,
            description: items[0].description,
            tableName: items[0].tableName
        })).subscribe(resp => {
            if (!!resp.value) {
                this._router.navigate(['reports', 'edit', resp.value]).then();
            }
        });
    }
}
