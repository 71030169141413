import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerEditRequestModel } from '../../../../../models/requests/customer-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { CustomerAccountBookingDialogDataModel } from './customer-account-booking-dialog/models/customer-account-booking-dialog-data.model';
import { CustomerAccountBookingDialogComponent } from './customer-account-booking-dialog/customer-account-booking-dialog.component';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-customer-edit-account',
    templateUrl: './customer-edit-account.component.html',
    styleUrls: ['./customer-edit-account.component.scss']
})
export class CustomerEditAccountComponent extends BaseEditSidebarItemComponent<CustomerModel, CustomerEditRequestModel> implements OnInit {
    UnitsEnum = UnitsEnum;
    PermissionsEnum = PermissionsEnum;
    transactionPermission: true | AuthPermissionModel = true;
    shouldReload = false;
    override formGroup = new FormGroup({
        accountMinAmount: new ArcFormControl<OptionalType<number>>(undefined),
        paymentDays: new ArcFormControl<OptionalType<number>>(undefined)
    });
    accountLimitLabel = '';

    private readonly matDialog = inject(MatDialog);
    private readonly permissionService = inject(PermissionService);
    private readonly changeDetection = inject(ChangeDetectorRef);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.transactionPermission = this.permissionService.hasPermission(PermissionsEnum.Transactions, PermissionTypeEnum.Create);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
        this.setAccountLimitLabel();
    }

    override prepareSaveModel(): Partial<CustomerEditRequestModel> {
        const value = this.formGroup.value;
        return {
            accountMinAmount: value.accountMinAmount,
            paymentDays: value.paymentDays
        };
    }

    override shouldReloadOnCancel(): boolean {
        return this.shouldReload;
    }

    openCustomerBookingDialog(personId: number, accountId: number, accountTypeId: number, isDebit: boolean): void {
        const dialogRef = this.matDialog.open(CustomerAccountBookingDialogComponent, {
            data: new CustomerAccountBookingDialogDataModel({
                personId,
                accountId,
                isDebit,
                accountTypeId
            }),
            width: '400px',
            maxWidth: '98vw',
            maxHeight: '98vsh'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === undefined || result === '') {
                return;
            }
            const acc = this.item().accounts?.find(a => a.id === accountId);
            if (acc !== undefined) {
                acc.amount = result;
                this.changeDetection.detectChanges();
                this.shouldReload = true;
            }
        });
    }

    private setAccountLimitLabel(): void {
        const type = this.item().accounts.find(acc => acc.isAccountTypeInvoice);

        if (!!type) {
            this.accountLimitLabel = this.translationService.getText('Customers.Edit.Account.AccountMinAmount', {
                value: type.accountTypeTitle
            });
        } else {
            this.formGroup.controls.accountMinAmount.disable();
        }
    }
}
