<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.Card.EditMode' | transloco }}</p>
        <p class="text-xl">{{ item().editmodeDescription }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.Card.StandardQuantity' | transloco }}</p>
        <p class="text-xl">{{ item().standardQuantity | arcNumber:2 }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.Card.TareGrams' | transloco }}</p>
        <p class="text-xl">{{ item().tareGrams || '-' }}</p>
    </div>
</div>
