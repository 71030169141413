<div
    class="relative rounded-lg size-full bg-on-app text-app"
    [style.background-color]="posLayoutGroupCommand().backgroundColor"
    [style.color]="posLayoutGroupCommand().foreColor" draggable="true">
    <!-- command content -->
    <div class="size-full">
        @if (!!imageSrc()) {
            <div class="absolute z-0 inset-0">
                <img [src]="imageSrc()" class="size-full object-scale-down" />
            </div>
        }
        <div class="absolute z-10 inset-[24px] break-words">{{ posLayoutGroupCommand().title }}</div>
    </div>

    <!-- remove button -->
    <div class="absolute top-1 left-1">
        <button class="p-1 rounded-full" matRipple (click)="remove.emit()">
            <arc-icon icon="close" [size]="16"></arc-icon>
        </button>
    </div>

    <!-- edit button -->
    <div class="absolute bottom-1 left-1">
        <button class="p-1 rounded-full" matRipple (click)="edit.emit()">
            <arc-icon icon="edit" [size]="16"></arc-icon>
        </button>
    </div>

    <!-- expand/collapse button -->
    <div class="absolute bottom-1 right-1">
        @if (posLayoutGroupCommand().isDoubleSize) {
            <button class="p-1 rounded-full" matRipple (click)="setIsDoubleSize(false)">
                <arc-icon class="rotate-90" icon="unfold_less" [size]="16"></arc-icon>
            </button>
        }
        @else if(canExpand()) {
            <button class="p-1 rounded-full" matRipple (click)="setIsDoubleSize(true)">
                <arc-icon class="rotate-90" icon="unfold_more" [size]="16"></arc-icon>
            </button>
        }
    </div>
</div>
