import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { RegionModel } from '../../../models/responses/region.model';
import { RegionListModel } from '../../../models/responses/region-list.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { RegionsStore } from '../../../services/stores/regions.store';
import { RegionCardComponent } from './region-card/region-card.component';
import { RegionEditBaseDataComponent } from './region-edit-items/region-edit-base-data/region-edit-base-data.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-regions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./regions.component.scss']
})
export class RegionsComponent extends PageListViewComponent<RegionListModel> {
    private readonly regionsStore = inject(RegionsStore);

    constructor() {
        super('Regions');

        this.config = new CardListViewConfigModel<RegionModel, RegionListModel>({
            entityName: 'Regions',
            store: this.regionsStore,
            cardComponent: RegionCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Regions.Edit.BaseData.ComponentTitle', component: RegionEditBaseDataComponent }],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'Regions.ViewBranches',
                clickFn: data => this._router.navigate(['settings/branches'], { queryParams: { RegionId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }
}
