export enum PaymentProcessingTypeEnum {
    None = 0,
    Eft = 1,
    Twint = 2,
    Boncard = 3,
    Legic = 4,
    Wechat = 5,
    AliPay = 6,
    InLane = 7
}
