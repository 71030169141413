<h1 mat-dialog-title>
    {{ 'Vouchers.Create.ComponentTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <div class="!mt-2 items-center grid grid-cols-[1fr_auto_auto] gap-4 ">
        <mat-form-field class="w-full" [arcErrorTooltip]="formGroup.controls.type"
            arcPortalTooltip="Vouchers.Create.VoucherType" subscriptSizing="dynamic">
            <mat-label>{{ 'Vouchers.Create.VoucherType' | transloco }}</mat-label>
            <mat-select [formControl]="formGroup.controls.type">
                @for (option of voucherTypesSelectModel; track option.value) {
                    <mat-option [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <arc-number-input label="General.Number" [formControl]="formGroup.controls.quantity" [decimalPlaces]="0"></arc-number-input>
        <arc-button type="flat" color="accent" [isLoading]="isUpdatingRows" (clicked)="updateRows()">
            {{'General.Actions.Create' | transloco}}
        </arc-button>
        @if (!this.isDiscountVoucher()) {
            <arc-select label="Vouchers.Create.PaymentType" [options]="voucherTransactionPaymentTypesSelectModel"
                [areOptionsTranslated]="true" [formControl]="formGroup.controls.paymentTypeId"
                [placeholder]="'Vouchers.Create.PaymentOutOfArcavis' | transloco" [hasFloatingLabel]="true">
            </arc-select>
        }
    </div>
    <div class="mt-6">
        @if (vouchersTableConfig(); as vouchersTableConfig) {
            <arc-editable-table [config]="vouchersTableConfig" [formControl]="vouchersFormControl">
            </arc-editable-table>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isDisabled]="vouchersFormControl.value.length <= 0" [isLoading]="isSaving"
        (clicked)="saveRecords()">
        {{ 'General.Actions.Save' | transloco }}
    </arc-button>
</mat-dialog-actions>
