import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { CustomerProfileModel } from '../../../models/customer-profile.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomerProfilesStore } from '../../../services/stores/customer-profiles.store';
import { CustomerProfileCardComponent } from './customer-profile-card/customer-profiles-card.component';
import { CustomerProfileListModel } from '../../../models/responses/customer-profile-list.model';
import { CustomerProfilesBaseDataComponent } from './customer-profiles-edit-items/customer-profiles-base-data/customer-profiles-base-data.component';
import { CustomerProfilesDiscountsComponent } from './customer-profiles-edit-items/customer-profiles-discounts/customer-profiles-discounts.component';
import { CustomerBonusProgramsComponent } from './customer-profiles-edit-items/customer-bonus-programs/customer-bonus-programs.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-customer-profiles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './customer-profiles.component.scss'
})
export class CustomerProfilesComponent extends PageListViewComponent<CustomerProfileListModel, CustomerProfileModel> {
    private readonly customerProfilesStore = inject(CustomerProfilesStore);

    constructor() {
        super('CustomerProfiles');
        this.config = new CardListViewConfigModel<CustomerProfileModel, CustomerProfileListModel>({
            entityName: 'CustomerProfiles',
            store: this.customerProfilesStore,
            cardComponent: CustomerProfileCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({ column: 'isDefault', direction: SortDirectionEnum.Desc }),
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'CustomerProfiles.Edit.BaseData.ComponentTitle', component: CustomerProfilesBaseDataComponent },
                    {
                        titleKey: 'CustomerProfiles.Edit.Discounts.ComponentTitle',
                        component: CustomerProfilesDiscountsComponent,
                        requiredPermission: PermissionsEnum.CustomerProfileDiscounts
                    },
                    {
                        titleKey: 'CustomerProfiles.Edit.BonusPrograms.ComponentTitle',
                        component: CustomerBonusProgramsComponent,
                        requiredPermission: PermissionsEnum.CustomerBonusPrograms
                    }
                ],
                headerSubtitle: item => of(item.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'CustomerProfiles.ShowCustomers',
                clickFn: data => this._router.navigate(['customers'], { queryParams: { CustomerProfileId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }
}
