<div class="min-h-svh flex flex-col">
    <mat-toolbar class="!pl-0 !pr-2 !text-on-top-bar-hover">
        <mat-toolbar-row>
            <!-- logo & "hamburger" menu -->
            <div class="grid place-items-center">
                <mat-icon svgIcon="arcavis_icon" class="scale-75" />
            </div>
            <div class="mr-8" id="logo"></div>

            <div class="grow"></div>

            @for (language of availableSiteLanguages; track language.code) {
                <button mat-button (click)="setSiteLanguage(language.code)"
                    class="!text-on-top-bar hover:!text-on-top-bar-hover !hidden sm:!block"
                    [class.!text-on-top-bar-selected]="language.code === languageCode()">
                    {{ language.code | uppercase }}
                </button>
            }

            <mat-menu #languageMenu="matMenu">
                @for (language of availableSiteLanguages; track language.code) {
                    <button mat-menu-item (click)="setSiteLanguage(language.code)" [class.!text-green]="language.code === languageCode()">
                        {{ language.name }}
                    </button>
                }
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="sm:!hidden">
                <mat-icon>translate</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="grow flex justify-center">
        <div class="w-full max-w-[600px] mx-8 mt-4 mb-28 md:mb-12 flex flex-col gap-4">
            @if (!hasLogoLoadFailed()) {
                <img class="w-full max-h-[100px] object-contain" [src]="logoSrc" (error)="hasLogoLoadFailed.set(true)">
            }

            @if (config(); as config) {
                @if (createResult(); as createResult) {
                    <!-- CREATE SUCCESS -->
                    <div class="sm:mt-16 flex flex-col items-center gap-4">
                        <p class="text-center text-3xl">{{ 'CustomerForm.CreateSuccess' | transloco}}</p>

                        @if (customerCardDownloadUrl(); as customerCardDownloadUrl) {
                            <qrcode [qrdata]="customerCardDownloadUrl" [width]="250" class="sm:hidden" />
                            <qrcode [qrdata]="customerCardDownloadUrl" [width]="400" class="hidden sm:block" />

                            @if (errorMessage(); as errorMessage) {
                                <p class="text-error text-xl text-center">{{ errorMessage }}</p>
                            }

                            <button mat-stroked-button color="primary" (click)="downloadCustomerCard(customerCardDownloadUrl)"
                                class="w-full max-w-[400px] !h-16" [disabled]="isDownloadingCustomerCard()">
                                <div class="flex items-center gap-2">
                                    @if (isDownloadingCustomerCard()) {
                                        <mat-spinner [diameter]="16" />
                                    }
                                    <span>{{ 'CustomerForm.DownloadCustomerCard' | transloco }}</span>
                                </div>
                            </button>
                        }

                        <button mat-flat-button color="primary" type="button" class="w-full max-w-[400px] !h-16" (click)="reloadPage()">
                            <span>{{ 'CustomerForm.Finish' | transloco }}</span>
                        </button>
                    </div>
                } @else {
                    <!-- CUSTOMER CREATION FORM -->
                    <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-4">
                        @if (formGroup.get('companyName'); as companyNameControl) {
                            <arc-input [control]="companyNameControl" labelKey="CustomerForm.CompanyName" class="grow-0" />
                        }

                        @if (formGroup.contains('legalFormId')) {
                            <arc-select [options]="legalForms()" [isLoading]="isLoadingGeneralData()" [areOptionsTranslated]="true"
                                formControlName="legalFormId" label="CustomerForm.CompanyLegalFormId">
                            </arc-select>
                        }

                        @if (formGroup.contains('salutationId')) {
                            <arc-select [options]="salutations()" [isLoading]="isLoadingGeneralData()" [areOptionsTranslated]="true"
                                formControlName="salutationId" label="CustomerForm.SalutationId">
                            </arc-select>
                        }

                        @if (formGroup.contains('salutationTitleId')) {
                            <arc-select [options]="salutationTitles()" [isLoading]="isLoadingGeneralData()" [areOptionsTranslated]="true"
                                formControlName="salutationTitleId" label="CustomerForm.SalutationTitleId">
                            </arc-select>
                        }

                        @if (formGroup.get('firstname'); as firstnameControl) {
                            <arc-input [control]="firstnameControl" labelKey="CustomerForm.FirstName" class="grow-0" />
                        }

                        @if (formGroup.get('name'); as nameControl) {
                            <arc-input [control]="nameControl" labelKey="CustomerForm.Name" class="grow-0" />
                        }

                        @if (formGroup.get('address'); as addressFormGroup) {
                            <arc-address-picker [formGroup]="addressFormGroup | transform : toFormGroup" [shouldShowQuickFeedback]="false" />
                        } @else if (formGroup.contains('countryIsoCode')) {
                            <arc-select [options]="countries()" [isLoading]="isLoadingGeneralData()" [areOptionsTranslated]="true"
                                formControlName="countryIsoCode" label="CustomerForm.Country">
                            </arc-select>
                        }

                        @if (formGroup.get('birthdate'); as birthdateControl) {
                            <arc-datepicker [control]="birthdateControl" labelKey="CustomerForm.Birthdate" />
                        }

                        @if (formGroup.contains('customerProfileId')) {
                            <arc-select [options]="customerProfiles()" [isLoading]="isLoadingGeneralData()" [areOptionsTranslated]="true"
                                formControlName="customerProfileId" label="CustomerForm.CustomerProfileId">
                            </arc-select>
                        }

                        @if (formGroup.contains('languageId')) {
                            <arc-select [options]="allowedLanguages" [areOptionsTranslated]="true" formControlName="languageId"
                                label="CustomerForm.LanguageId">
                            </arc-select>
                        }

                        @if (formGroup.get('contactEmail'); as emailControl) {
                            <arc-input [control]="emailControl" type="email" labelKey="CustomerForm.Email" class="grow-0" />
                        }

                        @if (formGroup.get('noCommunication'); as noCommunicationControl) {
                            <arc-checkbox [control]="noCommunicationControl" labelKey="CustomerForm.NoCommunication" class="w-fit" />
                        }

                        @if (formGroup.get('contactPhone'); as contactPhoneControl) {
                            <arc-input [control]="contactPhoneControl" labelKey="CustomerForm.ContactPhone" class="grow-0" />
                        }

                        @if (formGroup.get('contactMobile'); as contactMobileControl) {
                            <arc-input [control]="contactMobileControl" labelKey="CustomerForm.ContactMobile" class="grow-0" />
                        }

                        @if (formGroup.get('customerGroupIds'); as customerGroupIdsControl) {
                            <div>
                                <p class="text-dark-gray text-sm mb-2">{{ 'CustomerForm.CustomerGroups' | transloco }}</p>
                                @if (!isLoadingCustomerGroups()) {
                                    <div class="grid grid-cols-[auto_1fr] gap-4">
                                        @for (category of customerGroupCategories(); track category.value) {
                                            <p class="h-[32px] flex items-center">{{ category.title }}</p>
                                            <mat-chip-listbox #customerGroupChipListbox aria-orientation="horizontal" [multiple]="true"
                                                (change)="handleCustomerGroupChange()" [value]="category.selectedGroupIds">
                                                @for (group of category.groups; track group.id) {
                                                    <mat-chip-option [value]="group.id">
                                                        {{ group.title }}
                                                    </mat-chip-option>
                                                }
                                            </mat-chip-listbox>
                                        }
                                    </div>
                                } @else {
                                    <div class="flex items-center">
                                        <mat-spinner [diameter]="32" />
                                    </div>
                                }
                            </div>
                        }

                        @if (formGroup.get('acceptTerms'); as termsControl) {
                            <mat-checkbox formControlName="acceptTerms" color="primary" [arcErrorTooltip]="termsControl" class="w-fit">
                                <a [href]="config.termsUrl" target="_blank" rel="noopener noreferrer"
                                    class="cursor-pointer flex items-center gap-2"
                                    [class.text-primary]="termsControl.valid || !termsControl.touched"
                                    [class.text-error]="!termsControl.valid && termsControl.touched">
                                    <p>
                                        <span class="underline">{{ config.termsText }}</span>
                                        <span>*</span>
                                    </p>
                                    <arc-icon icon="open_in_new" [size]="14" />
                                </a>
                            </mat-checkbox>
                        }

                        <!-- error message -->
                        @if (errorMessage(); as errorMessage) {
                            <p class="text-center text-error text-lg">{{ errorMessage }}</p>
                        }

                        <button mat-flat-button color="primary" type="submit" class="mt-4 !h-16" [disabled]="isCreating()">
                            <div class="flex items-center gap-2">
                                @if (isCreating()) {
                                    <mat-spinner [diameter]="16" />
                                }
                                <span>{{ 'CustomerForm.Save' | transloco }}</span>
                            </div>
                        </button>

                        <button type="button" (click)="clearForm()" [disabled]="isCreating()"
                            class="mt-4 mx-auto p-1 w-fit underline text-primary hover:text-error">
                            {{ 'CustomerForm.ClearForm' | transloco }}
                        </button>
                    </form>
                }
            }
            @else if (isLoadingConfig()) {
                <div class="h-full grid place-items-center">
                    <mat-spinner [diameter]="64" />
                </div>
            }
            @else {
                <div class="h-full grid place-items-center">
                    <div class="max-w-[400px] flex flex-col gap-4">
                        <p class="text-2xl text-error text-center">
                            {{ 'CustomerForm.ErrorLoadingPage' | transloco }}
                        </p>

                        <button (click)="reloadPage()" mat-flat-button color="primary" type="button" class="mt-4 !h-16">
                            {{ 'CustomerForm.Retry' | transloco }}
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
