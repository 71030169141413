import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { PosReportsStore } from '../../../services/stores/pos-reports.store';
import { PosReportModel } from '../../../models/pos-report.model';
import { PosReportListModel } from '../../../models/responses/pos-report-list.model';
import { PosReportEditRequestModel } from '../../../models/requests/pos-report-edit-request.model';
import { PosReportCardComponent } from './pos-report-card/pos-report-card.component';
import { PosReportEditBaseDataComponent } from './pos-report-edit-items/pos-report-edit-base-data/pos-report-edit-base-data.component';
import { PosReportParametersService } from '../../../services/pos-report-parameters.service';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { BaseReportPage } from '../../../../components/abstractions/base-report-page';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

@Component({
    selector: 'arc-pos-reports',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./pos-reports.component.scss']
})
export class PosReportsComponent extends BaseReportPage<PosReportListModel> {
    private readonly posReportsStore = inject(PosReportsStore);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);
    private readonly reportParametersService = inject(PosReportParametersService);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super(
            inject(PosReportsStore),
            true,
            new ActionButtonModel({
                clickFn: () => this.openAddReport(),
                isHidden: () => !this._showTemplates,
                icon: 'add',
                min: 0,
                max: 0,
                isEnabled: true
            })
        );

        this.posReportsStore.showTemplates = this._showTemplates;
        this.config = new CardListViewConfigModel<PosReportModel, PosReportListModel, PosReportEditRequestModel>({
            entityName: 'PosReports',
            store: this.posReportsStore,
            cardComponent: PosReportCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
            cardImage: currentItem => {
                const token = this.securityStorage.getToken();
                const baseUrl = this.environmentService.baseUrl;

                return `${baseUrl}/pos-reports/${currentItem.id}/preview?token=${token}&templateKey=${currentItem.templateKey ?? ''}`;
            },
            cardBackgroundColor: currentItem => {
                if (currentItem.isTemplate && !currentItem.isInstalled) {
                    const style = getComputedStyle(document.body);
                    return style.getPropertyValue('--available-reports');
                }

                return '';
            },
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'PosReports.Edit.BaseData.ComponentTitle',
                        component: PosReportEditBaseDataComponent,
                        requiredPermission: PermissionsEnum.PosReports,
                        requiredPermissionType: PermissionTypeEnum.Create
                    }
                ],
                headerSubtitle: item => of(item.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'General.Actions.Open',
                clickFn: data => {
                    data = Array.isArray(data) ? data : [data];
                    this.reportParametersService.openReportParametersDialog(data[0]['id']);
                },
                isHidden: data => !!data && !data.isInstalled
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false, hasCreate: false }),
            customActionButtons: this._customActionButtons
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService
            .updateIsHidden(
                ActionButtonsService.editButtonKey,
                (btn, data) => !!data && (!this._showTemplates || (data.isTemplate && !data.isInstalled))
            )
            .updateIsHidden(
                ActionButtonsService.deleteButtonKey,
                (btn, data) => !!data && (!this._showTemplates || (data.isTemplate && !data.isInstalled))
            );
    }

    openAddReport(): void {
        this.sidebarService
            .openEdit({
                entityName: this.config.entityName,
                store: this.config.store,
                editComponents: [...this.config.editSidebarConfig!.editComponents],
                headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(this.config.entityName),
                headerSubtitle: currentItem => of((currentItem as any).title)
            })
            .subscribe(shouldReload => {
                if (shouldReload) {
                    this._searchRequestService.forceReload();
                }
            });
    }
}
