import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, map, of } from 'rxjs';

import { BaseComponent } from '../../../../../components/abstractions/base.component';
import { GeneralDataService } from '../../../../../core/services/general-data.service';
import { KeyValueModel } from '../../../../../core/models/key-value.model';
import { ArticleGroupsStore } from '../../../../services/stores/article-groups.store';
import { AccountingExportRecordsStore } from '../../../../services/stores/accounting-export-records.store';
import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { AccountingExportRecordListModel } from '../../../../models/responses/accounting-export-record-list.model';
import { AccountingRecordMultiCreateModel } from '../../../../models/requests/accounting-record-multi-create.model';
import { ToasterService } from '../../../../../core/services/toaster.service';

@Component({
    selector: 'arc-accounting-records-multi-create-dialog',
    templateUrl: './accounting-records-multi-create-dialog.component.html',
    styleUrl: './accounting-records-multi-create-dialog.component.scss'
})
export class AccountingRecordsMultiCreateDialogComponent extends BaseComponent implements OnInit {
    readonly isLoading = signal(true);
    readonly isCreating = signal(false);
    readonly articleTaxes = signal<KeyValueModel[]>([]);
    readonly branches = signal<KeyValueModel[]>([]);
    readonly articleGroups = signal<KeyValueModel[]>([]);
    readonly taxesSelected = signal(true);
    readonly branchesSelected = signal(true);
    readonly articleGroupsSelected = signal(true);

    readonly currentTax = computed(() => {
        const articleTaxes = this.articleTaxes();
        return articleTaxes.find(t => t.value?.toString() === this.data.item.taxRate?.toString());
    });

    readonly otherTaxesString = computed(() => {
        const articleTaxes = this.articleTaxes();
        const currentTax = this.currentTax();
        return articleTaxes
            .filter(t => t.key !== currentTax?.key)
            .map(tax => `${(+tax.value! * 1000) / 10}%`)
            .join(', ');
    });

    readonly currentBranch = computed(() => {
        const branches = this.branches();
        return branches.find(t => t.key === this.data.item.branchId);
    });

    readonly otherBranchesString = computed(() => {
        const branches = this.branches();
        const currentBranch = this.currentBranch();
        return branches
            .filter(b => b.key !== currentBranch?.key)
            .map(branch => branch.value)
            .join(', ');
    });

    readonly currentArticleGroup = computed(() => {
        const articleGroups = this.articleGroups();
        return articleGroups.find(t => t.key === this.data.item.articleGroupId);
    });

    readonly otherArticleGroupsString = computed(() => {
        const articleGroups = this.articleGroups();
        const currentArticleGroup = this.currentArticleGroup();
        return articleGroups
            .filter(a => a.key !== currentArticleGroup?.key)
            .map(group => group.value)
            .join(', ');
    });

    readonly creatingCount = computed(
        () =>
            (this.taxesSelected() && this.articleTaxes().length > 0 ? this.articleTaxes().length : 1) *
                (this.branchesSelected() && this.branches().length > 0 ? this.branches().length : 1) *
                (this.articleGroupsSelected() && this.articleGroups().length > 0 ? this.articleGroups().length : 1) -
            1
    );

    private readonly data: { item: AccountingExportRecordListModel } = inject(MAT_DIALOG_DATA);
    private readonly generalDataService = inject(GeneralDataService);
    private readonly articleGroupsStore = inject(ArticleGroupsStore);
    private readonly accountingExportRecordsStore = inject(AccountingExportRecordsStore);
    private readonly toasterService = inject(ToasterService);
    private readonly matDialogRef = inject(MatDialogRef);

    ngOnInit(): void {
        const articleTaxesResult = !!this.data.item.taxRate
            ? this.generalDataService.getGeneralData(GeneralDataTypeEnum.ArticleTaxes)
            : of([]);
        const branchesResult = !!this.data.item.branchId ? this.generalDataService.getGeneralData(GeneralDataTypeEnum.Branches) : of([]);
        const siblingGroupsResult = !!this.data.item.articleGroupId
            ? this.articleGroupsStore.getSiblingGroups(this.data.item.articleGroupId).pipe(map(res => res.value))
            : of([]);
        forkJoin({ articleTaxesResult, branchesResult, siblingGroupsResult }).subscribe(results => {
            this.articleTaxes.set(results.articleTaxesResult || []);
            this.taxesSelected.set(!!results.articleTaxesResult);

            this.branches.set(results.branchesResult || []);
            this.branchesSelected.set(!!results.branchesResult);

            this.articleGroups.set(results.siblingGroupsResult || []);
            this.articleGroupsSelected.set(!!results.siblingGroupsResult);

            this.isLoading.set(false);
        }).add(() => this.isCreating.set(false));
    }

    create(): void {
        const requestModel: AccountingRecordMultiCreateModel = {
            accountingRecordId: this.data.item.id,
            taxesSelected: this.taxesSelected(),
            branchesSelected: this.branchesSelected(),
            articleGroupsSelected: this.articleGroupsSelected()
        };
        this.isCreating.set(true);
        this.accountingExportRecordsStore.multiCreate(requestModel).subscribe(response => {
            if (response.value) {
                this.matDialogRef.close(true);
            } else {
                this.toasterService.showError('General.Alert.UnexpectedError');
                this.isCreating.set(false);
            }
        });
    }
}
