import { Component, effect, inject, signal, untracked } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';
import { InvoicesStore } from '../../../../../services/stores/invoices.store';
import { SimpleTransactionModel } from '../../../../../models/simple-transaction.model';

@Component({
    selector: 'arc-invoice-transactions',
    templateUrl: './invoice-transactions.detail-widget.html',
    styleUrls: ['./invoice-transactions.detail-widget.scss']
})
export class InvoiceTransactionsDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> {
    isLoading = signal(true);
    transactions = signal<SimpleTransactionModel[]>([]);

    private readonly invoicesStore = inject(InvoicesStore);

    constructor() {
        super();

        effect(() => {
            const entityModel = this.entityModel();
            untracked(() => {
                this.transactions.set(entityModel.transactions || []);
                if (entityModel.transactions.length < 5) {
                    this.isLoading.set(false);
                    return;
                }

                this.invoicesStore.getTransactions(this.entityModel().id).subscribe(r => {
                    this.transactions.set(r.value || []);
                    this.isLoading.set(false);
                });
            });

        });
    }
}
