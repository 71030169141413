<h1 mat-dialog-title>
    <ng-container *ngIf="!!data.model.title; else ctaNameTpl">
        {{ data.model.title }}
    </ng-container>
    <ng-template #ctaNameTpl>
        {{ data.contextAction.name }}
    </ng-template>
</h1>
<mat-dialog-content class="flex-1 overflow-auto !p-4">
    <div class="mb-4">
        <arc-dynamic-form #dynamicForm *ngIf="data.model.fields.length > 0; else loadingTpl" [formFields]="data.model.fields"
            (formSubmitted)="onFormSubmitted($event)"></arc-dynamic-form>
        <ng-template #loadingTpl>
            <div class="grid place-items-center">
                <mat-spinner [diameter]="64"></mat-spinner>
            </div>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
        {{ 'General.Actions.Cancel' | transloco }}
    </button>
    <button mat-flat-button (click)="onSave()" [attr.color]="color" [color]="color">
        <mat-icon *ngIf="data.contextAction.icon">{{ data.contextAction.icon }}</mat-icon>
        <ng-container *ngIf="!!data.model.actionButtonText; else actionButtonNameTpl">
            {{ data.model.actionButtonText }}
        </ng-container>
        <ng-template #actionButtonNameTpl>
            {{ 'General.Actions.Save' | transloco }}
        </ng-template>
    </button>
</mat-dialog-actions>
