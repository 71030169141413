import { Component, inject, signal } from '@angular/core';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl, ArcFormControlInterface } from '../../../../../../core/utils/arc-form-control';
import { RoleEditModel } from '../../../../../models/role-edit.model';
import { RoleModel } from '../../../../../models/role.model';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { PermissionsStore } from '../../../../../services/stores/permissions.store';
import { ColumnFilterModel } from '../../../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../../core/models/enums/filter-item-type.enum';
import { PermissionModel } from '../../../../../models/permission.model';

@Component({
    selector: 'arc-role-edit-pos-permissions',
    templateUrl: './role-edit-pos-permissions.component.html',
    styleUrl: './role-edit-pos-permissions.component.scss'
})
export class RoleEditPosPermissionsComponent extends BaseEditSidebarItemComponent<RoleModel, RoleEditModel> {
    controls = signal<ArcFormControlInterface<boolean>[]>([]);
    permissions = signal<PermissionModel[]>([]);

    private readonly permissionsStore = inject(PermissionsStore);

    override onItemSet(): void {
        this.permissionsStore
            .search(new SearchRequestModel({
                filters: [
                    new ColumnFilterModel({
                        column: 'PosScope',
                        values: [true],
                        comparisonOperator: ComparisonOperatorsEnum.True,
                        dataType: FilterItemTypeEnum.Boolean
                    })
                ],
                pageSize: 999
            }))
            .subscribe(resp => {
                if (!!resp.value) {
                    this.permissions.set(resp.value.records);
                    this.permissions().forEach(p => {
                        const posPermission = this.item().posPermissions.find(pp => pp.permissionId === p.id);
                        this.controls.update((c) => {
                            c.push(new ArcFormControl<boolean>(posPermission?.permit || false));
                            return [...c];
                        });
                    });
                }
            });
    }

    override prepareSaveModel(): Partial<RoleEditModel> {
        for (let i = 0; i < this.controls().length; i++) {
            const isAllowed = this.controls()[i].value;
            const permission = this.permissions()[i];
            const posPermission = this.item().posPermissions.find(pp => pp.permissionId === permission.id);

            if (!!posPermission) {
                posPermission.permit = isAllowed;
                posPermission.permitCreate = isAllowed;
                posPermission.permitUpdate = isAllowed;
                posPermission.permitDelete = isAllowed;
            } else if (isAllowed) {
                this.item().posPermissions.push({
                    id: 0,
                    description: permission.title,
                    permissionId: permission.id,
                    permit: isAllowed,
                    permitCreate: isAllowed,
                    permitUpdate: isAllowed,
                    permitDelete: isAllowed
                });
            }
        }

        return { posPermissions: this.item().posPermissions };
    }

    override hasUnsavedChanges(): boolean {
        return this.controls().some(c => c.dirty);
    }
}
