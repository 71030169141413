import { Component, HostListener, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';

import { AuthService } from '../../../../core/services/auth.service';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { SecuritySessionStorage } from '../../../../core/services/storages/security.session-storage';
import { AuthenticatedUserModel } from '../../../models/authenticated-user.model';

@Component({
    selector: 'arc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    formGroup: FormGroup;
    isCheckingAuth = true;
    showPasswordClearText = false;
    isSupporterLogin = false;
    isSuccessfulLogin = false;
    subdomain = '';
    selectedUserToImpersonate?: AuthenticatedUserModel;
    usersToImpersonate: AuthenticatedUserModel[] = [];

    private get isUserSupporter(): boolean {
        return this.formGroup.value.user.startsWith('s:');
    }

    private readonly router = inject(Router);
    private readonly formBuilder = inject(FormBuilder);
    private readonly authService = inject(AuthService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly securitySessionStorage = inject(SecuritySessionStorage);

    constructor() {
        this.formGroup = this.formBuilder.group({
            user: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    @HostListener('document:keydown.enter')
    onKeydownHandler(): void {
        if (!this.isCheckingAuth) {
            this.login();
        }
    }

    ngOnInit(): void {
        this.subdomain = this.environmentService.getSubdomain();
        this.authService.isLoggedIn()
            .pipe(
                finalize(() => this.isCheckingAuth = false)
            )
            .subscribe(isLoggedIn => {
                this.isCheckingAuth = false;

                if (isLoggedIn) {
                    this.authService.redirectFromLogin();
                }
            });
    }

    login(): void {
        if (this.isSupporterLogin && this.isSuccessfulLogin) {
            this.authService.impersonate(this.selectedUserToImpersonate!);
            return;
        }

        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.isCheckingAuth = true;

        this.authService.login(
            {
                ...this.formGroup.value,
                subdomain: this.subdomain
            },
            !this.isUserSupporter
        ).pipe(
            finalize(() => {
                this.isCheckingAuth = false;

                if (this.isUserSupporter) {
                    this.usersToImpersonate = this.securitySessionStorage.getUserInfo()?.usersToImpersonate || [];
                    this.isSupporterLogin = true;

                    if (this.usersToImpersonate.length > 0) {
                        this.selectedUserToImpersonate = this.usersToImpersonate[0];
                    }
                }
            })
        ).subscribe(isLoggedIn => this.isSuccessfulLogin = isLoggedIn);
    }

    redirectToPasswordRecover(): void {
        this.router.navigate(['request-password-reset']).then();
    }
}
