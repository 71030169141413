import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { ArticleGroupModel } from '../../models/article-group.model';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';

@Injectable({
    providedIn: 'root'
})
export class GeneralDataStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'general-data' }));
    }

    getUnreadMessagesCount(): Observable<ApiResponseModel<number>> {
        return this._requestService.makeGet<ApiResponseModel<number>>(this.getUrl('messages-count'));
    }

    getGeneralData(type: GeneralDataTypeEnum): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl(type));
    }

    getArticleGroupsExtended(): Observable<ApiResponseModel<ArticleGroupModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleGroupModel[]>>(this.getUrl('article-groups/extended'));
    }

    translate(text: string, targetLanguage: string, sourceLanguage: string): Observable<ApiResponseModel<string>> {
        return this._requestService.makePost<ApiResponseModel<string>>(this.getUrl('translate'), { text, targetLanguage, sourceLanguage });
    }
}
