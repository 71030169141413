import { Component, computed, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { VoucherListModel } from '../../../../../models/responses/voucher-list.model';
import { VoucherModel } from '../../../../../models/voucher.model';
import { Tools } from '../../../../../../core/utils/tools';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-voucher-type',
    templateUrl: './voucher-type.detail-widget.html',
    styleUrls: ['./voucher-type.detail-widget.scss']
})
export class VoucherTypeDetailWidget extends BaseDetailWidget<VoucherListModel, VoucherModel> {
    readonly bonusProgramPermission: true | AuthPermissionModel;
    readonly today = new Date();

    readonly isDiscountNumber = computed(() => Tools.Utils.isNumber(this.entityModel().type.discount));
    readonly isValidFromAmountNumber = computed(() => Tools.Utils.isNumber(this.entityModel().type.validFromAmount));
    readonly isValidForArticleMinPriceNumber = computed(() => Tools.Utils.isNumber(this.entityModel().type.validForArticleMinPrice));
    readonly voucherTypeTags = computed(() => this.entityModel().type.tags.map(t => t.title).join(', '));

    private readonly permissionService = inject(PermissionService);

    constructor() {
        super();
        this.bonusProgramPermission = this.permissionService.hasPermission(PermissionsEnum.CustomerBonusPrograms);
    }
}
