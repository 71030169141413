@if (formGroup(); as formGroup) {
    <div class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4"
        [arcQuickFeedback]="shouldShowQuickFeedback() ? '41' : undefined" [quickFeedbackPosition]="{ top: 10, left: 341 }">
        @if (!!formGroup.controls.street) {
            <arc-autocomplete [ngClass]="!!formGroup.controls.streetNumber ? 'col-span-9 ssm:col-span-12' : 'col-span-12'"
                [formControl]="formGroup.controls.street" label="General.Address.Street" [searchFn]="searchFn"
                [optionDisplayAccessor]="optionDisplayFn" valueAccessor="street" [allowArbitraryValues]="true"
                (optionSelected)="handleAddressSelected($event)" (blurred)="onStreetControlBlur()">
            </arc-autocomplete>
        }

        @if (!!formGroup.controls.streetNumber) {
            <arc-input class="col-span-3 ssm:col-span-12" labelKey="General.Address.StreetNumber"
                [control]="formGroup.controls.streetNumber">
            </arc-input>
        }

        @if (!!formGroup.controls.streetSupplement) {
            <arc-input [ngClass]="!!formGroup.controls.isPoBox && !!formGroup.controls.poBox ? 'col-span-6 ssm:col-span-12' : 'col-span-12'"
                labelKey="General.Address.StreetSupplement" [control]="formGroup.controls.streetSupplement">
            </arc-input>
        }

        @if (!!formGroup.controls.isPoBox && !!formGroup.controls.poBox) {
            <div class="col-span-6 ssm:col-span-12 flex gap-4 items-center">
                <arc-checkbox labelKey="General.Address.IsPoBox" [control]="formGroup.controls.isPoBox" />
                <arc-input class="min-w-[100px] grow" labelKey="General.Address.PoBox" [control]="formGroup.controls.poBox" />
            </div>
        }

        <arc-place-select class="col-span-12" [formGroup]="formGroup.controls.place" />
    </div>
}
