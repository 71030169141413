import { Component, effect, inject, untracked, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AccountingExportRecordModel } from '../../../../../models/accounting-export-record.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { AccountingExportRecordsEditDataComponent } from '../accounting-export-records-edit-data/accounting-export-records-edit-data.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { TreeAutocompleteConfigsService } from '../../../../../services/tree-autocomplete-configs.service';

@Component({
    selector: 'arc-accounting-export-records-edit-filter',
    templateUrl: './accounting-export-records-edit-filter.component.html',
    styleUrl: './accounting-export-records-edit-filter.component.scss'
})
export class AccountingExportRecordsEditFilterComponent extends BaseEditSidebarItemComponent<AccountingExportRecordModel> {
    override formGroup = new FormGroup({
        // eslint-disable-next-line no-null/no-null
        taxRate: new ArcFormControl<number | null>(null),
        articleGroupId: new ArcFormControl<OptionalType<number>>(undefined),
        exclArticleGroupId: new ArcFormControl<OptionalType<number>>(undefined),
        // eslint-disable-next-line no-null/no-null
        isNegativeAmount: new ArcFormControl<boolean | null>(null),
        branchId: new ArcFormControl<OptionalType<number>>(undefined),
        storeId: new ArcFormControl<OptionalType<number>>(undefined),
        customerProfileId: new ArcFormControl<OptionalType<number>>(undefined),
        transactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        transactionPurposeId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    isPayment: WritableSignal<OptionalType<boolean>>;
    articleTaxes: ButtonToggleModel<number | null>[] = [];
    articleAmounts: ButtonToggleModel<boolean | null>[] = [];
    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.isPayment = this.sharedDataService.getOrCreateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal);
        effect(() => {
            const isPayment = this.isPayment();
            untracked(() => {
                if (isPayment) {
                    this.formGroup.controls.taxRate.disable();
                    this.formGroup.controls.articleGroupId.disable();
                    this.formGroup.controls.exclArticleGroupId.disable();
                    this.formGroup.controls.transactionTypeId.enable();
                } else {
                    this.formGroup.controls.taxRate.enable();
                    this.formGroup.controls.articleGroupId.enable();
                    this.formGroup.controls.exclArticleGroupId.enable();
                    this.formGroup.controls.transactionTypeId.disable();
                }
            });
        });
    }
    onItemSet(): void {
        this.articleAmounts = [
            // eslint-disable-next-line no-null/no-null
            { label: this.translationService.getText('ExtAccountingExport.Edit.Filter.All'), value: null },
            { label: this.translationService.getText('ExtAccountingExport.Edit.Filter.OnlyNegatives'), value: true },
            { label: this.translationService.getText('ExtAccountingExport.Edit.Filter.OnlyPositives'), value: false }
        ];
        const item = this.item();
        // eslint-disable-next-line no-null/no-null
        this.formGroup.patchValue({ ...item, taxRate: item.taxRate ?? null, isNegativeAmount: item.isNegativeAmount ?? null });
        this.generalDataStore.getGeneralData(GeneralDataTypeEnum.ArticleTaxes).subscribe(r => {
            r.value
                ?.sort((kv1, kv2) => +kv1.value! - +kv2.value!)
                .forEach(kv => this.articleTaxes.push({ value: +kv.value!, label: `${(+kv.value! * 1000) / 10}%` }));
            // eslint-disable-next-line no-null/no-null
            this.articleTaxes.unshift({ value: null, label: this.translationService.getText('ExtAccountingExport.Edit.Filter.All') });
            if (item.taxRate !== undefined && !this.articleTaxes.find(t => t.value?.toString() === item.taxRate?.toString())) {
                this.formGroup.patchValue({ taxRate: undefined });
            }
        });
    }

    prepareSaveModel(): Partial<AccountingExportRecordModel> {
        const value = this.formGroup.value;
        if (this.isPayment()) {
            return {
                branchId: value.branchId,
                storeId: value.storeId,
                customerProfileId: value.customerProfileId,
                isNegativeAmount: value.isNegativeAmount ?? undefined,
                transactionTypeId: value.transactionTypeId,
                transactionPurposeId: value.transactionPurposeId
            };
        } else {
            return {
                taxRate: value.taxRate ?? undefined,
                articleGroupId: value.articleGroupId,
                exclArticleGroupId: value.exclArticleGroupId,
                isNegativeAmount: value.isNegativeAmount ?? undefined,
                branchId: value.branchId,
                storeId: value.storeId,
                customerProfileId: value.customerProfileId,
                transactionPurposeId: value.transactionPurposeId
            };
        }
    }
}
