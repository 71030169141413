import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { QueryDesignerEditModel } from '../../../../../models/query-designer-edit.model';
import { QueryDesignerModel } from '../../../../../models/query-designer.model';

@Component({
    selector: 'arc-query-designer-edit-base-data',
    templateUrl: './query-designer-edit-base-data.component.html',
    styleUrls: ['./query-designer-edit-base-data.component.scss']
})
export class QueryDesignerEditBaseDataComponent extends BaseEditSidebarItemComponent<QueryDesignerModel, QueryDesignerEditModel> {
    override formGroup = new FormGroup({
        name: new ArcFormControl('', Validators.required),
        description: new ArcFormControl()
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<QueryDesignerEditModel> {
        return { ...this.formGroup.getRawValue() };
    }
}
