import { Component, computed, inject, input } from '@angular/core';
import { getLocaleDayNames, FormStyle, TranslationWidth } from '@angular/common';

import { TranslationService } from '../../../../core/services/translation.service';
import { SupplierModel } from '../../../../app/models/supplier.model';
import { OptionalType } from '../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-supplier-data',
    templateUrl: './supplier-data.component.html',
    styleUrls: ['./supplier-data.component.scss']
})
export class SupplierDataComponent {
    readonly supplier = input.required<OptionalType<SupplierModel>>();

    /**
     * Removes the protocol from the url like 'http://' or 'https://'
     */
    readonly websiteUrl = computed(() => {
        const url = this.supplier()?.contactUrl ?? '';
        return url.slice(url.indexOf('//') + 1);
    });

    readonly deliveryDays = computed(() => {
        const supplier = this.supplier();
        return !supplier?.personDeliveryCondition
            ? ''
            : this.weekDays.filter((v, i) => this.getDeliveryDaysArray(supplier).includes(i)).join(', ');
    });

    private readonly translationService = inject(TranslationService);
    private readonly weekDays = getLocaleDayNames(this.translationService.current.code, FormStyle.Standalone, TranslationWidth.Abbreviated);

    private getDeliveryDaysArray(supplier?: SupplierModel): number[] {
        const days: number[] = [];

        if (!supplier) {
            return days;
        }

        if (!!supplier.personDeliveryCondition?.sunday) {
            days.push(0);
        }

        if (!!supplier.personDeliveryCondition?.monday) {
            days.push(1);
        }

        if (!!supplier.personDeliveryCondition?.tuesday) {
            days.push(2);
        }

        if (!!supplier.personDeliveryCondition?.wednesday) {
            days.push(3);
        }

        if (!!supplier.personDeliveryCondition?.thursday) {
            days.push(4);
        }

        if (!!supplier.personDeliveryCondition?.friday) {
            days.push(5);
        }

        if (!!supplier.personDeliveryCondition?.saturday) {
            days.push(6);
        }

        return days;
    }
}
