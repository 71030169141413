@if(isPayment()){
    <div class="flex flex-col gap-4">
        <span>{{'ExtAccountingExport.Edit.Filter.FilterTextPayments' | transloco}}</span>
        <div class="grid grid-cols-2 gap-4">
            <div class="flex items-baseline gap-4 col-span-2">
                <span>{{'ExtAccountingExport.Edit.Filter.Amounts' | transloco}}</span>
                <arc-button-toggle-group toggleGroupClasses="grow" class="grow" [control]="formGroup.controls.isNegativeAmount"
                    [items]="articleAmounts">
                </arc-button-toggle-group>
            </div>
            <arc-general-data-select [formControl]="formGroup.controls.branchId" [generalDataType]="GeneralDataTypeEnum.Branches"
                label="ExtAccountingExport.Edit.Filter.Branch" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.storeId" [generalDataType]="GeneralDataTypeEnum.Stores"
                label="ExtAccountingExport.Edit.Filter.Store" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.customerProfileId"
                [generalDataType]="GeneralDataTypeEnum.CustomerProfiles" label="ExtAccountingExport.Edit.Filter.CustomerProfile"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <div></div>
            <arc-general-data-select [formControl]="formGroup.controls.transactionTypeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionTypes" label="ExtAccountingExport.Edit.Filter.TransactionType"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.transactionPurposeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionPurposes" label="ExtAccountingExport.Edit.Filter.TransactionPurpose"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
        </div>
    </div>
} @else {
    <div class="flex flex-col gap-4">
        <span>{{'ExtAccountingExport.Edit.Filter.FilterTextArticleProperties' | transloco}}</span>
        <div class="flex items-baseline gap-4">
            <span>{{'ExtAccountingExport.Edit.Filter.TaxRate' | transloco}}</span>
            <arc-button-toggle-group [control]="formGroup.controls.taxRate" toggleGroupClasses="grow"
                [items]="articleTaxes">
            </arc-button-toggle-group>
        </div>
        <div class="flex gap-4 items-baseline">
            <arc-tree-autocomplete label="ExtAccountingExport.Edit.Filter.ArticleGroup" class="grow"
                [formControl]="formGroup.controls.articleGroupId"
                [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfigForSelection" [hasFloatingLabel]="true"
                [placeholder]="'General.All' | transloco">
            </arc-tree-autocomplete>
            <span>{{'ExtAccountingExport.Edit.Filter.Without' | transloco}}</span>
            <arc-tree-autocomplete label="ExtAccountingExport.Edit.Filter.ArticleGroup" class="grow"
                [formControl]="formGroup.controls.exclArticleGroupId"
                [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfigForSelection" [hasFloatingLabel]="true"
                placeholder="-">
            </arc-tree-autocomplete>
        </div>
        <div class="flex items-baseline gap-4">
            <span>{{'ExtAccountingExport.Edit.Filter.ArticleAmount' | transloco}}</span>
            <arc-button-toggle-group toggleGroupClasses="grow" [control]="formGroup.controls.isNegativeAmount"
                [items]="articleAmounts">
            </arc-button-toggle-group>
        </div>
        <span class="mt-4">{{'ExtAccountingExport.Edit.Filter.FilterTextTransactions' | transloco}}</span>
        <div class="grid grid-cols-2 gap-4">
            <arc-general-data-select [formControl]="formGroup.controls.branchId" [generalDataType]="GeneralDataTypeEnum.Branches"
                label="ExtAccountingExport.Edit.Filter.Branch" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.storeId" [generalDataType]="GeneralDataTypeEnum.Stores"
                label="ExtAccountingExport.Edit.Filter.Store" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.customerProfileId"
                [generalDataType]="GeneralDataTypeEnum.CustomerProfiles" label="ExtAccountingExport.Edit.Filter.CustomerProfile"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.transactionPurposeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionPurposes" label="ExtAccountingExport.Edit.Filter.TransactionPurpose"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
        </div>
    </div>
}
