import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, inject, signal } from '@angular/core';

import { ToasterService } from '../../../core/services/toaster.service';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { OptionalType } from '../../../core/models/types/optional.type';
import { CamtFilePreviewModel } from '../../../app/models/camt-file-preview.model';
import { CamtUploadService } from '../../../core/services/camt-upload.service';
import { CurrencyColumnModel } from '../../dynamic-table/models/column-types/currency-column.model';
import { StackedColumnModel } from '../../dynamic-table/models/column-types/stacked-column.model';
import { CustomColumnModel } from '../../dynamic-table/models/column-types/custom-column.model';
import { CamtStatusColumnComponent } from './camt-status-column/camt-status-column.component';
import { StaticTableConfigModel } from '../../list-views/static-table/models/static-table-config.model';
import { DateColumnModel } from '../../dynamic-table/models/column-types/date-column.model';
import { BaseComponent } from '../../abstractions/base.component';

@Component({
    selector: 'arc-camt-file-dialog',
    templateUrl: './camt-file-dialog.component.html',
    styleUrls: ['./camt-file-dialog.component.scss']
})
export class CamtFileDialogComponent extends BaseComponent implements OnInit {
    readonly fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    readonly isLoading = signal(false);
    readonly previewData = signal<CamtFilePreviewModel[]>([]);

    readonly previewTableConfig: StaticTableConfigModel = {
        defaultPageSize: 10,
        availableColumns: [
            new StackedColumnModel({
                propertyName: 'personDisplayName',
                propertyName2: 'reference',
                columnTitleKey: 'Components.CamtFileUpload.Invoice',
                widthPixels: 200
            }),
            new CurrencyColumnModel({
                propertyName: 'openAmount',
                columnTitleKey: 'Components.CamtFileUpload.InvoiceAmount',
                widthPixels: 200
            }),
            new CurrencyColumnModel({
                propertyName: 'amount',
                columnTitleKey: 'Components.CamtFileUpload.PaidAmount',
                widthPixels: 200
            }),
            new DateColumnModel({
                propertyName: 'valuta',
                columnTitleKey: 'Components.CamtFileUpload.PaidDate',
                widthPixels: 200
            }),
            new CustomColumnModel({
                propertyName: 'status',
                columnTitleKey: 'Components.CamtFileUpload.Status',
                customComponent: CamtStatusColumnComponent
            })
        ]
    };

    private readonly dialogRef = inject(MatDialogRef<CamtFileDialogComponent>);
    private readonly camtUploadService = inject(CamtUploadService);
    private readonly toasterService = inject(ToasterService);

    ngOnInit(): void {
        this.addSubscriptions(this.fileControl.valueChanges.subscribe(value => this.getFilePreview(value)));
    }

    getFilePreview(file?: File): void {
        this.isLoading.set(true);
        if (!!file) {
            this.camtUploadService
                .getCamtFilePreview(file)
                .subscribe(result => {
                    this.previewData.set(result);
                })
                .add(() => {
                    this.isLoading.set(false);
                });
        }
    }

    uploadCamtFile(): void {
        this.isLoading.set(true);
        this.camtUploadService.uploadCamtFile(this.fileControl.value!).subscribe(result => {
            let shouldReload = false;
            if (result) {
                this.toasterService.showSuccess('Components.CamtFileUpload.UploadSucceed');
                shouldReload = true;
            }
            this.isLoading.set(false);
            this.dialogRef.close(shouldReload);
        });
    }

    getPayableInvoicesCount(): number {
        return this.previewData().filter(d => !d.errorMessage).length;
    }
}
