<div class="!m-4 flex flex-col gap-4">
    <div>
        <p class="text-on-app-light">{{ 'TransactionTypes.List.ConfigurationType' | transloco }}</p>
        <p class="text-xl">
            {{ 'Enums.TransactionTypeConfigurationsEnum.' + TransactionTypeConfigurations[item().configurationType] | transloco}}
        </p>
    </div>
    @if(!!item().description){
        <div>
            <p class="text-on-app-light">{{ 'TransactionTypes.List.Description' | transloco }}</p>
            <p class="text-xl">{{ item().description }}</p>
        </div>
    }
    @if(!!item().requiredRole){
        <div>
            <p class="text-on-app-light">{{ 'TransactionTypes.List.RequiredRole' | transloco }}</p>
            <p class="text-xl">{{ item().requiredRole }}</p>
        </div>
    }
</div>
