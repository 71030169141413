<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <div class="flex gap-2 smd:flex-col">
        <arc-translation-input labelKey="PaymentTypes.Edit.BaseData.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
            [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
            [controlIt]="formGroup.controls.title_it" class="grow">
        </arc-translation-input>
        <arc-translation-input labelKey="PaymentTypes.Edit.BaseData.DebitTitle" translationDialogTitleKey="General.Actions.TranslateTitle"
            [controlDe]="formGroup.controls.titleDebit_de" [controlEn]="formGroup.controls.titleDebit_en"
            [controlFr]="formGroup.controls.titleDebit_fr" [controlIt]="formGroup.controls.titleDebit_it" class="grow">
        </arc-translation-input>
    </div>
    <div class="flex gap-2 smd:flex-col">
        <arc-select class="grow" label="PaymentTypes.Edit.BaseData.Currency" [formControl]="formGroup.controls.currencyId"
            [options]="currencyItems()">
        </arc-select>
        <arc-number-input class="grow" label="PaymentTypes.Edit.BaseData.RoundTo" [decimalPlaces]="2"
            [formControl]="formGroup.controls.roundTo"></arc-number-input>
    </div>
    <div class="flex gap-2 smd:flex-col">
        <arc-checkbox labelKey="PaymentTypes.Edit.BaseData.OpenCashDrawer" [control]="formGroup.controls.openCashDrawer">
        </arc-checkbox>
        <arc-checkbox labelKey="PaymentTypes.Edit.BaseData.Cash" [control]="formGroup.controls.isCash">
        </arc-checkbox>
    </div>
    <div class="flex gap-2 smd:flex-col">
        <arc-general-data-select class="grow" label="PaymentTypes.Edit.BaseData.AccountType"
            [formControl]="formGroup.controls.accountTypeId" [generalDataType]="GeneralDataTypeEnum.AccountTypes">
        </arc-general-data-select>
        <arc-select class="grow" label="PaymentTypes.Edit.BaseData.PaymentProcessing" [formControl]="formGroup.controls.paymentProcessing"
            [options]="paymentProcessingOptions">
        </arc-select>
    </div>
    <div class="flex gap-2 smd:flex-col">
        <arc-input class="grow" [control]="formGroup.controls.sort" labelKey="PaymentTypes.Edit.BaseData.Sort" type="number">
        </arc-input>
        <arc-general-data-select class="grow" label="PaymentTypes.Edit.BaseData.RequiredRole" [generalDataType]="GeneralDataTypeEnum.Roles"
            [formControl]="formGroup.controls.requiredRoleId">
        </arc-general-data-select>
    </div>
</form>
