import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ArticleGroupModel } from '../../models/article-group.model';
import { ArticleGroupListModel } from '../../models/responses/article-group-list.model';
import { ArticleGroupEditModel } from '../../models/responses/article-group-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleGroupsStore
    extends BaseCrudStore<ArticleGroupModel, ArticleGroupListModel, ArticleGroupModel, ArticleGroupEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'article-groups' }));
    }

    getMainGroups(): Observable<ApiResponseModel<ArticleGroupListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleGroupListModel[]>>(this.getUrl('main-groups'));
    }

    getSubGroups(parentGroupId: number): Observable<ApiResponseModel<ArticleGroupListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleGroupListModel[]>>(this.getUrl(`sub-groups/${parentGroupId}`));
    }

    updateParentGroup(id: number, newParentGroupId: number): Observable<boolean> {
        return this._requestService.makePut<boolean>(this.getUrl(`update-parent-group/${id}/${newParentGroupId}`));
    }

    moveArticles(currentGroupId: number, newGroupId: number): Observable<boolean> {
        return this._requestService.makePut<boolean>(this.getUrl(`move-articles/${currentGroupId}/${newGroupId}`));
    }

    getSiblingGroups(id: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl(`sibling-groups/${id}`));
    }
}
