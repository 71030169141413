import { Component, computed, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';
import { DateService } from '../../../../../../core/services/date.service';

@Component({
    selector: 'arc-invoice',
    templateUrl: './invoice.detail-widget.html',
    styleUrls: ['./invoice.detail-widget.scss']
})
export class InvoiceDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> {
    primaryButton = computed(() => {
        const listModel = this.listModel();
        if (listModel.canSend) {
            return { key: 'InvoiceSendContextAction', titleKey: 'General.Actions.Send' };
        } else if (listModel.canPay) {
            return { key: 'InvoicePayContextAction', titleKey: 'General.Paid' };
        }
        return undefined;
    });
    secondaryButton = computed(() => {
        const listModel = this.listModel();
        const entityModel = this.entityModel();
        if (listModel.canCancel) {
            return { key: 'InvoiceCancelContextAction', titleKey: 'General.Actions.Annulate' };
        } else if (listModel.canSendReminder) {
            const titleKey = entityModel.reminderDate1 ? 'Invoices.List.ReminderDate2' : 'Invoices.List.ReminderDate1';
            return { key: 'InvoiceSendReminderContextAction', titleKey };
        }
        return undefined;
    });

    paymentDiffDays = computed(() => {
        const entityModel = this.entityModel();
        if (!entityModel.datePaid) {
            return undefined;
        }
        return this.dateService.diff(entityModel.dueDate, entityModel.datePaid);
    });
    paymentDiffClass = computed(() => ((this.paymentDiffDays() ?? 0) > 0 ? '!text-error' : '!text-success'));
    paymentDiffTranslationKey = computed(() => `General.Units.Days.${this.paymentDiffDays() === 1 ? 'Singular' : 'Long'}`);
    readonly today = new Date();

    private readonly dateService = inject(DateService);
}
