import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerEditRequestModel } from '../../../../../models/requests/customer-edit-request.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-customer-edit-texts',
    templateUrl: './customer-edit-texts.detail-widget.html',
    styleUrls: ['./customer-edit-texts.detail-widget.scss']
})
export class CustomerEditTextsDetailWidget extends BaseEditSidebarItemComponent<CustomerModel, CustomerEditRequestModel> {
    override formGroup = new FormGroup({
        notes: new ArcFormControl<OptionalType<string>>(undefined),
        displayWarning: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<CustomerEditRequestModel> {
        const value = this.formGroup.value;
        return {
            notes: value.notes,
            displayWarning: value.displayWarning
        };
    }
}
