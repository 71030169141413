import { Component, HostBinding, OnInit, inject } from '@angular/core';

import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { CreateTicketStepEnum } from '../../../../app/models/enums/create-ticket-step.enum';

@Component({
    selector: 'arc-new-ticket',
    templateUrl: './new-ticket.component.html',
    styleUrl: './new-ticket.component.scss'
})
export class NewTicketComponent implements OnInit {
    @HostBinding('class') classList = 'flex flex-col grow';
    readonly createTicketService = inject(CreateTicketService);
    CreateTicketStepEnum = CreateTicketStepEnum;

    ngOnInit(): void {
        this.createTicketService.startNewTicket();
    }

    cancel(): void {
        this.createTicketService.reset();
    }
}
