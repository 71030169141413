import { Component, Signal, WritableSignal, effect, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { ArticleSupplierEditModel } from '../../../../../models/requests/article-supplier-edit.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArticleModel } from '../../../../../models/article.model';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleEditBaseDataComponent } from '../article-edit-base-data/article-edit-base-data.component';

@Component({
    selector: 'arc-article-edit-suppliers',
    templateUrl: './article-edit-suppliers.component.html',
    styleUrls: ['./article-edit-suppliers.component.scss']
})
export class ArticleEditSuppliersComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    static readonly orderNumberSignal = 'orderNumber';
    static readonly wasOrderNumberChangedByUserSignal = 'wasOrderNumberChangedByUser';
    static readonly mainSupplierSignal = 'mainSupplier';
    static readonly wasMainSupplierChangedByUserSignal = 'wasMainSupplierChangedByUser';

    ComplexDataTypesEnum = ComplexDataTypesEnum;
    override formGroup = new FormGroup({
        articleSuppliers: new ArcFormControl<ArticleSupplierEditModel[]>([])
    });
    suppliersCardConfig: EditableTableConfigModel<ArticleSupplierEditModel>;
    readonly isArticleBuyingPriceLinked: Signal<boolean>;

    private sharedDataService = inject(SharedDataService);
    private orderNumber: WritableSignal<OptionalType<string>>;
    private mainSupplier: WritableSignal<OptionalType<ArticleSupplierEditModel>>;
    private articleBuyingPrice: Signal<OptionalType<number>>;
    private readonly wasOrderNumberChangedByUser: WritableSignal<boolean>;
    private readonly wasMainSupplierChangedByUser: WritableSignal<boolean>;

    constructor() {
        super();

        this.wasOrderNumberChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditSuppliersComponent.wasOrderNumberChangedByUserSignal,
            false
        );
        this.wasMainSupplierChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditSuppliersComponent.wasMainSupplierChangedByUserSignal,
            false
        );
        this.orderNumber = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.orderNumberSignal);
        this.mainSupplier = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.mainSupplierSignal);
        this.articleBuyingPrice = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.buyingPriceInputValueSignal);
        this.isArticleBuyingPriceLinked = this.sharedDataService
            .getOrCreateSignalWithValue(ArticleEditBaseDataComponent.isBuyingPriceLinkedSignal, false);

        this.suppliersCardConfig = new EditableTableConfigModel<ArticleSupplierEditModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    personId: new ArcFormControl('', [Validators.required]),
                    isMainSupplier: new ArcFormControl(!this.formGroup.value.articleSuppliers?.some(a => a.isMainSupplier)),
                    orderNumber: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
                    unitQuantity: new ArcFormControl<number>(1, [Validators.required, CustomValidators.number()]),
                    minOrderQuantity: new ArcFormControl<number>(1, [Validators.required, CustomValidators.number()]),
                    buyingPrice: new ArcFormControl<OptionalType<number>>(this.item().buyingPrice, [
                        Validators.required,
                        CustomValidators.number()
                    ]),
                    buyingPriceExclusive: new ArcFormControl(true)
                }),
            onFormGroupValueChanged: (newValue, prevValue, formGroups) => {
                if (this.orderNumber() !== newValue.orderNumber && newValue.isMainSupplier) {
                    this.orderNumber.set(newValue.orderNumber);
                    this.wasOrderNumberChangedByUser.set(true);
                }

                if (newValue.isMainSupplier && !prevValue.isMainSupplier) {
                    // this is new main supplier, uncheck main supplier checkbox for all others
                    formGroups.forEach(fg => {
                        // eslint-disable-next-line eqeqeq
                        if (fg.value.id != newValue.id) {
                            fg.patchValue({ isMainSupplier: false });
                        }
                    });
                }
            }
        });

        // update main supplier signal on value change
        const valueChangedSub = this.formGroup.controls.articleSuppliers.valueChanges.subscribe(value => {
            const previousValue = this.mainSupplier();
            this.mainSupplier.set(value?.find(x => x.isMainSupplier));

            if (!!this.mainSupplier() && previousValue?.id !== this.mainSupplier()!.id) {
                this.wasMainSupplierChangedByUser.set(true);
            }
        });

        this.addSubscriptions(valueChangedSub);

        // effect to keep article buying price & main supplier buying price linked
        effect(() => {
            const articleBuyingPrice = this.articleBuyingPrice();
            const isArticleBuyingPriceLinked = this.isArticleBuyingPriceLinked();
            const mainSupplier = this.mainSupplier();

            const suppliers = this.formGroup.getRawValue().articleSuppliers;
            const mainSupplierIndex = suppliers.findIndex(s => s.isMainSupplier);

            if (!isArticleBuyingPriceLinked || !mainSupplier || mainSupplierIndex < 0) {
                return;
            }

            if (articleBuyingPrice !== mainSupplier.buyingPrice) {
                suppliers[mainSupplierIndex].buyingPrice = articleBuyingPrice ?? 0;
                this.formGroup.controls.articleSuppliers.setValue(suppliers);
            }
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.suppliersCardConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    onItemSet(): void {
        this.orderNumber.set(this.item().articleSuppliers.find(s => s.isMainSupplier)?.orderNumber);
        this.mainSupplier.set(this.item().articleSuppliers.find(s => s.isMainSupplier));
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return { articleSuppliers: this.formGroup.value.articleSuppliers };
    }
}
