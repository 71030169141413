<div class="arc-pos-layout-card-container w-full h-full flex flex-col gap-1 overflow-y-auto overflow-x-hidden" #container>
    <!-- top row -->
    <div class="flex" [class.gap-1]="!!item().bottomGroup">
        <!-- top left -->
        <div class="grow"></div>

        <!-- top right (top panel) -->
        <div class="grid grid-flow-row" [ngStyle]="topGridStyle()">
            @for(command of item().topGroup.commands; track $index; let i = $index) {
                <!-- skip this position if the previous command is double sized, since that one already takes 2 grid spaces -->
                @if (!item().topGroup.commands[i - 1]?.isDoubleSize) {
                    @if (command) {
                        <div [class.col-span-2]="command.isDoubleSize"
                            [style.background-color]="command.backgroundColor | transform : transformBackgroundColor">
                        </div>
                    } @else {
                        <div class="border border-dashed border-element-disabled"></div>
                    }
                }
            }
        </div>

        @if (!item().bottomGroup) {
            <div class="grow"></div>
        }
    </div>

    <!-- bottom panel -->
    @if (item().bottomGroup; as bottomGroup) {
        <div class="grid grid-flow-row" [ngStyle]="bottomGridStyle()">
            @for(command of bottomGroup.commands; track $index; let i = $index) {
                <!-- skip this position if the previous command is double sized, since that one already takes 2 grid spaces -->
                @if (!bottomGroup.commands[i - 1]?.isDoubleSize) {
                    @if (command) {
                        <div [class.col-span-2]="command.isDoubleSize"
                            [style.background-color]="command.backgroundColor | transform : transformBackgroundColor">
                        </div>
                    } @else {
                        <div class="border border-dashed border-element-disabled"></div>
                    }
                }
            }
        </div>
    }
</div>
