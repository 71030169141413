<div class="h-full w-full">
    @if (isLoading()) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32" />
        </div>
    } @else {
        @if (chartData(); as chartData) {
            <div class="h-full max-h-[170px]">
                <arc-chart [data]="chartData" type="bar" />
            </div>
        }
        <div class="flex flex-row mt-4 justify-between w-full">
            <div>
                <span class="text-on-app-light text-sm">{{ 'Customers.Detail.Turnover.ComponentTitle' | transloco }}</span>
                <div class="mt-2 text-2xl">{{ currentData()?.totalTurnover | arcCurrency }}</div>
            </div>
            <div>
                <span class="text-on-app-light text-sm">{{ 'Customers.Detail.Turnover.BonusPoints' | transloco }}</span>
                @if(bonusPointsPermission === true){
                    <div class="mt-2 text-2xl">{{ currentData()?.currentBonusPoints | arcNumber : 2 }}</div>
                } @else {
                    <arc-no-permission-lock [missingPermission]="bonusPointsPermission" />
                }
            </div>
            <div>
                <span class="text-on-app-light text-sm">{{ 'Customers.Detail.Turnover.CustomerAccountBalance' | transloco }}</span>
                <div class="mt-2 text-2xl" [class.text-error]="currentData()?.currentCustomerAccountBalance! < 0">
                    {{ currentData()?.currentCustomerAccountBalance | arcCurrency }}
                </div>
            </div>
        </div>
    }
</div>
