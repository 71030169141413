import { Directive, ViewChild, inject, Signal, signal, computed } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, of, switchMap, tap, repeat, delay, mergeMap, map } from 'rxjs';

import { BaseListViewConfigModel } from './base-list-view-config.model';
import { ActionButtonsService } from '../services/action-buttons.service';
import { ActionButtonModel } from '../models/action-button.model';
import { SearchRequestService } from '../services/search-request.service';
import { SearchRequestModel } from '../../app/models/requests/search-request.model';
import { LayoutService } from '../services/layout.service';
import { ListViewSettingsModel } from '../models/list-view-settings.model';
import { SidebarService } from '../services/sidebar.service';
import { ColumnSortModel } from '../models/column-sort.model';
import { Utils } from '../utils/tools/utils.tools';
import { OptionalType } from '../models/types/optional.type';
import { DialogService } from '../services/dialog.service';
import { Identifyable } from './identifyable';
import { SearchResponseModel } from '../../app/models/responses/search-response.model';
import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { BaseSelectableTable } from './base-selectable-table';
import { TableSelectionModel } from '../models/table-selection.model';

@Directive()
export abstract class BaseListViewComponent<
    T extends Identifyable<TId>,
    TList extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> extends BaseSelectableTable<TList> {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    isLoading = false;
    dataLoadedSubject = new Subject<boolean>();
    totalRecords = 0;
    singleRecordActionButtons: Signal<ActionButtonModel[]> = signal<ActionButtonModel[]>([]);
    currentSorting?: ColumnSortModel;
    selectionModel: TableSelectionModel<TList>;

    protected _listViewSettings = new ListViewSettingsModel();

    protected readonly _sidebarService = inject(SidebarService);
    protected readonly _actionButtonsService = inject(ActionButtonsService);
    protected readonly _searchRequestService = inject(SearchRequestService);
    protected readonly _layoutService = inject(LayoutService);
    protected readonly _matDialog = inject(MatDialog);

    private readonly dialogService = inject(DialogService);

    abstract config: BaseListViewConfigModel<T, TList, TCreate, TUpdate, TId>;

    constructor() {
        super();

        this.selectionModel = new TableSelectionModel<TList>(item => item.id);

        this._searchRequestService.init(new SearchRequestModel());

        this.singleRecordActionButtons = computed(() =>
            this._actionButtonsService
                .buttons()
                .filter(b => b.min === 1 && b.max >= 1 && !b.isHiddenOnSelection)
                .map(
                    b =>
                        new ActionButtonModel({
                            ...b,
                            isEnabled: true
                        })
                )
        );
        const loadingStartSub = this._searchRequestService.loadingStart$.subscribe(() => this.loadingCallback());
        const searchRequestChangedSub = this._searchRequestService.searchRequestChanged$
            .pipe(
                tap(() => this.loadingCallback()),
                switchMap(searchRequest => this.config.store.search(searchRequest))
            )
            .subscribe(result => this.loadData(result));

        this.addSubscriptions(loadingStartSub, searchRequestChangedSub);
    }

    /** Call this method from the components ngOnInit() Angular method. */
    onInit(): void {
        this._layoutService.getListViewSettings(this.config.entityName).subscribe(result => {
            this._listViewSettings = result ?? new ListViewSettingsModel();
            this.paginator._changePageSize(this._listViewSettings.paginatorPageSize ?? this.config.paginatorConfig.defaultPageSize);
        });
        this._searchRequestService.setPaginatorOptions(0, this.config.paginatorConfig.defaultPageSize);

        // apply default sort
        if (!!this.config.defaultSort) {
            this.currentSorting = Utils.deepCopy(this.config.defaultSort);
            this._searchRequestService.setSortings([this.currentSorting]);
        }

        if (!!this.config.pollAtEveryMs) {
            const poll = of({})
                .pipe(
                    mergeMap(() => this.config.store.search(this._searchRequestService.current)),
                    tap(result => this.loadData(result, true)),
                    delay(this.config.pollAtEveryMs),
                    repeat()
                )
                .subscribe();
            this.addSubscriptions(poll);
        }
    }

    loadingCallback(): void {
        this.isLoading = true;
    }

    afterLoadedCallback(): void { }

    handlePageEvent(event: PageEvent): void {
        if (event.pageSize !== this._listViewSettings.paginatorPageSize) {
            this._listViewSettings.paginatorPageSize = event.pageSize;
            this._layoutService.saveListViewSettings(this.config.entityName, this._listViewSettings);
        }

        this._searchRequestService.setPaginatorOptions(event.pageIndex, event.pageSize);
    }

    refresh(): void {
        this._searchRequestService.forceReload();
    }

    getItemId(id: any): string {
        return Utils.getId(id);
    }

    openEditSidebar(existingId?: TId, duplicatedItem?: T): Observable<OptionalType<boolean>> {
        if (!this.config.editSidebarConfig) {
            return of(undefined);
        }

        return this._sidebarService.openEdit<TList, T, TCreate, TUpdate, TId>(
            {
                entityName: this.config.entityName,
                store: this.config.store,
                editComponents: this.config.editSidebarConfig.editComponents,
                existingId,
                headerTitle:
                    this.config.editSidebarConfig.customHeaderTitle ??
                    this._sidebarService.getDefaultRightSidebarHeaderTitleFn<TId>(this.config.entityName),
                headerSubtitle: this.config.editSidebarConfig.headerSubtitle,
                headerAdditionalInfoComponent: this.config.editSidebarConfig.headerAdditionalInfoComponent,
                showHeaderCloseButton: this.config.editSidebarConfig.showHeaderCloseButton,
                duplicatedItem,
                propertiesExcludedFromDuplication: this.config.propertiesExcludedFromDuplication
            },
            this.config.editSidebarConfig.customMaxWidth
        );
    }

    handleCreateButtonClick(): void {
        if (!!this.config.handleCreateButtonClick) {
            this.config.handleCreateButtonClick().subscribe(shouldReload => {
                if (shouldReload) {
                    this.refresh();
                }
            });
            return;
        }

        this.openEditSidebar(undefined).subscribe(shouldReload => {
            if (shouldReload) {
                this.refresh();
            }
        });
    }

    handleEditButtonClick(item: TList): void {
        if (!!this.config.handleEditButtonClick) {
            this.config.handleEditButtonClick(item).subscribe(shouldReload => {
                if (shouldReload) {
                    this.refresh();
                }
            });

            return;
        }

        this.openEditSidebar(item.id).subscribe(shouldReload => {
            if (shouldReload) {
                this.refresh();
            }
        });
    }

    handleDeleteButtonClick(...items: TList[]): void {
        if (!!this.config.handleDeleteButtonClick) {
            this.config.handleDeleteButtonClick(items).subscribe(shouldReload => {
                if (shouldReload) {
                    this.refresh();
                }
            });

            return;
        }

        this.dialogService
            .openDeleteDialog(this.config.entityName, items.length, this.config.customDeleteConfirmationMessage)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.config.store.removeMany(items.map(i => i.id)).subscribe(response => {
                        if (response.value) {
                            this.refresh();
                        }
                    });
                }
            });
    }

    handleDuplicateButtonClick(itemId: TId): Observable<void> {
        return this.config.store.get(itemId).pipe(
            switchMap(resp =>
                this.config.transformRecordForDuplication(resp.value!).pipe(
                    map(transformedRecord => {
                        if (typeof transformedRecord.id === 'number') {
                            (transformedRecord as any).id = 0;
                        } else if (typeof transformedRecord.id === 'string') {
                            (transformedRecord as any).id = '';
                        }

                        const rightOpenSub = this._sidebarService.rightOpenSubject.subscribe(isOpen => {
                            if (isOpen) {
                                this.config.doBeforeOpenEditSidebarForDuplication(transformedRecord);
                            }
                        });

                        this.openEditSidebar(undefined, transformedRecord).subscribe(shouldReload => {
                            rightOpenSub.unsubscribe();

                            if (shouldReload) {
                                this.refresh();
                            }
                        });
                    })
                )
            )
        );
    }

    private loadData(result: ApiResponseModel<SearchResponseModel<TList, TId>>, isFromPoll = false): void {
        if (!isFromPoll && this.totalRecords !== result.value?.totalRecords) {
            this.paginator.firstPage();
        }

        this.data.set(result.value?.records ?? []);

        this.isLoading = false;
        this.totalRecords = result.value?.totalRecords ?? 0;

        this.dataLoadedSubject.next(true);
        this.afterLoadedCallback();
    }
}
