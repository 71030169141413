import { inject, SimpleChanges, Directive, OnChanges, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';

import { ActionButtonModel } from '../../core/models/action-button.model';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { Identifyable } from '../../core/abstractions/identifyable';

@Directive()
export abstract class BaseButtonsBarComponent<T extends Identifyable<TId>, TId> implements AfterViewInit, OnChanges {
    @Input() items: ActionButtonModel[] = [];
    @Input() shouldStopPropagation = false;

    visibleButtons: ActionButtonModel[] = [];
    menuButtons: ActionButtonModel[] = [];

    protected readonly _actionButtonsService = inject(ActionButtonsService);

    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    ngAfterViewInit(): void {
        this.setupItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['items'].firstChange && !!changes['items'].currentValue) {
            this.setupItems();
        }
    }

    onClick(evt: MouseEvent, btn: ActionButtonModel, data?: T): void {
        if (this.shouldStopPropagation) {
            evt.stopPropagation();
        }

        if (!!btn.clickFn) {
            btn.clickFn(btn, data);
        } else if (!!btn.key) {
            this._actionButtonsService.handleClick(btn.key, data);
        }
    }

    isLoading(key: string, itemId?: TId): boolean {
        const statusKey = this._actionButtonsService.getActionButtonLoadingKey(key, itemId);
        return this._actionButtonsService.loadingStatuses[statusKey] || this._actionButtonsService.getIsLoading(statusKey);
    }

    protected setupItems(data?: any, evaluateMultipleSelection = false): void {
        this.visibleButtons = [];
        this.menuButtons = [];
        this.items = this.items
            .filter(vb => this.isButtonVisible(vb.key, evaluateMultipleSelection, data))
            .sort((a, b) => a.order - b.order);

        if (this.items.length > 2) {
            this.visibleButtons = [...this.items.slice(0, 1)];
            this.menuButtons = [...this.items.slice(1)];
        } else {
            this.visibleButtons = [...this.items];
        }

        this.changeDetectorRef.detectChanges();
    }

    private isButtonVisible(key: string, evaluateMultipleSelection: boolean, data?: T): boolean {
        if (!evaluateMultipleSelection) {
            return !this._actionButtonsService.isHidden(key, data);
        }

        if (this._actionButtonsService.currentSelection.length === 0) {
            return !this._actionButtonsService.isHidden(key);
        }

        let isVisible = true;

        this._actionButtonsService.currentSelection.forEach(s => {
            if (this._actionButtonsService.isHidden(key, s)) {
                isVisible = false;
                return;
            }
        });

        return isVisible;
    }
}
