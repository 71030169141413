<form (submit)="quickAdd()" class="flex gap-4 mb-4 items-center flex-wrap">
    <arc-quick-search #quickAddArticleControl [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig" [(ngModel)]="quickAddArticleId"
        [disabled]="isAddingArticles()" (optionSelected)="quickAddArticleSelected()" label="Reservations.Edit.Article.Article"
        class="basis-[200px] grow shrink-0" [ngModelOptions]="{standalone: true}">
    </arc-quick-search>
    <arc-number-input #quickAddQuantityControl label="Reservations.Edit.Article.Quantity" [(ngModel)]="quickAddQuantity"
        class="basis-[150px] grow shrink-0" [disabled]="isAddingArticles()" [ngModelOptions]="{standalone: true}">
    </arc-number-input>

    <button type="submit" mat-icon-button [disabled]="isAddingArticles()">
        @if (isAddingArticles()) {
            <mat-spinner [diameter]="24"></mat-spinner>
        } @else {
            <mat-icon>add</mat-icon>
        }
    </button>
</form>

<div class="arc-reservation-articles-grid" cdkDropList (cdkDropListDropped)="drop($event)">
    <!-- title row -->
    <div class="grid grid-cols-subgrid col-span-full text-sm pb-2 border-b-2 border-solid border-app">
        <span></span>
        <span arcPortalTooltip="Reservations.Edit.Article.Article">{{ 'Reservations.Edit.Article.Article' | transloco }}</span>
        <span arcPortalTooltip="Reservations.Edit.Article.Quantity">{{ 'Reservations.Edit.Article.Quantity' | transloco }}</span>
        <span></span>
        <span arcPortalTooltip="Reservations.Edit.Article.PricePerUnit">{{ 'Reservations.Edit.Article.PricePerUnit' | transloco }}</span>
        <span arcPortalTooltip="Reservations.Edit.Article.Discount">{{ 'Reservations.Edit.Article.Discount' | transloco }}</span>
        <span></span>
        <span arcPortalTooltip="Reservations.Edit.Article.Total">{{ 'Reservations.Edit.Article.Total' | transloco }}</span>
        <span arcPortalTooltip="Reservations.Edit.Article.Remarks">{{ 'Reservations.Edit.Article.Remarks' | transloco }}</span>
        <span></span>
    </div>

    @for (article of reservationArticlesSorted(); track article.sort; let idx = $index) {
        <arc-reservation-edit-article-entry [article]="article" (update)="updateArticle($event)" (delete)="removeArticle(article)" cdkDrag
            class="arc-draggable-reservation-article" />
    }
</div>

<div class="w-full flex gap-4 justify-end mt-6">
    <arc-button type="stroked" (clicked)="updatePrices()" [isDisabled]="!canUpdatePrices()" [isLoading]="isRecalculating()"
        prefixIcon="sync" [matTooltip]="'Reservations.Edit.Article.UpdatePricesTooltip' | transloco"
        [matTooltipDisabled]="!canUpdatePrices()" matBadge="!" matBadgeColor="warn" [matBadgeHidden]="!canUpdatePrices()">
        {{ 'Reservations.Edit.Article.UpdatePrices' | transloco }}
    </arc-button>
    <arc-button type="stroked" (clicked)="openArticleSelectDialog()" [isLoading]="isAddingArticles()">
        {{ 'Reservations.Edit.Article.SelectArticle' | transloco }}
    </arc-button>
</div>
