<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyName" [control]="formGroup.controls.companyName" />
    <arc-address-picker class="col-span-12 mt-4" [formGroup]="formGroup.controls.companyAddress" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyVatNo" [control]="formGroup.controls.companyVatNo" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyUid" [control]="formGroup.controls.companyUid" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyPhoneNumber" [control]="formGroup.controls.companyPhoneNumber" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyFaxNumber" [control]="formGroup.controls.companyFaxNumber" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyEmail" [control]="formGroup.controls.companyEmail" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyWebsite" [control]="formGroup.controls.companyWebsite" />
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyHeadquartersPlace"
        [control]="formGroup.controls.companyHeadquartersPlace">
    </arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyJurisdictionPlace"
        [control]="formGroup.controls.companyJurisdictionPlace">
    </arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyCeoName" [control]="formGroup.controls.companyCeoName" />
</form>
