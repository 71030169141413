<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Extensions.Card.Description' | transloco }}</p>
        <p>{{ item().description }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Extensions.Card.NextExecutionTime' | transloco }}</p>
        <p>{{ item().nextExecutionTime | arcDate : 'short' }}</p>
    </div>
    @if(item().lastExecutionSuccess !== undefined){
        <div class="mb-4">
            <p class="text-on-app-light">{{ 'Extensions.Card.LastExecutionSuccess' | transloco }}</p>

            @if(item().lastExecutionSuccess){
                <mat-icon class="text-base !h-4 !w-4 mr-1 text-success">check_circle_outline</mat-icon>
                <span>{{ 'Extensions.Card.LastExecutionSuccessTrue' | transloco }}</span>
            } @else {
                <mat-icon class="text-base !h-4 !w-4 mr-1 text-error">error_outline</mat-icon>
                <span>{{ 'Extensions.Card.LastExecutionSuccessFalse' | transloco }}</span>
            }
        </div>
    }
</div>
