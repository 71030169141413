import { Component, inject, OnInit, signal } from '@angular/core';

import { InventoryArticleListModel } from '../../../../../models/responses/inventory-article-list.model';
import { InventoryArticleModel } from '../../../../../models/inventory-article.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InventoryScansStore } from '../../../../../services/stores/inventory-scans.store';
import { InventoryScanDataModel } from '../../../../../models/inventory-scan-data.model';
import { IconColumnModel } from '../../../../../../components/dynamic-table/models/column-types/icon-column.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { InventoryScanSourceEnum } from '../../../../../models/enums/inventory-scan-source.enum';
import { IconModel } from '../../../../../../core/models/icon.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';

@Component({
    selector: 'arc-inventory-article-scans.detail-widget',
    templateUrl: './inventory-article-scans.detail-widget.html',
    styleUrl: './inventory-article-scans.detail-widget.scss'
})
export class InventoryArticleScansDetailWidgetComponent
    extends BaseDetailWidget<InventoryArticleListModel, InventoryArticleModel, object>
    implements OnInit {
    readonly isLoading = signal(true);
    readonly items = signal<InventoryScanDataModel[]>([]);
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 4,
        availableColumns: [
            new IconColumnModel({
                columnTitleKey: 'InventoryArticles.Detail.Scans.Device',
                propertyName: 'inventoryScanSource',
                iconMapping: new Map([
                    [InventoryScanSourceEnum.App, new IconModel({ name: 'smartphone' })],
                    [InventoryScanSourceEnum.Backoffice, new IconModel({ name: 'computer' })]
                ]),
                widthPixels: 90
            }),
            new StringColumnModel({
                columnTitleKey: 'InventoryArticles.Detail.Scans.User',
                propertyName: 'userName',
                widthPixels: 90,
                isCompact: true
            }),
            new DateColumnModel({
                columnTitleKey: 'InventoryArticles.Detail.Scans.CreatedDate',
                propertyName: 'createdDate',
                widthPixels: 90,
                format: 'short',
                isCompact: true
            }),
            new StringColumnModel({
                columnTitleKey: 'InventoryArticles.Detail.Scans.Stock',
                propertyName: 'stock',
                widthPixels: 80,
                isCompact: true,
                customFormatter: (record, value) => (value > 0 ? '+' + value : value)
            })
        ]
    };

    private readonly inventorieScansStore = inject(InventoryScansStore);

    ngOnInit(): void {
        this.isLoading.set(true);
        this.inventorieScansStore.getScans(this.listModel().articleId, this.listModel().inventoryId).subscribe(r => {
            this.items.set(r.value || []);
            this.isLoading.set(false);
        });
    }
}
