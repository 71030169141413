<div class="h-full">
    <div class="h-full w-full">
        <mat-spinner *ngIf="isLoading; else chart" [diameter]="32"></mat-spinner>
        <ng-template #chart>
            <div *ngIf="!!chartData" class="h-full max-h-[170px]">
                <arc-chart [data]="chartData" type="bar"></arc-chart>
            </div>
            <div class="flex flex-row mt-4 justify-between w-full">
                <div>
                    <span class="text-on-app-light text-sm">{{'Widgets.ArticleTurnover.Last12MonthsTurnover' | transloco}}</span>
                    <div class="mt-2 text-xl">{{ currentData?.last12MonthsTotalTurnover | arcCurrency }}</div>
                </div>
                <div>
                    <span class="text-on-app-light text-sm">{{'Widgets.ArticleTurnover.Last12MonthsQuantity' | transloco}}</span>
                    <div class="mt-2 text-xl">{{ currentData?.last12MonthsTotalQuantity | arcNumber : 0 }}</div>
                </div>
                <div>
                    <span class="text-on-app-light text-sm">{{'Widgets.ArticleTurnover.TotalTurnover' | transloco}}</span>
                    <div class="mt-2 text-xl">{{ currentData?.grandTotal | arcCurrency }}</div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
