<div class="h-full w-full flex flex-col">
    <div class="w-full overflow-auto grid grid-cols-12 gap-2">
        <div class="flex flex-col col-span-6">
            <div class="p-2 bg-gray text-black">
                <div class="text-xs">{{ 'Invoices.Detail.Details.InvoiceAmount' | transloco }}</div>
                <div class="text-xl">{{ entityModel().amount | arcCurrency }}</div>
            </div>
        </div>
        <div class="flex flex-col col-span-6">
            <div class="p-2 bg-primary text-on-primary">
                <div class="text-xs">{{ 'Invoices.Detail.Details.OpenAmount' | transloco }}</div>
                <div class="text-xl">{{ entityModel().openAmount | arcCurrency }}</div>
            </div>
        </div>
    </div>
    <div class="w-full mt-6 grid grid-cols-12 gap-4">
        <!--First column-->
        <div class="flex flex-col col-span-4">
            <div>
                <p class="text-on-app-light">{{ 'Invoices.Detail.Details.IssuedDate' | transloco }}</p>
                <p class="text-xl">{{ entityModel().issuedDate | arcDate }}</p>
            </div>
            @if(!!entityModel().reminderDate1){
                <div class="mt-6">
                    <p class="text-on-app-light">{{ 'Invoices.Detail.Details.ReminderDate1' | transloco }}</p>
                    <p class="text-xl">{{ entityModel().reminderDate1 | arcDate }}</p>
                </div>
            }
        </div>
        <!--Second column-->
        <div class="flex flex-col col-span-4">
            <div>
                <p class="text-on-app-light">{{ 'Invoices.Detail.Details.DateSent' | transloco }}</p>
                <p class="text-xl">{{ entityModel().dateSent | arcDate }}</p>
            </div>
            @if(!!entityModel().reminderDate2){
                <div class="mt-6">
                    <p class="text-on-app-light">{{ 'Invoices.Detail.Details.ReminderDate2' | transloco }}</p>
                    <p class="text-xl">{{ entityModel().reminderDate2 | arcDate }}</p>
                </div>
            }
        </div>
        <!--Third column-->
        <div class="flex flex-col col-span-4">
            <div>
                <p class="text-on-app-light">{{ 'Invoices.Detail.Details.DueDate' | transloco }}</p>
                <p class="text-xl" [class.text-error]="entityModel().dueDate < today">
                    {{ entityModel().dueDate | arcDate }}
                </p>
            </div>
            @if(!!entityModel().datePaid){
                <div class="mt-6">
                    <p class="text-on-app-light">{{ 'Invoices.Detail.Details.DatePaid' | transloco }}</p>
                    <p class="text-xl">{{ entityModel().datePaid | arcDate }}</p>
                    <div class="inline-flex justify-center items-center py-1 px-2 rounded border border-solid border-current"
                        [ngClass]="paymentDiffClass()">
                        {{ paymentDiffDays() }} {{ paymentDiffTranslationKey() | transloco }}
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="grow w-full grid grid-cols-12">
        <div class="col-span-12 mt-auto">
            <div class="flex flex-row-reverse items-end gap-4">
                @if(primaryButton(); as primaryButton){
                    <arc-button [actionButtonKey]="primaryButton.key" [actionButtonTitleKey]="primaryButton.titleKey"
                        [currentData]="listModel()" color="accent" type="flat">
                    </arc-button>
                }
                @if(secondaryButton(); as secondaryButton){
                    <arc-button [actionButtonKey]="secondaryButton.key" [actionButtonTitleKey]="secondaryButton.titleKey"
                        [currentData]="listModel()" type="stroked">
                    </arc-button>
                }
            </div>
        </div>
    </div>
</div>
