<!-- filter -->
@if ((item().status !== OrderStatusEnum.Draft && item().status !== OrderStatusEnum.Suggestion) && (articlesDeviatingCount() > 0 || articlesNotDeliveredCount() > 0)) {
    <div class="flex justify-end">
        <mat-chip-listbox (change)="onFilterChanged($event)" #chipList [hideSingleSelectionIndicator]="true">
            <mat-chip-option value="all">
                <span>{{ 'Orders.Edit.Articles.All' | transloco }} ({{ articlesTotalCount() }})</span>
            </mat-chip-option>
            @if (articlesDeviatingCount() > 0) {
                <mat-chip-option value="deviating" [class.!bg-warn.!text-on-warn]="chipList.value === 'deviating'"
                    [class.!bg-warn-light.!text-on-warn-light]="chipList.value !== 'deviating'">
                    <span>{{ 'Orders.Edit.Articles.Deviating' | transloco }} ({{ articlesDeviatingCount() }})</span>
                </mat-chip-option>
            }
            @if (articlesNotDeliveredCount() > 0) {
                <mat-chip-option value="notDelivered" color="warn" [class.!bg-error-light]="chipList.value !== 'notDelivered'">
                    <span>{{ 'Orders.Edit.Articles.NotDelivered' | transloco }} ({{ articlesNotDeliveredCount() }})</span>
                </mat-chip-option>
            }
        </mat-chip-listbox>
    </div>
}

@if (item().status === OrderStatusEnum.Draft || item().status === OrderStatusEnum.Suggestion) {
    <arc-order-article-quick-add [order]="item()" [orderArticlesControl]="formGroup.controls.orderArticles"></arc-order-article-quick-add>
}

@if (!!tableConfig) {
    @if(isCalculatingArticles()) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    } @else {
        <arc-editable-table [config]="tableConfig" [formControl]="formGroup.controls.orderArticles" [filterPredicate]="filterPredicate">
        </arc-editable-table>
    }
}
