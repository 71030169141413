<div class="flex justify-end mb-4">
    <arc-button color="primary" (clicked)="accordion().openAll()">{{'Roles.Edit.Permissions.ExpandAll' | transloco}}</arc-button>
    <arc-button color="primary" (clicked)="accordion().closeAll()">{{'Roles.Edit.Permissions.CollapseAll' | transloco}}</arc-button>
</div>
<mat-accordion #detailedPermissions multi>
    @for(key of data | async; track key) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>{{key}}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group fitInkBarToContent [selectedIndex]="getPermissionTabIndex(key)">
                <mat-tab [label]="'Roles.Edit.Permissions.General' | transloco">
                    <div class="mt-4 px-16 overflow-x-hidden">
                        <arc-selection-slider [options]="permissionSliderOptions" [ngModel]="getGeneralPermissionValue(key)"
                            (ngModelChange)="onGeneralPermissionChanged(key, $event)">
                        </arc-selection-slider>
                    </div>
                </mat-tab>
                <mat-tab [label]="'Roles.Edit.Permissions.Individual' | transloco">
                    <div class="mt-8">
                        @for (p of permissionGroups()[key]; track p) {
                            <span class="font-bold">{{p.title}}</span>
                            <div class="flex my-4">
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Read)"
                                    (change)="changeView(p, $event.checked)">
                                    {{'Roles.Edit.Permissions.View' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Create)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Create)">
                                    {{'Roles.Edit.Permissions.Create' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Update)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Update)">
                                    {{'Roles.Edit.Permissions.Edit' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Delete)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Delete)">
                                    {{'Roles.Edit.Permissions.Delete' | transloco}}
                                </mat-checkbox>
                            </div>
                        }
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-expansion-panel>
    }
</mat-accordion>
<mat-paginator [length]="permissionGroupsKeys().length" [pageSize]="5" [showFirstLastButtons]="true" [hidePageSize]="true">
</mat-paginator>
