import { Component, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserEditModel } from '../../../../../models/user-edit.model';
import { UserModel } from '../../../../../models/user.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { BlobsStore } from '../../../../../services/stores/blobs.store';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-user-profile-photo',
    templateUrl: './user-profile-photo.component.html',
    styleUrl: './user-profile-photo.component.scss'
})
export class UserProfilePhotoComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        profileImageBlobFileName: new ArcFormControl<OptionalType<string>>(undefined),
        profileImageBlobFileExtension: new ArcFormControl<OptionalType<string>>(undefined)
    });
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    imageSrc = '';

    get profileImage(): string {
        return Utils.getBase64ImageSrc(this.imageSrc);
    }

    private readonly blobsStore = inject(BlobsStore);

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(file => {
            if (!!file) {
                Utils.blobToBase64(file).subscribe(resp => {
                    this.formGroup.controls.profileImageBlobFileName.setValue(resp);
                    this.formGroup.controls.profileImageBlobFileExtension.setValue(file.name.split('.').pop());
                    this.formGroup.controls.profileImageBlobFileName.markAsDirty();
                    this.formGroup.controls.profileImageBlobFileExtension.markAsDirty();
                    this.imageSrc = resp;
                    this.fileControl.setValue(undefined);
                });
            }
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item());
        if (this.formGroup.value.profileImageBlobFileName) {
            this.blobsStore.getFileByName(this.formGroup.value.profileImageBlobFileName!).subscribe(res => (this.imageSrc = res.value!));
        }
    }
    override prepareSaveModel(): Partial<UserEditModel> {
        const value = this.formGroup.value;
        return {
            profileImageBlobFileName: value.profileImageBlobFileName,
            profileImageBlobFileExtension: value.profileImageBlobFileExtension
        };
    }
}
